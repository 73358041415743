import DashService from '../service/DashService';

/*-- Action Creators for Trips Fetch--*/
export const REQUEST_TRIPS = 'REQUEST_ALL_TRIPS';
export const requestTrips = () => ({
  type: REQUEST_TRIPS
});

export const RECEIVE_TRIPS_SUCCESS = 'RECEIVE_TRIPS_SUCCESS';
export const receiveTripsSuccess = payload => ({
  type: RECEIVE_TRIPS_SUCCESS,
  payload
});

export const RECEIVE_TRIPS_FAILURE = 'RECEIVE_TRIPS_FAILURE';
export const receiveTripsFailure = payload => ({
  type: RECEIVE_TRIPS_FAILURE,
  payload
});

export function fetchTrips(payload, token) {
  return dispatch => {
    dispatch(requestTrips());

    return DashService.getTrips(payload, token)
      .then(res => {
        if (!res.data || (res.returnCode && res.returnCode !== 0)) {
          dispatch(receiveTripsFailure(res));
        } else {
          dispatch(receiveTripsSuccess(res));
        }

        return res;
      })
      .catch(err => dispatch(receiveTripsFailure(err)));
  };
}

export function fetchSharedTrips(payload, token) {
  return dispatch => {
    dispatch(requestTrips());

    return DashService.getSharedTrips(payload, token)
      .then(res => {
        let checkResponseError = false;
        if (!res.returnCode) {
          const result = JSON.parse(JSON.stringify(res));
          checkResponseError = result.response && result.response.status && result.response.status === 403;
        }

        if (checkResponseError || (res.returnCode && res.returnCode !== 0)) {
          dispatch(receiveTripsFailure(res));
        } else {
          dispatch(receiveTripsSuccess(res));
        }
        return res;
      })
      .catch(err => dispatch(receiveTripsFailure(err)));
  };
}

export function fetchPCACTrips(data, token) {
  return dispatch => {
    dispatch(requestTrips());
    return DashService.searchPCACTrips(token, data)
      .then(res => {
        let checkResponseError = false;
        if (!res.returnCode) {
          const result = JSON.parse(JSON.stringify(res));
          checkResponseError = result.response && result.response.status && result.response.status === 403;
        }

        if (checkResponseError || (res.returnCode && res.returnCode !== 0)) {
          dispatch(receiveTripsFailure(res));
        } else {
          dispatch(receiveTripsSuccess(res));
        }
        return res;
      })
      .catch(err => dispatch(receiveTripsFailure(err)));
  };
}

export const EDIT_PCAC_TRIPS_SUCCESS = 'EDIT_PCAC_TRIPS_SUCCESS';
export const editPCACTripsSuccess = payload => ({
  type: EDIT_PCAC_TRIPS_SUCCESS,
  payload
});

export const EDIT_PCAC_TRIPS_FAILURE = 'EDIT_PCAC_TRIPS_FAILURE';
export const editPCACTripsFailure = payload => ({
  type: EDIT_PCAC_TRIPS_FAILURE,
  payload
});

export const VIEW_PCAC_TRIPS_FAILURE = 'VIEW_PCAC_TRIPS_FAILURE';
export const viewPCACTripsFailure = payload => ({
  type: VIEW_PCAC_TRIPS_FAILURE,
  payload
});

export function editPCACTrip(tripId, token) {
  return dispatch => {
    return DashService.editPCACTrip(token, tripId)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(editPCACTripsSuccess(res));
        } else {
          dispatch(editPCACTripsFailure(res));
        }
        return res;
      })
      .catch(err => dispatch(editPCACTripsFailure(err)));
  };
}

export function viewPCACTrip(tripId, token) {
  return dispatch => {
    return DashService.viewPCACTrip(token, tripId)
      .then(res => {
        if (res.returnCode !== 0) {
          dispatch(viewPCACTripsFailure(res));
        }
        return res;
      })
      .catch(err => dispatch(viewPCACTripsFailure(err)));
  };
}

export const PUBLISH_TRIP_FAILURE = 'PUBLISH_TRIP_FAILURE';
export const publishTripFailure = data => ({
  type: PUBLISH_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});
export const PUBLISH_TRIP_SUCCESS = 'PUBLISH_TRIP_SUCCESS';
export const publishTripSuccess = data => ({
  type: PUBLISH_TRIP_SUCCESS,
  payload: data,
  msg: data.msg
});

export function publishTrip(data, token) {
  return dispatch => {
    return DashService.publishTrip(data, token)
      .then(res => {
        dispatch(publishTripSuccess(res));
        return res;
      })
      .catch(err => {
        dispatch(publishTripFailure(err));
      });
  };
}

/*-- Action Manage for Collaborators--*/
export const RECEIVE_COLLABORATORS_FAILURE = 'RECEIVE_COLLABORATORS_FAILURE';
export const receiveCollaboratorsFailure = data => ({
  type: RECEIVE_COLLABORATORS_FAILURE,
  payload: data,
  msg: data.msg
});

export function getCollaborators(tripId, timeZone, token) {
  return dispatch => {
    return DashService.getCollaborators(tripId, timeZone, token)
      .then(res => res)
      .catch(err => dispatch(receiveCollaboratorsFailure(err)));
  };
}

export const RECEIVE_USER_FIND_FAILURE = 'RECEIVE_USER_FIND_FAILURE';
export const receiveUserFindFailure = data => ({
  type: RECEIVE_USER_FIND_FAILURE,
  payload: data,
  msg: data.msg
});

export function getFindUser(tripId, searchName, token) {
  return dispatch => {
    return DashService.findUser(tripId, searchName, token)
      .then(res => {
        if (res.returnCode === 6) dispatch(receiveUserFindFailure(res));
        return res;
      })
      .catch(err => dispatch(receiveUserFindFailure(err)));
  };
}

export const ADD_UPDATE_COLLABORATOR_SUCCESS = 'ADD_UPDATE_COLLABORATOR_SUCCESS';
export const addUpdateCollaboratorSuccess = payload => ({
  type: ADD_UPDATE_COLLABORATOR_SUCCESS,
  payload
});

export const ADD_UPDATE_COLLABORATOR_FAILURE = 'ADD_UPDATE_COLLABORATOR_FAILURE';
export const addUpdateCollaboratorFailure = data => ({
  type: ADD_UPDATE_COLLABORATOR_FAILURE,
  payload: data,
  msg: data.msg
});

export function addUpdateCollaborator(inTripId, isAdding, collaborator, token) {
  return dispatch => {
    if (isAdding) {
      return DashService.addCollaborator(inTripId, collaborator, token)
        .then(res => {
          if (res.returnCode === 0) {
            const result = String(res.msg).split('-');
            dispatch(
              addUpdateCollaboratorSuccess({
                returnCode: res.returnCode,
                msg: result[0].trim(),
                severityLevel: res.severityLevel
              })
            );
            return res;
          } else {
            return dispatch(addUpdateCollaboratorFailure(res));
          }
        })
        .catch(err => {
          dispatch(addUpdateCollaboratorFailure(err));
        });
    } else {
      return DashService.updateCollaborator(inTripId, collaborator, token)
        .then(res => {
          res.returnCode === 0
            ? dispatch(addUpdateCollaboratorSuccess(res))
            : dispatch(addUpdateCollaboratorFailure(res));
          return res;
        })
        .catch(err => {
          dispatch(addUpdateCollaboratorFailure(err));
        });
    }
  };
}

export const REMOVE_COLLABORATOR_SUCCESS = 'REMOVE_COLLABORATOR_SUCCESS';
export const removeCollaboratorSuccess = payload => ({
  type: REMOVE_COLLABORATOR_SUCCESS,
  payload
});

export const REMOVE_COLLABORATOR_FAILURE = 'REMOVE_COLLABORATOR_FAILURE';
export const removeCollaboratorFailure = data => ({
  type: REMOVE_COLLABORATOR_FAILURE,
  payload: data,
  msg: data.msg
});

export function removeCollaborator(inTripId, collaborator, token) {
  return dispatch => {
    return DashService.removeCollaborator(inTripId, collaborator, token)
      .then(res => {
        res.returnCode === 0 ? dispatch(removeCollaboratorSuccess(res)) : dispatch(removeCollaboratorFailure(res));
        return res;
      })
      .catch(err => {
        dispatch(removeCollaboratorFailure(err));
      });
  };
}

export const RESEND_INVITE_COLLABORATOR_SUCCESS = 'RESEND_INVITE_COLLABORATOR_SUCCESS';
export const resendInviteCollaboratorSuccess = payload => ({
  type: RESEND_INVITE_COLLABORATOR_SUCCESS,
  payload
});

export const RESEND_INVITE_COLLABORATOR_FAILURE = 'RESEND_INVITE_COLLABORATOR_FAILURE';
export const resendInviteCollaboratorFailure = data => ({
  type: RESEND_INVITE_COLLABORATOR_FAILURE,
  payload: data,
  msg: data.msg
});

export function resendInviteCollaborator(inTripId, collaborator, token) {
  return dispatch => {
    return DashService.resendInviteCollaborator(inTripId, collaborator, token)
      .then(res => {
        res.returnCode === 0
          ? dispatch(resendInviteCollaboratorSuccess(res))
          : dispatch(resendInviteCollaboratorFailure(res));
        return res;
      })
      .catch(err => {
        dispatch(resendInviteCollaboratorFailure(err));
      });
  };
}

/*-- Action Manage for Travellers--*/

export const RECEIVE_TRAVELLERS_FAILURE = 'RECEIVE_TRAVELLERS_FAILURE';
export const receiveTravellersFailure = payload => ({
  type: RECEIVE_TRAVELLERS_FAILURE,
  payload
});
export function getTravellers(tripId, timeZone, token) {
  return dispatch => {
    return DashService.getTravellers(tripId, timeZone, token)
      .then(res => res)
      .catch(err => dispatch(receiveTravellersFailure(err)));
  };
}

export const ADD_TRAVELER_SUCCESS = 'ADD_TRAVELER_SUCCESS';
export const addTravelerSuccess = (payload, message) => ({
  type: ADD_TRAVELER_SUCCESS,
  payload
});

export const ADD_TRAVELER_FAILURE = 'ADD_TRAVELER_FAILURE';
export const addTravelerFailure = data => ({
  type: ADD_TRAVELER_FAILURE,
  payload: data,
  msg: data.msg
});

export function addTraveler(tripId, travelerObj, token) {
  return dispatch => {
    return DashService.addTraveler(tripId, travelerObj, token)
      .then(res => {
        if (res.returnCode === 0) {
          const result = String(res.msg).split('-');
          dispatch(
            addTravelerSuccess({ returnCode: res.returnCode, msg: result[0].trim(), severityLevel: res.severityLevel })
          );
          return res;
        } else {
          return dispatch(addTravelerFailure(res));
        }
      })
      .catch(err => {
        dispatch(addTravelerFailure(err));
      });
  };
}
export const REMOVE_TRAVELER_SUCCESS = 'REMOVE_TRAVELER_SUCCESS';
export const removeTravelerSuccess = payload => ({
  type: REMOVE_TRAVELER_SUCCESS,
  payload
});

export const REMOVE_TRAVELER_FAILURE = 'REMOVE_TRAVELER_FAILURE';
export const removeTravelerFailure = data => ({
  type: REMOVE_TRAVELER_FAILURE,
  payload: data,
  msg: data.msg
});

export function removeTraveler(tripId, travelerObj, token) {
  return dispatch => {
    return DashService.removeTraveler(tripId, travelerObj, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(removeTravelerSuccess(res));
          return res;
        } else {
          return dispatch(removeTravelerFailure(res));
        }
      })
      .catch(err => {
        dispatch(removeTravelerFailure(err));
      });
  };
}

/*-- Action Creators for Trip Manage --*/

export const ADD_TRIP_SUCCESS = 'ADD_TRIP_SUCCESS';
export const addTripSuccess = payload => ({
  type: ADD_TRIP_SUCCESS,
  payload
});
export const UPDATE_TRIP_SUCCESS = 'UPDATE_TRIP_SUCCESS';
export const UpdateTripSuccess = payload => ({
  type: UPDATE_TRIP_SUCCESS,
  payload
});

export const ADD_TRIP_FAILURE = 'ADD_TRIP_FAILURE';
export const addTripFailure = data => ({
  type: ADD_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export const UPDATE_TRIP_FAILURE = 'UPDATE_TRIP_FAILURE';
export const UpdateTripFailure = data => ({
  type: UPDATE_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export const DELETE_TRIP_FAILURE = 'DELETE_TRIP_FAILURE';
export const deleteTripFailure = data => ({
  type: DELETE_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export const DELETE_TRIP_SUCCESS = 'DELETE_TRIP_SUCCESS';
export const deleteTripSuccess = payload => ({
  type: DELETE_TRIP_SUCCESS,
  payload
});

export const DUPLICATE_TRIP_SUCESS = 'DUPLICATE_TRIP_SUCESS';
export const duplicateTripSuccess = payload => ({
  type: DUPLICATE_TRIP_SUCESS,
  payload
});

export const DUPLICATE_TRIP_FAILURE = 'DUPLICATE_TRIP_FAILURE';
export const duplicateTripFailure = data => ({
  type: DUPLICATE_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export function addUpdateTrip(tripObj, token) {
  return dispatch => {
    return DashService.addUpdateTrip(tripObj, token)
      .then(res => {
        if (res && res.returnCode === 0) {
          const result = String(res.msg).split('-');
          tripObj.inTripId
            ? dispatch(
                UpdateTripSuccess({
                  returnCode: res.returnCode,
                  msg: result[0].trim(),
                  severityLevel: res.severityLevel
                })
              )
            : dispatch(
                addTripSuccess({ returnCode: res.returnCode, msg: result[0].trim(), severityLevel: res.severityLevel })
              );
          return res;
        } else {
          return tripObj.inTripId
            ? dispatch(UpdateTripFailure({ msg: 'errorUpdatingTrip' }))
            : dispatch(addTripFailure({ msg: `${res.msg.split(' - ')[1]}. ${res.msg.split(' - ')[2]}` }));
        }
      })
      .catch(err => {
        tripObj.inTripId ? dispatch(UpdateTripFailure(err)) : dispatch(addTripFailure(err));
      });
  };
}

export function deleteTrip(tripId, token) {
  return dispatch => {
    return DashService.deleteTrip(tripId, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(deleteTripSuccess(res));
        } else {
          dispatch(deleteTripFailure(res));
        }
        return res;
      })
      .catch(err => {
        dispatch(deleteTripFailure(err));
      });
  };
}

export function duplicateTrip(tripId, inTripName, token) {
  return dispatch => {
    return DashService.duplicateTrip(tripId, inTripName, token)
      .then(res => {
        if (res.returnCode === 0) {
          const result = String(res.msg).split('-');
          dispatch(
            duplicateTripSuccess({
              returnCode: res.returnCode,
              msg: result[0].trim(),
              severityLevel: res.severityLevel
            })
          );
        } else {
          dispatch(duplicateTripFailure(res));
        }
        return res;
      })
      .catch(err => {
        dispatch(duplicateTripFailure(err));
      });
  };
}

/*-- Action Trip Brand --*/

export const GET_TRIP_BRANDING_FAILURE = 'GET_TRIP_BRANDING_FAILURE';
export const getTripBrandingFailure = data => ({
  type: GET_TRIP_BRANDING_FAILURE,
  payload: data,
  msg: data.msg
});

export function getTripBranding(tripId, token) {
  return dispatch => {
    return DashService.getTripBranding(tripId, token)
      .then(res => res)
      .catch(err => {
        dispatch(getTripBrandingFailure(err));
      });
  };
}

export const TOGGLE_BRAND_TRIP_SUCCESS = 'TOGGLE_BRAND_TRIP_SUCCESS';
export const toggleBrandTripSuccess = payload => ({
  type: TOGGLE_BRAND_TRIP_SUCCESS,
  payload
});

export const TOGGLE_BRAND_TRIP_FAILURE = 'TOGGLE_BRAND_TRIP_FAILURE';
export const toggleBrandTripFailure = data => ({
  type: TOGGLE_BRAND_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export function toggleBrandTrip(tripId, tripObj, token) {
  return dispatch => {
    return DashService.toggleBrandTrip(tripId, tripObj, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(toggleBrandTripSuccess(res));
          return res;
        } else {
          return dispatch(toggleBrandTripFailure(res));
        }
      })
      .catch(err => {
        dispatch(toggleBrandTripFailure(err));
      });
  };
}

export const TOGGLE_MAIN_BRAND_TRIP_SUCCESS = 'TOGGLE_MAIN_BRAND_TRIP_SUCCESS';
export const toggleMainBrandTripSuccess = payload => ({
  type: TOGGLE_MAIN_BRAND_TRIP_SUCCESS,
  payload
});
export const TOGGLE_MAIN_BRAND_TRIP_FAILURE = 'TOGGLE_MAIN_BRAND_TRIP_FAILURE';
export const toggleMainBrandTripFailure = data => ({
  type: TOGGLE_MAIN_BRAND_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export function toggleMainBrandTrip(tripId, tripObj, token) {
  return dispatch => {
    if (!tripObj.isEnable) {
      tripObj.inEnabled = true;
      return DashService.toggleBrandTrip(tripId, tripObj, token).then(res => {
        if (res.returnCode === 0) {
          return DashService.toggleMainBrandTrip(tripId, tripObj, token)
            .then(res => {
              if (res.returnCode === 0) {
                dispatch(toggleMainBrandTripSuccess(res));
                return res;
              } else {
                tripObj.inEnabled = false; //Reverting changes
                DashService.toggleBrandTrip(tripId, tripObj, token).then(res => res);
                return dispatch(toggleMainBrandTripFailure(res));
              }
            })
            .catch(err => {
              tripObj.inEnabled = false; //Reverting changes
              DashService.toggleBrandTrip(tripId, tripObj, token).then(res => res);
              return dispatch(toggleMainBrandTripFailure(err));
            });
        } else {
          return dispatch(toggleMainBrandTripFailure(res));
        }
      });
    } else {
      return DashService.toggleMainBrandTrip(tripId, tripObj, token)
        .then(res => {
          if (res.returnCode === 0) {
            dispatch(toggleMainBrandTripSuccess(res));
            return res;
          } else {
            return dispatch(toggleMainBrandTripFailure(res));
          }
        })
        .catch(err => {
          return dispatch(toggleMainBrandTripFailure(err));
        });
    }
  };
}

/*-- Action Activity Logs for Trip Manage --*/

export const REQUEST_ACTIVITY_LOGS_TRIP_FAILURE = 'REQUEST_ACTIVITY_LOGS_TRIP_FAILURE';
export const requestActivityLogsTripFailure = data => ({
  type: REQUEST_ACTIVITY_LOGS_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export function getActivityTripLog(tripId, start, max, timeZoneOffSet, token) {
  return dispatch => {
    return DashService.getActivityTripLog(tripId, start, max, timeZoneOffSet, token)
      .then(res => res)
      .catch(err => {
        dispatch(requestActivityLogsTripFailure(err));
      });
  };
}

export const AUTOPICK_COVER_TRIP_SUCESS = 'AUTOPICK_COVER_TRIP_SUCESS';
export const autoPickCoverTripSucess = data => ({
  type: AUTOPICK_COVER_TRIP_SUCESS,
  payload: data,
  msg: data.msg
});
export const AUTOPICK_COVER_TRIP_FAILURE = 'AUTOPICK_COVER_TRIP_FAILURE';
export const autoPickCoverTripFailure = data => ({
  type: AUTOPICK_COVER_TRIP_FAILURE,
  payload: data,
  msg: data.msg
});

export function addCoverTripAuto(data, token) {
  return dispatch => {
    return DashService.addCoverTripAuto(data, token)
      .then(res => {
        if (res.returnCode === 0) dispatch(autoPickCoverTripSucess(res));
        else dispatch(autoPickCoverTripFailure(res));
        return res;
      })
      .catch(err => {
        dispatch(autoPickCoverTripFailure(err));
      });
  };
}

export const GDS_IMPORT_SUCCESSS = 'GDS_IMPORT_SUCCESSS';
export const importGdsSuccessful = data => ({
  type: GDS_IMPORT_SUCCESSS,
  payload: data,
  msg: data.msg
});

export const GDS_IMPORT_FAILURE = 'GDS_IMPORT_FAILURE';
export const importGdsFailure = data => ({
  type: GDS_IMPORT_FAILURE,
  payload: data,
  msg: data.msg
});

export function importGds(data, token) {
  return dispatch => {
    return DashService.parseGDSImport(data.inTripId, data, token)
      .then(res => {
        const resp = !res.returnCode ? res.replace(/<[^>]+>/g, '').trim() : null;
        if (resp && resp.includes('imported')) {
          dispatch(importGdsSuccessful({ res, msg: resp }));
        } else {
          if (res.returnCode) dispatch(importGdsFailure(res));
          else dispatch(importGdsFailure({ res, msg: resp }));
        }

        return res;
      })
      .catch(err => {
        dispatch(importGdsFailure(err));
      });
  };
}

/*-- Action to Guide Import --*/
export const IMPORT_AFAR_SUCCESS = 'IMPORT_AFAR_SUCCESS';
export const IMPORT_AFAR_FAILURE = 'IMPORT_AFAR_FAILURE';
export const importAfarSuccess = data => ({
  type: IMPORT_AFAR_SUCCESS,
  payload: data,
  msg: data.msg
});
export const importAfarFailure = data => ({
  type: IMPORT_AFAR_FAILURE,
  payload: data,
  msg: data.msg
});

export function addAfarGuideSelectedToContent(tripId, data, token) {
  return dispatch => {
    return DashService.addAfarSelectedToContent(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) dispatch(importAfarSuccess(res));
        else dispatch(importAfarFailure(res));
        return res;
      })
      .catch(message => dispatch(importAfarFailure(message)));
  };
}

export function addAfarPointsToBooking(tripId, data, token) {
  return dispatch => {
    return DashService.addAfarPointsToBooking(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) dispatch(importAfarSuccess(res));
        else dispatch(importAfarFailure(res));
        return res;
      })
      .catch(message => dispatch(importAfarFailure(message)));
  };
}

export const ADD_WCITIES_SUCCESS = 'ADD_WCITIES_SUCCESS';
export const ADD_WCITIES_FAILURE = 'ADD_WCITIES_FAILURE';
export const addWCitiesSuccess = data => ({
  type: ADD_WCITIES_SUCCESS,
  payload: data,
  msg: data.msg
});
export const addWCitiesFailure = data => ({
  type: ADD_WCITIES_FAILURE,
  payload: data,
  msg: data.msg
});

export function addAllWCities(tripId, token) {
  return dispatch => {
    return DashService.addWAllCitiesGuide(tripId, token).then(res => {
      if (res.returnCode === 0) dispatch(addWCitiesSuccess(res));
      else dispatch(addWCitiesFailure(res));
      return res;
    });
  };
}

export function addSelectedWCities(tripId, data, token) {
  return dispatch => {
    return DashService.addSelectedWCities(tripId, data, token).then(res => {
      if (res.returnCode === 0) dispatch(addWCitiesSuccess(res));
      else dispatch(addWCitiesFailure(res));
      return res;
    });
  };
}
