export const INITIAL_STATE = 'hidden';
export const ANIMATING_STATE = 'visible';
export const EXIT_STATE = 'exit';

export const CARD_ANIMATIONS = {
  [INITIAL_STATE]: {
    opacity: 0,
    y: -10
  },
  [ANIMATING_STATE]: {
    opacity: 1,
    y: 0,
    transition: {
      y: { type: 'spring', damping: 10, velocity: 500 },
      opacity: { duration: 0.8 }
    }
  }
};

export const CARD_LIST_ANIMATIONS = {
  [INITIAL_STATE]: {},
  [ANIMATING_STATE]: { transition: { staggerChildren: 0.3 } }
};

export const HEIGHT_TOGGLE_ANIMATION = {
  [INITIAL_STATE]: {
    height: 0,
    transition: {
      height: {
        // ease: [0.4, 0.0, 0.2, 1],
        duration: 0.1,
        type: 'spring',
        damping: 20
      }
    }
  },
  [ANIMATING_STATE]: {
    height: 'auto',
    transition: {
      height: {
        // ease: [0.4, 0.0, 0.2, 1],
        duration: 0.1,
        type: 'spring',
        damping: 10
      }
    }
  }
};

export const SLIDE_ANIMATIONS = {
  [INITIAL_STATE]: (direction) => {
    return {
      opacity: 0,
      x: direction >= 0 ? -1000 : 1000
    };
  },
  [ANIMATING_STATE]: {
    position: 'relative',
    zIndex: 100,
    opacity: 1,
    x: 0,
    width: '100%',
    transition: {
      // x: { type: 'spring', damping: 10, velocity: 500 },
      // opacity: { duration: 0.8 }
      x: { type: 'spring', stiffness: 300, damping: 30, duration: 0.2 },
      opacity: { duration: 0.2 }
    }
  },
  [EXIT_STATE]: (direction) => {
    return {
      zIndex: 10,
      opacity: 0,
      x: direction >= 0 ? -1000 : 1000,
      width: 0,
      position: 'absolute'
    };
  }
};

export const PARENT_SLIDE_ANIMATIONS = {
  [INITIAL_STATE]: {},
  [ANIMATING_STATE]: { transition: { staggerChildren: 0.3 } }
};
