import React from 'react';
// import ShippingLeads from '../ShippingLeads/ShippingLeads';
// import ShipmentRates from '../shipment_rates/ShipmentRates';
// import MakeOffer from '../ShippingLeads/MakeOffer/MakeOffer';
// import ChatMakeOffer from '../Chat/ChatViewPane/OfferChat/ChatMakeOffer/ChatMakeOffer';
// import SmartDocuments from '../SmartDocuments/SmartDocuments';
// import MyShipper from '../MyShipper/MyShipper';
// import ShipperDetailForm from '../MyShipper/AddShipperForm/ShipperDetailForm/ShipperDetailForm'
// import ShipperManagement from '../MyShipper/ShipperManagement/ShipperManagement';
// import MyServices from '../MyServices/MyServices';
// import MobileChatView from '../Chat/MobileChat/MobileChatView/MobileChatView';
// import MobileShipmentDetails from '../Chat/MobileChat/MobileShipmentDetails/MobileShipmentDetails';
// import MobileChat from '../Chat/MobileChat/MobileChat';
// import SelectShipment from '../MyShipper/CreateShipment/SelectShipment';
// import EnterDetails from '../MyShipper/CreateShipment/SelectShipment/EnterDetails/EnterDetails';
// import CandC from '../MyShipper/CreateShipment/SelectShipment/c_and_c/CandC';
// import EnterCompanyDetails from '../Billing/CompanyDashboard/EnterCompanyDetails/EnterCompanyDetails';
// import EnterBankDetails from '../Billing/CompanyDashboard/EnterBankDetails/EnterBankDetails';

import { userPermission } from 'util/forwarderPermissionRoles';
import AccountCmpyLinkType from 'model/enum/AccountCmpyLinkType';


const ShipmentRates = React.lazy(() => import('../shipment_rates/ShipmentRates'));
const MakeOffer = React.lazy(() => import('../ShippingLeads/MakeOffer/MakeOffer'));
const ChatMakeOffer = React.lazy(() => import('../Chat/ChatViewPane/OfferChat/ChatMakeOffer/ChatMakeOffer'));
const SmartDocuments = React.lazy(() => import('../SmartDocuments/SmartDocuments'));
const MyShipper = React.lazy(() => import('../MyShipper/MyShipper'));
const ShipperDetailForm = React.lazy(() => import('../MyShipper/AddShipperForm/ShipperDetailForm/ShipperDetailForm'));
const ShipperManagement = React.lazy(() => import('../MyShipper/ShipperManagement/ShipperManagement'));
const MyServices = React.lazy(() => import('../MyServices/MyServices'));
const MobileChatView = React.lazy(() => import('../Chat/MobileChat/MobileChatView/MobileChatView'));
const MobileShipmentDetails = React.lazy(() => import('../Chat/MobileChat/MobileShipmentDetails/MobileShipmentDetails'));
const MobileChat = React.lazy(() => import('../Chat/MobileChat/MobileChat'));
const SelectShipment = React.lazy(() => import('../MyShipper/CreateShipment/SelectShipment'));
const EnterDetails = React.lazy(() => import('../MyShipper/CreateShipment/SelectShipment/EnterDetails/EnterDetails'));
const CandC = React.lazy(() => import('../MyShipper/CreateShipment/SelectShipment/c_and_c/CandC'));
const EnterCompanyDetails = React.lazy(() => import('../Billing/CompanyDashboard/EnterCompanyDetails/EnterCompanyDetails'));
const EnterBankDetails = React.lazy(() => import('../Billing/CompanyDashboard/EnterBankDetails/EnterBankDetails'));
const ShipperDashboard = React.lazy(() => import('../Dashboard'));
const ShipperShipments = React.lazy(() => import('../Shipments'));
const ShipperProfile = React.lazy(() => import('../Profile'));
const ShipperSettings = React.lazy(() => import('../Settings'));
const ShipperTransactions = React.lazy(() => import('../Transactions'));
const ShipperProducts = React.lazy(() => import('../Products'));
const ShipperCustomers = React.lazy(() => import('../Customers'));
const ShipperQuotes = React.lazy(() => import('../Orders'));
const ShipperDocuments = React.lazy(() => import('../Document/Documents'));
const ShipperChat = React.lazy(() => import('../Chat'));
const ShipperCalendar = React.lazy(() => import('../Calendar'));
const ShipperReports = React.lazy(() => import('../Reports'));
const ShipperBilling = React.lazy(() => import('../Billing'));
const ShippingLeads = React.lazy(() => import('../ShippingLeads/ShippingLeads'));
// Freight Pages
const FreightHome = React.lazy(() => import('../Freight'));

//  Shipper Sub Pages
const ShipperShipmentDetails = React.lazy(() =>
  import('../Shipments/ShipmentDetails')
);
const ShipperAccountsPersonal = React.lazy(() =>
  import('../SettingsPersonalInfo')
);
const ShipperAccountsCompany = React.lazy(() =>
  import('../SettingsCompanyInfo')
);
const ShipperAccountsShipping = React.lazy(() =>
  import('../SettingsShippingInfo')
);
const ShipperAccountsPayments = React.lazy(() =>
  import('../SettingsSubscription')
);
const ShipperAccountsPrivacy = React.lazy(() => import('../SettingsPrivacy'));
const ShipperAccountsSecurity = React.lazy(() => import('../SettingsSecurity'));
const ShipperAccountsReferrals = React.lazy(() =>
  import('../SettingsReferrals')
);
const ShipmentDetail = React.lazy(() => import('../ShipmentDetail'))

const ProfileCompletionForm = React.lazy(() => import('../ProfileCompletionForm/ProfileCompletionForm'));

const DirectPayoutOnboardingConfirm = React.lazy(() => import("../Billing/DirectPayoutOnboardingConfirm"))


const RouteMaps = {






  '/direct-payout/onboarding/callback': {
    allowed: userPermission.billing_earnings.create,
    protected: true,
    exact: true,
    label: 'Direct Payout Onboarding Callback',
    primary: true,
    path: '/direct-payout/onboarding/callback',
    component: DirectPayoutOnboardingConfirm
  },







  // smart documents routes
  '/smart-documents': {
    allowed: userPermission.smart_documents.read,
    protected: true,
    exact: true,
    label: 'Smart Documents',
    primary: true,
    path: '/smart-documents',
    component: SmartDocuments
  },
  '/smart-documents/:route': {
    allowed: userPermission.smart_documents.read,
    protected: true,
    exact: true,
    label: 'Smart Documents',
    primary: true,
    path: '/smart-documents/:route',
    component: SmartDocuments
  },














  // my shipper routes
  '/shipper': {
    allowed: userPermission.my_shipper.read,
    protected: true,
    exact: true,
    label: 'My Shipper',
    primary: true,
    path: '/shipper',
    component: MyShipper
  },

  '/shipper/invite-form': {
    allowed: userPermission.my_shipper.create,
    protected: true,
    exact: true,
    label: 'My Shipper',
    primary: true,
    path: '/shipper/invite-form',
    component: ShipperDetailForm
  },

  '/shipper/:id': {
    allowed: userPermission.my_shipper_individual.read,
    protected: true,
    exact: true,
    label: 'My Shipper Management',
    primary: true,
    path: '/shipper/:id',
    component: ShipperManagement
  },






  // dashboard routes
  '/dashboard': {
    allowed: userPermission.dashboard.read,
    protected: true,
    exact: true,
    label: 'Dashboard',
    primary: true,
    path: '/dashboard',
    component: ShipperDashboard
  },









  //  shipment leads routes
  '/shipment-leads': {
    allowed: userPermission.shipment_leads.read,
    protected: true,
    exact: true,
    label: 'Shipment Leads',
    primary: true,
    path: '/shipment-leads',
    component: ShippingLeads
  },















  // my services routes
  '/services': {
    allowed: userPermission.my_services.read,
    protected: true,
    exact: true,
    label: 'My Services',
    primary: false,
    path: '/services',
    component: MyServices
  },




    // complete profile setup 
    '/update-profile-meta': {
      allowed: [AccountCmpyLinkType.OWNER],
      protected: true,
      exact: true,
      label: 'Profile Setup',
      primary: true,
      path: '/update-profile-meta',
      component: ProfileCompletionForm,
    },


  


  //  mail box rouutes

  '/mailbox': {
    allowed: userPermission.inbox.read,
    protected: true,
    exact: true,
    label: 'Mailbox',
    primary: true,
    path: '/mailbox',
    component: ShipperChat
  },
  '/m/mailBox/message': {
    allowed: userPermission.inbox.read,
    protected: true,
    exact: true,
    label: 'Mobile Chat',
    primary: false,
    path: '/m/mailBox/message',
    component: MobileChatView
  },
  '/m/mailBox/shipment-details': {
    allowed: userPermission.inbox.read,
    protected: true,
    exact: true,
    label: 'Mobile Chat',
    primary: false,
    path: '/m/mailBox/shipment-details',
    component: MobileShipmentDetails
  },
  '/m/mailBox': {
    allowed: userPermission.inbox.read,
    protected: true,
    exact: true,
    label: 'Mobile Chat',
    primary: false,
    path: '/m/mailBox',
    component: MobileChat
  },









  // create shipment routes
  '/select-shipment': {
    allowed: userPermission.shipments.create,
    protected: true,
    exact: true,
    label: 'Select Shipment',
    primary: false,
    path: '/select-shipment',
    component: SelectShipment
  },


  '/enter-basic-details': {
    allowed: userPermission.shipments.create,
    protected: true,
    exact: true,
    label: 'Enter Basic Details',
    primary: false,
    path: '/enter-basic-details',
    component: EnterDetails
  },

  '/enter-product-details': {
    allowed: userPermission.shipments.create,
    protected: true,
    exact: true,
    label: 'Enter Product Details',
    primary: false,
    path: '/enter-product-details',
    component: CandC
  },





  // stripe account onboarding

  // '/c/onboarding': {
  //   allowed: [AccountCmpyLinkType.OWNER],
  //   protected: true,
  //   exact: true,
  //   label: 'Company Details',
  //   primary: false,
  //   path: '/c/onboarding',
  //   component: EnterCompanyDetails
  // },

  // '/b/onboarding': {
  //   allowed: [AccountCmpyLinkType.OWNER],
  //   protected: true,
  //   exact: true,
  //   label: 'Bank Details',
  //   primary: false,
  //   path: '/b/onboarding',
  //   component: EnterBankDetails
  // },


































  //  shipments page routes
  '/shipments': {
    allowed: userPermission.shipments.read,
    protected: true,
    exact: true,
    label: 'Shipments',
    primary: true,
    path: '/shipments',
    component: ShipperShipments
  },
  '/shipments/:status': {
    allowed: userPermission.shipments.read,
    protected: true,
    exact: true,
    label: 'Shipments',
    primary: true,
    path: '/shipments/:status',
    component: ShipperShipments
  },
  '/shipment-detail/:shipment_id': {
    allowed: userPermission.shipments.read,
    protected: true,
    exact: true,
    label: 'Shipments Detail',
    primary: true,
    path: '/shipment-detail/:shipment_id',
    component: ShipmentDetail
  },





  // insights page routes
  '/insights': {
    allowed: userPermission.insights.read,
    protected: true,
    exact: true,
    label: 'Insights',
    primary: true,
    path: '/insights',
    component: ShipperReports
  },











  // attachment page routes
  '/attachment': {
    allowed: userPermission.documents.read,
    protected: true,
    exact: true,
    label: 'Attachments',
    primary: true,
    path: '/attachment',
    component: ShipperDocuments
  },
  // '/attachment/:tab': {
  //   allowed: userPermission.documents.read,
  //   protected: true,
  //   exact: true,
  //   label: 'Attachments',
  //   primary: true,
  //   path: '/attachment/:tab',
  //   component: ShipperDocuments
  // },









  // billing page routes
  '/billing': {
    allowed: userPermission.billing_earnings.read,
    protected: true,
    exact: true,
    label: 'Billing',
    primary: true,
    path: '/billing',
    component: ShipperBilling
  },


  '/billing/:tab': {
    allowed: userPermission.billing_earnings.read,
    protected: true,
    exact: true,
    label: 'Billing',
    primary: true,
    path: '/billing/:tab',
    component: ShipperBilling
  },

















  // settings routes
  '/settings': {
    allowed: [],
    protected: false,
    exact: true,
    label: 'Settings',
    primary: true,
    path: '/settings',
    component: ShipperSettings
  },
  '/settings/account': {
    allowed: [],
    protected: true,
    exact: true,
    label: 'General Account Settings',
    primary: false,
    path: '/settings/account',
    component: ShipperSettings
  },
  '/settings/company-info': {
    allowed: [],
    protected: true,
    exact: true,
    label: 'Company Info',
    primary: false,
    path: '/settings/company-info',
    component: ShipperSettings
  },
  // '/settings/shipping-info': {
  //   allowed: [],
  //   protected: true,
  //   exact: true,
  //   label: 'Shipping Info',
  //   primary: false,
  //   path: '/settings/shipping-info',
  //   component: ShipperSettings
  // },
  // '/settings/subscription': {
  //   allowed: [],
  //   protected: true,
  //   exact: true,
  //   label: 'Subscription',
  //   primary: true,
  //   path: '/settings/subscription',
  //   component: ShipperSettings
  // },
  // '/settings/privacy': {
  //   allowed: [],
  //   protected: false,
  //   exact: true,
  //   label: 'Privacy',
  //   primary: false,
  //   path: '/settings/privacy',
  //   component: ShipperSettings
  // },
  '/settings/security': {
    allowed: [],
    protected: false,
    exact: true,
    label: 'Security',
    primary: false,
    path: '/settings/security',
    component: ShipperSettings
  },
  // '/settings/referrals': {
  //   allowed: [],
  //   protected: false,
  //   exact: true,
  //   label: 'Referrals',
  //   primary: false,
  //   path: '/settings/referrals',
  //   component: ShipperSettings
  // },
  '/settings/notifications': {
    allowed: [],
    protected: false,
    exact: true,
    label: 'Notifications',
    primary: false,
    path: '/settings/notifications',
    component: ShipperSettings
  },
  '/settings/preferences': {
    allowed: [],
    protected: false,
    exact: true,
    label: 'Global Preferences',
    primary: false,
    path: '/settings/preferences',
    component: ShipperSettings
  },















  // '/chat/make-offer': {
  //   protected: false,
  //   exact: true,
  //   label: 'Shipping Leads',
  //   primary: true,
  //   path: '/chat/make-offer',
  //   component: ChatMakeOffer
  // },

  // '/tools/shippent-leads/make-offer/:id': {
  //   protected: false,
  //   exact: true,
  //   label: 'Shipping Leads',
  //   primary: true,
  //   path: '/tools/shippent-leads/make-offer/:id',
  //   component: MakeOffer
  // },
  // '/shippment/my-proposal/view-offer/:id': {
  //   protected: false,
  //   exact: true,
  //   label: 'Shipping Leads',
  //   primary: true,
  //   path: '/shippment/my-proposal/view-offer/:id',
  //   component: MakeOffer
  // },
  // '/tools/rate-sheet': {
  //   protected: false,
  //   exact: true,
  //   label: 'Rate Sheet',
  //   primary: true,
  //   path: '/tools/rate-sheet',
  //   component: ShipmentRates
  // },

  // '/wallet': {
  //   protected: false,
  //   exact: true,
  //   label: 'Wallet',
  //   primary: true,
  //   path: '/wallet',
  //   component: ShipperTransactions
  // },

  // '/shipments/:shipmentid/details': {
  //   protected: false,
  //   exact: true,
  //   label: 'Details',
  //   primary: false,
  //   path: '/shipments/:shipmentid/details',
  //   component: ShipperShipmentDetails
  // },



  // '/bookings': {
  //   protected: false,
  //   exact: true,
  //   label: 'Bookings',
  //   primary: true,
  //   path: '/bookings',
  //   component: ShipperQuotes
  // },
  // '/quotes': {
  //   protected: false,
  //   exact: true,
  //   label: 'Quotes',
  //   primary: true,
  //   path: '/quotes',
  //   component: ShipperQuotes
  // },

  // '/freight': {
  //   protected: false,
  //   exact: true,
  //   label: 'Freight',
  //   primary: true,
  //   path: '/freight',
  //   component: FreightHome
  // },
  // '/freight/quote': {
  //   protected: false,
  //   exact: true,
  //   label: 'Freight',
  //   primary: true,
  //   path: '/freight/quote',
  //   component: FreightHome
  // },
  // //Extra Pages
  // '/profile': {
  //   protected: false,
  //   exact: true,
  //   label: 'Profile',
  //   primary: true,
  //   path: '/profile',
  //   component: ShipperProfile
  // },
  // '/products': {
  //   protected: false,
  //   exact: true,
  //   label: 'Products',
  //   primary: true,
  //   path: '/products',
  //   component: ShipperProducts
  // },
  // '/customers': {
  //   protected: false,
  //   exact: true,
  //   label: 'Customers',
  //   primary: true,
  //   path: '/customers',
  //   component: ShipperCustomers
  // },
  // '/chats': {
  //   protected: false,
  //   exact: true,
  //   label: 'Chats',
  //   primary: true,
  //   path: '/chats',
  //   component: ShipperChat
  // },
  // '/calendar': {
  //   protected: false,
  //   exact: true,
  //   label: 'Calendar',
  //   primary: true,
  //   path: '/calendar',
  //   component: ShipperCalendar
  // }
};

export default RouteMaps;
