import { Button, Divider } from "@mui/material";
import Style from "./Rejected.module.css";
import KYCPageWrapper from "components/KYCUploadSystem/KYCPageWrapper/KYCPageWrapper";
import BoxContainer from "components/KYCUploadSystem/BoxContainer/BoxContainer";

/* eslint-disable */

const Rejected = ({ type, setShow, rejectedFeedback }) => {
    return (
        <>
            {/* <KYCPageWrapper> */}
            <div>
                {/* <h1 className={Style.MainHeading}>KYC Rejected</h1> */}
                
                <h3 className={Style.MainHeading}>
                            {type && type} KYC Rejected
                        </h3>


                <BoxContainer>
                    <div className={Style.left_inner}>
                        {/* {rejectedFeedback && rejectedFeedback?.reasons?.filter(x => x.name !== "Other")?.length > 0 &&
                                <>
                                    {rejectedFeedback?.reasons?.filter(x => x.name !== "Other")?.map((item, index) => (
                                        <div className="d-flex flex-column gap-1">
                                            <div className="app-page-title--heading">
                                                <h1>{item?.name}</h1>
                                                <Divider style={{ width: "100%" }} className="mt-2" />
                                            </div>
                                            <span className={Style.left_inner_desc}>
                                                {item?.description}
                                            </span>
                                        </div>
                                    ))}
                                </>
                            }
                            {rejectedFeedback && rejectedFeedback.additional_note &&
                                <div className="d-flex flex-column gap-1">
                                    <div className="app-page-title--heading">
                                        <h1>Additional Note</h1>
                                        <Divider style={{ width: "100%" }} className="mt-2" />
                                    </div>
                                    <span className={Style.left_inner_desc}>
                                        {rejectedFeedback.additional_note}
                                    </span>
                                </div>
                            } */}












                        <div className={Style.MsgContainer}>
                            {rejectedFeedback && rejectedFeedback?.individual?.reasons?.filter(x => x.name !== "Other")?.length > 0 &&
                                <>
                                    {rejectedFeedback?.individual?.reasons?.filter(x => x.name !== "Other")?.map((item, index) => (
                                        <div className="d-flex flex-column gap-1">
                                            <div className="app-page-title--heading">
                                                <h3>{item?.name}</h3>
                                                <Divider style={{ width: "100%" }} className="mt-2" />
                                            </div>
                                            <span className={Style.left_inner_desc}>
                                                {item?.description}
                                            </span>
                                        </div>
                                    ))}
                                </>
                            }
                            {rejectedFeedback && rejectedFeedback?.individual?.additional_note &&
                                <div className="d-flex flex-column gap-1">
                                    <div className="app-page-title--heading">
                                        <h3>Additional Note</h3>
                                        <Divider style={{ width: "100%" }} className="mt-2" />
                                    </div>
                                    <span className={Style.left_inner_desc}>
                                        {rejectedFeedback?.individual?.additional_note}
                                    </span>
                                </div>
                            }
                            {rejectedFeedback && rejectedFeedback?.company?.reasons?.filter(x => x.name !== "Other")?.length > 0 &&
                                <>
                                    {rejectedFeedback?.company?.reasons?.filter(x => x.name !== "Other")?.map((item, index) => (
                                        <div className="d-flex flex-column gap-1">
                                            <div className="app-page-title--heading">
                                                <h3 className="font-size-lg font-weight-bold">{item?.name}</h3>
                                                <Divider style={{ width: "100%" }} className="mt-2" />
                                            </div>
                                            <span className={Style.left_inner_desc}>
                                                {item?.description}
                                            </span>
                                        </div>
                                    ))}
                                </>
                            }
                            {rejectedFeedback && rejectedFeedback?.company?.additional_note &&
                                <div className="d-flex flex-column gap-1">
                                    <div className="app-page-title--heading">
                                        <h3>Additional Note</h3>
                                        <Divider style={{ width: "100%" }} className="mt-2" />
                                    </div>
                                    <span className={Style.left_inner_desc}>
                                        {rejectedFeedback?.company?.additional_note}
                                    </span>
                                </div>
                            }
                        </div>























                        <span style={{ color: "red" }}>Note: Please re-upload all the documents again</span><br />
                        <Button
                            className={`${Style.submitButton}`}
                            onClick={() => setShow(true)}
                        >
                            Re-upload Documents
                        </Button>
                    </div>
                </BoxContainer>
            </div>
            {/* </KYCPageWrapper> */}
        </>
    )
}
/* eslint-disable */
export default Rejected

