import { HttpMethods, httpService } from './global';

export default class ShipmentLeadsService {

  static fetchShipmentLeads(id, page, data, token) {
    const url = `shipment-leads/fetch?id=${id}&page=${page}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_leads',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static acceptShipmentLeadLead(id, shipmentId, token) {
    const url = `shipment-leads/accept-shipment-lead?id=${id}&shipmentId=${shipmentId}`;
    return httpService(
      HttpMethods.GET,
      url,
      {},
      {
        token,
        type: 'shipment_leads',
        method: 'edit',
        platform: 'Forwarder'
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
