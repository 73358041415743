import { CommonModel } from 'model';
import AccountType from 'model/enum/AccountType';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ForwarderRoute = ({
  component: Component,
  hideNav,
  auth,
  checkSession,
  hideHeader,
  ...rest
}) => {
  console.log('Inside Forwarder routes');
  return (
    <Route
      exact
      {...rest}
      render={(props) => {
        console.log('Checking session Inside Forwarder routes render', props);
        const token = CommonModel.createUserTokenObj(auth);
        checkSession(token);
        return auth && auth.authenticated ? (
          auth.accountType === AccountType.FORWARDER ? (
            <Component {...props} hideNav={hideNav} hideHeader={hideHeader} />
          ) : (
            <Redirect
              to={{
                pathname: '/error403',
                state: { referrer: props.location, match: props.match }
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: props.location, match: props.match }
            }}
          />
        );
      }}
    />
  );
};

export default ForwarderRoute;
