export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const requestLogin = creds => ({
  type: LOGIN_REQUEST,
  isFetching: true,
  isAuthenticated: false,
  creds
});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const receiveLogin = user => ({
  type: LOGIN_SUCCESS,
  isFetching: false,
  isAuthenticated: true,
  token: user.token
});

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const loginError = message => ({
  type: LOGIN_FAILURE,
  isFetching: false,
  isAuthenticated: false,
  message
});
