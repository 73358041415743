import { getBaseUrl } from 'config/config';
// import { HttpMethods, HttpService } from './index';
import { HttpMethods, httpService } from './global';

export default class BillingService {
  static async fetchBiilingChartData(id, token,year) {
    const url = `forwarder/fetch-billing-chart/${id}?year=${year}`;
           return httpService(HttpMethods.GET, url, null, {
             token,
             type: 'billing_my_wallet',
             method: 'read',
             platform: 'Forwarder'
           })
             .then((res) => {
               return res;
             })
             .catch((err) => console.log(err));

  }
}
