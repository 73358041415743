import { combineReducers } from 'redux';
import dashboard from './dashboard';
import drawer from './drawer';
import messages from './messages';
import serviceworker from './serviceworker';
import signup from './signup';
import session from './session';
import user from './user';
import billing from './billing';
import ThemeOptions from './ThemeOptions';
import makeOfferDetailsReducer from './makeOfferDetails'
import chatRoom from './chat'
import socketConnection from './socket'
import createShipment from './createShipment';
import filterDataRedux from './filterData'
import loadingReducer from './loader'
const rootReducer = combineReducers({
  dashboard,
  drawer,
  messages,
  serviceworker,
  signup,
  session,
  user,
  billing,
  ThemeOptions,
  makeOfferDetailsReducer,
  chatRoom,
  socketConnection,
  createShipment,
  filterDataRedux,
  loadingReducer
});

export default rootReducer;
