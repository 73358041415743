import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Header from './Header';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { logout } from '../../actions';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      setSidebarToggleMobile: (enable) =>
        dispatch(setSidebarToggleMobile(enable))
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
