import React from 'react'
import ReactPhoneNumberInput from './ReactPhoneNumberInput'
import CustomPhoneNumberInput from './CustomPhoneNumberInput'

const PhoneNumberInput = ({ country, number, handleCountry, handleNumber, variant }) => {
  return (
    <>
      {/* <ReactPhoneNumberInput
        country={country}
        handleCountry={handleCountry}
        number={number}
        handleNumber={handleNumber}
      /> */}
      <CustomPhoneNumberInput
        countryuseState={country?.value?.toLowerCase()}
        number={number}
        handleCountry={handleCountry}
        handleNumber={handleNumber}
        variant={variant}
      />
    </>
  )
}

export default PhoneNumberInput