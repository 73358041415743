import Style from "./button.module.css";
import { Tooltip } from "@mui/material";

const CustomButton = ({ children, icon, color, onClick, style, tooltip }) => {
    /* eslint-disable */
    return (
        <Tooltip title={tooltip}>
            <button
                onClick={onClick}
                className={Style.btn}
                style={{
                    color: color ? color : 'var(--primary)',
                    '--before-color': color ? color : "var(--primary)",
                    // '--before-color': color ? color : "#2f358b",
                    fontWeight: '600',
                    ...style
                }}
            >
                {icon}
                <span style={{ lineHeight: '115%' }}>{children}</span>
            </button>
        </Tooltip>
        /* eslint-enable */
    )
}

export default CustomButton