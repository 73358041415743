export const SELECT_SHIPPER = "SELECT_SHIPPER"
export const selectShipper = (payload) => ({
    type: SELECT_SHIPPER,
    payload
});


export const SELECT_SHIPEMENT = "SELECT_SHIPEMENT"
export const selectShipment = (payload) => ({
    type: SELECT_SHIPEMENT,
    payload
});


export const ENTER_BASIC_DETAILS = "ENTER_BASIC_DETAILS"
export const enterBasicDetails = (payload) => ({
    type: ENTER_BASIC_DETAILS,
    payload
});

export const CLEAR_SHIPMENT = "CLEAR_SHIPMENT"
export const clearShipment = (payload) => ({
    type: CLEAR_SHIPMENT,
    payload
});

