import Style from "./TopBar.module.css";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

/* eslint-disable */

const title_options = [
  { value: 'jack', label: 'Jack' },
  { value: 'lucy', label: 'Lucy' },
  { value: 'Yiminghe', label: 'yiminghe' },
]


const TopBar = ({ breakdownData, setBreakdownData, addSection, handleReset }) => {

  const [title, setTitle] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


  return (
    <div className={Style.container}>
      <label htmlFor="title" className={Style.title}>Title</label>

      <div className={Style.main}>
        {/* <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={Style.input}
                /> */}

        <FormControl variant="standard" sx={{ flex: 1, minWidth: '120px' }}>
          <Select
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          className={Style.btns}
          disabled={!title}
          onClick={() => addSection(title)}
        >
          Add Section
        </Button>

        <Button
          variant="contained"
          className={Style.btns}
        >
          Use Previous Template
        </Button>

        <Button
          variant="contained"
          className={Style.reset_btn}
          onClick={() => setConfirmDialogOpen(true)}
        >
          Reset
        </Button>

      </div>

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={handleReset}
        question={"Are you sure you want to reset this rate-sheet?"}
      />



    </div>
  )
}
/* eslint-disable */
export default TopBar