import { CommonModel } from './index';
import * as moment from 'moment';

export default class CruiseModel {
  static createNewCruiseReservation = (cruise, umTripId) => {
    const departTime = moment.utc(cruise.departDate).format('MM/DD/YYYY');
    const arrivalTime = moment.utc(cruise.arrivalDate).format('MM/DD/YYYY');
    let cruiseObj = {
      '@type': 'CruiseShipReservation',
      reservationFor: {
        provider: {
          name: cruise.cruiseName
        },
        departTime: moment.utc(`${departTime} ${cruise.sailingTime}`),
        departPort: {
          name: cruise.departPort,
          umId: cruise.departPortUmId
        },
        arrivalTime: moment.utc(`${arrivalTime} ${cruise.disembarkTime}`),
        arrivalPort: {
          name: cruise.arrivalPort,
          umId: cruise.arrivalPortUmId
        }
      },
      umTripId: umTripId,
      reservationNumber: cruise.bookingNumber,
      inCruiseLocator: cruise.inCruiseLocator,
      inCruiseCmpyName: cruise.cmpyName,
      detailsId: cruise.detailsId,
      crossDate: cruise.crossDate,
      importSrc: cruise.importSrc ? cruise.importSrc : null
    };

    if (cruise.cruiseNameUmId !== '') {
      cruiseObj.reservationFor.provider.umId = cruise.cruiseNameUmId;
    }

    if (cruise.departPortUmId !== '') {
      cruiseObj.reservationFor.departPort.umId = cruise.departPortUmId;
    }

    if (cruise.arrivalPortUmId !== '') {
      cruiseObj.reservationFor.arrivalPort.umId = cruise.arrivalPortUmId;
    }

    return cruiseObj;
  };

  static createNewCruiseStopObj = (cruiseStop, umTripId) => {
    const arrivalTime = `${cruiseStop.arrivalDate.format('MM/DD/YYYY')} ${cruiseStop.arrivalTime}`;
    const departTime = `${moment.utc(cruiseStop.departureDate).format('MM/DD/YYYY')} ${cruiseStop.departureTime}`;
    return {
      '@type': 'CruiseStopReservation',
      umTripId,
      name: cruiseStop.portName,
      arrivalTime: moment.utc(arrivalTime, 'MM/DD/YYYY HH:mm').format(),
      departTime: moment.utc(departTime, 'MM/DD/YYYY HH:mm').format(),
      reservationFor: {
        name: cruiseStop.portName,
        activityType: 'CRUISE_STOP'
      },
      crossDate: cruiseStop.crossDate,
      umId: cruiseStop.umId ? cruiseStop.umId : null
    };
  };

  static createNewCruiseStopPostObj = (cruiseStop, crossDate) => {
    return {
      '@type': 'CruiseStopReservation',
      umTripId: cruiseStop.umTripId,
      name: cruiseStop.name,
      startTime: cruiseStop.arrivalTime,
      finishTime: cruiseStop.departTime,
      reservationFor: {
        name: cruiseStop.name,
        activityType: cruiseStop.reservationFor.activityType
      },
      crossDate,
      umId: cruiseStop.umId
    };
  };

  static updatedCruiseReservation = (cruise, rates, description, cancellationPolicy, pricing, umTripId) => {
    const departDate = `${cruise.departDate.format('MM/DD/YYYY')} ${cruise.sailingTime}`;
    const arrivalDate = `${cruise.arrivalDate.format('MM/DD/YYYY')} ${cruise.disembarkTime}`;
    let cruiseReservationObj = {
      '@type': 'CruiseShipReservation',
      reservationFor: {
        provider: {
          name: cruise.name
        },
        departTime: moment.utc(departDate, 'MM/DD/YYYY HH:mm').format(),
        departPort: {
          name: cruise.departPort
        },
        arrivalTime: moment.utc(arrivalDate, 'MM/DD/YYYY HH:mm').format(),
        arrivalPort: {
          name: cruise.arrivalPort
        }
      },
      umTripId,
      reservationNumber: cruise.reservationNumber,
      inCruiseLocator: cruise.inCruiseLocator,
      inCruiseCmpyName: cruise.cmpyName,
      name: cruise.name,
      umId: cruise.umId,
      crossDate: cruise.crossDate,
      category: cruise.category,
      deckNumber: cruise.deck,
      cabinNumber: cruise.cabin,
      bedding: cruise.bedding,
      diningPlan: cruise.dining
    };

    if (cruise.nameUmId !== '') {
      cruiseReservationObj.reservationFor.provider.umId = cruise.nameUmId;
    }

    if (cruise.departPortUmId !== '') {
      cruiseReservationObj.reservationFor.departPort.umId = cruise.departPortUmId;
    }

    if (cruise.arrivalPortUmId !== '') {
      cruiseReservationObj.reservationFor.arrivalPort.umId = cruise.arrivalPortUmId;
    }

    if (rates !== cruise.rates) {
      cruiseReservationObj.rates = rates;
    }

    if (Object.keys(pricing).length !== 0 && pricing.constructor === Object) {
      cruiseReservationObj.taxes = pricing.taxes;
      cruiseReservationObj.subtotal = pricing.subtotal;
      cruiseReservationObj.fees = pricing.fees;
      cruiseReservationObj.currency = pricing.currency;
      cruiseReservationObj.total = pricing.total;
    }

    if (description !== cruise.description) {
      cruiseReservationObj.description = description;
    }

    if (cancellationPolicy !== cruise.cancellationPolicy) {
      cruiseReservationObj.cancellationPolicy = cancellationPolicy;
    }

    return cruiseReservationObj;
  };

  static createCruiseReservation = (cruise, umTripId) => {
    let cruiseReservationObj = {
      '@type': 'CruiseReservation',
      umId: cruise.umId,
      umTripId,
      ...cruise
    };

    if (cruise.rates) {
      cruiseReservationObj.rates = CommonModel.createRateList(cruise.rates);
    }

    return cruiseReservationObj;
  };
}
