export default class TransferModel {
  /**
   *
   * @param umTripId
   * @returns {function(*): function(*): {umTripId: *, name: *}}
   */
  static setTripIdAndName = umTripId => name => reservationObj => ({
    umTripId,
    name,
    ...reservationObj
  });

  /**
   *
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @returns {{companyName: *, companyNameUmId: *, contactName: *}}
   */
  static createTransportDetailsObj = (companyName, companyNameUmId, contactName) => ({
    companyName,
    companyNameUmId,
    contactName
  });

  /**
   *
   * @param fn
   * @returns {function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): *}
   */
  static summaryFormat = fn => {
    return a => {
      return b => {
        return c => {
          return d => {
            return e => {
              return f => {
                return g => {
                  return h => {
                    return i => {
                      return j => {
                        return k => fn(a, b, c, d, e, f, g, h, i, j, k);
                      };
                    };
                  };
                };
              };
            };
          };
        };
      };
    };
  };

  /**
   *
   * @param type
   * @returns {*}
   */
  static getReservationTypeFormat = type => {
    if (type.includes('Train')) {
      return TransferModel.getTrainFormat;
    } else if (type.includes('Taxi')) {
      return TransferModel.getTaxiFormat;
    } else if (type.includes('RentalCarDropOff')) {
      return TransferModel.getRentalCarDropOffFormat;
    } else if (type.includes('RentalCar')) {
      return TransferModel.getRentalCarFormat;
    } else if (type.includes('Bus')) {
      return TransferModel.getBusFormat;
    } else {
      return TransferModel.getGeneralTypeFormat;
    }
  };

  /**
   *
   * @param type
   * @param startTime
   * @param startLocation
   * @param endTime
   * @param endLocation
   * @param transportTitle
   * @param reservationNumber
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @param crossDate
   * @returns {{'@type': string, reservationNumber: *, serviceType: *, reservationFor: {departTime: *, arrivalTime: *, departStation: {name: *}, arrivalStation: {name: *}, provider: {name: *, umId: *}}, crossDate: *}}
   */
  static getTrainFormat = (
    type,
    startTime,
    startLocation,
    endTime,
    endLocation,
    transportTitle,
    reservationNumber,
    companyName,
    companyNameUmId,
    contactName,
    crossDate
  ) => {
    let trainObj = {
      '@type': `${type}Reservation`,
      reservationNumber: reservationNumber,
      serviceType: transportTitle,
      reservationFor: {
        departTime: startTime,
        arrivalTime: endTime,
        departStation: {
          name: startLocation
        },
        arrivalStation: {
          name: endLocation
        },
        provider: {}
      },
      crossDate
    };

    if (companyName !== '') {
      trainObj.reservationFor.provider.name = companyName;
    }

    if (companyNameUmId !== '') {
      trainObj.reservationFor.provider.umId = companyNameUmId;
    } else if (contactName !== '') {
      trainObj.reservationFor.provider.contactPoint = contactName;
    }

    return trainObj;
  };

  /**
   *
   * @param type
   * @param startTime
   * @param startLocation
   * @param endTime
   * @param endLocation
   * @param transportTitle
   * @param reservationNumber
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @param crossDate
   * @returns {{'@type': string, reservationNumber: *, serviceType: *, pickupTime: *, dropoffTime: *, pickupLocation: {name: *}, dropoffLocation: {name: *}, reservationFor: {provider: {name: *, umId: *}}, crossDate: boolean}}
   */
  static getTaxiFormat = (
    type,
    startTime,
    startLocation,
    endTime,
    endLocation,
    transportTitle,
    reservationNumber,
    companyName,
    companyNameUmId,
    contactName,
    crossDate
  ) => {
    const taxiObj = {
      '@type': `${type}Reservation`,
      reservationNumber: reservationNumber,
      serviceType: transportTitle,
      pickupTime: startTime,
      dropoffTime: endTime,
      pickupLocation: {
        name: startLocation
      },
      dropoffLocation: {
        name: endLocation
      },
      reservationFor: {
        provider: {}
      },
      crossDate
    };

    if (companyName !== '') {
      taxiObj.reservationFor.provider.name = companyName;
    }

    if (companyNameUmId !== '') {
      taxiObj.reservationFor.provider.umId = companyNameUmId;
    }

    if (contactName !== '') {
      taxiObj.reservationFor.provider.contactPoint = contactName;
    }

    return taxiObj;
  };

  /**
   *
   * @param type
   * @param startTime
   * @param startLocation
   * @param endTime
   * @param endLocation
   * @param transportTitle
   * @param reservationNumber
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @param crossDate
   * @returns {{'@type': string, reservationNumber: *, serviceType: *, pickupTime: *, dropoffTime: *, pickupLocation: {name: *}, dropoffLocation: {name: *}, reservationFor: {rentalCompany: {name: *, umId: *}}, crossDate: boolean}}
   */
  static getRentalCarFormat = (
    type,
    startTime,
    startLocation,
    endTime,
    endLocation,
    transportTitle,
    reservationNumber,
    companyName,
    companyNameUmId,
    contactName,
    crossDate
  ) => {
    let rentalCarObj = {
      '@type': `${type}Reservation`,
      reservationNumber: reservationNumber,
      serviceType: transportTitle,
      pickupTime: startTime,
      dropoffTime: endTime,
      pickupLocation: {
        name: startLocation
      },
      dropoffLocation: {
        name: endLocation
      },
      reservationFor: {
        rentalCompany: {}
      },
      crossDate
    };

    if (companyName !== '') {
      rentalCarObj.reservationFor.rentalCompany.name = companyName;
    }

    if (companyNameUmId !== '') {
      rentalCarObj.reservationFor.rentalCompany.umId = companyNameUmId;
    }

    if (contactName !== '') {
      rentalCarObj.reservationFor.rentalCompany.contactPoint = contactName;
    }

    return rentalCarObj;
  };

  /**
   *
   * @param type
   * @param startTime
   * @param startLocation
   * @param endTime
   * @param endLocation
   * @param transportTitle
   * @param reservationNumber
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @param crossDate
   * @returns {{'@type': string, reservationNumber: *, serviceType: *, pickupTime: *, dropoffTime: *, pickupLocation: {name: *}, dropoffLocation: {name: *}, reservationFor: {rentalCompany: {name: *, umId: *}}, crossDate: boolean}}
   */
  static getRentalCarDropOffFormat = (
    type,
    startTime,
    startLocation,
    endTime,
    endLocation,
    transportTitle,
    reservationNumber,
    companyName,
    companyNameUmId,
    contactName,
    crossDate
  ) => {
    let rentalCarObj = {
      '@type': `${type}Reservation`,
      reservationNumber: reservationNumber,
      serviceType: transportTitle,
      pickupTime: endTime,
      dropoffTime: endTime,
      pickupLocation: {
        name: endLocation
      },
      dropoffLocation: {
        name: endLocation
      },
      reservationFor: {
        rentalCompany: {}
      },
      crossDate
    };

    if (companyName !== '') {
      rentalCarObj.reservationFor.rentalCompany.name = companyName;
    }

    if (companyNameUmId !== '') {
      rentalCarObj.reservationFor.rentalCompany.umId = companyNameUmId;
    }

    if (contactName !== '') {
      rentalCarObj.reservationFor.rentalCompany.contactPoint = contactName;
    }

    return rentalCarObj;
  };

  /**
   *
   * @param type
   * @param startTime
   * @param startLocation
   * @param endTime
   * @param endLocation
   * @param transportTitle
   * @param reservationNumber
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @param crossDate
   * @returns {{'@type': string, reservationNumber: *, serviceType: *, reservationFor: {departureTime: *, departureBusStop: {name: *}, arrivalTime: *, arrivalBusStop: {name: *}, provider: {name: *, umId: *}}, crossDate: boolean}}
   */
  static getBusFormat = (
    type,
    startTime,
    startLocation,
    endTime,
    endLocation,
    transportTitle,
    reservationNumber,
    companyName,
    companyNameUmId,
    contactName,
    crossDate
  ) => {
    let busObj = {
      '@type': `${type}Reservation`,
      reservationNumber: reservationNumber,
      serviceType: transportTitle,
      reservationFor: {
        departureTime: startTime,
        departureBusStop: {
          name: startLocation
        },
        arrivalTime: endTime,
        arrivalBusStop: {
          name: endLocation
        },
        provider: {}
      },
      crossDate
    };

    if (companyName !== '') {
      busObj.reservationFor.provider.name = companyName;
    }

    if (companyNameUmId !== '') {
      busObj.reservationFor.provider.umId = companyNameUmId;
    }

    if (contactName !== '') {
      busObj.reservationFor.provider.contactPoint = contactName;
    }

    return busObj;
  };

  /**
   *
   * @param type
   * @param startTime
   * @param startLocation
   * @param endTime
   * @param endLocation
   * @param transportTitle
   * @param reservationNumber
   * @param companyName
   * @param companyNameUmId
   * @param contactName
   * @param crossDate
   * @returns {{'@type': string, reservationNumber: *, serviceType: *, reservationFor: {pickupTime: *, dropoffTime: *, pickupLocation: {name: *}, dropoffLocation: {name: *}, provider: {name: *, umId: *}}, crossDate: boolean}}
   */
  static getGeneralTypeFormat = (
    type,
    startTime,
    startLocation,
    endTime,
    endLocation,
    transportTitle,
    reservationNumber,
    companyName,
    companyNameUmId,
    contactName,
    crossDate
  ) => {
    let genObj = {
      '@type': `${type}Reservation`,
      reservationNumber: reservationNumber,
      serviceType: transportTitle,
      reservationFor: {
        pickupTime: startTime,
        dropoffTime: endTime,
        pickupLocation: {
          name: startLocation
        },
        dropoffLocation: {
          name: endLocation
        },
        provider: {}
      },
      crossDate
    };

    if (companyName !== '') {
      genObj.reservationFor.provider.name = companyName;
    }

    if (companyNameUmId !== '') {
      genObj.reservationFor.provider.umId = companyNameUmId;
    }

    if (contactName !== '') {
      genObj.reservationFor.provider.contactPoint = contactName;
    }

    return genObj;
  };

  /**
   *
   * @param umTripId
   * @param umId
   * @param rates
   * @param description
   * @param cancellationPolicy
   * @param pricing
   * @param summary
   * @param transportDetails
   * @returns {{name: (*|string), umTripId: *, umId: *, description: *, cancellationPolicy: *, rates: *}}
   */
  static generateTransferReservation = (
    umTripId,
    umId,
    rates,
    description,
    cancellationPolicy,
    pricing,
    summary,
    transportDetails
  ) => {
    return {
      name: transportDetails.companyName,
      umTripId,
      umId,
      ...pricing,
      ...summary,
      description,
      cancellationPolicy,
      rates
    };
  };

  /**
   *
   * @param reservation
   * @param type
   * @returns {*|string}
   */
  static getStartTime = (reservation, type) => {
    let startTime = '';
    if (type.includes('Train')) {
      startTime = reservation.item.reservationFor.departTime;
    } else if (type.includes('RentalCarDropOff')) {
      startTime = reservation.item.dropoffTime ? reservation.item.dropoffTime : null;
    } else if (type.includes('Taxi') || type.includes('RentalCar')) {
      startTime = reservation.item.pickupTime ? reservation.item.pickupTime : null;
    } else if (type.includes('Bus')) {
      startTime = reservation.item.reservationFor.departureTime;
    } else {
      startTime = reservation.item.reservationFor.pickupTime ? reservation.item.reservationFor.pickupTime : null;
    }

    return startTime;
  };

  /**
   *
   * @param reservation
   * @param type
   * @returns {*|string}
   */
  static getEndTime = (reservation, type) => {
    if (type.includes('Train')) {
      return reservation.item.reservationFor.arrivalTime;
    } else if (type.includes('Taxi') || type.includes('RentalCarDropOff') || type.includes('RentalCar')) {
      return reservation.item.dropoffTime ? reservation.item.dropoffTime : null;
    } else if (type.includes('Bus')) {
      return reservation.item.reservationFor.arrivalTime;
    } else {
      return reservation.item.reservationFor.dropoffTime ? reservation.item.reservationFor.dropoffTime : null;
    }
  };

  /**
   *
   * @param reservation
   * @param type
   * @returns {*|string}
   */
  static getStartLocation = (reservation, type) => {
    let startLocation = '';
    if (type.includes('Train')) {
      startLocation = reservation.item.reservationFor.departStation.name;
    } else if (type.includes('RentalCarDropOff')) {
      startLocation = reservation.item.dropoffLocation ? reservation.item.dropoffLocation.name : '';
    } else if (type.includes('Taxi') || type.includes('RentalCar')) {
      startLocation = reservation.item.pickupLocation ? reservation.item.pickupLocation.name : '';
    } else if (type.includes('Bus')) {
      startLocation = reservation.item.reservationFor.departureBusStop.name;
    } else {
      startLocation =
        reservation.item.reservationFor.pickupLocation && reservation.item.reservationFor.pickupLocation.name
          ? reservation.item.reservationFor.pickupLocation.name
          : '';
    }

    return startLocation;
  };

  /**
   *
   * @param reservation
   * @param type
   * @returns {*|string}
   */
  static getEndLocation = (reservation, type) => {
    let endLocation = '';
    if (type.includes('Train')) {
      endLocation = reservation.item.reservationFor.arrivalStation.name;
    } else if (type.includes('RentalCarDropOff')) {
      endLocation = reservation.item.dropoffLocation ? reservation.item.dropoffLocation.name : '';
    } else if (type.includes('Taxi') || type.includes('RentalCar')) {
      endLocation = reservation.item.dropoffLocation ? reservation.item.dropoffLocation.name : null;
    } else if (type.includes('Bus')) {
      endLocation = reservation.item.reservationFor.arrivalBusStop.name;
    } else {
      endLocation = reservation.item.reservationFor.dropoffLocation
        ? reservation.item.reservationFor.dropoffLocation.name
        : '';
    }

    return endLocation;
  };

  /**
   *
   * @param type
   * @returns {string}
   */
  static getTransferIcon = type => {
    if (type.includes('Train')) {
      return 'directions_railway';
    } else if (type.includes('Bus')) {
      return 'directions_bus';
    } else if (type.includes('Taxi') || type.includes('Car')) {
      return 'directions_car';
    } else if (type.includes('Ferry')) {
      return 'directions_boat';
    } else {
      return 'airport_shuttle';
    }
  };

  /**
   *
   * @param reservation
   * @returns {Function}
   */
  static getContactObj = reservation => type => {
    if (type.includes('RentalCar')) {
      return reservation.item.reservationFor.rentalCompany;
    } else {
      return reservation.item.reservationFor.provider;
    }
  };
}
