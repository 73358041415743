import QuoteService from 'service/QuoteService';

/*-- Action Creators for Quotes Fetch --*/
export const REQUEST_QUOTE = 'REQUEST_QUOTE';
export const requestQuote = () => ({
  type: REQUEST_QUOTE
});

export const RECEIVE_QUOTE_SUCCESS = 'RECEIVE_QUOTE_SUCCESS';
export const receiveQuoteSuccess = (data) => ({
  type: RECEIVE_QUOTE_SUCCESS,
  payload: data
});

export const RECEIVE_QUOTE_FAILURE = 'RECEIVE_QUOTE_FAILURE';
export const receiveQuoteFailure = (msg) => ({
  type: RECEIVE_QUOTE_FAILURE,
  msg
});

export function searchQuotes(data) {
  return (dispatch) => {
    dispatch(requestQuote());

    return QuoteService.search(data)
      .then((res) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (res) {
              dispatch(receiveQuoteSuccess(res));
            } else {
              dispatch(receiveQuoteFailure(res));
            }
            console.log('Search for quotes resp - action', res);
            resolve(res);
          }, 3500);
        });
      })
      .catch((res) => {
        console.log('Search for quotes resp - action catch', res);
        dispatch(receiveQuoteFailure(res));
      });
  };
}
