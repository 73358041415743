import React from 'react'
import Style from "./ActivityCard.module.css";

import avatar1 from 'assets/images/avatars/avatar1.jpg';
const ActivityCard = ({ data }) => {

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        const hours = date.getHours();
        const minutes = date.getMinutes();
    
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');
    
        if (date.toDateString() === currentDate.toDateString()) {
          return `Today, ${formattedHours}:${formattedMinutes} ${ampm}`;
        } else if (date.toDateString() === yesterday.toDateString()) {
          return `Yesterday, ${formattedHours}:${formattedMinutes} ${ampm}`;
        } else {
          const day = date.getDate();
          const month = date.toLocaleString('default', { month: 'short' });
          return `${day} ${month}, ${formattedHours}:${formattedMinutes} ${ampm}`;
        }
      }


    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    <div className="font-weight-bold">
                        <span className={`${Style.activity_title} text-black`}>
                            {data.content}
                        </span>
                    </div>
                    <small className="d-flex pt-2 align-items-center">
                        <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
                            <div className="avatar-icon">
                                <img alt="..." src={data.avatar} className={Style.avatar} />
                            </div>
                        </div>
                        <div className={Style.user_name}>{ data.name}</div>
                    </small>
                </div>
                <small>
                    <span className={`${Style.date_time} pl-2 text-muted`}>{formatDate(data.date)}</span>
                </small>
            </div>

            <div className="divider my-3" />
        </>
    )
}

export default ActivityCard