import React from 'react'
import Style from "./WrapperContainer.module.css";
import Logo from "assets/logos/kargo-04-light.svg"
const WrapperContainer = ({ children, showText = true }) => {
    return (
        <div className={Style.container} style={{ backgroundImage: 'url(assets/authentication/login_back.png)' }}>
            <div className={Style.main}>

                <div className={Style.inner_main}>
                    <div className='d-flex flex-column w-100' style={{ gap: '1.5rem' }}>
                        {showText ?
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div>
                                    <img src={Logo} width="200px" height="auto" alt="KARGOPLEX" />
                                </div>

                                <span className={Style.text_bold_big}>One tool for your whole team needs</span>
                            </div>
                            :
                            null
                        }

                        {children}
                    </div>
                </div>

                <div className={`${Style.right_container} position-relative d-block`}>
                    {!showText &&
                        <div className={Style.right_brand}>
                            <div>
                                <img src={Logo} width="200px" height="auto" alt="KARGOPLEX" />
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default WrapperContainer