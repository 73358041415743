import DataSource from 'model/enum/DataSource';
import QuoteResultModel from 'model/QuoteResultModel';
import {
  SEARATES_QUOTES_MULTIPLE,
  FREIGHTOS_QUOTES_MULTI_3
} from 'util/shippingRatesSample';
import { HttpMethods, HttpService } from './index';

export default class QuoteService {
  static search(data) {
    const url = '';

    console.log('QuoteService', data);

    return Promise.resolve(
      QuoteResultModel.getQuoteResultTypeFormat(
        DataSource.FREIGHTOS,
        FREIGHTOS_QUOTES_MULTI_3,
        data
      )
    );

    // return HttpService.request(HttpMethods.POST, url, data)
    //   .then((res) => {
    //     console.log('Search for quotes resp', res);
    //     return SEARATES_QUOTES_MULTIPLE;
    //   })
    //   .catch((err) => console.error(err));
  }

  static fetchSavedQuote(quoteId, token) {
    return HttpService.request(
      HttpMethods.GET,
      `quotes/${quoteId}/fetch`,
      {},
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }
}
