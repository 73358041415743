import DashService from '../service/DashService';

export const UPLOAD_SUCCESSFUL = 'UPLOAD_SUCCESSFUL';
export const uploadSuccessful = data => ({
  type: UPLOAD_SUCCESSFUL,
  payload: data,
  msg: data.msg
});

export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const uploadFailed = data => ({
  type: UPLOAD_FAILED,
  payload: data,
  msg: data.msg
});

export const SEARCH_LIBRARY_FAILURE = 'SEARCH_LIBRARY';
export const searchLibraryWebFailure = data => ({
  type: SEARCH_LIBRARY_FAILURE,
  payload: data,
  msg: data
});

export function searchLibraryWeb(inCmpyId, inTripId, inNoteId, inKeyword, inImgSearchType, inWebUrl, token) {
  return dispatch => {
    return DashService.searchImg(inCmpyId, inTripId, inNoteId, inKeyword, inImgSearchType, inWebUrl, token)
      .then(res => {
        if (!res) {
          dispatch(searchLibraryWebFailure(res));
        }

        return res;
      })
      .catch(err => {
        return dispatch(searchLibraryWebFailure('Website not found.'));
      });
  };
}

export const DELETE_NOTE_IMAGE_SUCCESSFUL = 'DELETE_NOTE_IMAGE_SUCCESSFUL';
export const deleteNoteImageSuccessful = data => ({
  type: DELETE_NOTE_IMAGE_SUCCESSFUL,
  payload: data,
  msg: data.msg
});

export const DELETE_NOTE_IMAGE_FAILED = 'DELETE_NOTE_IMAGE_FAILED';
export const deleteNoteImageFailed = data => ({
  type: DELETE_NOTE_IMAGE_FAILED,
  payload: data,
  msg: data.msg
});
export function deleteNoteImage(data, token) {
  return dispatch => {
    return DashService.deleteNotePhoto(data, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(deleteNoteImageSuccessful(res));
        } else {
          dispatch(deleteNoteImageFailed(res));
        }

        return res;
      })
      .catch(err => dispatch(uploadFailed(err)));
  };
}

export function uploadImageFromSearch(data, token) {
  return dispatch => {
    return DashService.uploadToUrl(data, 'api/wi/v1/trip/saveImageCoverFromLibrary', token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(uploadSuccessful(res));
        } else {
          dispatch(uploadFailed(res));
        }

        return res;
      })
      .catch(err => dispatch(uploadFailed(err)));
  };
}

export const IMPORT_PDF_SUCCESSFUL = 'IMPORT_PDF_SUCCESSFUL';
export const importPdfSuccessful = data => ({
  type: IMPORT_PDF_SUCCESSFUL,
  payload: data,
  msg: data.msg
});

export const IMPORT_PDF_FAILURE = 'IMPORT_PDF_FAILURE';
export const importPdfFailure = data => ({
  type: IMPORT_PDF_FAILURE,
  payload: data,
  msg: data.msg ? data.msg : data.message
});

export function importPdf(file, payload, inTripId, token) {
  return dispatch => {
    return DashService.getAWSCredentials(payload, token, `api/wi/v1/trip/${inTripId}/uploadPdfBooking`)
      .then(res => {
        return DashService.uploadToSignedRequest(file, res.signed_request).then(_ => {
          return DashService.getPdfImportParsed(inTripId, res.tripAttachmentId, payload.inFileProvider, token).then(
            resUpload => {
              return resUpload.returnCode === 0
                ? dispatch(importPdfSuccessful(resUpload))
                : dispatch(importPdfFailure(resUpload));
            }
          );
        });
      })
      .catch(err =>
        dispatch(
          importPdfFailure({
            config: err.config,
            msg: err.message,
            request: err.request,
            response: err.response
          })
        )
      );
  };
}
