import React from 'react'
import Style from "./SectionHeading.module.css";

const SectionHeading = ({ title, size, classes }) => {
    return (
        <div className='app-page-title--heading'>
            <h1 className={`${size ? '' : Style.heading} ${classes}`} style={{ fontSize: size }}>{title}</h1>
        </div>
    )
}

export default SectionHeading