import React, { useState } from 'react';
import Style from "./MobileChatListPane.module.css";
import clsx from 'clsx';

import {
    List,
    ListItem,
} from '@material-ui/core';

import avatar1 from 'assets/images/avatars/avatar1.jpg';
// import { ACTIVE_SHIPMENTS } from '../../Shipments/ActiveShipments/MockData';
import { ACTIVE_SHIPMENTS } from '../../../Shipments/ActiveShipments/MockData';
import RouteIcon from 'assets/svg/RouteIcon.svg';
import { withStyles } from '@material-ui/styles';
import { formatDate } from 'util/Utils';
import CustomChip from 'sites/shipper/components/CustomChip/CustomChip'
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';

const styles = (theme) => ({
    routeIcon: {
        height: '0.5rem'
    }
});

const MobileChatListPane = ({ classes, handlesMessageSelect, chatData }) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [inputBg, setInputBg] = useState(false);
    const toggleInputBg = () => setInputBg(!inputBg);

    const statusColor = (status) => {
        switch (status) {
            case "pending":
                return "warning"
            case "completed":
                return "primary"
            case "cancelled":
                return "danger"
            default:
                return "success"
        }
    }
    function formatDateForChat(timestamp) {
        const date = new Date(timestamp);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        const hours = date.getHours();
        const minutes = date.getMinutes();

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');

        if (date.toDateString() === currentDate.toDateString()) {
            return `${formattedHours}:${formattedMinutes} ${ampm}`;
        } else if (date.toDateString() === yesterday.toDateString()) {
            return `Yesterday`;
        } else {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            return `${day} ${month}`;
            /*, ${formattedHours}:${formattedMinutes} ${ampm}*/
        }
    }
    return (
        <>
            <div className="w-100">
                <div>
                    {/* <List
                        component="div"
                        className="nav-pills nav-neutral-second flex-column"> */}

                    <ListItem
                        component="a"
                        className={`d-block m-0`}
                        button
                        onClick={() => handlesMessageSelect(chatData)}

                    >
                        <div className="d-flex">
                            <div className={`w-20`}>
                                <div className="text-gray-700 d-flex c-justify-content-center mr-1">
                                    {/* <div className="avatar-icon-wrapper avatar-icon-md">
                                        <div className="badge badge-success badge-circle">
                                            Online
                                        </div>
                                        <div className="avatar-icon rounded-circle">
                                            <img alt="..." src={chatData?.shipper_logo} />
                                        </div>
                                    </div> */}
                                    <CustomAvatar
                                        className={"avatar-icon rounded-circle"}
                                        height={50} width={50}
                                        name={chatData?.shipper_name}
                                        src={chatData?.shipper_logo}
                                    />
                                </div>
                            </div>

                            {/* show this only when the drawer is expanded  */}
                            <div className="w-80">
                                <div className="font-size-sm mb-1 d-flex justify-content-between">
                                    <span className="font-weight-bold pr-2 mr-2">
                                        {chatData?.shipper_name}
                                    </span>
                                    <div className="text-black-50 font-size-xs">
                                        {formatDateForChat(
                                            chatData?.last_message?.dateAndTime
                                        )}
                                    </div>
                                </div>

                                <div className="font-size-xs mb-1 mr-2 text-truncate">
                                    {chatData.last_message.type === "text" ? <>{chatData.last_message.content}</> : "Offer Card"}

                                </div>


                                {chatData.route &&
                                    <span className="text-black opacity-5 d-block mb-1">
                                        <div className={'font-size-xs text-truncate'}>
                                            <span>
                                                <span> {chatData.route.origin}</span>
                                                <img
                                                    className={clsx(classes.routeIcon, 'mx-2')}
                                                    src={RouteIcon}
                                                    alt="route icon"
                                                />
                                                <span>{chatData.route.destination}</span>
                                            </span>
                                        </div>
                                    </span>
                                }




                                {/* <div className="font-size-xs mb-1 text-truncate d-flex justify-content-between"> */}
                                {/* <span className="">
                                                        {formatDate(chatData.createdTimestamp)}
                                                    </span> */}
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        {chatData?.route?.status &&
                                            <CustomChip name={`Shipment ${chatData?.route?.status}`} variant={"light"} rounded color={statusColor(chatData.route.status)} />
                                        }
                                    </div>
                                    {chatData?.unreadMessageCount > 0 &&
                                        <CustomChip name={chatData?.unreadMessageCount} rounded variant={"filled"} color={"success"} />
                                    }
                                </div>

                                {/* show this when the number is > 0 only max 99 */}
                                {/* <div className={Style.notification_status}>
                                        99
                                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </ListItem>
                    <div className="divider mx-3" />

                    {/* </List> */}
                </div>
                {/* <div className="text-black-50 text-center p-3 d-block mb-3">
                    <span className="btn-wrapper--label">- End of messages -</span>
                </div> */}
            </div>
        </>
    );
};

export default withStyles(styles)(MobileChatListPane);
