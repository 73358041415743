import React from 'react'
import Style from './KYCPageWrapper.module.css'
const KYCPageWrapper = ({ children }) => {
    return (
        <div className={Style.container}>
            <div className={Style.main}>
                <div>
                    {children}
                </div>

                <div className={Style.right_container}>
                    <img src="/assets/kyc/right_image.png" width="80%" />
                </div>
            </div>

        </div>
    )
}

export default KYCPageWrapper