import { getBaseUrl } from 'config/config';
import { httpService,HttpMethods } from './global';

export default class NotificationsService {
  static async fetchNotificationData(id, token) {
    const url = `forwarder/fetch-notification-data/${id}`;
      
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'notifications_settings',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async manageNotifications(data, token) {
    const url = `forwarder/manage-notification`;
    // const result = await fetch(url, {
    //   method: 'PUT',
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //     'Content-Type': 'application/json',
    //     type: 'notifications_settings',
    //     method: 'edit',
    //     platform: 'Forwarder'
    //   },
    //   body: JSON.stringify(data)
    // });
    // const response = await result.json();
    // return response;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'notifications_settings',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async manageTurnOnNotificationsAndSubscription(data, token) {
    const url = `forwarder/manage-all-notifications-subscriptions`;
    // const result = await fetch(url, {
    //   method: 'PUT',
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //     'Content-Type': 'application/json',
    //     type: 'notifications_settings',
    //     method: 'edit',
    //     platform: 'Forwarder'
    //   },
    //   body: JSON.stringify(data)
    // });
    // const response = await result.json();
    // return response;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'notifications_settings',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async manageNotificationMethod(data, token) {
    const url = `forwarder/update-notification-method`;
    // const result = await fetch(url, {
    //   method: 'PUT',
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //     'Content-Type': 'application/json',
    //     type: 'notifications_settings',
    //     method: 'edit',
    //     platform: 'Forwarder'
    //   },
    //   body: JSON.stringify(data)
    // });
    // const response = await result.json();
    // return response;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'notifications_settings',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
