export const REQUEST_APP_REFRESH = 'REQUEST_APP_REFRESH';
const requestAppRefresh = data => ({
  type: REQUEST_APP_REFRESH,
  payload: data
});

export function refreshApp(bool) {
  return dispatch => {
    dispatch(requestAppRefresh(bool));
  };
}
