import { Button, TextField, MenuItem } from '@mui/material';

import { Edit } from '@mui/icons-material';
import { useState } from 'react';
import CustomButton from 'components/CustomButton/Button';

const genders = [
    {
        value: 'Male',
        label: 'Male',
    },
    {
        value: 'Female',
        label: 'Female',
    },
    {
        value: 'Not Provided',
        label: 'Not Provided',
    }
]
/* eslint-disable */
const EditGender = ({ data, setData, onSubmit, onEditClick, editCancelClick, canEdit }) => {

    const [isFormOpen, setIsFormOpen] = useState(false);

    const toggleEdit = (planDetailId, editFormId) => {
        const planDetail = document.getElementById(planDetailId);
        const editForm = document.getElementById(editFormId);

        planDetail.classList.toggle("c-hidden")
        editForm.classList.toggle("c-hidden")
        editForm.classList.contains("c-hidden") ? setIsFormOpen(false) : setIsFormOpen(true);
    }

    const handleClick = () => {
        onSubmit()
        toggleEdit("gender-detail", "gender-form")
    }

    return (
        <>
            <div>
                <div className="c-flex c-justify-content-between c-items-center">
                    <span className="c-font-small">Gender</span>
                    {canEdit &&
                        <>
                            {isFormOpen
                                ?
                                <CustomButton
                                    onClick={() => { toggleEdit("gender-detail", "gender-form"); editCancelClick() }}
                                    style={{ fontSize: '0.8rem' }}
                                    tooltip="Cancel"
                                >
                                    Cancel
                                </CustomButton>
                                :
                                <CustomButton
                                    onClick={() => { toggleEdit("gender-detail", "gender-form"); onEditClick() }}
                                    icon={<Edit style={{ fontSize: '0.8rem' }} />}
                                    style={{ fontSize: '0.8rem' }}
                                    tooltip="Edit"
                                >
                                    Edit
                                </CustomButton>}
                        </>
                    }

                </div>
                <span id="gender-detail" className="c-font-title c-font-light">{data.gender}</span>

                <div id="gender-form" className="c-hidden">

                    <div className="c-mt-1 c-flex-col c-gap-1">
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select"
                                defaultValue={data.gender}
                                value={data.gender}
                                onChange={(e) => setData({ ...data, gender: e.target.value })}
                                size="small"
                            >
                                {genders.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div>
                            <Button onClick={handleClick} size="small" variant="contained" style={{ textTransform: 'none', backgroundColor: '#2f358b' }}>Save</Button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}
/* eslint-enable */
export default EditGender;