import { Checkbox } from '@material-ui/core';
import React, { useState } from 'react'
import Style from "./CheckWithLabel.module.css";
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';




const CheckWithLabel = ({ label, index }) => {

    const [isChecked, setIsChecked] = useState(false);

    return (
        <div
            onClick={() => setIsChecked(state => !state)}
            className={`${Style.container} ${isChecked && Style.check_container}`}
        >
            <Checkbox
                checked={isChecked}
                id={`${label}-${index}`}
                size='small'
                icon={<RadioButtonUncheckedRoundedIcon />}
                checkedIcon={<CheckCircleOutlineRoundedIcon />}
                className={Style.check_btn}
            />
            <span className='font-size-xs'>{label}</span>
        </div>
    )
}


export default CheckWithLabel