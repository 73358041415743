/* eslint-disable array-callback-return */
import * as moment from 'moment';
import {
  CARGO_CATEGORIES,
  SERVICE_PROVIDER_TYPES,
  TRANSPORT_MODES
} from 'util/constants';
import { getCountryByValue } from 'util/countryList';
import CommonModel from './CommonModel';

export default class QuoteResultModel {
  static initQuote = (type, searchParams) => {
    const {
      load: { cargoType = {}, cargoDetails = {} } = {}
    } = (searchParams = {});
    const { containerType = {} } = cargoDetails;
    return {
      '@type': 'QuoteModel',
      id: null,
      transportService: null,
      transportMode: {
        //CARGO_CATEGORIES
        label: null,
        title: null,
        value: null,
        icon: null,
        mode: { label: null, value: null, icon: null }
      },
      currency: null,
      incoterms: null,
      createDate: null,
      validTo: null,
      origin: {
        type: null,
        city: {
          id: null,
          name: null,
          code: null,
          country: null,
          lat: null,
          lng: null
        },
        port: {
          id: null,
          name: null,
          code: null,
          country: null,
          lat: null,
          lng: null,
          inaccessible: false
        }
      },
      destination: {
        type: null,
        city: {
          id: null,
          name: null,
          code: null,
          country: null,
          lat: null,
          lng: null
        },
        port: {
          id: null,
          name: null,
          code: null,
          country: null,
          lat: null,
          lng: null,
          inaccessible: false
        }
      },
      freight: {
        carrier: {
          id: null,
          type: null,
          name: null,
          logo: null
        },
        cargo: {
          quantity: cargoDetails.quantity,
          value: containerType.value,
          label: containerType.label
        },
        co2Total: {
          emissions: { value: null, unit: null },
          price: { value: null, currency: null }
        },
        validTo: null,
        linerTerms: null,
        freightCost: {
          code: null,
          name: null,
          type: null,
          quantity: null,
          originalPrice: {
            amount: null,
            currency: null
          },
          price: {
            amount: null,
            currency: null
          },
          co2: {
            emissions: { value: null, unit: null },
            price: { value: null, currency: null }
          }
        },
        transitTime: {
          unit: null,
          from: null,
          to: null
        },
        portToPortTransitTime: {
          unit: null,
          from: null,
          to: null
        },
        distance: { value: null, unit: null },
        alternative: false,
        overdue: false,
        remarks: null,
        rateOwner: false,
        freightFees: [],
        serviceFees: [],
        portFeesFrom: [],
        portFeesTo: [],
        truckFrom: [],
        truckTo: [],
        railFrom: [],
        railTo: [],
        dryFrom: null,
        dryTo: null,
        bargeFrom: {
          price: null,
          distance: null,
          transitTime: null,
          validTo: null,
          currency: null,
          co2: {
            emissions: { value: null, unit: null },
            price: { value: null, currency: null }
          },
          port: {
            id: null,
            name: null,
            code: null,
            countryCode: null,
            lat: null,
            lng: null,
            inaccessible: null
          }
        },
        bargeTo: {
          price: null,
          distance: null,
          transitTime: null,
          validTo: null,
          currency: null,
          co2: {
            emissions: { value: null, unit: null },
            price: { value: null, currency: null }
          },
          port: {
            id: null,
            name: null,
            code: null,
            countryCode: null,
            lat: null,
            lng: null,
            inaccessible: null
          }
        },
        services: []
      },
      priceIndicator: {
        rateTypes: [],
        includeOriginPortCharges: false,
        includeDestinationPortCharges: false,
        totalCharge: {
          value: null,
          currencyID: null
        },
        totalChargeDiscounted: {
          value: null,
          currencyID: null
        }
      },
      serviceProviders: [
        {
          providerTypeCode: null,
          name: null,
          id: null
        }
      ],
      URIReferences: {
        bookingConfirm: {
          uri: null,
          validTo: null
        },
        checkout: {
          uri: null,
          validTo: null
        }
      },
      remarks: [],
      additionalInformation: []
    };
  };

  static createQuotesList = (type, quotes, searchParams) =>
    quotes
      ? quotes.map((quote) =>
          QuoteResultModel.createQuoteObj(type, quote, searchParams)
        )
      : [];

  static createQuoteObj = (type, quote, searchParams) => {
    let quoteObj = this.initQuote(type, searchParams);

    const {
      referenceID,
      transportMode,
      transportService,
      connection,
      originLocation,
      destinationLocation,
      businessInfo: { parties } = {},
      nearby,
      createDate,
      validTo,
      insurance,
      priceIndicator,
      remarks,
      co2Emissions,
      additionalInformation,
      URIReferences
    } = quote;

    quoteObj.id = referenceID;
    quoteObj.transportService = transportService;
    quoteObj.createDate = createDate;
    quoteObj.validTo = validTo;

    // Transport Modes & Services
    if (transportMode) {
      quoteObj.transportMode =
        transportMode === 'FCL' || transportMode === 'LCL'
          ? TRANSPORT_MODES.SEA
          : TRANSPORT_MODES.AIR;

      quoteObj.cargoService =
        CARGO_CATEGORIES[String(transportMode).toUpperCase()];
    }
    // Origin
    if (originLocation) {
      quoteObj.origin.type = originLocation.locationTypeCode;
      quoteObj.origin.port.id = originLocation.locationCode;
      quoteObj.origin.port.name = originLocation.locationCode;
      quoteObj.origin.port.code = originLocation.locationCode;
      if (originLocation.countryID) {
        quoteObj.origin.port.country = getCountryByValue(
          originLocation.countryID.value
        );
      }
    }
    // Destination
    if (destinationLocation) {
      quoteObj.origin.type = destinationLocation.locationTypeCode;
      quoteObj.origin.port.id = destinationLocation.locationCode;
      quoteObj.origin.port.name = destinationLocation.locationCode;
      quoteObj.origin.port.code = destinationLocation.locationCode;
      if (destinationLocation.countryID) {
        quoteObj.origin.port.country = getCountryByValue(
          destinationLocation.countryID.value
        );
      }
    }
    // Freight -
    // Carrier & ServiceProviders
    if (parties && parties.length > 0) {
      quoteObj.serviceProviders = [];
      // eslint-disable-next-line array-callback-return
      parties.map((p) => {
        const prov = {
          id: p.ID,
          name: p.name,
          type: SERVICE_PROVIDER_TYPES[p.partyTypeCode]
        };
        if (p.partyTypeCode === 'FW') {
          quoteObj.freight.carrier = prov;
        }
        quoteObj.serviceProviders.push(prov);
      });
    }

    if (co2Emissions) {
      const co2Obj = {
        emissions: {
          value: co2Emissions.value,
          unit: co2Emissions.unitCode.toUpperCase()
        }
      };
      quoteObj.freight.co2Total = co2Obj;
    }

    if (connection.connectionSegments) {
      // eslint-disable-next-line array-callback-return
      connection.connectionSegments.map((segment) => {
        if (segment.charges) {
          if (segment.transportMode === 'FTL') {
            if (segment.legs && segment.legs.length > 0) {
              const segmentFirstLeg = segment[0];
              if (segmentFirstLeg.origin.locationTypeCode.includes('Address')) {
                segment.charges.map((charge) => {
                  quoteObj.freight.truckFrom.push({
                    code: charge.feeCode,
                    name: charge.feeName,
                    type: charge.feeType,
                    quantity: charge.quantity,
                    originalPrice: {
                      amount: charge.unitRate.value,
                      currency: charge.unitRate.currencyID
                    },
                    price: {
                      amount: charge.rate.value,
                      currency: charge.rate.currencyID
                    }
                  });
                });
              } else {
                segment.charges.map((charge) => {
                  quoteObj.freight.truckTo.push({
                    code: charge.feeCode,
                    name: charge.feeName,
                    type: charge.feeType,
                    quantity: charge.quantity,
                    originalPrice: {
                      amount: charge.unitRate.value,
                      currency: charge.unitRate.currencyID
                    },
                    price: {
                      amount: charge.rate.value,
                      currency: charge.rate.currencyID
                    }
                  });
                });
              }
            } else if (segment.transportMode === 'FCL') {
              segment.charges.map((charge) => {
                const chargeObj = {
                  code: charge.feeCode,
                  name: charge.feeName,
                  type: charge.feeType,
                  quantity: charge.quantity,
                  originalPrice: {
                    amount: charge.unitRate.value,
                    currency: charge.unitRate.currencyID
                  },
                  price: {
                    amount: charge.rate.value,
                    currency: charge.rate.currencyID
                  }
                };
                if (charge.name.lowerCase().includes(['freight', 'ocean'])) {
                  let co2Obj = null;
                  if (co2Emissions) {
                    co2Obj = {
                      emissions: {
                        value: co2Emissions.value,
                        unit: co2Emissions.unitCode.toUpperCase()
                      }
                    };
                  }
                  quoteObj.freight.freightCost = { ...chargeObj, co2: co2Obj };
                } else if (charge.name.lowerCase().includes(['export'])) {
                  quoteObj.freight.portFeesFrom.push(chargeObj);
                } else if (charge.name.lowerCase().includes(['import'])) {
                  quoteObj.freight.portFeesTo.push(chargeObj);
                } else {
                  quoteObj.freight.freightFees.push(chargeObj);
                }
              });
            }
          }
        }
      });
    }

    if (
      connection.transitTime &&
      connection.transitTime.estimatedTransitTimes &&
      connection.transitTime.estimatedTransitTimes.length > 0
    ) {
      const firstTT = connection.transitTime.estimatedTransitTimes[0];
      quoteObj.freight.transitTime = {
        from: firstTT.from.value,
        to: firstTT.to.value,
        unit: firstTT.from.unitCode || firstTT.to.unitCode
      };
    }

    if (
      connection.portToPortTransitTime &&
      connection.portToPortTransitTime.estimatedTransitTimes &&
      connection.portToPortTransitTime.estimatedTransitTimes.length > 0
    ) {
      const firstTT = connection.portToPortTransitTime.estimatedTransitTimes[0];
      quoteObj.freight.portToPortTransitTime = {
        from: firstTT.from.value,
        to: firstTT.to ? firstTT.to.value : null,
        unit: firstTT.from.unitCode || firstTT.to.unitCode
      };
    }

    if (connection.generalCharges) {
      connection.generalCharges.map((charge) => {
        let chargeObj = {
          code: charge.feeCode,
          name: charge.feeName,
          type: charge.feeType,
          quantity: charge.quantity,
          originalPrice: {
            amount: charge.unitRate.value,
            currency: charge.unitRate.currencyID
          },
          price: {
            amount: charge.rate.value,
            currency: charge.rate.currencyID
          }
        };
        if (charge.feeType === 'insurance') {
          // insurance
          if (insurance && insurance.insuranceValueAmount) {
            const insuranceObj = {
              value: insurance.insuranceValueAmount.value,
              currency: insurance.insuranceValueAmount.currencyID
            };
            chargeObj = { ...chargeObj, insuranceValue: insuranceObj };
          }
        }
        quoteObj.freight.serviceFees.push(chargeObj);
      });
    }

    if (priceIndicator) {
      quoteObj.priceIndicator = {
        ...priceIndicator,
        totalCharge: {
          value: priceIndicator.totalCharge.value,
          currency: priceIndicator.totalCharge.currencyID
        },
        totalChargeDiscounted: {
          value: priceIndicator.totalChargeDiscounted.value,
          currency: priceIndicator.totalChargeDiscounted.currencyID
        }
      };
    }

    quoteObj.URIReferences = URIReferences;
    quoteObj.remarks = [remarks];
    quoteObj.additionalInformation = additionalInformation;

    return quoteObj;
  };

  /**
   *
   * @param type
   * @returns {*}
   */
  static getQuoteResultTypeFormat = (type, result, searchParams) => {
    if (type.toLowerCase().includes('freightos')) {
      return QuoteResultModel.getFreightosFormat(type, result, searchParams);
    } else if (type.toLowerCase().includes('searates')) {
      return QuoteResultModel.getSearatesFormat(type, result, searchParams);
    } else {
      return QuoteResultModel.getKargoplexFormat(type, result, searchParams);
    }
  };

  /**
   *
   * @param quoteType
   * @param result
   *
   * @returns {{'@type': string, name: *, reservationFor: {activityType: *, startDate: {value: moment.Moment}, endDate: {value: moment.Moment}, location: {name: *}, organizer: {name: *}}, reservationNumber: *, finishLocation: {name: *}, crossDate: *}}
   */
  static getKargoplexFormat = (quoteType, result, searchParams) => {
    const quotes = QuoteResultModel.createQuotesList();
    const summary = QuoteResultModel.createSummaryObj();
    const pagination = CommonModel.createPaginationObj();
    const identity = QuoteResultModel.createIdentityObj();
    const meta = QuoteResultModel.generateMeta();

    return QuoteResultModel.createQuoteResult(
      quoteType,
      quotes,
      summary,
      pagination,
      identity,
      meta
    );
  };

  /**
   *
   * @param quoteType
   * @param result
   *
   * @returns {{'@type': string, name: *, reservationFor: {activityType: *, startDate: {value: moment.Moment}, endDate: {value: moment.Moment}, location: {name: *}, organizer: {name: *}}, reservationNumber: *, finishLocation: {name: *}, crossDate: *}}
   */
  static getSearatesFormat = (quoteType, result, searchParams) => {
    const quotes = QuoteResultModel.createQuotesList();
    const summary = QuoteResultModel.createSummaryObj();
    const pagination = CommonModel.createPaginationObj();
    const identity = QuoteResultModel.createIdentityObj();
    const meta = QuoteResultModel.generateMeta();

    return QuoteResultModel.createQuoteResult(
      quoteType,
      quotes,
      summary,
      pagination,
      identity,
      meta
    );
  };

  /**
   *
   * @param quoteType
   * @param result
   *
   * @returns {{'@type': string, name: *, reservationFor: {activityType: *, startDate: {value: moment.Moment}, endDate: {value: moment.Moment}, location: {name: *}, organizer: {name: *}}, reservationNumber: *, finishLocation: {name: *}, crossDate: *}}
   */
  static getFreightosFormat = (quoteType, result, searchParams) => {
    const {
      messageHeader: { messageID, conversationID } = {},
      businessInfo,
      quotes,
      paging,
      URIReferences
    } = result;

    const quotesList = QuoteResultModel.createQuotesList(
      quoteType,
      quotes,
      searchParams
    );

    // Summary
    let summaryName, randomQuote;
    if (quotes && quotes.length > 0) {
      randomQuote = quotes[0] || {};
    }
    const {
      transportMode,
      transportService,
      originLocation,
      destinationLocation
    } = randomQuote;

    const { countryID: originCountry } = originLocation;
    const { countryID: destinationCountry } = originLocation;

    summaryName = QuoteResultModel.generateResultName(
      transportMode,
      originCountry.value,
      destinationCountry.value,
      transportService
    );

    const summaryObj = QuoteResultModel.createSummaryObj(
      summaryName,
      messageID,
      conversationID,
      'freightos',
      null,
      null,
      transportMode,
      transportService,
      originLocation,
      destinationLocation,
      []
    );

    const { nextCursor, next, hasMore, count } = paging;
    const pagination = CommonModel.createPaginationObj(
      nextCursor,
      next,
      hasMore,
      count
    );
    const identity = QuoteResultModel.createIdentityObj();
    const meta = QuoteResultModel.generateMeta();

    return QuoteResultModel.createQuoteResult(
      quoteType,
      quotesList,
      summaryObj,
      pagination,
      identity,
      meta
    );
  };

  static generateResultName = (
    transportMode,
    originCountry,
    destinationCountry,
    transportService
  ) => {
    let summaryName = '';

    if (transportMode) {
      summaryName = summaryName.concat(transportMode, ' | ');
    }
    if (originCountry) {
      summaryName = summaryName.concat(originCountry, ' ');
    }
    if (destinationCountry) {
      summaryName = summaryName.concat('to ', destinationCountry, ' ');
    }
    if (transportService) {
      summaryName = summaryName.concat('(', transportService, ')');
    }
    return summaryName;
  };

  /**
   *
   * @param quoteType
   * @param quotes
   * @param summary
   * @param pagination
   * @param identity
   * @param meta
   *
   * @returns {{'@type': *, quotes: *, summary: *, pagination: *, identity: *, meta: *, }}
   */
  static createQuoteResult = (
    quoteType,
    quotes,
    summary,
    pagination,
    identity,
    meta
  ) => {
    let quoteResultObj = {
      '@type': `QuoteResult${quoteType}`,
      summary,
      quotes,
      pagination,
      identity,
      meta
    };

    return quoteResultObj;
  };

  /**
   *
   * @param name description of shipment e.g FCL - AC to NG
   * @param resultsId
   * @param searchId
   * @param source
   * @param createdDate
   * @param validDate
   * @param transportMode
   * @param transportServiceType
   * @param origin
   * @param destination,
   * @param includedServices
   *
   * @returns {{name: *, resultsId: *, searchId: *, source: *, createdDate: *, validDate: *, transportMode: *, transportServiceType: *, origin: *, destination: *, includedServices: *}}
   */
  static createSummaryObj = (
    name,
    resultsId,
    searchId,
    source,
    createdDate,
    validDate,
    transportMode,
    transportServiceType,
    origin,
    destination,
    includedServices
  ) => {
    let quoteResultSummaryObj = {
      name,
      resultsId,
      searchId,
      source,
      createdDate,
      validDate,
      transportMode,
      transportServiceType,
      origin,
      destination,
      includedServices
    };

    return quoteResultSummaryObj;
  };

  /**
   *
   * @param firstName
   * @param lastName
   * @param email
   * @param phone
   * @param isAdmin
   * @param isForwarder
   * @param isKFA
   *
   * @returns {{ firstName: *, lastName: *, email: *, phone: *, isAdmin: *, isForwarder: *, isKFA: *}}
   */
  static createIdentityObj = (
    firstName,
    lastName,
    email,
    phone,
    isAdmin,
    isForwarder,
    isKFA
  ) => ({
    firstName,
    lastName,
    email,
    phone,
    isAdmin,
    isForwarder,
    isKFA
  });

  /**
   *
   * @param {boolean} bookingViaEmail
   * @param {boolean} advertising
   * @param referenceLinks
   * @param rest
   *
   * @returns {{bookingViaEmail: *, advertising: *, references: * , ...rest}}
   */
  static generateMeta = (
    bookingViaEmail,
    advertising,
    referenceLinks,
    rest
  ) => {
    return {
      bookingViaEmail,
      advertising,
      referenceLinks,
      ...rest
    };
  };
}
