import * as moment from 'moment';

export default class FlightModel {
  static createPassengerList = passengers =>
    passengers ? passengers.map(passenger => FlightModel.createPassengerObj(passenger)) : [];

  static createPassengerObj = passenger => {
    let passengerObj = {
      '@type': 'FlightReservation',
      underName: {
        familyName: '',
        givenName: ''
      },
      crossDate: false
    };

    if (passenger.givenName) {
      passengerObj.underName.givenName = passenger.givenName;
    }

    if (passenger.underName && passenger.underName.givenName) {
      passengerObj.underName.givenName = passenger.underName.givenName;
    }

    if (passenger.familyName) {
      passengerObj.underName.familyName = passenger.familyName;
    }

    if (passenger.underName && passenger.underName.familyName) {
      passengerObj.underName.familyName = passenger.underName.familyName;
    }

    if (passenger.ticketToken) {
      passengerObj.ticketNumber = passenger.ticketToken;
    }

    if (passenger.ticketNumber) {
      passengerObj.ticketNumber = passenger.ticketNumber;
    }

    if (passenger.airplaneSeat) {
      passengerObj.seat = passenger.airplaneSeat;
    }

    if (passenger.meal) {
      passengerObj.meal = passenger.meal;
    }

    if (passenger.reservationFor) {
      passengerObj.meal = passenger.reservationFor.mealService;
    }

    if (passenger.airplaneSeatClass) {
      if (passenger.airplaneSeatClass.seatCategory) {
        passengerObj.airplaneSeatClass = {
          name: passenger.airplaneSeatClass.seatCategory
        };
      } else {
        passengerObj.airplaneSeatClass = {
          name: passenger.airplaneSeatClass
        };
      }
    }

    if (passenger.programMembershipUsed) {
      passengerObj.programMembership = {
        membershipNumber: passenger.programMembershipUsed.membershipNumber
      };
    }

    if (passenger.membershipNumber) {
      passengerObj.programMembership = {
        membershipNumber: passenger.membershipNumber
      };
    }

    if (passenger.seat) {
      passengerObj.seat = passenger.seat;
    }

    if (passenger.meal) {
      passengerObj.meal = passenger.meal;
    }

    return passengerObj;
  };

  static createNewFlightReservation = (flight, umTripId) => {
    const regExp = /\(([^)]+)\)/g;

    const matches = flight.airline.match(regExp);
    const lastMatch = matches ? matches[matches.length - 1] : '';
    const iataCode = lastMatch !== '' ? lastMatch.substring(1, 3) : '';
    const departureDateConverted = `${flight.departureDate.format('MM/DD/YYYY')} ${flight.departureTime}`;
    const arrivalDateConverted = `${flight.arrivalDate.format('MM/DD/YYYY')} ${flight.arrivalTime}`;
    let flightObj = {
      '@type': 'FlightReservation',
      umTripId: umTripId,
      name: flight.airline,
      reservationFor: {
        flightNumber: flight.flightNumber,
        airline: {
          name: flight.airline
        },
        departureAirport: {
          name: flight.departureAirport
        },
        arrivalAirport: {
          name: flight.arrivalAirport
        },
        departureTime: moment.utc(departureDateConverted, 'MM/DD/YYYY HH:mm').format(),
        arrivalTime: moment.utc(arrivalDateConverted, 'MM/DD/YYYY HH:mm').format()
      },
      crossDate: flight.crossDate
    };

    if (flight.airlineUmId !== '' && iataCode !== '') {
      flightObj.reservationFor.airline.umId = flight.airlineUmId;
      flightObj.reservationFor.airline.iataCode = iataCode;
    }

    if (flight.departureAirportUmId !== '') {
      flightObj.reservationFor.departureAirport.umId = flight.departureAirportUmId;
    }

    if (flight.arrivalAirportUmId !== '') {
      flightObj.reservationFor.arrivalAirport.umId = flight.arrivalAirportUmId;
    }

    return flightObj;
  };

  static createFlightReservation = (flight, umTripId) => {
    const { subReservation, ...props } = flight;

    let flightReservationObj = {
      '@type': 'FlightReservation',
      umId: flight.umId,
      umTripId: umTripId,
      crossDate: flight.crossDate,
      ...props
    };

    if (subReservation) {
      flightReservationObj.passengers = FlightModel.createPassengerList(subReservation);
    }

    return flightReservationObj;
  };

  static createSummaryObj = (
    airlineName,
    airlineUmId,
    airlineIataCode,
    arrivalAirportName,
    arrivalAirportUmId,
    arrivalAirportIataCode,
    arrivalTerminal,
    arrivalTime,
    departureAirportName,
    departureAirportUmId,
    departureAirportIataCode,
    departureTerminal,
    departureTime,
    flightNumber,
    reservationNumber,
    crossDate
  ) => {
    let flightSummaryObj = {
      name: airlineName,
      reservationNumber,
      reservationFor: {
        airline: {
          name: airlineName
        },
        arrivalTime,
        flightNumber,
        departureTime,
        arrivalAirport: {
          name: arrivalAirportName
        },
        arrivalTerminal,
        departureAirport: {
          name: departureAirportName
        },
        departureTerminal
      },
      crossDate
    };

    if (airlineUmId !== '') {
      flightSummaryObj.reservationFor.airline.umId = airlineUmId;
    }

    if (airlineIataCode !== '') {
      flightSummaryObj.reservationFor.airline.iataCode = airlineIataCode;
    }

    if (arrivalAirportUmId !== '') {
      flightSummaryObj.reservationFor.arrivalAirport.umId = arrivalAirportUmId;
    }

    if (arrivalAirportIataCode !== '') {
      flightSummaryObj.reservationFor.arrivalAirport.iataCode = arrivalAirportIataCode;
    }

    if (departureAirportUmId !== '') {
      flightSummaryObj.reservationFor.departureAirport.umId = departureAirportUmId;
    }

    if (departureAirportIataCode !== '') {
      flightSummaryObj.reservationFor.departureAirport.iataCode = departureAirportIataCode;
    }

    return flightSummaryObj;
  };

  static updateFlightReservation = (
    umTripId,
    umId,
    rates,
    passengers,
    description,
    cancellationPolicy,
    pricing,
    summary
  ) => ({
    '@type': 'FlightReservation',
    ...summary,
    umTripId,
    passengers,
    umId,
    rates,
    ...pricing,
    description,
    cancellationPolicy
  });
}
