import { useEffect, useState } from "react";
import Style from "./ShipmentRates.module.css";
import RateSheet from "./rate_sheet/RateSheet";
import Blank from "./blank/Blank";
import Header from "layout-components/Header/Header";
import RightDrawer from "sites/shipper/components/layout/RightDrawer";
import AddRateSheetForm from "./AddRateSheetForm/AddRateSheetForm";
import { ForwarderService } from "service";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import ForwarderTeam from "service/ForwarderTeam";
import PageError403 from 'components/PageError403';
import { ScaleLoader } from "react-spinners";
import Tabs from "components/Tabs";
/* eslint-disable */

const ShipmentRates = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([]);
    const [openRightDrawer, setOpenRightDrawer] = useState(false);
    const toggleRightDrawer = (bool) => {
        setOpenRightDrawer(bool);
    };


    const [rateSheets, setRateSheets] = useState([])
    const [error, setError] = useState([])

    const auth = useSelector((state) => state.session.auth)

    const [run, setRun] = useState(false) // to referesh the data after some action is performed from the frontend
    const inits = async () => {
        await ForwarderService.fetchRateSheet(auth.accountId).then(data => {
            if (data.length > 0) {
                setRateSheets(data)
            } else {
                setError(data.error)
            }

        })
    }
    useEffect(() => {
        inits()
    }, [auth, run])



    return (
        <>
            {loading ?
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <ScaleLoader color={'#793de6'} loading={loading} />
                </div>
                :

                <>
                        <div className={Style.container}>
                            <div className={Style.main}>
                                <div className={Style.options_container}>
                                    <Button
                                        className="btn-primary"
                                        onClick={() => toggleRightDrawer(true)}
                                    >
                                        Create Rate Sheet
                                    </Button>
                                </div>

                                <Tabs
                                    content={[
                                        {
                                            label: 'Standard',
                                            View: data ?
                                                <RateSheet rateSheets={rateSheets} run={run} setRun={setRun} toggleRightDrawer={toggleRightDrawer} />
                                                :
                                                <Blank />
                                        },
                                        {
                                            label: 'Custom',
                                            View: data ?
                                                <Blank />
                                                :
                                                <RateSheet rateSheets={rateSheets} run={run} setRun={setRun} toggleRightDrawer={toggleRightDrawer} />
                                        },
                                    ]}
                                />

                                {/* {data ?
                                    <RateSheet rateSheets={rateSheets} run={run} setRun={setRun} toggleRightDrawer={toggleRightDrawer} />
                                    :
                                    <Blank />
                                } */}
                            </div>

                            <RightDrawer
                                onClose={() => toggleRightDrawer(false)}
                                open={openRightDrawer}
                            >
                                <AddRateSheetForm run={run} setRun={setRun} toggleRightDrawer={toggleRightDrawer} />
                            </RightDrawer>

                        </div>
                </>
            }


        </>
    )
}
/* eslint-enable */
export default ShipmentRates