import { CommonModel } from 'model';
import { ProfileService } from 'service';
import PaymentService from 'service/PaymentService';

/*-- Action Creators for Info Fetch --*/
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const userInfoRequest = () => ({
  type: USER_INFO_REQUEST
});

export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const userInfoSuccess = (data) => ({
  type: USER_INFO_SUCCESS,
  payload: data
});

export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';
export const userInfoFailure = (data) => ({
  type: USER_INFO_FAILURE,
  payload: data
});

export function fetchUserInfo(id, token) {
  return (dispatch) => {
    dispatch(userInfoRequest());

    return ProfileService.getUserInfo(id, token)
      .then((res) => {
        console.log('User Info ===', res);
        if (res.returnCode === 0) {
          dispatch(userInfoSuccess(res));
        } else {
          dispatch(userInfoFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userInfoFailure(res)));
  };
}

/*-- Action Creators for Info Fetch --*/
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const userUpdateRequest = () => ({
  type: USER_UPDATE_REQUEST
});

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const userUpdateSuccess = (data) => ({
  type: USER_UPDATE_SUCCESS,
  payload: data
});

export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const userUpdateFailure = (data) => ({
  type: USER_UPDATE_FAILURE,
  payload: data
});

export function updateUserInfo(data, auth) {
  console.log('Update Data: ', data, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.updateUserInfo(auth.accountId, data, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function updateUserBillingInfo(data, auth) {
  console.log('Update Data: ', data, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.updateUserBillingInfo(auth.accountId, data, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function updateUserAvatar(formData, auth) {
  console.log('Action: Update Avatar Data: ', formData, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.updateUserAvatar(auth.accountId, formData, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function deleteUserAvatar(auth) {
  console.log('Action: Delete Avatar: ', auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.deleteUserAvatar(auth.accountId, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function updateUserCompanyInfo(data, auth) {
  console.log('Update Company Data: ', data, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.updateUserCompanyInfo(auth.accountId, data, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function updateCompanyLogo(cmpyId, data, auth) {
  console.log('Action: Update Logo Data: ', cmpyId, data, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.updateCompanyLogo(auth.accountId, cmpyId, data, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function deleteCompanyLogo(cmpyId, auth) {
  console.log('Action: Delete Logo: ', cmpyId, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.deleteCompanyLogo(auth.accountId, cmpyId, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function updateUserShippingInfo(data, auth) {
  console.log('Update Company Data: ', data, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.updateUserShippingInfo(auth.accountId, data, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export function changePassword(data, auth) {
  console.log('Changing Pwd: ', data, auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(userUpdateRequest());

    return ProfileService.changePassword(auth.accountId, data, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(userUpdateSuccess(res));
        } else {
          dispatch(userUpdateFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(userUpdateFailure(res)));
  };
}

export const BILLING_INFO_REQUEST = 'BILLING_INFO_REQUEST';
export const billingInfoRequest = () => ({
  type: BILLING_INFO_REQUEST
});

export const BILLING_INFO_SUCCESS = 'BILLING_INFO_SUCCESS';
export const billingInfoSuccess = (data) => ({
  type: BILLING_INFO_SUCCESS,
  payload: data
});

export const BILLING_INFO_FAILURE = 'BILLING_INFO_FAILURE';
export const billingInfoFailure = (data) => ({
  type: BILLING_INFO_FAILURE,
  payload: data
});

export function fetchSavedCards(auth) {
  console.log('fetchSavedCards: ', auth);
  const token = CommonModel.createUserTokenObj(auth);
  return (dispatch) => {
    dispatch(billingInfoRequest());

    return PaymentService.fetchSavedCards(auth.accountId, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(billingInfoSuccess(res));
        } else {
          dispatch(billingInfoFailure(res));
        }

        return res;
      })
      .catch((res) => dispatch(billingInfoFailure(res)));
  };
}
