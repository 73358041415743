import React from 'react'
import { useLocation } from 'react-router-dom';
import Style from "./DocumentPreviewPage.module.css";

const DocumentPreviewPage = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlParam = queryParams.get('url');
    const type = queryParams.get('type')

    return (
        <div className={Style.outer_container}>
            <iframe align='middle' className={type == 'image' ? Style.iframe : Style.d_iframe} src={urlParam} title="Preview"></iframe>
        </div>
    )
}

export default DocumentPreviewPage