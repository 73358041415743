// import AWS from 'aws-sdk';

import ConstantValuesService from "../service/ConstantValuesService";
// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   region: process.env.REACT_APP_AWS_REGION
// });

// const s3 = new AWS.S3();

export const upload_to_s3 = async (file, folder, token) => {
  // console.log(file);
  // const params = {
  //   Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  //   Key: `${folder}/${file.name}`,
  //   Body: file,
  //   ContentType: file.type
  // };
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folder);
    const result = await ConstantValuesService.uploadToS3(formData, token)
    if (result.error) {
      console.error('Error uploading to S3:', result.error);
    } else {
      return result.link
    }

    // const data = await s3.upload(params).promise();
    // console.log(data.Location);
    // return data.Location;
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
  }
};

export const deleteFileFromS3 = async (fileUrl, token) => {
  // const urlParts = fileUrl.split('/');
  // const keyParts = [urlParts[3], urlParts[4], urlParts[5]];
  // const fileKey = keyParts.join('/');
  // const params = {
  //     Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  //     Key: fileKey,
  // };

  ConstantValuesService.deleteFromS3({ fileUrl: fileUrl }, token).then(res => {
    if (!res?.success) {
      console.error('Error deleting file:', res?.error);
      // throw res?.error;
    } else {
      console.log(res?.message)
    }
  })
  try {
    // await s3.deleteObject(params).promise();
    // console.log('File deleted successfully');
  } catch (error) {
    console.error('Error deleting file:', error);
  }
};


export const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes