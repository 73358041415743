import BoxContainer from 'components/KYCUploadSystem/BoxContainer/BoxContainer'
import { Button } from '@mui/material'
import React from 'react'
import EditGender from '../../MyShipper/ShipperManagement/Reuseables/EditGender';
import EditForm from '../../MyShipper/ShipperManagement/Reuseables/EditForm';
import { addressToString } from 'util/Utils';
import EditableForm from 'components/EditableForm';
import Style from './NotVerified.module.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { handlePreview, showPreview } from 'util/OpenPreviewWindow/OpenPreviewWindow';
import { VerificationStatus } from 'util/VerificationStatus';

const VerificationSummaryDetails = ({ kycStatus, formData, data, acknowledgement, setAcknowledgement }) => {
    function convertToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    return (
        <>
            <BoxContainer>
                <div className={Style.left_inner} style={{ gap: "1rem" }}>
                    <div className="app-page-title--heading"><h1>Details</h1></div>
                    {(kycStatus.individual === VerificationStatus.PENDING || kycStatus.individual === VerificationStatus.REJECTED) &&
                        <>
                            <EditForm
                                title="Legal Name"
                                canEdit={false}
                                detail={`${formData.first_name} ${formData.last_name}`}
                            />
                            <div className="divider" />
                            <EditForm
                                title="Email"
                                canEdit={false}
                                detail={`${formData.email}`}
                            />
                            <div className="divider" />
                            <EditableForm
                                id="phone_number"
                                canEdit={false}
                                label="Phone Number"
                                value={`${formData.phone_number}`}
                            >
                            </EditableForm>
                            <div className="divider" />
                            <EditGender
                                canEdit={false}
                                data={formData}
                            />
                            <div className="divider" />
                            <EditForm
                                title="Date Of Birth"
                                canEdit={false}
                                detail={convertToYYYYMMDD(formData.date_of_birth)}
                            />
                            {(kycStatus.company === VerificationStatus.PENDING || kycStatus.company === VerificationStatus.REJECTED) &&
                                <div className="divider" />
                            }
                        </>
                    }
                    {(kycStatus.company === VerificationStatus.PENDING || kycStatus.company === VerificationStatus.REJECTED) &&

                        <>
                            <EditForm
                                title="Company Name"
                                canEdit={false}
                                detail={formData.company_name}
                            />
                            <div className="divider" />
                            <EditForm
                                title="Job Title"
                                canEdit={false}
                                detail={formData.jobTitle}
                            />
                            <div className="divider" />
                            <EditForm
                                title="Industry"
                                canEdit={false}
                                detail={formData.industry}
                            />
                            <div className="divider" />
                            <EditableForm
                                id="address"
                                canEdit={false}
                                label="Address"
                                value={addressToString(formData.address)}
                            >
                            </EditableForm>
                        </>}

                    <div className="divider" />
                    <div className="app-page-title--heading"><h1>Personal Verification Documents</h1></div>
                    {data.Individual.map((item, index) => (
                        <>
                            <ShowDocuments
                                name={item.docType}
                                number={item.docNumber}
                                uploads={item.uploads}
                            />
                            {(index !== data.Individual.length - 1) && <div className="divider" />}
                        </>
                    ))}
                    {/* {data.is_not_registered_company &&
                        <span className={Style.form_head}>My company is not registered in government records </span>
                    } */}
                    {data.Company.length > 0 && <>
                        <div className="divider" />
                        <div className="app-page-title--heading"><h1>Company Verification Documents</h1></div>
                        {data.Company.map((item, index) => (
                            <>
                                <ShowDocuments
                                    name={item.docType}
                                    number={item.docNumber}
                                    uploads={item.uploads}
                                />
                                {(index !== data.Individual.length - 1) && <div className="divider" />}
                            </>
                        ))}
                    </>
                    }
                    <div className="divider" />
                    <label style={{ display: "flex", alignItems: "start", gap: "10px" }}>
                        <input style={{ margin: "5px 0 0 0" }} type="checkbox" checked={acknowledgement} onChange={(e) => setAcknowledgement(e.target.checked)} />
                        <span className="font-size-sm text-black">I hereby confirm that all information provided in the uploaded documents is accurate and truthful to the best of my knowledge. I understand that any misrepresentation or falsification may result in consequences as per the applicable laws and regulations. By checking this box, I acknowledge and consent to the verification process based on the information provided.</span>
                    </label>
                </div>
            </BoxContainer>
        </>
    )
}

export default VerificationSummaryDetails



const ShowDocuments = ({ name, number, uploads }) => {
    return (
        <div>
            <div style={{ padding: "0 0 0.5rem 0" }}>
                {name} - {number}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                {uploads.map((item, index) => (
                    <Button
                        onClick={() => handlePreview(showPreview(item.file))}
                        className={`${Style.view_doc_btn} btn-neutral-primary`}
                        startIcon={<VisibilityIcon />}
                    >
                        {item.name}
                    </Button>
                ))}
            </div>
        </div>
    )
}