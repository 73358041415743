import { responsiveFontSizes } from '@material-ui/core';
import { getBaseUrl } from '../config/config';
import { HttpMethods, HttpService } from './index';

export default class ForwarderService {
  static createForwarder(data) {
    const url = 'user/forwarder-registration';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        // console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static verifyForwarderEmail(data) {
    const url = 'user/verify-forwarder-registration';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        // console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async submit_kyc_documents(formData) {
    const baseURL = getBaseUrl();

    const url = `${baseURL}user/upload-documents-for-kyc`;
    try {
      const response = await fetch(`${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }

    // return HttpService.requestFormData(HttpMethods.PUT, url, data)
    //     .then((res) => {
    //         // console.log('createUser SRVICE resp', res);
    //         return res;
    //     })
    //     .catch((err) => console.error(err));
  }

  static createRateSheet(id, data) {
    const url = `forwarder/create-rate-sheet?forwarder_id=${id}`;
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetchRateSheet(id) {
    const url = `forwarder/fetch-rate-sheet?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static createPriceBreakDown(data) {
    const url = `forwarder/upload-rate-sheet`;
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static deleteRateSheet(id) {
    const url = `forwarder/delete-rate-sheet?id=${id}`;
    return HttpService.request(HttpMethods.DELETE, url)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static editRateSheet(id, data) {
    const url = `forwarder/edit-rate-sheet?id=${id}`;
    return HttpService.request(HttpMethods.PUT, url, data)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static updatePriceBreakDown(data) {
    const url = `forwarder/update-price-breakdown`;
    return HttpService.request(HttpMethods.PUT, url, data)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }





  

  static make_offer(data) {
    const url = `forwarder/send-proposal`;
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetch_proposal(id) {
    const url = `forwarder/fetch-proposal?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetch_proposal_by_id(id) {
    const url = `forwarder/fetch-single-proposal?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        // window.alert("hello", res)
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async fetch_shipement_forwarder(id, data, token) {
    const url = `shippment/fetch-shipment-forwarder?id=${id}`;
    const response = await fetch(`${getBaseUrl()}${url}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    return result;
  }

  // static create_document(data) {
  //   const url = `forwarder/create-document`;
  //   return HttpService.request(HttpMethods.POST, url, data)
  //     .then((res) => {
  //       // window.alert("hello",res)
  //       return res;
  //     })
  //     .catch((err) => console.error(err));
  // }

  // static fetch_documents(id, page, data, accountId,token) {
  //   const url = `forwarder/fetch-document?forwarder_id=${id}&page=${page}&accountId=${accountId}`;
  //   return HttpService.request(HttpMethods.POST, url, data)
  //     .then((res) => {
  //       // window.alert("hello",res)
  //       return res;
  //     })
  //     .catch((err) => console.error(err));
  // }

  static async createActiveRegion(id, data, token) {
    const url = `${getBaseUrl()}forwarder/service/create-active-region?id=${id}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'active_region',
        method: 'create',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    return result;
  }

  static async updateActiveRegion(id, data, token) {
    const url = `${getBaseUrl()}forwarder/service/update-active-region?id=${id}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'active_region',
        method: 'edit',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    return result;
  }

  static async deleteActiveRegion(id, forwarderServiceId, token) {
    const url = `${getBaseUrl()}forwarder/service/delete-active-region?id=${id}&forwarderServiceId=${forwarderServiceId}`;
    const response = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'active_region',
        method: 'delete',
        platform: 'Forwarder'
      }
    });
    const result = await response.json();
    return result;
  }

  static async fetchService(id, token) {
    const url = `${getBaseUrl()}forwarder/service/fetch-active-region?id=${id}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'my_services',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  // static async fetchInactiveService(id, country, token) {
  //     const url = `${getBaseUrl()}forwarder/service/fetch-inactive-services?id=${id}&country=${country}`;
  //     const res = await fetch(url, {
  //         headers: {
  //             "Authorization": "Bearer " + token
  //         }
  //     })
  //     const result = await res.json()
  //     return result
  // }

  static async updateServiceStatus(id, forwarderService_id, serviceId, token) {
    const url = `${getBaseUrl()}forwarder/service/update-service-status?id=${id}&forwarderService_id=${forwarderService_id}&serviceId=${serviceId}`;
    const response = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'my_services',
        method: 'edit',
        platform: 'Forwarder'
      }
    });
    const result = await response.json();
    return result;
  }

  static async createNote(data) {
    const url = `${getBaseUrl()}shippment/create-shippment-note`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetchNotes(id, type) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-note?id=${id}&type=${type}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async updateShipmentStatus(data) {
    const url = `${getBaseUrl()}shippment/update-shippment-status`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetchShipmentStatus(id) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-status?id=${id}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async updateImportantDates(data, token) {
    const url = `${getBaseUrl()}shippment/update-important-dates`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetch_shipment_documents(id, token) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-documents?id=${id}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async cancelShipment(data, token) {
    const url = `${getBaseUrl()}shippment/cancel-shipment`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async checkReviewGiven(id, token) {
    const url = `${getBaseUrl()}shippment/rating/check?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async fetchDocumentsForwarder(id, token) {
    const url = `${getBaseUrl()}shippment/documents/fetch-forwarder?forwarder_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async fetchRequestCreated(id, token) {
    const url = `${getBaseUrl()}shippment/request-change/fetch-request-change?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async rejectRequestCreated(data, token) {
    const url = `${getBaseUrl()}shippment/request-change/reject-request-change`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async sendProposalOnRequestChange(data, token) {
    const url = `${getBaseUrl()}shippment/request-change/send-proposal-request-change`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }
}
