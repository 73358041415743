import Tabs from "components/Tabs";
import ContainerDetails from "./ContainerDetails/ContainerDetails";
import Style from "./ExpandDetails.module.css";
import PriceHistoryChart from "./chart/Chart";
import InnerTab from "./InnerTab/InnerTab";


/* eslint-disable */

const ExpandDetails = ({ run, setRun, id, data, data_id }) => {
    const content = [
        {
            label: '20FT',
            View: <InnerTab name={"20FT"} run={run} setRun={setRun} data={data} data_id={data_id} />
        },
        {
            label: '40FT',
            View: <InnerTab name={"40FT"} run={run} setRun={setRun} data={data} data_id={data_id} />
        },
        {
            label: '40FT HC',
            View: <InnerTab name={"40FT HC"} run={run} setRun={setRun} data={data} data_id={data_id} />
        },
        {
            label: '45FT HC',
            View: <InnerTab name={"45FT HC"} run={run} setRun={setRun} data={data} data_id={data_id} />
        }
    ]

    return (
        <div id={id} className={`${Style.extend}`}>
            <div className={Style.extend_inner_container}>

                <Tabs content={content} className={Style.tabs_container} />

                {/* <div className={Style.container}>
                    <ContainerDetails name={"20FT"} run={run} setRun={setRun} data={data} data_id={data_id} />
                    <ContainerDetails name={"40FT"} run={run} setRun={setRun} data={data} data_id={data_id} />
                    <ContainerDetails name={"40FT HC"} run={run} setRun={setRun} data={data} data_id={data_id} />
                    <ContainerDetails name={"45FT HC"} run={run} setRun={setRun} data={data} data_id={data_id} />
                </div> */}

                {/* <div className={Style.graph_container}>
                    <PriceHistoryChart />
                </div> */}
            </div>
        </div>

    )
}
/* eslint-enable */
export default ExpandDetails