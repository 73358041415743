import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class CollaboratorService {
  static async fetchCollaborators(id, page, query, token) {
    const url = `tool/fetch-collaborator?id=${id}&query=${query}&page=${page}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipments',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }



  static checkCollaboratorAllreadyExist(data, token) {
    const url = 'tool/check-collaborator-email';
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }



  static createCollaborator(data, token) {
    const url = 'tool/create-collaborator';
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static deleteCollaborator(id) {
    const url = `tool/delete-collaborator?id=${id}`;
    return HttpService.request(HttpMethods.DELETE, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static updateCollaborator(id, data) {
    const url = `tool/update-collaborator?id=${id}`;
    return HttpService.request(HttpMethods.PUT, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }
}
