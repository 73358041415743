import { CUSTOM_BROKER_LIST } from 'util/custombrokerList';
import { FORWARDING_AGENTS } from 'util/forwarderList';
import { INSURANCE_BROKER_LIST } from 'util/insuranceBrokers';
import { SHIPPING_LINES } from 'util/shippingLines';
import {
  CONTAINER_STATUS,
  CARGO_TYPES,
  SHIPMENT_STATUS
} from '../../../../../util/constants';

export const ACTIVE_SHIPMENTS = {
  data: [
    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505620',
      bookingName: 'Escavators',
      createdTimestamp: 1613379940000,
      lastUpdatedTimestamp: 1613481940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.SHIPPING_IN_PROGRESS,
      shipmentInfo: {
        category: 'Machineries',
        description: 'Escavators',
        weight: '23000 KG',
        containerType: CARGO_TYPES.FTL_40_OT,
        quantity: 7,
        origin: {
          placeOfReceipt: undefined,
          port: 'Abuja',
          country: { label: 'Nigeria', value: 'NG' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Douala',
          country: { label: 'Cameroon', value: 'CM' }
        },
        bookingRef: '193CA0679394',
        carrierInfo: {
          company: SHIPPING_LINES.ESHIPPER,
          vessel: 'Alvanbeem',
          voyageNo: '134E42'
        },
        expectedDelivery: 1615504400000,
        containerStatus: {
          location: 'COTONOU, BJ',
          status: CONTAINER_STATUS.TRANSSHIPMENT_LOADED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR1,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER1,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER1
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505607',
      bookingName: 'Soda Ash',
      createdTimestamp: 1610279940000,
      lastUpdatedTimestamp: 1610299940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.CARGO_HOLD,
      shipmentInfo: {
        category: 'Organic Salt',
        description: 'Soda Ash',
        weight: '21000 KG',
        containerType: CARGO_TYPES.FCL_40_RFG,
        quantity: 8,
        origin: {
          placeOfReceipt: undefined,
          port: 'Yarimca',
          country: { label: 'Turkey', value: 'TR' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Apapa',
          country: { label: 'Nigeria', value: 'NG' }
        },
        bookingRef: '193CA0679394',
        containerNo: 'CXDU2004500',
        carrierInfo: {
          company: SHIPPING_LINES.MSC,
          vessel: 'MSC ALYSSA',
          voyageNo: '134E42'
        },
        billOfLading: {
          id: 'MEDUMT146799',
          url: ''
        },
        expectedDelivery: 1616504400000,
        containerStatus: {
          location: 'ANTWERP, VAN, BE',
          status: CONTAINER_STATUS.TRANSSHIPMENT_DISCHARGED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR2,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER2,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER2
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505609',
      bookingName: 'Immersion Oils',
      createdTimestamp: 1610279940000,
      lastUpdatedTimestamp: 1610299940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.CARGO_READY,
      shipmentInfo: {
        category: 'Other Plants and Animals',
        description: 'Immersion Oils',
        weight: '21000 KG',
        containerType: CARGO_TYPES.SHIP_BREAK_BULK,
        quantity: 1,
        origin: {
          placeOfReceipt: undefined,
          port: 'Casablanca',
          country: { label: 'Morocco', value: 'MA' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Miami',
          country: { label: 'United States', value: 'US' }
        },
        bookingRef: '193CA0679394',
        containerNo: 'CXDU2004500',
        carrierInfo: {
          company: SHIPPING_LINES.CMA,
          vessel: 'MSC ALYSSA',
          voyageNo: '134E42'
        },
        billOfLading: {
          id: 'MEDUMT146799',
          url: ''
        },
        expectedDelivery: 1616504400000,
        containerStatus: {
          location: 'CASABLANCA, MA',
          status: CONTAINER_STATUS.GATE_IN_FULL
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR3,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER3,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER3
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505608',
      bookingName: 'Toyota and Lexus SUVs',
      createdTimestamp: 1610379940000,
      lastUpdatedTimestamp: 1610399940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.DEPART_FOR_SHIPPING,
      shipmentInfo: {
        category: 'Used Automobiles',
        description: 'Lexus and Toyota SUVs',
        weight: '21000 KG',
        containerType: CARGO_TYPES.FCL_45_HC,
        quantity: 2,
        origin: {
          placeOfReceipt: 'Toronto, ON',
          port: 'Montreal',
          country: { label: 'Canada', value: 'CA' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Apapa',
          country: { label: 'Nigeria', value: 'NG' }
        },
        bookingRef: '193CA0679394',
        containerNo: 'CXDU2004500',
        carrierInfo: {
          company: SHIPPING_LINES.HAPAG,
          vessel: 'Tayma',
          voyageNo: '154E42'
        },
        billOfLading: {
          id: 'TCLUT146799',
          url: ''
        },
        expectedDelivery: 1617504400000,
        containerStatus: {
          location: 'MONTREAL, QC, CA',
          status: CONTAINER_STATUS.LOADED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR4,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER1,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER4
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505616',
      bookingName: 'Children cabinet',
      createdTimestamp: 1611279940000,
      lastUpdatedTimestamp: 1612299940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.SHIPPING_IN_PROGRESS,
      shipmentInfo: {
        category: 'Furnitures',
        description: 'Children cabinet',
        weight: '3000 KG',
        containerType: CARGO_TYPES.FCL_20_OT,
        quantity: 1,
        origin: {
          placeOfReceipt: undefined,
          port: 'London',
          country: { label: 'United Kingdom', value: 'GB' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Mombasa',
          country: { label: 'Kenya', value: 'KE' }
        },
        bookingRef: '193CA0679394',
        containerNo: 'ZIMU2004500',
        carrierInfo: {
          company: SHIPPING_LINES.ZIM,
          vessel: 'ZIMED',
          voyageNo: '134E42'
        },
        billOfLading: {
          id: 'ZIMUMT146799',
          url: ''
        },
        expectedDelivery: 1618504400000,
        containerStatus: {
          location: 'MOMBASA, KE',
          status: CONTAINER_STATUS.DISCHARGED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR5,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER2,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER1
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505617',
      bookingName: 'Light Fixtures',
      createdTimestamp: 1611279940000,
      lastUpdatedTimestamp: 1612299940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.ARRIVED_AT_DESTINATION,
      shipmentInfo: {
        category: 'Electricals & Electronics',
        description: 'Light Fixtures',
        weight: '13000 KG',
        containerType: CARGO_TYPES.LCL,
        quantity: 3,
        origin: {
          placeOfReceipt: undefined,
          port: 'Guangzhou',
          country: { label: 'China', value: 'CN' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Accra',
          country: { label: 'Ghana', value: 'GH' }
        },
        bookingRef: '193CA0679394',
        containerNo: 'CSCU2004500',
        carrierInfo: {
          company: SHIPPING_LINES.COSCO,
          vessel: 'Alvanbeem',
          voyageNo: '134E42'
        },
        billOfLading: {
          id: 'CSCUT146799',
          url: ''
        },
        expectedDelivery: 1617504400000,
        containerStatus: {
          location: 'ACCRA, GH',
          status: CONTAINER_STATUS.GATE_OUT_FULL
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR6,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER3,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER2
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505618',
      bookingName: 'Coarse Sands',
      createdTimestamp: 1611379940000,
      lastUpdatedTimestamp: 1612499940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.ARRIVED_AT_DESTINATION,
      shipmentInfo: {
        category: 'Minerals and Stones',
        description: 'Coarse Sands',
        weight: '23000 KG',
        containerType: CARGO_TYPES.SHIP_CARGO_BULK,
        quantity: 1,
        origin: {
          placeOfReceipt: undefined,
          port: 'Cape Town',
          country: { label: 'South Africa', value: 'ZA' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Dubai',
          country: { label: 'United Arab Emirates', value: 'AE' }
        },
        bookingRef: '193CA0679394',
        carrierInfo: {
          company: SHIPPING_LINES.MAERSK,
          vessel: 'Alvanbeem',
          voyageNo: '134E42'
        },
        billOfLading: {
          id: 'APMT146799',
          url: ''
        },
        expectedDelivery: 1618504400000,
        containerStatus: {
          location: 'BAHRAIN, BH',
          status: CONTAINER_STATUS.TRANSSHIPMENT_LOADED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR7,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER2,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER3
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505619',
      bookingName: 'Coal',
      createdTimestamp: 1611379940000,
      lastUpdatedTimestamp: 1612499940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.ARRIVED_AT_DESTINATION,
      shipmentInfo: {
        category: 'Minerals and Stones',
        description: 'Coal',
        weight: '23000 KG',
        containerType: CARGO_TYPES.FRL_HOPPER_WAGON,
        quantity: 7,
        origin: {
          placeOfReceipt: undefined,
          port: 'Addis Ababa',
          country: { label: 'Ethiopia', value: 'ET' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Suez Canal',
          country: { label: 'Egypt', value: 'EG' }
        },
        bookingRef: '193CA0679394',
        containerNo: 'APM2004500',
        carrierInfo: {
          company: SHIPPING_LINES.RAILCORP,
          vessel: 'Alvanbeem',
          voyageNo: '134E42'
        },
        expectedDelivery: 1618504400000,
        containerStatus: {
          location: 'AIN SUKHNA, EG',
          status: CONTAINER_STATUS.TRANSSHIPMENT_DISCHARGED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR1,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER1,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER4
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505620',
      bookingName: 'Escavators',
      createdTimestamp: 1613379940000,
      lastUpdatedTimestamp: 1613481940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.ARRIVED_AT_DESTINATION,
      shipmentInfo: {
        category: 'Machineries',
        description: 'Escavators',
        weight: '23000 KG',
        containerType: CARGO_TYPES.FTL_20_RFG,
        quantity: 7,
        origin: {
          placeOfReceipt: undefined,
          port: 'Abuja',
          country: { label: 'Nigeria', value: 'NG' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Douala',
          country: { label: 'Cameroon', value: 'CM' }
        },
        bookingRef: '193CA0679394',
        carrierInfo: {
          company: SHIPPING_LINES.ESHIPPER,
          vessel: 'Alvanbeem',
          voyageNo: '134E42'
        },
        expectedDelivery: 1615504400000,
        containerStatus: {
          location: 'COTONOU, BJ',
          status: CONTAINER_STATUS.TRANSSHIPMENT_LOADED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR2,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER3,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER1
    },

    {
      id: '23hjdjbh45559+hfhgfk235',
      bookingNumber: 'KP-505621',
      bookingName: 'Escavators',
      createdTimestamp: 1613379940000,
      lastUpdatedTimestamp: 1613481940000,
      createdBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      updatedBy: { name: 'George Lambo', email: 'george@lambologix.com' },
      status: SHIPMENT_STATUS.ARRIVED_AT_DESTINATION,
      shipmentInfo: {
        category: 'Machineries',
        description: 'Escavators',
        weight: '23000 KG',
        containerType: CARGO_TYPES.FRL_CLOSED_WAGON,
        quantity: 7,
        origin: {
          placeOfReceipt: undefined,
          port: 'Abuja',
          country: { label: 'Nigeria', value: 'NG' }
        },
        destination: {
          placeOfDelivery: undefined,
          port: 'Douala',
          country: { label: 'Cameroon', value: 'CM' }
        },
        bookingRef: '193CA0679394',
        carrierInfo: {
          company: SHIPPING_LINES.CSA,
          vessel: 'Alvanbeem',
          voyageNo: '134E42'
        },
        expectedDelivery: 1615504400000,
        containerStatus: {
          location: 'COTONOU, BJ',
          status: CONTAINER_STATUS.TRANSSHIPMENT_LOADED
        }
      },
      forwarderInfo: FORWARDING_AGENTS.FWDR4,
      customBrokerInfo: CUSTOM_BROKER_LIST.BROKER1,
      insuranceInfo: INSURANCE_BROKER_LIST.BROKER2
    }
  ]
};

export const getShipment = (id) => {
  return ACTIVE_SHIPMENTS.data.find(
    (shipment) => shipment.bookingNumber === id
  );
};
