// import {  toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { toastify } from '../../../../actions';


export const notify = (dispatch, type, message) => {
    const data = {
        msg: message,
        error:type
    }
    dispatch(toastify(data))

    // if(type === "error"){
    //     toast.error(`${message}`, {
    //         position: "bottom-right",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //         });
    // }else if(type === 'success'){
    //     toast.success(`${message}` ,{
    //         position: "bottom-right",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //     })
    // }
}
