import React, { useState } from 'react'
import Address from '../../MyShipper/ShipperManagement/Reuseables/Address';
import EditGender from '../../MyShipper/ShipperManagement/Reuseables/EditGender';
import PhoneNumberInput from 'components/PhoneNumberInput';
import EditForm from '../../MyShipper/ShipperManagement/Reuseables/EditForm';
import EditableForm from 'components/EditableForm';
import BoxContainer from 'components/KYCUploadSystem/BoxContainer/BoxContainer';
import Style from './NotVerified.module.css'
import { VerificationStatus } from 'util/VerificationStatus';
import moment from 'moment';
import { addressToString, DOBErrorMsg, isValidDateOfBirth } from 'util/Utils';

const PersonalDetails = ({ formData, setFormData, kycStatus }) => {
    const [DOBError, setDOBError] = useState("")

    const [tempUseState, setTempUseState] = useState({
        company_name: "",
        jobTitle: "",
        industry: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        phone_number_code: "",
        gender: "",
        date_of_birth: "",
        address: null
    })

    const handleAddress = (val) => {
        setFormData({
            ...formData,
            address: {
                ...formData.address,
                country: val?.country,
                state: val?.state,
                city: val?.city,
                street: val?.street,
                suite: val?.suite,
                postalCode: val?.postalCode
            }
        })
    }




    const onEditClick = () => {
        setTempUseState({
            ...tempUseState,
            company_name: formData.company_name,
            jobTitle: formData.jobTitle,
            industry: formData.industry,
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone_number: formData.phone_number,
            phone_number_code: formData.phone_number_code,
            gender: formData.gender,
            date_of_birth: formData.date_of_birth,
            address: formData.address
        })
    }


    const editCancelClick = () => {
        setFormData({
            ...formData,
            company_name: tempUseState.company_name,
            jobTitle: tempUseState.jobTitle,
            industry: tempUseState.industry,
            first_name: tempUseState.first_name,
            last_name: tempUseState.last_name,
            email: tempUseState.email,
            phone_number: tempUseState.phone_number,
            phone_number_code: tempUseState.phone_number_code,
            gender: tempUseState.gender,
            date_of_birth: tempUseState.date_of_birth,
            address: tempUseState.address
        })
        setTempUseState({
            ...tempUseState,
            company_name: "",
            jobTitle: "",
            industry: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            phone_number_code: "",
            gender: "",
            date_of_birth: "",
            address: null
        })
    }


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "date_of_birth") {
            setDOBError("")
            if (!isValidDateOfBirth(value)) {
                setDOBError(DOBErrorMsg)
            }
        }
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const [handleSaveType, setHandleSaveType] = useState(null)
    const [confirmSubmit, setConfirmSubmit] = useState(false);

    const handleSave = () => {

    }

    function convertToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    return (
        <>
            <BoxContainer>
                <div className={Style.left_inner} style={{ gap: "1rem" }}>
                    {(kycStatus.individual === VerificationStatus.PENDING || kycStatus.individual === VerificationStatus.REJECTED) &&
                        <>
                            <EditForm
                                title="Legal Name"
                                editCancelClick={editCancelClick}
                                canEdit={true}
                                onEditClick={onEditClick}
                                detail={`${formData.first_name} ${formData.last_name}`}
                                fieldsDetail={[
                                    {
                                        fieldLabel: 'First Name',
                                        fieldType: 'text',
                                        value: formData.first_name,
                                        name: 'first_name',
                                        onChange: (e) => handleChange(e)
                                    },
                                    {
                                        fieldLabel: 'Last Name',
                                        fieldType: 'text',
                                        value: formData.last_name,
                                        name: 'last_name',
                                        onChange: (e) => handleChange(e)
                                    }
                                ]}
                                onSubmit={() => { setHandleSaveType('P1'); setConfirmSubmit(true); }}
                            />
                            <div className="divider" />
                            <EditForm
                                title="Email"
                                editCancelClick={editCancelClick}
                                canEdit={false}
                                onEditClick={onEditClick}
                                detail={`${formData.email}`}
                                fieldsDetail={[
                                    {
                                        fieldLabel: 'Email',
                                        fieldType: 'email',
                                        value: formData.email,
                                        name: 'email',
                                        onChange: (e) => handleChange(e)
                                    }
                                ]}
                                onSubmit={() => { setHandleSaveType('P2'); setConfirmSubmit(true); }}
                            />
                            <div className="divider" />
                            <div style={{ margin: "-1em 0" }}>
                                <EditableForm
                                    id="phone_number"
                                    canEdit={true}
                                    // btnCallback={setEditing}
                                    label="Phone Number"
                                    value={`${formData.phone_number}`}
                                    onSave={() => { setHandleSaveType("P3"); setConfirmSubmit(true); }}
                                    onCancel={editCancelClick}
                                    onEditClick={onEditClick}
                                >
                                    <div className="c-flex c-flex-wrap c-gap-1 c-mt-1">
                                        <PhoneNumberInput
                                            country={formData.phone_number_code}
                                            number={formData.phone_number}
                                            handleCountry={((val) => setFormData({ ...formData, phone_number_code: val }))}
                                            handleNumber={((val) => setFormData({ ...formData, phone_number: val }))}
                                        />
                                    </div>
                                </EditableForm>
                            </div>
                            <div className="divider" />
                            <EditGender
                                editCancelClick={editCancelClick}
                                canEdit={true}
                                onEditClick={onEditClick}
                                setData={setFormData}
                                data={formData}
                                onSubmit={() => { setHandleSaveType('P4'); setConfirmSubmit(true); }}
                            />
                            <div className="divider" />
                            <EditForm
                                title="Date Of Birth"
                                editCancelClick={editCancelClick}
                                canEdit={true}
                                onEditClick={onEditClick}
                                detail={moment(formData.date_of_birth).format("ll")}
                                fieldsDetail={[
                                    {
                                        fieldLabel: 'Date Of Birth',
                                        fieldType: 'date',
                                        value: convertToYYYYMMDD(formData.date_of_birth),
                                        name: 'date_of_birth',
                                        onChange: (e) => handleChange(e),
                                        error: DOBError
                                    }
                                ]}
                                disabled={DOBError ? true : false}
                                onSubmit={() => { setHandleSaveType('P5'); setConfirmSubmit(true); }}
                            />
                            {(kycStatus.company === VerificationStatus.PENDING || kycStatus.company === VerificationStatus.REJECTED) &&

                                <div className="divider" />
                            }
                        </>
                    }
                    {(kycStatus.company === VerificationStatus.PENDING || kycStatus.company === VerificationStatus.REJECTED) &&
                        <>
                            <EditForm
                                title="Company Name"
                                editCancelClick={editCancelClick}
                                canEdit={true}
                                onEditClick={onEditClick}
                                detail={formData.company_name}
                                fieldsDetail={[
                                    {
                                        fieldLabel: 'Company Name',
                                        fieldType: 'text',
                                        value: formData.company_name,
                                        name: 'company_name',
                                        onChange: (e) => handleChange(e)
                                    }
                                ]}
                                onSubmit={() => { setHandleSaveType('C1'); setConfirmSubmit(true); }}
                            />

                            <div className="divider" />
                            <EditForm
                                title="Industry"
                                editCancelClick={editCancelClick}
                                canEdit={true}
                                onEditClick={onEditClick}
                                detail={formData.industry}
                                fieldsDetail={[
                                    {
                                        fieldLabel: 'Industry',
                                        fieldType: 'text',
                                        value: formData.industry,
                                        name: 'industry',
                                        onChange: (e) => handleChange(e)
                                    }
                                ]}
                                onSubmit={() => { setHandleSaveType('C2'); setConfirmSubmit(true); }}
                            />

                            <div className="divider" />
                            <EditForm
                                title="Job Title"
                                editCancelClick={editCancelClick}
                                canEdit={true}
                                onEditClick={onEditClick}
                                detail={formData.jobTitle}
                                fieldsDetail={[
                                    {
                                        fieldLabel: 'Job Title',
                                        fieldType: 'text',
                                        value: formData.jobTitle,
                                        name: 'jobTitle',
                                        onChange: (e) => handleChange(e)
                                    }
                                ]}
                                onSubmit={() => { setHandleSaveType('C3'); setConfirmSubmit(true); }}
                            />

                            <div className="divider" />
                            <div style={{ margin: "-1em 0" }}>
                                <EditableForm
                                    id="address"
                                    canEdit={true}
                                    label="Address"
                                    value={addressToString(formData.address)}
                                    onSave={() => { setHandleSaveType("P6"); setConfirmSubmit(true); }}
                                    onCancel={editCancelClick}
                                    onEditClick={onEditClick}
                                >
                                    <Address
                                        info={formData.address}
                                        onUpdate={handleAddress}
                                    />
                                </EditableForm>
                            </div>
                        </>
                    }

                </div>
            </BoxContainer>
        </>
    )
}

export default PersonalDetails