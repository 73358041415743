import moment from 'moment';
export default class ImportModel {
  static inboxSearchObj = (inSource, inKeyword, inNewBookingsOnly) => {
    return {
      inStartPos: '0',
      inSource,
      inKeyword,
      inNewBookingsOnly,
      timeZone: moment().utcOffset()
    };
  };

  static inboxCreateTripObj = (trip, tripName) => {
    let bookings = '';
    for (let booking in trip.bookings) {
      bookings += trip.bookings[booking].pk + ',';
    }

    if (bookings.length > 0) {
      bookings = bookings.substring(0, bookings.length - 1);
    }

    return {
      inTripId: '',
      inTripName: tripName,
      inBookings: bookings
    };
  };

  static inboxCopyTripObj = (trip, tripid) => {
    let bookings = '';
    for (let booking in trip.bookings) {
      bookings += trip.bookings[booking].pk + ',';
    }

    if (bookings.length > 0) {
      bookings = bookings.substring(0, bookings.length - 1);
    }

    return {
      inTripId: tripid,
      inTripName: trip.reservationName,
      inBookings: bookings
    };
  };
}
