import React, { useState } from 'react'
import Style from "./MobilePaneFooter.module.css";
import { IconButton, OutlinedInput } from '@material-ui/core';
import { Add, Send } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateChatData } from 'actions';
import { UserType } from "model/enum/AccountType"

const MobilePaneFooter = ({ scroll, setScroll }) => {
    const dispatch = useDispatch()
    const chatFromRedux = useSelector((state) => state.chatRoom.chat_data)
    const socket = useSelector((state) => state.socketConnection.socket)
    const auth = useSelector((state) => state.session.auth)
    const user = useSelector((state) => state.user.info)
    const [message, setMessage] = useState()

    const handleInput = (e) => {
        setMessage(e.target.value)
    }

    const sendMessage = () => {
        if (!message) return
        const data_to_send = {
            type: 'text',
            content: message,
            sender: auth.accountId,
            dateAndTime: new Date(),
            userType: UserType.FORWARDER,
            userName: `${auth?.firstName} ${auth.lastName}`,
            userAvatar: user?.avatar?.url,
            companyName: user?.company?.companyName,
            companyLogo: user?.company?.cmpyLogo?.url,
        };

        const send_in_socket = {
            room: chatFromRedux.room_id,
            data: data_to_send,
        }
        socket.emit('send_message', send_in_socket);
        setMessage('');
        dispatch(updateChatData({ ...data_to_send }))
        // dispatch(updateChatData({...data_to_send,senderName:`${auth?.firstName} ${auth.lastName}`,senderAvatar:user?.avatar?.url}))
        setScroll(!scroll)
    }

    return (
        <div className={Style.container}>
            {/* <IconButton className={Style.action_btn}>
                <Add style={{ color: '#fff', fontSize: '20px' }} />
            </IconButton> */}

            {/* <input className={Style.input} size='1' placeholder='Message...' /> */}
            <OutlinedInput
                value={message}
                onChange={handleInput}
                size="small"
                rowsMax={3}
                multiline
                placeholder="Message..."
                className={Style.input}
            />

            <IconButton onClick={sendMessage} className={Style.action_btn}>
                <Send style={{ color: '#fff', fontSize: '20px' }} />
            </IconButton>
        </div>
    )
}

export default MobilePaneFooter