import React, { useState } from 'react'
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading'
import Style from "./MobileShipmentCardPreview.module.css";
import StatusPopup from '../ShipmentOverview/StatusPopup/StatusPopup'
import MobileOverview from './MobileOverview/MobileOverview'
import MobileContentWrapper from './Reusables/MobileContentWrapper/MobileContentWrapper'
import { ACTIVE_SHIPMENTS } from '../../ActiveShipments/MockData'
import ShipmentPayment from '../ShipmentPayment'
import ShipmentCollaborators from '../ShipmentCollaborators'
import ShipmentTracking from '../ShipmentTracking'
import ShipmentActivity from '../ShipmentActivity';
import ShipmentDocuments from '../ShipmentDocuments';

import { Button, Tooltip } from '@material-ui/core'
import { MoreHoriz, Search } from '@material-ui/icons'
import { West } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import RouteIcon from 'assets/svg/RouteIcon.svg';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';


const tabData = [
    { label: 'Overview', icon: <Search />, },
    { label: 'Payment', icon: <AttachMoneyIcon />, },
    { label: 'Collaborators', icon: <PeopleIcon />, },
    { label: 'Tracking', icon: <LocationOnIcon />, },
    { label: 'Document', icon: <DescriptionIcon />, },
    { label: 'Activity', icon: <TravelExploreIcon />, },
]

const dummyBooking = ACTIVE_SHIPMENTS.data[0];

const MobileShipmentCardPreview = () => {

    const [openStatusPopup, setOpenStatusPopup] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (index) => {
        setActiveTab(index)
    }

    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <Tooltip title="Back" arrow>
                    <IconButton
                        className={`text-primary`}
                        size='small'
                        onClick={() => { }}
                    >
                        <West />
                    </IconButton>
                </Tooltip>

                <SectionHeading title="Shipment Details" />

                <Tooltip title="More..." arrow>
                    <IconButton
                        className={``}
                        size='small'
                        onClick={() => { }}
                    >
                        <MoreHoriz />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="d-flex align-items-start justify-content-between c-gap-1 px-3">
                <div className={`d-flex align-items-center font-weight-bold font-size-xs mb-2`}>
                    <span>Abuja, NG</span>
                    <img
                        className={Style.route_icon}
                        src={RouteIcon}
                        alt="route icon"
                    />
                    <span>Dauala, CM</span>
                </div>
                <div className="font-size-xs position-relative">
                    <Button
                        onClick={() => setOpenStatusPopup(state => !state)}
                        className={`${Style.status_btn} btn-pill btn-primary text-capitalize`}
                    >
                        Transhipment Loaded
                    </Button>
                    <StatusPopup open={openStatusPopup} />
                </div>
            </div>

            <div className={Style.tabs_container}>
                <Tab
                    onChange={handleChange}
                    activeTab={activeTab}
                    tabData={tabData}
                />
            </div>

            <div>
                <TabPanel activeTab={activeTab} index={0}>
                    <MobileContentWrapper title='Overview'>
                        <MobileOverview />
                    </MobileContentWrapper>
                </TabPanel>
                <TabPanel activeTab={activeTab} index={1}>
                    <MobileContentWrapper title='Payment'>
                        <ShipmentPayment booking={dummyBooking} />
                    </MobileContentWrapper>
                </TabPanel>
                <TabPanel activeTab={activeTab} index={2}>
                    <MobileContentWrapper title='Payment'>
                        <ShipmentCollaborators booking={dummyBooking} />
                    </MobileContentWrapper>
                </TabPanel>
                <TabPanel activeTab={activeTab} index={3}>
                    <MobileContentWrapper title='Tracking'>
                        <ShipmentTracking booking={dummyBooking} />
                    </MobileContentWrapper>
                </TabPanel>
                <TabPanel activeTab={activeTab} index={4}>
                    <MobileContentWrapper title='Documents'>
                        <ShipmentDocuments booking={dummyBooking} />
                    </MobileContentWrapper>
                </TabPanel>
                <TabPanel activeTab={activeTab} index={5}>
                    <MobileContentWrapper
                        title='Activity'
                        actionComponent={
                            <Button
                                className={`${Style.action_btn} btn-primary`}
                            >
                                Request Change
                            </Button>
                        }
                    >
                        <ShipmentActivity isMobile={true} booking={dummyBooking} />
                    </MobileContentWrapper>
                </TabPanel>
            </div>


        </div>
    )
}

const Tab = ({ onChange, tabData, activeTab }) => (
    <>
        {tabData?.map((data, index) => {
            return (
                <Tooltip title={data.label} arrow key={index}>
                    <Button
                        className={`${index === activeTab ? `${Style.active_tab_btn} btn-primary` : `btn-neutral-primary ${Style.tab_btn}`}`}
                        onClick={() => onChange(index)}
                    >
                        {data.icon}
                    </Button>
                </Tooltip>
            )
        })}
    </>
)

const TabPanel = ({ activeTab, index, children }) => (
    <div
        hidden={activeTab !== index}
    >
        {activeTab === index &&
            <>
                {children}
            </>
        }

    </div>
)

export default MobileShipmentCardPreview