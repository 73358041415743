import React, { useEffect, useState } from 'react'
import Style from "./LeadDetails.module.css";
import { Button } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const ServiceText = ({ title }) => (
    <span className='d-flex c-gap-half align-items-center'>
        <div style={{ width: '7px', height: '7px', borderRadius: '50%', background: 'var(--primary)' }}></div>
        <span>{title}</span>
    </span>
)


const LeadDetails = ({ pageData }) => {


    const getCargoType = (data) => {
        let size = '';
        if (
            data.cargoType.label === 'LCL' &&
            data.cargoDetails.packageType === 'Pallets'
        ) {
            size = data.cargoDetails?.packageDetails?.palletType.label;
        }
        if (
            data.cargoType.label === 'LCL' &&
            data.cargoDetails.packageType === 'Boxes'
        ) {
            size = `${data.cargoDetails?.packageDetails?.dimensions.length} 
                    x ${data.cargoDetails?.packageDetails?.dimensions.width} 
                    x ${data.cargoDetails?.packageDetails?.dimensions.height} ${data.cargoDetails?.packageDetails?.dimensions.unit}`;
        }
        if (data.cargoType.label === 'FCL') {
            size = data.cargoDetails?.containerType?.label;
        }
        if (data.cargoType.label === 'BULK') {
            size = `${data.cargoDetails?.volume.volume} ${data.cargoDetails?.volume.unit}`;
        }
        return size;
    };

    const getCargoIcon = (data) => {
        let image = data?.cargoType?.icon;
        return image;
    };

    const getLoadType = (data) => {
        let type = data?.cargoType?.label;
        return type;
    };


    const [services, setServices] = useState([])
    useEffect(() => { 
        const array = []
        if (pageData?.services?.custom_brokerage) {array.push("Custom Clearance") }
        if (pageData?.services?.inspection_services) {array.push("Inspection") }
        if (pageData?.services?.port_charges) {array.push("Port Charges") }
        if (pageData?.services?.certification) { array.push("Certification") }
        if (pageData?.services?.labelling) { array.push("Labelling") }
        if (pageData?.services?.specialised_handling) { array.push("Specialised Handling") }
        if (pageData?.services?.packaging) { array.push("Packaging") }
        setServices(array)
    },[pageData?.services])



    return (
        <div className={Style.container}>
            <span className={Style.title}>Shipment Summary | {pageData?.request_id}</span>

            <div className={Style.top_details}>
                <div>
                    <span className={Style.label}>Transportation Mode:</span>
                    <span className={Style.value}>{pageData?.transport_mode}</span>

                    <span className={Style.label}>Ready On:</span>
                    <span className={Style.value}>{pageData?.ready_on}</span>
                </div>
                <div>
                    <span className={Style.label}>Origin:</span>
                    <span className={Style.value}>
                        {pageData?.origin?.address_type?.label} |
                        {pageData?.origin?.address_country?.label} |
                        {pageData?.origin?.address_location?.label}
                    </span>

                    <span className={Style.label}>Destination:</span>
                    <span className={Style.value}>
                        {pageData?.destination?.address_type?.label} |
                        {pageData?.destination?.address_country?.label} |
                        {pageData?.destination?.address_location?.label}
                    </span>
                </div>
            </div>

            <div className={`d-flex align-items-center justify-content-between flex-wrap c-gap-2`}>
                <div className={Style.simple_grid}>
                    <span className={Style.label}>Shipment Details:</span>
                    <span className={Style.value}>
                        {getLoadType(pageData.load)} ({getCargoType(pageData.load)})
                        {/* | Total Shipment(111.0201) */}
                    </span>
                    
                    <span className={Style.label}>Requested Services:</span>
                    <span className={Style.value}>
                    {services.length > 0 ?
                            <>
                                {services.map((item, index) => (
                                    <ServiceText title={item} key={index} />
                                ))}
                            </>
                        :
                            <span style={{fontSize:"12px"}}>
                                No Services Selected
                        </span>}
                        {/* <ServiceText title="Custom Brokerage" />
                        <ServiceText title="Inspection" />
                        <ServiceText title="Insurance (Value Of Goods $5000)" /> */}
                        {/* <span> | Additional Services(Packing, Labelling)</span> */}
                    </span>

                    {/* <span className={Style.label}>Company Details:</span> */}
                    {/* <span className={Style.value}>Company (Cargo Pvt Ltd) | Full Name(Agatsuma Zenitsu) | Phone(9823748920) | Commodities(Construction Materials)</span> */}
                </div>

                {/* <div className={Style.actions_container}>
                    <Button
                        className="btn-outline-primary shadow-none "
                        style={{ padding: '0.5rem 1rem', fontSize: 'clamp(9px, 2vw, 12px)' }}
                    >
                        Add Services
                    </Button>
                    <Button
                        className="btn-white text-danger"
                        style={{ padding: '0.5rem 1rem', fontSize: 'clamp(9px, 2vw, 12px)' }}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-primary shadow-none"
                        style={{ padding: '0.5rem 1rem', fontSize: 'clamp(9px, 2vw, 12px)' }}
                    >
                        Save
                    </Button>
                </div> */}
            </div>


        </div>
    )
}

export default LeadDetails