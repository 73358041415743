import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Style from "./MobileChat.module.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Search } from '@material-ui/icons';
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading';
import { IconButton } from '@material-ui/core';
import MobileChatListPane from './MobileChatListPane/MobileChatListPane';


import {
    ACTIVE_SHIPMENTS,
    getShipment
} from '../../Shipments/ActiveShipments/MockData';
import { useDispatch, useSelector } from 'react-redux';
import { setChatData, setChatRoom, updateChatData, updateUnreadChatCount } from 'actions';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';

const MobileChat = () => {
    const history = useHistory();
    const dispatch = useDispatch()

    const [selectedMessage, setSelectedMessage] = useState(
        ACTIVE_SHIPMENTS.data[0]
    );

    const handlesMessageSelect = (data) => {

        dispatch(setChatRoom(data.room_id))
        history.push(`/m/mailBox/message`);


    };

    const [chats, setChats] = useState([])
    const auth = useSelector((state) => state.session.auth)
    const socket = useSelector((state) => state.socketConnection.socket)
    // console.log(socket)


    const fetchAllChats = () => {
        if (socket) {
            const data_to_send = {
                id: auth.cmpyId
            }
            socket?.emit("forwarderChatList", data_to_send)
        }
    }

    useEffect(() => {
        if (socket) {
            socket?.on("forwarderChatsResultList", (data) => {
                const count = data.filter(item => item.unreadMessageCount > 0).length
                dispatch(updateUnreadChatCount(count))
                setChats(data)
            })
        }
    }, [])


    useEffect(() => {
        fetchAllChats()
    }, [socket, auth])

    useEffect(() => {
        if (!socket) return
        socket.on('recieve_message', (data) => {
            fetchAllChats()
        });

    }, [socket])



    return (
        <div className={Style.contianer}>
            {/* <div className={Style.top_bar}>
                <div>
                    <IconButton size='small' onClick={() => history.push(`/`)}>
                        <ArrowBackIosIcon style={{ color: 'var(--secondary-text)', fontSize: '18px' }} />
                    </IconButton>
                    <SectionHeading title="Messages" size="1.2rem" />
                </div>

                <div>
                    <Search />
                </div>
            </div> */}
            <PageTitle titleHeading="Messages" />

            <div>
                {chats &&
                    chats.length > 0 ?
                    <>
                        {chats.map((item, index) => (
                            <MobileChatListPane
                                key={index}
                                chatData={item}
                                // selectedMessage={selectedMessage}
                                handlesMessageSelect={handlesMessageSelect}
                            />
                        ))}
                    </> : <>
                    <div className="d-flex align-items-center w-100 justify-content-center" style={{height:"200px"}}>No Chats Available</div>
                    </>
                }

            </div>

        </div>
    )
}

export default MobileChat