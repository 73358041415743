export const MyShipperGuide = [
    {
        heading:"View Shipper",
        icon:"",
        description:"Efficiently Manage Shippers with Detailed Insights",
        content: [
            "In shipper management, the 'View Shipper button' redirects the forwarder to the 'My Shipper Management' page. This page contains three tabs: Shipper Details, Shipments, and Transactions. The forwarder can view comprehensive details related to the shipper, including KYC information. Additionally, they can review all shipments conducted with this shipper and examine all transactions between the forwarder and the shipper."
        ]
    },
    {
        heading:"Create Shipment in Shipper Management",
        icon:"",
        description:"Streamline Shipment Creation for Efficient Operations",
        content: [
            "As a forwarder, you can create shipments for your shippers in two ways. You can either select the shipper from your shipper list on the dashboard or add the shipper first and then create a shipment for them. This flexibility allows for efficient and effective shipment management tailored to your operational needs."
        ]
    },

    {
        heading:"Add Shipper on Shipments Management Page",
        icon:"",
        description:"Simplified Shipper Addition for Efficient Operations",
        content: [
            "Adding a shipper to your dashboard is essential for creating shipments. Click 'Add Shipper' Enter the shipper's email address, and they will receive a notification email. Once the shipper confirms their participation, they will be added to your shipper list, allowing you to create shipments for them effortlessly."
            
        ]
    },

]