import {
  LOGOUT_REQUEST,
  RECEIVE_INFO_FAILURE,
  RECEIVE_RESERVATIONS_FAILURE,
  RECEIVE_TRIPS_FAILURE,
  SIGNUP_SUCCESS,
  TOGGLE_DETAILS_DRAWER,
  TOGGLE_TRIP_DRAWER
} from '../actions';

export const initialState = {
  detailsDrawerOpen: false,
  tripDrawerOpen: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_DETAILS_DRAWER:
      return { ...state, detailsDrawerOpen: action.payload };

    case TOGGLE_TRIP_DRAWER:
      return { ...state, tripDrawerOpen: action.payload };

    case RECEIVE_RESERVATIONS_FAILURE:
    case RECEIVE_TRIPS_FAILURE:
    case RECEIVE_INFO_FAILURE:
    case SIGNUP_SUCCESS:
    case LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
}
