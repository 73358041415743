import { connect } from 'react-redux';

import RightDrawer from './RightDrawer';

const mapDispatchToProps = (dispatch) => ({
  // setHeaderDrawerToggle: (enable) => dispatch(setHeaderDrawerToggle(enable))
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RightDrawer);
