import { Button, Divider, IconButton } from "@mui/material";
import Style from "./ContainerDetails.module.css";
import ViewBreakdown from "../../ViewBreakdown/ViewBreakdown";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

/* eslint-disable */

const ContainerDetails = ({ run, setRun, name, data, data_id }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const toggleBreakdown = (bool) => {
        setIsOpen(bool)
    }

    const toggleBreakdownEdit = (bool) => {
        setIsOpenEdit(bool)
    }

    const calculateTotal = (item) => {
        let sum = 0;
        item?.map(data => {
            data.data.map(amount_data => sum += Number(amount_data.amount))
        })
        return sum
    }


    const ongoing_price = {
        effective_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.effective_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.effective_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.effective_date.split("T")[0],



        expiry_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.expiry_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.expiry_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.expiry_date.split("T")[0],

        amount: calculateTotal(data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.price_breakdown)
    }

    const past_price = {
        effective_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.effective_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.effective_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.effective_date.split("T")[0],



        expiry_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.expiry_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.expiry_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.expiry_date.split("T")[0],

        amount: calculateTotal(data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.price_breakdown)
    }




    return (
        <>
            <div className={Style.container}>
                <div className={Style.left_container}>
                    <span className={Style.container_size}>{name}</span>
                    {/* <Button
                        variant="contained"
                        size="small"
                        className={Style.view_breakdown_btn}
                        onClick={() => toggleBreakdown(true)}
                    >
                        View Breakdown
                    </Button> */}
                    <div className={Style.btn_container}>
                        {(data?.load.fcl[name]).length > 0 &&
                            <IconButton
                                onClick={() => toggleBreakdownEdit(true)}
                                className={Style.view_breakdown_btn}
                            >
                                <EditIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                        }

                        <IconButton
                            onClick={() => toggleBreakdown(true)}
                            className={Style.view_breakdown_btn}
                        >
                            <AddIcon style={{ fontSize: '20px' }} />
                        </IconButton>
                    </div>
                </div>

                <div className={Style.right_container}>

                    <div className={Style.innter_details}>
                        <div className={Style.flex_col}>
                            <span className={Style.effective_date}>{ongoing_price.effective_date}</span>
                            <span className={Style.expiry_date}>{ongoing_price.expiry_date}</span>
                        </div>
                        <span className={Style.price}>${ongoing_price.amount}</span>
                    </div>
                    <Divider />
                    <div className={Style.innter_details}>
                        <div className={Style.flex_col}>
                            <span className={Style.effective_date}>{past_price.effective_date}</span>
                            <span className={Style.expiry_date}>{past_price.expiry_date}</span>
                        </div>
                        <span className={Style.price}>${past_price.amount}</span>
                    </div>

                </div>
                <ViewBreakdown
                    data={data}
                    run={run}
                    setRun={setRun}
                    data_id={data_id}
                    name={name}
                    open={isOpen}
                    handleClose={() => toggleBreakdown(false)}
                />
                {isOpenEdit &&
                    <ViewBreakdown
                        run={run}
                        setRun={setRun}
                        method={"edit"}
                        data={data}
                        data_id={data_id}
                        name={name}
                        open={isOpenEdit}
                        handleClose={() => toggleBreakdownEdit(false)}
                    />
                }

            </div>


            <>
            </>
        </>
    )
}
/* eslint-disable */
export default ContainerDetails