import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import KycDocumentsUpload from '../KYC Documents Upload/kycDocumentsUpload';
import { Redirect } from 'react-router';
import RoleType from 'model/enum/RoleType';
import { fetchUserInfo } from 'actions';
import Style from './WaitingRoom.module.css';
import WaitingRoomHeader from './WaitingRoomHeader/WaitingRoomHeader';
import { VerificationStatus } from 'util/VerificationStatus';


const WaitingRoom = () => {

    const { type } = useParams();
    const auth = useSelector((state) => state.session.auth)
    const user = useSelector((state) => state.user.info)
    const history = useHistory()
    const dispatch = useDispatch()


    useEffect(() => {
        if (!auth || !auth.authenticated) {
            history.push('/login')
        }
    }, [auth])


    useEffect(() => {
        if (auth && auth.authenticated) {
            dispatch(fetchUserInfo(auth.accountId, auth.token));
        }
    }, [auth]);

    useEffect(() => {
        if (user && user?.company?.kyc_status?.individual === VerificationStatus.APPROVED && user?.company?.kyc_status?.company === VerificationStatus.APPROVED) {
            history.push('/')
        }
    }, [user])


    return (
        <>
            {auth && type === "kyc" &&
                <div className={Style.container}>
                    <WaitingRoomHeader />
                    <KycDocumentsUpload auth={auth} id={auth.accountId} user={user} token={auth.token} />
                </div>
            }
        </>
    )
}

export default WaitingRoom