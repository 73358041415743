import { httpService, HttpMethods } from "./global";

export default class DocumentService {
  // static searchDocFromLibrary(data, token) {
  //   return HttpService.request(HttpMethods.POST, 'api/wi/v1/guide/destinationSearch', data, {}, token).then(res => res);
  // }

  // static attachDocToTrip(data, token) {
  //   return HttpService.request(HttpMethods.POST, 'api/wi/v1/trips/newTrip/addGuide', data, {}, token).then(res => res);
  // }
  static fetchDocumentsForwarder(data, token) {
    const url = `documents/f/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'documents',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
