import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorMsg: {
    color: '#f44336',
    fontSize: '12px'
  }
});

const StyledSelect = (props) => {
  const {
    classes,
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
    error,
    helperText,
    variant,
    margin,
    fullWidth,
    required,
    size,
    placeholder,
    defaultValue,
    ...rest
  } = props;

  const selectRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (selectRef) {
      setAnchorEl(selectRef.current);
    }
  }, [selectRef]);

  return (
    <FormControl
      className={clsx({ 'styled-select-dense': size === 'small' })}
      required={required}
      margin={margin}
      variant={variant}
      fullWidth={fullWidth}
      error={error && helperText}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...rest}
        ref={selectRef}
        id={name}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        MenuProps={{
          PaperProps: {
            className: `mt-1 ${options.length > 5 && "h-30"}`
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          anchorEl: anchorEl,
          getContentAnchorEl: null
        }}>
        {options &&
          options.map((option, index) => {
            const key = `option-${index}`;
            return (
              <MenuItem key={key} className="mx-2" value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
      </Select>
      {error && helperText && (
        <FormHelperText variant="outlined" error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

StyledSelect.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  required: false,
  size: 'small'
};

StyledSelect.propTypes = {
  /**
   * The key of the input field.
   */
  name: PropTypes.string.isRequired,
  /**
   * The label of the input input.
   */
  label: PropTypes.string.isRequired,
  /**
   * The value of the input field.
   */
  value: PropTypes.any.isRequired,
  /**
   * Array of options to display in dropdown.
   */
  options: PropTypes.array.isRequired,
  /**
   * Func that handles on change events.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * If true, the input field will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * onBlur callBack
   */
  onBlur: PropTypes.func,
  /**
   * If true, the input field is highlighted in red.
   */
  error: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium'])
};

export default withStyles(styles)(StyledSelect);
