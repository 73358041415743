/* eslint-disable */
import {
  LOGOUT_REQUEST,
  INVALID_SESSION,
  SIGNUP_SUCCESS,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE
} from '../actions';
import {
  LOGGED_IN_ELSEWHERE,
  RECEIVE_AUTH_FAILURE,
  RECEIVE_AUTH_SUCCESS
} from '../actions/auth';

export const initialState = {
  info: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_AUTH_SUCCESS:
      const {
        firstName,
        lastName,
        email,
        cmpyId,
        cmpyName,
        accountId
      } = action.payload;
      return {
        ...state,
        info: {
          ...state.info,
          firstName,
          lastName,
          email,
          cmpyId,
          cmpyName,
          accountId
        }
      };

    case USER_INFO_SUCCESS:
      return { ...state, info: action.payload };

    case INVALID_SESSION:
    case RECEIVE_AUTH_FAILURE:
    case LOGGED_IN_ELSEWHERE:
    case USER_INFO_FAILURE:
    case SIGNUP_SUCCESS:
    case LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
}
/* eslint-enable */
