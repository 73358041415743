import { AuthService } from 'service';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const requestLogout = () => ({
  type: LOGOUT_REQUEST
});

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const receiveLogoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const receiveLogoutFailure = () => ({
  type: LOGOUT_SUCCESS
});

export function logout(token) {
  return (dispatch) => {
    return AuthService.logout(token).then((res) => {
      if (res && res.returnCode === 0) {
        dispatch(requestLogout());
      }
    });
  };
}
