export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
export const setChatRoom = (payload) => ({
  type: SET_CHAT_ROOM,
  payload
});

export const SET_CHAT_DATA = 'SET_CHAT_DATA';
export const setChatData = (payload) => ({
  type: SET_CHAT_DATA,
  payload
})


export const UPDATE_CHAT_DATA = 'UPDATE_CHAT_DATA';
export const updateChatData = (payload) => ({
  type: UPDATE_CHAT_DATA,
  payload
})

export const WITH_DRAW_OFFER_FROM_CHAT = 'WITH_DRAW_OFFER_FROM_CHAT';
export const withdrawOfferFromChat = (payload) => ({
  type: WITH_DRAW_OFFER_FROM_CHAT,
  payload
})

export const EXPIRE_OFFER_FROM_CHAT = 'EXPIRE_OFFER_FROM_CHAT';
export const expireOfferFromChat = (payload) => ({
  type: EXPIRE_OFFER_FROM_CHAT,
  payload
})


export const CLEAR_CHAT_DATA = 'CLEAR_CHAT_DATA';
export const clearChatData = () => ({
  type: CLEAR_CHAT_DATA,
})


export const UPDATE_UNREAD_CHATS_COUNT = 'UPDATE_UNREAD_CHATS_COUNT';
export const updateUnreadChatCount = (payload) => ({
  type: UPDATE_UNREAD_CHATS_COUNT,
  payload
})