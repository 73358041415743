import React from 'react'
import { addressToString, getFileExtensionIcon } from 'util/Utils';

const DummyContent = ({ classes, data }) => {
    return (
        <div className="bg-light w-100 rounded position-relative overflow-hidden">
            <div className={classes.row}>
                <div className={classes.routeDetails}>

                    




                    <div className="mb-3">
                        <div className={classes.locationTitle}>
                            <span>Full Name</span>
                        </div>
                        <div className={classes.location}>
                            <span>
                                {data.fullName}
                            </span>
                        </div>
                    </div>





                    <div className="mb-3">
                        <div className={classes.locationTitle}>
                            <span>Email</span>
                        </div>
                        <div className={classes.location}>
                            <span>
                                {data.email}
                            </span>
                        </div>
                    </div>




                    <div className="mb-3">
                        <div className={classes.locationTitle}>
                            <span>Telephone</span>
                        </div>
                        <div className={classes.location}>
                            <span>
                                <a
                                    href={`tel:${data.phone_number}`}>
                                    {data.phone_number}
                                </a>
                            </span>
                        </div>
                    </div>






                    <div className="mb-3">
                        <div className={classes.locationTitle}>
                            <span>Address</span>
                        </div>
                        <div className={classes.location}>
                            <span>
                                {addressToString(
                                    data.address
                                )}
                            </span>
                        </div>
                    </div>









                </div>
            </div>
        </div>
    )
}

export default DummyContent