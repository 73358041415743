import classNames from 'classnames'
import React from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Tooltip } from '@material-ui/core';

const CustomChip = ({
    color,
    variant,
    name,
    tooltip = null,
    truncate = false,
    dot = false,
    rounded = false
}) => {

    const chipStyle = classNames(`d-flex align-items-center m-0 px-2 h-auto py-1 text-capitalize ${truncate && "text-truncate"}`, {
        [`border-1  badge text-${color} badge-neutral-${color}`]: variant === "light-border",
        [`badge text-${color} badge-neutral-${color}`]: variant === "light",
        [`border-1 badge text-light badge-${color}`]: variant === "filled"
    })

    // console.log("name : ", name, "color : ", color)

    // font-size-xs
    return (
        <>
            <Tooltip disableHoverListener={tooltip ? false : true} title={name} placement='bottom-start'>
                <p
                    className={`${chipStyle}`}
                    style={{
                        borderRadius: rounded ? "50px" : "5px",
                        fontSize: "12px",
                        gap: "0.25rem",
                        letterSpacing: "0.2px",
                        fontWeight: "500",
                        lineHeight: "15px",
                        width: "max-content",
                        maxWidth: "150px",
                        cursor: "default"
                    }}>
                    {dot &&
                        <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                    }
                    <span className={`${truncate && "text-truncate"}`} >
                        {name}
                    </span>
                </p>
            </Tooltip>
        </>
    )
}

export default CustomChip