import React, { useEffect, useState } from 'react'
import ForwarderService from '../../../../service/ForwarderService';
import InReview from './InReview/InReview';
import Rejected from './Rejected/Rejected';
import Suspended from './Suspended/Suspended';
import Approved from './Approved/Approved';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from 'actions';
import { notify } from 'sites/shipper/components/Toast/toast';
import NotVerified from './NotVerified/NotVerified'
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { upload_to_s3 } from 'util/awsUploader';
import { AuthService } from 'service';
import { VerificationStatus } from 'util/VerificationStatus';
import InreviewStrip from './InreviewStrip/InreviewStrip';
import KYCPageWrapper from 'components/KYCUploadSystem/KYCPageWrapper/KYCPageWrapper';
import ConstantValuesService from 'service/ConstantValuesService';
import { AccountTypeLabel } from 'util/accountType';




const KycDocumentsUpload = ({ auth, id, user, token }) => {

  const [data, setData] = useState({
    Individual: [],
    Company: [],
    // is_not_registered_company: false
  })
  const [show, setShow] = useState(false)
  const userdata = useSelector(state => state.user.info)

  const [formData, setFormData] = useState({
    first_name: userdata?.firstName,
    last_name: userdata?.lastName,
    email: userdata?.email,
    phone_number_code: userdata?.phoneNumber?.country_code,
    phone_number: userdata?.phoneNumber?.contact_number,
    date_of_birth: userdata?.dateOfBirth,
    gender: userdata?.gender,
    address: {
      addressCountry: userdata?.company?.address?.addressCountry,
      addressRegion: userdata?.company?.address?.addressRegion,
      addressLocality: userdata?.company?.address?.addressLocality,
      streetAddress: userdata?.company?.address?.streetAddress,
      suite: userdata?.company?.address?.suite,
      postalCode: userdata?.company?.address?.postalCode
    },


    company_name: userdata?.company?.companyName,
    jobTitle: userdata?.company?.jobTitle,
    industry: userdata?.company?.industry,
  })



  useEffect(() => {

    if (!userdata) {
      return
    }
    let phoneNumberCode = null

    if (userdata?.phoneNumberCode) {
      ConstantValuesService.fetchCountryList(res => {
        if (res.error) {
          console.log(res.error)
        } else {
          phoneNumberCode = res.filter((item)=>item.phone === userdata?.phoneNumberCode)[0]
        }
      })
    }
    setFormData({
      ...formData,
      first_name: userdata?.firstName,
      last_name: userdata?.lastName,
      email: userdata?.email,

      phone_number: userdata?.phoneNumber,
      phone_number_code:phoneNumberCode,
      
      
      date_of_birth: userdata?.dateOfBirth,
      gender: userdata?.gender,
      
      address: {
        ...formData.address,
        country: userdata?.company?.address?.country,
        state: userdata?.company?.address?.state,
        city: userdata?.company?.address?.city,
        street: userdata?.company?.address?.street,
        suite: userdata?.company?.address?.suite,
        postalCode: userdata?.company?.address?.postalCode
      },


      company_name: userdata?.company?.companyName,
      jobTitle: userdata?.company?.jobTitle,
      industry: userdata?.company?.industry,
    })
  }, [userdata])

  const [acknowledgement, setAcknowledgement] = useState(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch()


  const handleSubmit = async (event) => {
    setLoading(true);
    setError("");
    const personalDocuments = []
    const companyDocuments = []

    if (user?.company?.kyc_status?.individual === VerificationStatus.PENDING || user?.company?.kyc_status?.individual === VerificationStatus.REJECTED) {
      await Promise.all(data.Individual.map(async (file) => {
        const uploadedFiles = []

        await Promise.all(file.uploads.map(async (uploadFile) => {
          uploadedFiles.push(
            {
              extension: uploadFile.extension,
              name: uploadFile.name,
              type: uploadFile.type,
              file: await upload_to_s3(uploadFile.file, `kyc-documents/${id}`,auth.token)
            }
          )
        }))
        personalDocuments.push(
          {
            docType: file.docType,
            docName: file.docName,
            docNumber: file.docNumber,
            uploads: uploadedFiles
          }
        )
      }))
    }



    if (user?.company?.kyc_status?.company === VerificationStatus.PENDING || user?.company?.kyc_status?.company === VerificationStatus.REJECTED) {
      await Promise.all(data.Company.map(async (file) => {
        const uploadedFiles = []

        await Promise.all(file.uploads.map(async (uploadFile) => {
          uploadedFiles.push(
            {
              extension: uploadFile.extension,
              name: uploadFile.name,
              type: uploadFile.type,
              file: await upload_to_s3(uploadFile.file, `kyc-documents/${id}`,auth.token)
            }
          )
        }))
        companyDocuments.push(
          {
            docType: file.docType,
            docName: file.docName,
            docNumber: file.docNumber,
            uploads: uploadedFiles
          }
        )
      }))

    }







    const data_to_send = {
      accountId:auth.accountId,
      cmpyId:auth.cmpyId,
      cmpyOwnerId:auth.cmpyOwnerId,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      company_name: formData.company_name,
      jobTitle: formData.jobTitle,
      industry: formData.industry,
      phone_number_code: formData.phone_number_code,
      phone_number: formData.phone_number,
      date_of_birth: formData.date_of_birth,
      gender: formData.gender,
      address: formData.address,
      individual: personalDocuments,
      company: companyDocuments,
      // is_not_registered_company: data.is_not_registered_company
    }

    try {
      ForwarderService.submit_kyc_documents(data_to_send).then(res => {
        if (res.error) {
          setError(res.error);
          setLoading(false)
        } else {
          notify(dispatch, "success", res.message)
          dispatch(fetchUserInfo(id, token))
          setLoading(false)
          setShow(false)
        }
      })

    } catch (err) {
      setError('An error occurred while uploading the files. Please try again later.');
    }
  };

  const [rejectedDoc, setRejectedDoc] = useState({
    show: false,
    Company: [],
    Individual: [],
    // is_not_registered_company: false
  })

  const [rejectedFeedback, setRejectedFeedback] = useState(null)

  useEffect(() => {
    AuthService.fetch_forwarder_kyc_documents(auth.cmpyOwnerId, auth.cmpyId, token).then(res => {
      if (res.error) {
        console.log(res.error)
      }
      else {
        if (res.kyc.kyc_verification_status.individual === VerificationStatus.REJECTED) {
          setRejectedDoc({
            ...rejectedDoc,
            show: true,
            // Company: res.kyc.kyc_details.company,
            Individual: res.kyc.kyc_details.individual,
            // is_not_registered_company: res.kyc.kyc_details.is_not_registered_company
          })
        }
        if (res.kyc.kyc_verification_status.company === VerificationStatus.REJECTED) {
          setRejectedDoc({
            ...rejectedDoc,
            show: true,
            Company: res.kyc.kyc_details.company,
            // Individual: res.kyc.kyc_details.individual,
            // is_not_registered_company: res.kyc.kyc_details.is_not_registered_company
          })
        }
        setRejectedFeedback(res.kyc.kyc_rejection_feedback)
      }
    })
  }, [])





  // Pending
  // Approved
  // In Review
  // Rejected



  return (
    <>

      {loading && <LoaderComponent loading={loading} />}
      <KYCPageWrapper>
        {(user?.company?.kyc_status?.individual === VerificationStatus.PENDING && user?.company?.kyc_status?.company === VerificationStatus.PENDING) || show ?
          <NotVerified
            formData={formData}
            setFormData={setFormData}
            kycStatus = {user?.company?.kyc_status}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            error={error}
            rejectedDoc={rejectedDoc}
            acknowledgement={acknowledgement}
            setAcknowledgement={setAcknowledgement}
          />
          :
          <>





            {(user?.company?.kyc_status?.individual === VerificationStatus.INREVIEW && user?.company?.kyc_status?.company === VerificationStatus.INREVIEW) &&
              <InReview />
            }




            {(user?.company?.kyc_status?.individual === VerificationStatus.REJECTED && user?.company?.kyc_status?.company === VerificationStatus.REJECTED) && show === false &&
              <Rejected show={show} setShow={setShow} rejectedFeedback={rejectedFeedback} />
            }







            {(user?.company?.kyc_status?.individual === VerificationStatus.APPROVED && user?.company?.kyc_status?.company === VerificationStatus.INREVIEW) &&
              <div className='d-flex flex-column c-gap-1'>
                <InreviewStrip
                  type={AccountTypeLabel.Individual}
                  status={user?.company?.kyc_status?.individual}
                />
                <InReview type="Company" />
              </div>
            }









            {(user?.company?.kyc_status?.individual === VerificationStatus.INREVIEW && user?.company?.kyc_status?.company === VerificationStatus.APPROVED) &&
              <div className='d-flex flex-column c-gap-1'>
                <InreviewStrip
                  type={AccountTypeLabel.Company}
                  status={user?.company?.kyc_status?.company}
                />
                <InReview type="Individual" />
              </div>
            }








            {(user?.company?.kyc_status?.individual === VerificationStatus.APPROVED && user?.company?.kyc_status?.company === VerificationStatus.REJECTED) &&
              <div className='d-flex flex-column c-gap-1'>
                <InreviewStrip
                  type={AccountTypeLabel.Individual}
                  status={user?.company?.kyc_status?.individual}
                />
                <Rejected type="Company" show={show} setShow={setShow} rejectedFeedback={rejectedFeedback} />
              </div>
            }







            {(user?.company?.kyc_status?.individual === VerificationStatus.REJECTED && user?.company?.kyc_status?.company === VerificationStatus.APPROVED) &&
              <div className='d-flex flex-column c-gap-1'>
                <InreviewStrip
                  type={AccountTypeLabel.Company}
                  status={user?.company?.kyc_status?.company}
                />
                <Rejected type="Individual" show={show} setShow={setShow} rejectedFeedback={rejectedFeedback} />
              </div>
            }






            {(user?.company?.kyc_status?.individual === VerificationStatus.INREVIEW && user?.company?.kyc_status?.company === VerificationStatus.REJECTED) &&
              <div className='d-flex flex-column c-gap-1'>
                <InreviewStrip
                  type={AccountTypeLabel.Individual}
                  status={user?.company?.kyc_status?.individual}
                />
                <Rejected type="Company" show={show} setShow={setShow} rejectedFeedback={rejectedFeedback} />
              </div>
            }





            {(user?.company?.kyc_status?.individual === VerificationStatus.REJECTED && user?.company?.kyc_status?.company === VerificationStatus.INREVIEW) &&
              <div className='d-flex flex-column c-gap-1'>
                <InreviewStrip
                  type={AccountTypeLabel.Company}
                  status={user?.company?.kyc_status?.company}
                />
                <Rejected type="Individual" show={show} setShow={setShow} rejectedFeedback={rejectedFeedback} />
              </div>
            }





          </>
        }

      </KYCPageWrapper>








      {/*   {user?.kyc_status === "In Review" && <InReview />}
      {user?.company?.kyc_status?.company === "REJECTED" && show === false && <Rejected show={show} setShow={setShow} rejectedFeedback={rejectedFeedback} />} */}
      {/* {user.kyc_status === "Suspended" && <Suspended/>} */}
      {/* {user?.kyc_status === "Approved" && <Approved />}
      {(user?.kyc_status === "Pending" || show === true) &&
        <NotVerified
          formData={formData}
          setFormData={setFormData}
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
          error={error}
          rejectedDoc={rejectedDoc}
          acknowledgement={acknowledgement}
          setAcknowledgement={setAcknowledgement}
        />
      } */}
    </>
  )
}
export default KycDocumentsUpload


// user = in review / company - rejected
// user / company
// A    /  A        X



// u = I || C = I = show chip
  
// A    /  I        Y      user is approved chip and company in review card
// I    /  A        Y      company is approved chip and user in review card

// A    /  R        Y      user is approved chip and company rejection reasons
// R    /  A        Y      company is approved chip and user rejection reasons

// R    /  I        Y      Rejections reasons and company in review card
// I    /  R        Y      Rejections reasons and user in review card

// I    /  I        Y      in review card global
// R    /  R        Y      Rejection reasons




