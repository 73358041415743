export default class UploadModel {
  static createImageObj = (name, userId) => {
    return {
      '@type': 'ImageObject',
      name,
      userId
    };
  };

  static createBookingImageObj = (name, bookingId, userId) => {
    return {
      '@type': 'ImageObject',
      name,
      bookingId,
      userId
    };
  };
}
