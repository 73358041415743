import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Menu, Button, List, ListItem } from '@material-ui/core';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import placeholderAvatar from 'assets/images/avatars/placeholder-avatar.jpeg';
import { withStyles } from '@material-ui/core/styles';
import { CommonModel } from 'model';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'actions';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';

const StyledBadge = withStyles({
    badge: {
        backgroundColor: 'var(--success)',
        color: 'var(--success)',
        boxShadow: '0 0 0 2px #fff',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
})(Badge);

const styles = () => ({});

const WaitingRoomUserBox = ({ classes }) => {


    const auth = useSelector((state) => state.session.auth)
    const user = useSelector((state) => state.user.info)
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const doLogout = () => {
        const token = CommonModel.createUserTokenObj(auth).token;
        dispatch(logout(token));
    };


    console.log(auth)


    return (
        <>
            <Button
                variant="text"
                onClick={handleClick}
                className="btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
                disableRipple>
                <div className="d-block p-0 avatar-icon-wrapper">
                    {/* <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        badgeContent=" "
                        classes={{ badge: 'bg-success badge-circle border-0' }}
                        variant="dot">
                        <div className="avatar-icon rounded">
                            <img
                                src={
                                    user && user.avatar
                                        ? user.avatar.url
                                        : placeholderAvatar
                                }
                                alt="..."
                            />
                        </div>
                    </StyledBadge> */}
                    <CustomAvatar
                        className="avatar-icon rounded"
                        height={35} width={35}
                        name={user.firstName}
                        src={user?.avatar?.url}
                    />
                </div>

                {/* {userInfo && (
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold pt-2 line-height-1">
              {userInfo.firstName} {userInfo.lastName}
            </div>
            <span className="text-black-50">
              {userInfo.role ? userInfo.role : 'Shipper'}
            </span>
          </div>
        )} */}
                <div className="d-none d-xl-block pl-2">
                    <span className="text-black-50">
                        <small>
                            {auth && auth.accountCmpyLinkType
                                ? auth.accountCmpyLinkType
                                : 'Shipper'}
                        </small>
                    </span>
                    {user && (
                        <div className="font-weight-bold">
                            {user.firstName} {user.lastName}
                        </div>
                    )}
                </div>
                <span className="pl-1 pl-xl-3">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
                </span>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                classes={{ list: 'p-0' }}
                onClose={handleClose}>
                <div className="dropdown-menu-xl overflow-hidden p-0">
                    <div className="d-flex p-4 bg-secondary">
                        {/* <div className="avatar-icon flex-shrink-0 rounded mr-3">
                            <img
                                src={
                                    auth && auth.avatar
                                        ? auth.avatar.url
                                        : placeholderAvatar
                                }
                                alt="..."
                            />
                        </div> */}
                        <CustomAvatar
                        className="avatar-icon rounded"
                        height={35} width={35}
                        name={auth.firstName}
                        src={user?.avatar?.url}
                    />
                        {auth && (
                            <div>
                                <h6 className="font-weight-bold mb-1 text-black">
                                    {auth.firstName} {auth.lastName}
                                </h6>
                                <p className="text-gray-700 mb-0">{auth.email}</p>
                            </div>
                        )}

                    </div>
                    <div className="divider" />
                    <List
                        component="div"
                        className="nav-neutral-danger nav-pills-rounded flex-column p-3">
                        <ListItem component="a" button
                            onClick={doLogout}
                        >
                            <div className="mr-2">
                                <ExitToAppTwoToneIcon />
                            </div>
                            <span>Log out</span>
                        </ListItem>
                    </List>
                </div>
            </Menu>
        </>
    );
};


export default withStyles(styles)(WaitingRoomUserBox);
