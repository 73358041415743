import { Divider } from "@material-ui/core";
import Style from "./Summary.module.css";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, Input, Select } from "antd";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import SectionSubHeading from "components/Common/Heading/SectionSubHeading/SectionSubHeading";

/* eslint-disable */

const Summary = ({ chatEditOffer, method, name, data, breakdownData, setBreakdownData, handleSubmit, isMakeOffer = false }) => {

    const [total, setTotal] = useState(0);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmOfferDialogOpen, setConfirmOfferDialogOpen] = useState(false);

    // useEffect(()=>{
    //     if(breakdownData && breakdownData.price_breakdown){
    //         breakdownData.data.map()
    //     }
    // }, [breakdownData])


    const calculateSubTotal = (data) => {
        let sum = 0;
        data.map(amount_data => sum += Number(amount_data.amount));
        return sum
    }

    const calculateTotal = () => {
        let sum = 0;
        breakdownData?.price_breakdown.map(data => {
            data.data.map(amount_data => sum += Number(amount_data.amount))
        })
        setTotal(sum)
    }

    useEffect(() => {
        calculateTotal()
    }, [breakdownData])


    // this handles the date changes 
    const handleDateChange = (e, date_type) => {
        const date = e;
        setBreakdownData((previousData) => ({
            ...previousData,
            [date_type]: date.target.value
        }))
    }


    return (
        <div className={Style.container}>
            <span className={Style.heading}>Summary</span>

            <div className={Style.main}>

                <div className={Style.flex_justify_between}>
                    <span className={Style.detail_text}>Origin Port ID:</span>
                    <span className={Style.detail_text}>{data?.origin?.port_id}</span>
                </div>
                <div className={Style.flex_justify_between}>
                    <span className={Style.detail_text}>Destination Port Id</span>
                    <span className={Style.detail_text}>{data?.destination?.port_id}</span>
                </div>
                <div className={Style.flex_justify_between}>
                    <span className={Style.detail_text}>Container Size:</span>
                    <span className={Style.detail_text}>{name}</span>
                </div>
                {!isMakeOffer || !chatEditOffer &&
                    <>
                        <div className={Style.flex_justify_between}>
                            <span className={Style.detail_text}>Old Price:</span>
                            <span className={Style.detail_text}>$0.00</span>
                        </div>
                        <div className={Style.flex_justify_between}>
                            <span className={Style.detail_text}>Today's Price:</span>
                            <span className={Style.detail_text}>$0.00</span>
                        </div>
                    </>
                }


                <Divider />

                {breakdownData?.price_breakdown?.map((data, idx) => {
                    return (
                        <div key={idx} className={Style.flex_justify_between}>
                            <span className={Style.detail_text}>{data.title}</span>
                            <span className={Style.detail_text_bold}>{calculateSubTotal(data.data)}</span>
                        </div>
                    )
                })}

                <Divider />

                <div className={Style.flex_justify_between}>
                    <span className={Style.detail_text_total}>Total:</span>
                    <span className={Style.detail_text_total}>${total}</span>
                </div>
                <Divider />
                <div className={Style.time_container}>
                    <SectionSubHeading title={'Transit Time'} />
                    <div className={Style.flex_col}>
                        <label htmlFor="p-to-p-time" className={Style.detail_text}>Port to Port Time:</label>
                        <Input
                            id="p-to-p-time"
                            name="p_to_p_time"
                            // value={data.amount}
                            type="number"
                            required
                        />
                    </div>
                    <div className={Style.flex_col}>
                        <label htmlFor="estimate-time" className={Style.detail_text}>Estimate Time:</label>
                        <Input
                            id="estimate-time"
                            name="estimate_time"
                            type="number"
                            required
                        />
                    </div>
                </div>
                <Divider />
                <div className={Style.flex_col}>
                    <span className={Style.detail_text}>Transport Mode</span>
                    <Select
                        options={transportModeList}
                    />
                </div>
                <Divider />
                {((breakdownData?.effective_date !== null) && (new Date(breakdownData?.effective_date) < new Date()))
                    ?
                    <div className={Style.flex_justify_between} style={{ gap: '1rem' }}>
                        <span className={Style.detail_text} style={{ color: 'green' }}>Effective Date</span>
                        <span>{breakdownData.effective_date}</span>
                    </div>
                    :
                    <div className={Style.flex_justify_between} style={{ gap: '1rem' }}>
                        <span className={Style.detail_text} style={{ color: 'green' }}>Effective Date</span>
                        <input
                            type="date"
                            onChange={(e) => handleDateChange(e, "effective_date")}
                            value={breakdownData.effective_date}
                            name="effective_date"
                        />
                    </div>
                }

                <div className={Style.flex_justify_between} style={{ gap: '1rem' }}>
                    <span className={Style.detail_text} style={{ color: 'red' }}>Expiry Date</span>
                    {/* date picket here  */}
                    <input
                        type="date"
                        value={breakdownData?.expiry_date}
                        name="expiry_date"
                        onChange={(e) => handleDateChange(e, "expiry_date")}
                    />
                </div>


                {/* if its for MakeOffer then show only make offer button  */}
                {!chatEditOffer &&
                    <>
                        {!isMakeOffer
                            ?
                            <>
                                <Divider />
                                <div className={Style.suggested_price}>
                                    <span style={{ paddingRight: '1em' }}>Today's Suggested Price</span>
                                    <span>$2800 - $3000</span>
                                </div>

                                <div className={Style.add_form}>
                                    <Button
                                        onClick={() => setConfirmDialogOpen(true)}
                                        style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                                    >
                                        {method === "edit" ? "Update" : "Add Now"}
                                    </Button>
                                </div>
                            </>
                            :
                            // make offer button 
                            <>
                                <Button
                                    onClick={() => setConfirmOfferDialogOpen(true)}
                                    style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                                >
                                    Make Offer
                                </Button>
                            </>
                        }

                    </>
                }


                {chatEditOffer &&

                    <>
                        <Button
                            onClick={() => setConfirmOfferDialogOpen(true)}
                            style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                        >
                            Send Offer
                        </Button>

                    </>
                }

            </div>

            {/* edit and add rate sheet dialog  */}
            <ConfirmDialog
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleSubmit}
                question={method === "edit" ? "Are you sure you want to update this rate-sheet?" : "Are you sure you want to add this rate-sheet?"}
            />

            {/* make offer confirmation dialog  */}
            <ConfirmDialog
                confirmDialogOpen={confirmOfferDialogOpen}
                setConfirmDialogOpen={setConfirmOfferDialogOpen}
                confirmDialogFunction={handleSubmit}
                question={"Are you sure you want to make this offer?"}
            />
        </div>
    )
}




const transportModeList = [
    {
        value: 'Air',
        label: 'Air',
    },
    {
        value: 'Sea',
        label: 'Sea',
    },
]



/* eslint-disable */
export default Summary