import React, { useState } from 'react'
import { Button, Divider } from '@mui/material'
import Style from './DocumentBox.module.css'
import { showPreview } from 'util/OpenPreviewWindow/OpenPreviewWindow'
import UploadDocumentPopUp from '../UploadDocumentPopUp/UploadDocumentPopUp'
import { IconButton } from '@mui/material';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { MenuItem } from '@material-ui/core';
import DocPreview from '../UploadDocumentPopUp/DocPreview'
import classNames from 'classnames'
const DocumentBox = ({
    mainHeading,
    showOption,
    data,
    setData,
    type,
    classes = "",
    dropDownOption,
    urlToDelete,
    setUrlToDelete,
    showActionButton,
    setShowActionButton,
}) => {




    const [openAdd, setOpenAdd] = useState(null);

    const [documentDetail, setDocumentDetail] = useState(
        {
            docType: "",
            docName: "",
            docNumber: "",
            uploads: [

            ]
        }
    )



    const handleCancel = () => {
        setDocumentDetail({
            docType: "",
            docName: "",
            docNumber: "",
            uploads: [

            ]
        })
    }

    const handleDone = () => {
        const array = data[type] || []
        array.push(
            {
                docType: documentDetail.docType,
                docName: documentDetail.docName,
                docNumber: documentDetail.docNumber,
                uploads: documentDetail.uploads
            }
        )
        setData({
            ...data,
            [type]: array
        })
        handleCancel()
        setShowActionButton(true)
    }

    const [isEdit, setIsEdit] = useState(false)
    const [editIndex, setEditIndex] = useState("")

    // const handleDelete = () => {
    //     const array = data[type].filter((item, index) => index !== editIndex)
    //     setData({
    //         ...data,
    //         [type]: array
    //     })
    // }


    const handleDelete = () => {
        const array = data[type].filter((item, index) => {
            if (index !== editIndex) {
                return item
            } else {
                const deleteLinks = []
                item.uploads.map((links) => {
                    if (typeof links.file === 'string') {
                        deleteLinks.push(links.file)
                    }
                })
                setUrlToDelete((prev) => [...prev, ...deleteLinks])
            }
        })
        setData({
            ...data,
            [type]: array
        })
        setShowActionButton(true)
    }


    const handleClick = () => {
        if (isEdit) {
            return
        }
        const existingData = data[type].filter((item, index) => index === editIndex)[0]
        setDocumentDetail({
            docType: existingData.docType,
            docName: existingData.docName,
            docNumber: existingData.docNumber,
            uploads: existingData.uploads
        })
        setOpenAdd(true)
        setIsEdit(true)
    }

    const handleEditSubmit = () => {
        const array = data[type]
        array[editIndex] = documentDetail
        setData({
            ...data,
            [type]: array
        })
        setIsEdit(false)
        setOpenAdd(false)
        handleCancel()
        setShowActionButton(true)
    }


    const handleAddDocument = () => {
        setOpenAdd(true)
        handleCancel()
    }


    const [openMoreOptions, setOpenMoreOptions] = useState(null);

    const handleCloseOpenMoreOption = () => {
        setOpenMoreOptions(null);
    }
    const CardClass = classNames(Style.card, classes);

    return (
        <>
            <div className={CardClass}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="app-page-title--heading"><h1>{mainHeading}</h1></div>
                    {showOption &&
                        <Button
                            className={Style.AddButton}
                            onClick={() => { handleAddDocument(type) }}
                            variant='outlined'
                        >
                            + Add
                        </Button>
                    }
                </div>
                <div>
                    {data[type].length > 0 ?
                        <>
                            {data[type].map((item, index) => (
                                <>
                                    <div key={index} style={{ padding: "0 0 15px 0" }}>
                                        {/* <Divider /> */}
                                        <div className="divider" />
                                        <div style={{ padding: "15px 0 15px 0", display: "flex", justifyContent: "space-between" }}>
                                            <span>{item.docName} - {item.docNumber}</span>
                                            {showOption &&
                                                <IconButton
                                                    onClick={(e) => {
                                                        setOpenMoreOptions(e.currentTarget)
                                                        setEditIndex(index)
                                                    }}
                                                >
                                                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                                                </IconButton>}

                                        </div>
                                        <div className={Style.docContainer}>
                                            {item.uploads.map((upload) => (
                                                <>
                                                    <DocPreview
                                                        document={{
                                                            name: upload.name,
                                                            url: showPreview(upload.file),
                                                            extension: upload.extension
                                                        }}
                                                        index={index}
                                                        showActionButton={false}
                                                    />
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>
                        :
                        <div>
                            {/* <Divider style={{ margin: "0 0 10px 0" }} /> */}
                            <div className="divider" style={{ margin: "0 0 10px 0" }} />
                            <span
                                className={Style.form_head}
                                style={{ fontSize: "14px", color: '#5A6470' }}
                            >
                                No Documents Selected
                            </span>
                        </div>
                    }
                </div>

            </div>
            <SmallPopupWrapper
                open={openMoreOptions}
                onClose={handleCloseOpenMoreOption}
            >
                <MenuItem onClick={() => { handleCloseOpenMoreOption(); handleClick() }}>Edit</MenuItem>
                <MenuItem onClick={() => { handleCloseOpenMoreOption(); handleDelete() }}> Remove</MenuItem>
            </SmallPopupWrapper>
            <UploadDocumentPopUp
                open={openAdd}
                isEdit={isEdit}
                existingData={data}
                documentDetail={documentDetail}
                setDocumentDetail={setDocumentDetail}
                handleDone={handleDone}
                handleCancel={handleCancel}
                onClose={() => { setOpenAdd(false); setIsEdit(false) }}
                handleEditSubmit={handleEditSubmit}
                dropDownOption={dropDownOption}
                documentType={type}
                setIsEdit={setIsEdit}
                setEditIndex={setEditIndex}
                urlToDelete={urlToDelete}
                setUrlToDelete={setUrlToDelete}
            />
        </>
    )
}

export default DocumentBox