import DashService from '../service/DashService';

/*-- Action Creators for Reservation Fetch --*/
export const REQUEST_RESERVATIONS = 'REQUEST_RESERVATIONS';
export const requestReservations = () => ({
  type: REQUEST_RESERVATIONS
});

export const RECEIVE_RESERVATIONS_SUCCESS = 'RECEIVE_RESERVATIONS_SUCCESS';
export const receiveReservationsSuccess = data => ({
  type: RECEIVE_RESERVATIONS_SUCCESS,
  payload: data
});

export const RECEIVE_RESERVATIONS_FAILURE = 'RECEIVE_RESERVATIONS_FAILURE';
export const receiveReservationsFailure = msg => ({
  type: RECEIVE_RESERVATIONS_FAILURE,
  msg
});

export function fetchReservations(tripId, token) {
  return dispatch => {
    dispatch(requestReservations());

    return DashService.getReservations(tripId, token)
      .then(res => {
        if (!res.returnCode) {
          dispatch(receiveReservationsSuccess(res));
        } else {
          dispatch(receiveReservationsFailure(res));
        }

        return res;
      })
      .catch(err => dispatch(receiveReservationsFailure(err)));
  };
}

export function clearReservations() {
  return dispatch => {
    dispatch(receiveReservationsSuccess([]));
  };
}

/*-- Action Creators for Reservation Update --*/
export const REQUEST_UPDATE_RESERVATION = 'REQUEST_UPDATE_RESERVATION';
export const requestUpdateReservation = () => ({
  type: REQUEST_UPDATE_RESERVATION
});

export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const updateReservationSuccess = payload => ({
  type: UPDATE_RESERVATION_SUCCESS,
  payload,
  msg: 'successfullyUpdatedReservation'
});

export const UPDATE_RESERVATION_FAILURE = 'UPDATE_RESERVATION_FAILURE';
export const updateReservationFailure = data => ({
  type: UPDATE_RESERVATION_FAILURE,
  payload: data,
  msg: data.msg
});

export function updateReservation(id, updatedReservation, user, type) {
  return dispatch => {
    dispatch(requestUpdateReservation());

    return DashService.updateReservations(id, updatedReservation, user, type).then(res => {
      const result = String(res.msg).split('-');
      if (res.returnCode !== 0) {
        dispatch(updateReservationFailure(res));
      } else {
        dispatch(
          updateReservationSuccess({
            returnCode: res.returnCode,
            msg: result[0].trim(),
            severityLevel: res.severityLevel
          })
        );
      }
      return res;
    });
  };
}

/*-- Action Creators for Reservation Add --*/
export const REQUEST_ADD_RESERVATION = 'REQUEST_ADD_RESERVATION';
export const requestAddReservation = () => ({
  type: REQUEST_ADD_RESERVATION
});

export const ADD_RESERVATION_SUCCESS = 'ADD_RESERVATION_SUCCESS';
export const addReservationSuccess = data => ({
  type: ADD_RESERVATION_SUCCESS,
  payload: data,
  msg: data.msg
});

export const ADD_RESERVATION_FAILURE = 'ADD_RESERVATION_FAILURE';
export const addReservationFailure = data => ({
  type: ADD_RESERVATION_FAILURE,
  payload: data,
  msg: data.msg
});

export function addReservation(reservation, token, type) {
  return dispatch => {
    dispatch(requestAddReservation());

    return DashService.addReservation(reservation, token, type)
      .then(res => {
        if (res.returnCode === 0) {
          const result = String(res.msg).split('-');
          dispatch(
            addReservationSuccess({
              returnCode: res.returnCode,
              msg: result[0].trim(),
              severityLevel: res.severityLevel
            })
          );
          return res;
        } else {
          return dispatch(addReservationFailure(res));
        }
      })
      .catch(message => dispatch(addReservationFailure(message)));
  };
}

/*-- Action Creators for Reservation Delete --*/
export const REQUEST_DELETE_RESERVATION = 'REQUEST_DELETE-RESERVATION';
export const requestDeleteReservation = () => ({
  type: REQUEST_DELETE_RESERVATION
});

export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const deleteReservationSuccess = payload => ({
  type: DELETE_RESERVATION_SUCCESS,
  payload,
  msg: 'successfullyDeletedReservation'
});

export const DELETE_RESERVATION_FAILURE = 'DELETE_RESERVATION_FAILURE';
export const deleteReservationFailure = data => ({
  type: DELETE_RESERVATION_FAILURE,
  payload: data,
  msg: data.msg
});

export function deleteReservation(reservation, token, type) {
  return dispatch => {
    dispatch(requestDeleteReservation());

    return DashService.deleteReservation(reservation, token, type)
      .then(message => dispatch(deleteReservationSuccess(message)))
      .catch(message => dispatch(deleteReservationFailure(message)));
  };
}

/*-- Action Creators for Setting Active Reservation --*/
export const ACTIVE_RESERVATION = 'ACTIVE_RESERVATION';
export const activeReservation = reservation => ({
  type: ACTIVE_RESERVATION,
  payload: reservation
});

export function setActiveReservation(reservation) {
  return dispatch => dispatch(activeReservation(reservation));
}

/*-- Action Supplier Integration --*/

export const SUPPLIER_INTEGRATION_TOKEN_FAILURE = 'SUPPLIER_INTEGRATION_TOKEN_FAILURE';
export const getTokenSupplierIntegrationFailure = data => ({
  type: SUPPLIER_INTEGRATION_TOKEN_FAILURE,
  payload: data,
  msg: data.msg
});

export function getTokenWetu(tripId, cmpyName, token) {
  return dispatch => {
    return DashService.checkWetuToken(tripId, cmpyName, token)
      .then(res => {
        if (res.returnCode !== 0) {
          dispatch(getTokenSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(getTokenSupplierIntegrationFailure(message)));
  };
}

export function getTokenFCHolidays(tripId, token) {
  return dispatch => {
    return DashService.importFCHolidays(tripId, token)
      .then(res => {
        if (res.returnCode !== 0) {
          dispatch(getTokenSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(getTokenSupplierIntegrationFailure(message)));
  };
}

export function getTokenGogoVacation(tripId, token) {
  return dispatch => {
    return DashService.getTokenGogoVacation(tripId, token)
      .then(res => {
        if (res.returnCode !== 0) {
          dispatch(getTokenSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(getTokenSupplierIntegrationFailure(message)));
  };
}

export const SUPPLIER_INTEGRATION_IMPORT_FAILURE = 'SUPPLIER_INTEGRATION_IMPORT_FAILURE';
export const SUPPLIER_INTEGRATION_IMPORT_SUCCESS = 'SUPPLIER_INTEGRATION_IMPORT_SUCCESS';

export const importSupplierIntegrationFailure = data => ({
  type: SUPPLIER_INTEGRATION_IMPORT_FAILURE,
  payload: data,
  msg: data.msg
});

export const importSupplierIntegrationSuccess = data => ({
  type: SUPPLIER_INTEGRATION_IMPORT_SUCCESS,
  payload: data,
  msg: data.msg
});

export function importSupplierIntegrationTravelCorp(tripId, data, title, token) {
  return dispatch => {
    return DashService.importTravelCorp(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) {
          const resMsg = res.msg ? res.msg.replace('TravelCorp', title).trim() : '';
          dispatch(
            importSupplierIntegrationSuccess({
              ...res,
              msg: resMsg
            })
          );
        } else {
          dispatch(importSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(importSupplierIntegrationFailure(message)));
  };
}

export function importSupplierIntegrationWetu(tripId, data, token) {
  return dispatch => {
    return DashService.importWetu(tripId, data, token)
      .then(res => {
        setTimeout(() => {
          if (res.returnCode === 0) {
            dispatch(importSupplierIntegrationSuccess(res));
          } else {
            dispatch(importSupplierIntegrationFailure(res));
          }
        }, 8500);
        return res;
      })
      .catch(message => dispatch(importSupplierIntegrationFailure(message)));
  };
}

/*-- Action GOGO Vacation --*/

export function importSupplierIntegrationGogo(tripId, data, token) {
  return dispatch => {
    return DashService.importGogo(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(importSupplierIntegrationSuccess(res));
        } else {
          dispatch(importSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(importSupplierIntegrationFailure(message)));
  };
}

/*-- Action ShoreTrip --*/

export function importShoreTrip(tripId, data, token) {
  return dispatch => {
    return DashService.importShoreTrip(tripId, data, token)
      .then(res => {
        const result = String(res.msg).split('-');
        if (res.returnCode === 0) {
          dispatch(
            importSupplierIntegrationSuccess({
              returnCode: res.returnCode,
              msg: result[0].trim(),
              severityLevel: res.severityLevel
            })
          );
        } else {
          dispatch(importSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(importSupplierIntegrationFailure(message)));
  };
}

/*-- Action FCHolidays --*/

export function importFCHolidays(tripId, data, token) {
  return dispatch => {
    return DashService.importFCHolidays(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(importSupplierIntegrationSuccess(res));
        } else {
          dispatch(importSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(importSupplierIntegrationFailure(message)));
  };
}

/*-- Action Template  --*/
export function importTemplate(tripId, data, token) {
  return dispatch => {
    return DashService.importTemplate(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(importSupplierIntegrationSuccess(res));
        } else {
          dispatch(importSupplierIntegrationFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(importSupplierIntegrationFailure(message)));
  };
}

export const DOCUMENT_PAGE_COPY_SUCCESS = 'DOCUMENT_PAGE_COPY_SUCCESS';
export const DOCUMENT_PAGE_COPY_FAILURE = 'DOCUMENT_PAGE_COPY_FAILURE';

export const documentPageCopySuccess = data => ({
  type: DOCUMENT_PAGE_COPY_SUCCESS,
  payload: data,
  msg: data.msg
});

export const documentPageCopyFailure = data => ({
  type: DOCUMENT_PAGE_COPY_FAILURE,
  payload: data,
  msg: data.msg
});

export function documentPageCopy(data, token) {
  return dispatch => {
    return DashService.copyPage(data, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(documentPageCopySuccess(res));
        } else {
          dispatch(documentPageCopyFailure(res));
        }
        return res;
      })
      .catch(message => dispatch(documentPageCopyFailure(message)));
  };
}

/*-- Action Change Date Note  --*/

export const NOTE_DATE_CHANGE_SUCCESS = 'NOTE_DATE_CHANGE_SUCCESS';
export const NOTE_DATE_CHANGE_FAILURE = 'NOTE_DATE_CHANGE_FAILURE';

export const noteDateChangeSuccess = data => ({
  type: NOTE_DATE_CHANGE_SUCCESS,
  payload: data,
  msg: data.msg
});

export const noteDateChangeFailure = data => ({
  type: NOTE_DATE_CHANGE_FAILURE,
  payload: data,
  msg: data.msg
});

export function changeNoteDate(tripId, data, token) {
  return dispatch => {
    return DashService.changeDateNote(tripId, data, token)
      .then(res => {
        if (res.returnCode === 0) {
          dispatch(noteDateChangeSuccess(res));
        } else {
          dispatch(noteDateChangeFailure(res));
        }
        return res;
      })
      .catch(err => dispatch(noteDateChangeFailure(err)));
  };
}

export const NOTE_PDF_ORDER_CHANGE_SUCCESS = 'NOTE_PDF_ORDER_CHANGE_SUCCESS';
export const NOTE_PDF_ORDER_CHANGE_FAILURE = 'NOTE_PDF_ORDER_CHANGE_FAILURE';
export const notePdfOrderChangeSuccess = data => ({
  type: NOTE_PDF_ORDER_CHANGE_SUCCESS,
  payload: data,
  msg: data.msg
});
export const notePdfOrderChangeFailure = data => ({
  type: NOTE_PDF_ORDER_CHANGE_FAILURE,
  payload: data,
  msg: data.msg
});

export function changeTagsOnBooking(tripId, data, token) {
  return dispatch => {
    return DashService.changeTagsOnBooking(tripId, data, token)
      .then(res => {
        if (res.pageBreakAfter !== null) dispatch(notePdfOrderChangeSuccess({ msg: 'Success' }));
        else dispatch(notePdfOrderChangeFailure(res));
        return res;
      })
      .catch(err => dispatch(notePdfOrderChangeFailure(err)));
  };
}
