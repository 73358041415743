import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class PaymentService {

  static fetchStripePublicKey() {
    let url = 'stripe/publicKey';

    return HttpService.request(HttpMethods.GET, url, {})
      .then((res) => {
        console.log('fetching Stripe key resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static setupIntent(uid) {
    let url = `stripe/setupIntent/${uid}`;

    return HttpService.request(HttpMethods.POST, url, {})
      .then((res) => {
        console.log('setupIntent resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static webhook() {
    let url = 'stripe/webhook';

    return HttpService.request(HttpMethods.POST, url, {})
      .then((res) => {
        console.log('webhook resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetchSavedCards(uid, token) {
    return HttpService.request(
      HttpMethods.GET,
      `stripe/${uid}/fetchCards `,
      {},
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }















  // created by rajkumar jaiswal




  static async checkStripeAccount(id, token) {
    const url = `payment/f/stripe-account-status?forwarder_id=${id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




  static async forwarder_stripe_accout_create(id, data, token) {
    const url = `payment/f/stripe-accout-create?id=${id}`;
    return httpService(HttpMethods.POST, data, null, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }



  static async forwarder_stripe_create_representative(id, data, token) {
    const url = `${getBaseUrl()}payment/f/stripe-create-representative?id=${id}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  static async forwarder_stripe_add_external_account(id, data, token) {
    const url = `${getBaseUrl()}payment/f/stripe-add-external-account?id=${id}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }






  static async fetchForwarderEarnings(token) {
    const url = `payment/f/fetch-earnings`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }











  static async fetchPayoutDetails(token) {
    const url = `payment/f/fetch-payout-details`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }



  static async updateDefaultPayoutMethod(data, token) {
    const url = `payment/f/update-default-payout-method`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




  static async updateBankDetails(data, token) {
    const url = `payment/f/update-bank-details`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




  static async fetchBankDetails(index, token) {
    const url = `payment/f/fetch-bank-details?bank_detail_index=${index}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
  static async removeBankDetails(index, token) {
    const url = `payment/f/remove-bank-details?bank_detail_index=${index}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }



  static async onBoardStripeAccount(token) {
    const url = `payment/f/onboard-stripe-account`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async getStripeAccountStatus(token) {
    const url = `payment/f/get-onboarding-account-status`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  static async loginStripeAccount(token) {
    const url = `payment/f/login-onboarded-account`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }



  static async removeStripeAccount(token) {
    const url = `payment/f/remove-onboarded-account`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_earnings',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  






}
