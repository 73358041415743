import { Divider } from "@material-ui/core";
import Style from "./breakdown_table.module.css";
import BreakdownTableRow from "./BreakdownTableRow/BreakdownTableRow";
import { useEffect, useState } from "react";
import SectionSubHeading from "components/Common/Heading/SectionSubHeading/SectionSubHeading";
import { getCurrencyIcon, numberToLocaleString } from "util/Shipment/shipment";
/* eslint-disable */
const BreakdownTable = ({ data }) => {

    const [subTotal, setSubTotal] = useState(0);
    console.log(data)
    useEffect(() => {
        let sum = 0;
        if (data) {
            data?.data.map(data => sum += Number(data.amount))
        }
        setSubTotal(sum)
    }, [data])

    return (
        <div style={{ overflowX: 'auto' }}>
            <div className={Style.container}>

                <div className={Style.head_container}>
                    <SectionSubHeading title={data.title} />
                    {/* <div style={{ display: "flex", gap: '1rem', alignItems: 'center' }}>
                        <span className={Style.title}>{data?.title}</span>
                    </div> */}
                </div>

                <Divider />

                <div className={Style.table_header}>
                    <div>Fee Code </div>
                    <div>Fee Name </div>
                    <div>Comment </div>
                    <div>Units </div>
                    <div>Unit Price </div>
                    <div>Amount</div>
                </div>

                {data?.data.map((d, index) => {
                    return (
                        <BreakdownTableRow key={index} data={d} />
                    )
                })}

                <Divider />

                <div className={Style.table_header}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="font-weight-bold">Subtotal </div>
                    <div className="font-weight-bold">{getCurrencyIcon("usd")}{numberToLocaleString(subTotal)} USD</div>
                </div>
            </div>
        </div>
    );
}
/* eslint-enable */
export default BreakdownTable;