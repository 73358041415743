import { useEffect, useState } from "react";
import Style from "./TableRow.module.css";
import { Button, IconButton } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import { Input, Select } from "antd";
import { Divider } from "@material-ui/core";

/* eslint-disable */

const fee_code_list = [
    {
        value: 'ABCD',
        label: 'ABCD',
    },
    {
        value: 'XYZ',
        label: 'XYZ',
    },
    {
        value: 'PQRS',
        label: 'PQRS',
    },
    {
        value: 'PICKUP 40\'',
        label: 'PICKUP 40\'',
    },
    {
        value: 'OCEAN 40\'',
        label: 'OCEAN 40\'',
    },
    {
        value: `DDO`,
        label: `DDO`,
    },
]

const fee_name_list = {
    ABCD: 'Hello',
    XYZ: 'Hii',
    PQRS: 'Heyy',
    'PICKUP 40\'': 'Pickup Charge', // 40 feet
    'OCEAN 40\'': 'Ocean Freight Cost', // 40 feet
    DDO: 'Destination Documentation Fee',
};

const comment_list = [
    {
        value: 'Origin',
        label: 'Origin',
    },
    {
        value: 'Destination',
        label: 'Destination',
    },
    {
        value: 'Ports',
        label: 'Ports',
    },
    {
        value: 'Per Container',
        label: 'Per Container',
    },
    {
        value: 'Per Container. Carrier: HLCL',
        label: 'Per Container. Carrier: HLCL',
    },
    {
        value: 'Per shipment',
        label: 'Per shipment',
    },
]






const TableRow = ({ method, data, setCheck, handleRowValues, rowIndex, tableIndex, handleRowDelete, handleRowSelectValues }) => {

    const [isEditOn, setIsEditOn] = useState(true);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    useEffect(() => {
        if (method === "edit") {
            setIsEditOn(false)
        }
    }, [])

    useEffect(() => {
        setCheck(isEditOn)
    }, [isEditOn])

    // console.log(data)

    // this handle the fee_name from fee_code 
    useEffect(() => {
        const fee_name = fee_name_list[data.fee_code];

        let e = {
            target: {
                value: fee_name,
                name: 'fee_name'
            }
        }

        handleRowValues(e, tableIndex, rowIndex);


    }, [data.fee_code])

    // this handles the amount 
    useEffect(() => {
        const amount = Number(data.units) * Number(data.unit_price);

        let e = {
            target: {
                value: amount,
                name: 'amount'
            }
        }

        handleRowValues(e, tableIndex, rowIndex);

    }, [data.units, data.unit_price])


    return (
        <>
            <Divider />

            <div className={Style.container}>

                {(!isEditOn)
                    ?
                    <>
                        <div>{data.fee_code}</div>
                        <div>{data.fee_name}</div>
                        <div>{data.comment}</div>
                        <div>{data.units}</div>
                        <div>{data.unit_price}</div>
                        <div>{data.amount}</div>

                        <div className={Style.action_container}>
                            <IconButton
                                onClick={(e) => handleRowDelete(tableIndex, rowIndex)}
                                className={`${Style.action_btn} ${Style.delete_btn}`}>
                                <RemoveIcon style={{ fontSize: '10px' }} />
                            </IconButton>
                            <IconButton
                                onClick={() => setIsEditOn(true)}
                                className={Style.action_btn}>
                                <EditIcon style={{ fontSize: '10px' }} />
                            </IconButton>
                        </div>
                    </>
                    :
                    <>
                        <div className={Style.input_container}>
                            <Select
                                style={{ width: 120 }}
                                onChange={(e) => handleRowSelectValues(e, tableIndex, rowIndex, "fee_code")}
                                name="fee_code"
                                value={data.fee_code}
                                options={fee_code_list}
                                className={Style.input}
                            />
                        </div>
                        <div className={Style.input_container}>
                            <Input
                                name="fee_name"
                                value={fee_name_list[data.fee_code]}
                                // onChange={(e) => handleRowValues(e, tableIndex, rowIndex)}
                                required
                                className={Style.input}
                                readOnly
                            />
                        </div>
                        <div className={Style.input_container}>
                            <Select
                                style={{ width: 120 }}
                                onChange={(e) => handleRowSelectValues(e, tableIndex, rowIndex, "comment")}
                                options={comment_list}
                                className={Style.input}
                                name="comment"
                                value={data.comment}
                            />
                        </div>
                        <div className={Style.input_container}>
                            <Input name="units" value={data.units} disabled type="number" required className={Style.input} />
                            {/* <Input name="units" value={data.units} onChange={(e) => handleRowValues(e, tableIndex, rowIndex)} type="number" required className={Style.input} /> */}
                        </div>
                        <div className={Style.input_container}>
                            <Input name="unit_price" value={data.unit_price} onChange={(e) => handleRowValues(e, tableIndex, rowIndex)} type="number" required className={Style.input} />
                        </div>
                        <div className={Style.input_container}>
                            <Input
                                name="amount"
                                value={data.amount}
                                type="number"
                                required
                                className={Style.input}
                            />
                        </div>
                        <div className={Style.action_container}>
                            <Button
                                variant="outlined"
                                size="small"
                                className={Style.save_btn}
                                onClick={() => setIsEditOn(false)}
                                disabled={!data.fee_code || !data.comment || !data.units || !data.unit_price}
                            >
                                Save
                            </Button>
                            <IconButton
                                onClick={(e) => { setCheck(false); handleRowDelete(tableIndex, rowIndex); }}
                                className={`${Style.action_btn} ${Style.delete_btn}`}
                            >
                                <RemoveIcon style={{ fontSize: '10px' }} />
                            </IconButton>
                        </div>
                    </>
                }


            </div>
        </>
    )
}
/* eslint-disable */
export default TableRow