export const transitTimeCalculator = (time) => {
    let result = ''
    if (time?.min === time?.max) {
        result = `${time?.min} ${time?.unit}`
    } else {
        result = `${time?.min}-${time?.max} ${time?.unit}`
    }
    return result
}


export const numberToLocaleString = (num) => {
    // Convert to number with two decimal places
    const x = Number(num).toFixed(2);
    // Format using toLocaleString
    const newNum = parseFloat(x).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return newNum;
}




export const getCurrencyIcon = (currency) => {
    return currencyIconList[currency?.toLowerCase()] ? currencyIconList[currency?.toLowerCase()] : currency
}


const currencyIconList = {
    "usd": "$"
} 