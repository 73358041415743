import { Dialog, DialogContent, IconButton } from '@material-ui/core'
import BreakdownTable from 'components/breakdown_table/BreakdownTable'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Style from "./Preview.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Edit } from '@material-ui/icons';
import ViewBreakdown from 'sites/shipper/pages/shipment_rates/rate_sheet/ViewBreakdown/ViewBreakdown';

const Preview = ({ handleSubmit, open, data, togglePreview ,setOpenModal2}) => {






    











    return (
        <>
            {/* {open && */}
            {/* <div className={Style.container}> */}

            {/* <div className={Style.inner_container}> */}
            <div className={Style.main}>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div>
                        {data.map((item, index) => (
                            <BreakdownTable data={item} key={index} />
                        ))}

                    </div>

                </PerfectScrollbar>

                {/* close previe btn  */}
                <IconButton
                    className={Style.close_btn}
                    onClick={() => togglePreview()}
                >
                    <CloseIcon style={{ color: '#fff', fontSize: '16px' }} />
                </IconButton>
                {/* edit offer btn  */}
                <IconButton
                    className={Style.edit_btn}
                    onClick={() => { setOpenModal2(true); togglePreview(); }}
                >
                    <Edit style={{ color: '#fff', fontSize: '16px' }} />
                </IconButton>
            </div>





        </>
    )
}

export default Preview