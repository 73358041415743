import React, { useEffect, useState } from 'react'
import {
    Grid,
    Container,
    LinearProgress,
    Card,
    Button,
    List,
    ListItem
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Alert from '@material-ui/lab/Alert';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import Style from "./FileUploader.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import DocPreview from '../DocPreview';
import { MAX_FILE_SIZE } from 'util/awsUploader';


const FileUploader = ({ type, limit, documentDetail, setDocumentDetail, setFileSizeError, description, urlToDelete, setUrlToDelete }) => {



    const showPreview = (file) => {
        if (typeof file === 'string') {
            return file;
        } else {
            return URL.createObjectURL(file);
        }
    };

    const checkFileSize = (items) => {
        let counter = 0
        items.map((item) => {
            if (item.size > MAX_FILE_SIZE) {
                setFileSizeError("Please select file less than 2MB")
                counter = counter + 1
            }
        })
        return counter > 0 ? false : true
    }


    const {
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps,
    } = useDropzone({
        maxFiles: "1",
        maxSize: "2097152",
        accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', '.docx'],
        onDrop: (acceptedFiles) => {
            setFileSizeError("")
            if (acceptedFiles.length === 0) { setFileSizeError("Please select a file"); return }
            if (acceptedFiles.length + documentDetail.uploads.filter(item => item.type === type).length > limit) {
                setFileSizeError("Maximum Limit Exceeds");
                return
            }
            // if (documentDetail.uploads.length > limit) { return }
            if (!checkFileSize(acceptedFiles)) { return }
            const array = []
            documentDetail.uploads.map((item) => {
                if (item.type === type) {
                    array.push({
                        file: item.file,
                        extension: item.extension,
                        name: item.name,
                        type: type
                    })
                } else {
                    array.push({
                        file: item.file,
                        extension: item.extension,
                        name: item.name,
                        type: item.type
                    })
                }
            })

            acceptedFiles.map((item) => {
                array.push({
                    file: item,
                    extension: item.path.split(".").pop(),
                    name: item.name,
                    type: type
                })
            })
            setDocumentDetail({
                ...documentDetail,
                uploads: array
            })
        }
    });

    // const handleDelete = (index) => {
    //     const newArray = documentDetail.uploads.filter((item, idx) => idx !== index)
    //     setDocumentDetail({
    //         ...documentDetail,
    //         uploads: newArray
    //     })
    // }

    const handleDelete = (index) => {
        const newArray = documentDetail.uploads.filter((item, idx) => {
            if (idx !== index) {
                return item
            } else {
                if (typeof item.file === 'string') {
                    setUrlToDelete((prev) => [...prev, item.file])
                }
            }
        })
        setDocumentDetail({
            ...documentDetail,
            uploads: newArray
        })
    }

    const [thumbs, setThumbs] = useState([])

    useEffect(() => {
        let array = []
        array = documentDetail && documentDetail.uploads.length > 0 && documentDetail.uploads.map((item, index) => {
            if (item.type === type) {
                return (
                    <DocPreview
                        document={{
                            name: item.name,
                            url: showPreview(item.file),
                            extension: item.extension
                        }}
                        index={index}
                        handleDelete={handleDelete}
                        edit={false}
                    />
                )
            }
        })
        setThumbs(array)
    }, [documentDetail])


    return (
        <>
            <div>
                <span className='font-size-xs'>{description}</span>
                {documentDetail &&
                    (documentDetail.uploads.filter(item => item.type === type)
                        && (documentDetail.uploads.filter(item => item.type === type).length !== limit)) &&
                    <div className="dropzone">
                        <div
                            {...getRootProps({
                                className: 'dropzone-upload-wrapper'
                            })}>
                            <input {...getInputProps()} />
                            <div className="dropzone-inner-wrapper p-4" style={{ minWidth: '210px', minHeight: '100px', background: 'white' }}>
                                {isDragAccept && (
                                    <div>
                                        <div className="d-55 hover-scale-lg icon-blob icon-blob-animated btn-icon text-success mx-auto">
                                            <svg
                                                className="d-55 opacity-2"
                                                viewBox="0 0 600 600"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(300,300)">
                                                    <path
                                                        d="M170.4,-137.2C213.2,-82.3,234.8,-11.9,223.6,56.7C212.4,125.2,168.5,191.9,104.3,226.6C40.2,261.3,-44.1,264,-104,229.8C-163.9,195.7,-199.4,124.6,-216.2,49.8C-233,-25.1,-231,-103.9,-191.9,-158C-152.7,-212.1,-76.4,-241.6,-6.3,-236.6C63.8,-231.6,127.7,-192.2,170.4,-137.2Z"
                                                        fill="currentColor"
                                                    />
                                                </g>
                                            </svg>
                                            <div className="blob-icon-wrapper">
                                                <CheckIcon className="d-20" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {isDragReject && (
                                    <div>
                                        <div className="d-55 hover-scale-lg icon-blob icon-blob-animated btn-icon text-danger mx-auto">
                                            <svg
                                                className="d-55 opacity-2"
                                                viewBox="0 0 600 600"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(300,300)">
                                                    <path
                                                        d="M169,-144C206.7,-87.5,216.5,-18,196.9,35.7C177.3,89.4,128.3,127.1,75.2,150.7C22,174.2,-35.4,183.5,-79.7,163.1C-124,142.7,-155.1,92.6,-164.1,40.9C-173.1,-10.7,-160.1,-64,-129,-118.9C-98,-173.8,-49,-230.4,8.3,-237.1C65.7,-243.7,131.3,-200.4,169,-144Z"
                                                        fill="currentColor"
                                                    />
                                                </g>
                                            </svg>
                                            <div className="blob-icon-wrapper">
                                                <CloseTwoToneIcon className="d-20" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!isDragActive && (
                                    <div className={Style.drag_zone_text_container}>
                                        <span className={Style.upload_text}>Upload</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }

                <div>
                    {thumbs.length > 0 && (

                        <div className={documentDetail.docType === "Other" && Style.ThumbContainer}>
                            {thumbs}
                        </div>

                    )}
                </div>

            </div>
        </>
    )
}

export default FileUploader