import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HeaderTitle from './HeaderTitle';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTitle);
