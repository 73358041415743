import Style from "./Blank.module.css";

/* eslint-disable */

const Blank = () => {
    return (
        <div className={Style.container}>
            <img className={Style.blank_img} src="/assets/forwarder/shipment_rates/no-rates.svg" alt="No Rates Available" />
        </div>
    )
}

/* eslint-enable */
export default Blank