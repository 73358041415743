export const ShipmentsGuide = [
   {
        heading: "Shipment Tabs",
        icon: "",
        description: "Easily Filter and Manage Your Shipments",
        content: [
            "Shipment tabs help forwarders filter and manage their shipments efficiently. Shipments can be categorized as active, pending, completed, or canceled. These tabs allow forwarders to quickly find and review the status of specific shipments. There are four types of shipment tabs.",
            "Active Shipments: These are shipments that have been initiated by the forwarder but are not yet completed.",
            "Pending Shipments: These are shipments that have been accepted by the forwarder but have not yet been initiated.",
            "Completed Shipments: These shipments have reached their destination, with all steps completed and the status marked as out for delivery.",
            "Cancelled Shipments: These are shipments that have been created but subsequently canceled by either the forwarder or the shipper through the shipment details resource center."
        ]
    },
    {
        heading: " Shipment Analytics Cards",
        icon: "",
        description: "Gain Detailed Insights into Your Shipment Analytics",
        content: [
            "Access comprehensive analytics on your shipments to understand their current status and performance. The shipment analytics cards provide insights into the number of active, pending, completed, and canceled shipments. Here is what each category represents.",
            "Active Shipments: These are shipments that have been initiated by the forwarder but are not yet completed.",
            "Pending Shipments: These are shipments that have been accepted by the forwarder but have not yet been initiated.",
            "Completed Shipments: These shipments have reached their destination, with all steps completed and the status marked as out for delivery.",
            "Cancelled Shipments: These are shipments that have been created but subsequently canceled by either the forwarder or the shipper through the shipment details resource center."
        ]
    },
    {
        heading: "Create Shipment on Shipments Page",
        icon: "",
        description: "Effortless Shipment Creation for Forwarders",
        content: [
            "As a forwarder, you can easily create shipments for your shippers. There are two methods available: select the shipper from your shipper list on the dashboard or add the shipper first and then create the shipment. This flexible approach ensures seamless management of your shipping operations."
        ]
    },
    {
        heading: "Add Shipper on Shipments Page",
        icon: "",
        description: "Simplified Shipper Addition for Efficient Operations",
        content: [
            "Adding a shipper to your dashboard is essential for creating shipments. Navigate to the shipper page or dashboard page and click 'Add Shipper' Enter the shipper's email address, and they will receive a notification email. Once the shipper confirms their participation, they will be added to your shipper list, allowing you to create shipments for them effortlessly."
        ]
    },
    {
        heading: "View Details",
        icon: "",
        description: "Comprehensive Shipment Information at Your Disposal",
        content: [
            "The 'View Details' feature provides an in-depth understanding of your shipment, covering everything from container tracking to various tabs such as Overview, Payments, Attachments, Activity, Notes, Add-ons, Delivery, and Resource Center",
            "If you need detailed information regarding the forwarder, consignor, consignee, attachments (e.g., House Bill of Lading, Master Bill of Lading, Insurance Certificate), dates (e.g., pickup, departure), transit times, port-to-port times, shipment value, shipping line, and more, you can find all the necessary details here. Additionally, you have the ability to update these details as needed."
            
        ]
    },
    
   
]
