import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { ClimbingBoxLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { autoRefreshPage, updateServiceWorker } from 'serviceWorker';

const MinimalLayout = (props) => {
  const { children, isLoading } = props;

  useEffect(() => {
    const id = setInterval(() => {
      // Check server every 30s for service worker update
      updateServiceWorker();
    }, 30000);

    autoRefreshPage();

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <BlockUi
      tag="div"
      blocking={isLoading}
      loader={<ClimbingBoxLoader color={'#793de6'} loading={isLoading} />}>
      {children}
    </BlockUi>
  );
};

MinimalLayout.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  isLoading: state.messages.isLoading
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MinimalLayout);
