import { InsightsGuide } from "./Insights";
import { BillingGuide } from "./billing";
import { DashboardGuide } from "./dashboard";
import { DocumentsGuide } from "./documents";
import { InboxGuide } from "./inbox";
import { MyServicesGuide } from "./my services";
import { MyShipperGuide } from "./myshipper";
import { SettingGuide } from "./setting";
import { ShipmentsGuide } from "./shipments";

/* eslint-disable */



import {
    AttachFile,
    Dashboard,
    DirectionsBoatOutlined,
    MailOutlined,
    ReceiptOutlined,
    SettingsOutlined,
    InsertChartOutlined,
} from '@material-ui/icons';

import ArticleIcon from '@mui/icons-material/Article';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { userPermission } from "util/forwarderPermissionRoles";

export const HelpGuideContent = {
    dashboard: DashboardGuide,
    shipments: ShipmentsGuide,
    shipper: MyShipperGuide,
    services: MyServicesGuide,
    insights: InsightsGuide,
    attachment: DocumentsGuide,
    billing: BillingGuide,
    mailbox: InboxGuide,
    settings: SettingGuide,
    "shipment-leads": [],
    "smart-documents": [],
}

export const HelpGuideHome = [
    {
        label: "Dashboard",
        icon: <Dashboard style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "dashboard",
        description: "Access your dashboard for an overview of shipment reports and analytics, including total shipments, active regions, smart documents, shipment leads, and shipment cards.",
        allowed: userPermission.dashboard.read
    },
    {
        label: "Shipment",
        icon: <DirectionsBoatOutlined style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "shipments",
        description:"View and analyze all shipment details, including active, pending, completed, and cancelled shipments, ensuring smooth logistics management.",
        allowed: userPermission.shipments.read
    },
    {
        label: "My Shipper",
        icon: <GroupsTwoToneIcon style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "shipper",
        description: "Manage shipper details, transactions, and communications to streamline your interactions and maintain strong business relationships.",
        allowed: userPermission.my_shipper.read
    },
    {
        label: "My Services",
        icon: <AddBusinessTwoToneIcon style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "services",
        description: "Define and control the regions and specific services you offer, ensuring efficient and targeted service delivery for your clients.",
        allowed: userPermission.my_services.read
    },
    {
        label: "Insights",
        icon: <InsertChartOutlined style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "insights",
        description: "Gain valuable analytics on your shippers, active regions, shipments, and earnings to make informed decisions and optimize operations.",
        allowed: userPermission.insights.read
    },

    {
        label: "Shipment Leads",
        icon: <SsidChartIcon style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "shipment-leads",
        description: "Track and manage potential shipments and inquiries to convert leads into successful deliveries efficiently.",
        allowed: userPermission.shipment_leads.read
    },
    {
        label: "Smart Documents",
        icon: <ArticleIcon style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "smart-documents",
        description: "Easily send, receive, and manage essential shipment documents and files, ensuring smooth and secure information exchange.",
        allowed: userPermission.smart_documents.read
    },

    {
        label: "Attachments",
        icon: <AttachFile style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "attachment",
        description: "Access and organize all documents and images related to shipments, shared between forwarders and shippers for seamless information exchange.",
        allowed: userPermission.documents.read
    },
    {
        label: "Billing",
        icon: <ReceiptOutlined style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "billing",
        description: "Review your financial transactions, including earnings, payouts, and commissions, to maintain clear and accurate financial records.",
        allowed: userPermission.billing_earnings.read
    },
    {
        label: "Inbox",
        icon: <MailOutlined style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "mailbox",
        description: "Communicate directly with shippers to resolve queries and coordinate shipment details, enhancing collaboration and service quality.",
        allowed: userPermission.inbox.read
    },
    {
        label: "Setting",
        icon: <SettingsOutlined style={{ color: "rgba(68, 49, 184, 1)" }} />,
        value: "settings",
        description: "Customize your account, security, notification, and preference settings for a tailored and secure user experience on Kargoplex.",
        allowed: []
    }
]

