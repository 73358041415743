import Style from "./TeamMemberBox.module.css";
import React from 'react'
import avatar1 from "assets/images/avatars/avatar1.jpg"
import { Close } from "@material-ui/icons";

const TeamMemberBox = () => {
    return (
        <div className={Style.container}>
            <img src={avatar1} alt="img" style={{ width: '21px', height: '21px', borderRadius: '50%' }} />
            <div className="d-flex flex-column">
                <span className={Style.user_role}>Consignee</span>
                <span className={Style.user_name}>Milan</span>
            </div>
            <Close style={{ color: 'var(--secondary-text)', fontSize: '8px' }} />


        </div>
    )
}

export default TeamMemberBox