import React, { useState } from 'react'
import Style from './NotVerified.module.css'
import { COMPANY_DOCUMENTS, PERSONAL_DOCUMENTS } from '../mockData';
import RejectedDoc from './RejectedDoc';
import DocumentBox from 'components/KYCUploadSystem/DocumentBox/DocumentBox';
import BoxContainer from 'components/KYCUploadSystem/BoxContainer/BoxContainer';
import { VerificationStatus } from 'util/VerificationStatus';
const VerificationDocuments = ({ kycStatus, data, setData, rejectedDoc }) => {
    const [urlToDelete, setUrlToDelete] = useState([])
    const [showActionButton, setShowActionButton] = useState(false)
    return (
        <>
            {rejectedDoc.show &&
                <BoxContainer classes={Style.rejectedDocBox}>
                    <RejectedDoc rejectedDoc={rejectedDoc} />
                </BoxContainer>
            }


            <BoxContainer>
                {(kycStatus.individual === VerificationStatus.PENDING || kycStatus.individual === VerificationStatus.REJECTED) &&
                    <DocumentBox
                        mainHeading="Personal Verification Documents"
                        type="Individual"
                        data={data}
                        setData={setData}
                        showOption={true}
                        dropDownOption={PERSONAL_DOCUMENTS}
                        urlToDelete={urlToDelete}
                        setUrlToDelete={setUrlToDelete}
                        showActionButton={showActionButton}
                        setShowActionButton={setShowActionButton}
                    />
                }
                {/* <div className={Style.left_inner} style={{ display: "block", paddingBottom: "20px" }}>
                    <input
                        type='checkbox'
                        checked={data.is_not_registered_company}
                        onChange={(e) => {
                            setData({
                                ...data,
                                is_not_registered_company: e.target.checked,
                                Company: []
                            })
                        }} />
                    <span className={Style.form_head}>  My company is not registered in government records </span>
                </div> */}
                {/* {!data.is_not_registered_company && */}
                {(kycStatus.company === VerificationStatus.PENDING || kycStatus.company === VerificationStatus.REJECTED) &&
                    <DocumentBox
                        mainHeading="Company Verification Documents"
                        type="Company"
                        data={data}
                        setData={setData}
                        showOption={true}
                        dropDownOption={COMPANY_DOCUMENTS}
                        urlToDelete={urlToDelete}
                        setUrlToDelete={setUrlToDelete}
                        showActionButton={showActionButton}
                        setShowActionButton={setShowActionButton}
                    />
                }
                {/* } */}

            </BoxContainer>
        </>
    )
}

export default VerificationDocuments