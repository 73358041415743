
const VerificationObject = Object.freeze({
  "Approved": "APP",
  "Rejected": "REJ",
  "Pending": "PEN",
  "In Review": "REV"
});

export const VerificationStatus = Object.freeze({
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  INREVIEW: "INREVIEW"
});

export const VerificationStatusArray = Object.entries(VerificationObject).map(([label, value]) => ({ label, value }));
