import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import RouteIcon from 'assets/svg/RouteIcon.svg';
import ShipmentIcon from '../../Reusables/ShipmentIcon/ShipmentIcon';
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import CountryFlag from 'components/CountryFlag';
import LocationPin from 'assets/svg/location/pin.svg';
import { formatDate } from 'util/Utils';
import LeafletMap from 'components/LeafletMap';
import clsx from 'clsx';
import { CONTAINER_STATUS_LENGTH, SHIPMENT_STATUS } from 'util/constants';
import Style from "./ShipmentOverview.module.css";
import DetailRow from './DetailRow/DetailRow';
import ProgressStepper from 'components/Common/ProgressStepper/ProgressStepper';
import Status from 'sites/shipper/pages/ShippingLeads/Card/Status/Status';
import AddServicePopup from './AddServicePopup/AddServicePopup';
import Team from './ManagerBox/Team/Team';
import Driver from './ManagerBox/Driver/Driver';
import StatusPopup from './StatusPopup/StatusPopup';
import FromTo from 'components/Common/from_to/FromToVert';
import { ForwarderService, ShipmentsService } from 'service';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'sites/shipper/components/Toast/toast';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { transitTimeCalculator } from 'util/Shipment/shipment';
import GreenStatus from 'sites/shipper/pages/Dashboard/RecentShipments/ShipmentSummaryCard/GreenStatus/GreenStatus';
import moment from 'moment';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import { renderShipmentAddress } from 'util/Shipment/shipmentLoads';
const styles = (theme) => ({
  mapWrapper: {
    height: 200,
    width: '100%'
  },
  routeIcon: {
    margin: '0 15px',
    height: '1rem'
  },
  origin: {
    marginLeft: '24px',
    position: 'relative',
    paddingBottom: '16px',
    '&::before': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '-24px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: '#0088ff',
      zIndex: 2
    },
    '&::after': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '-20px',
      width: '2px',
      height: '100%',
      backgroundColor: '#0088ff',
      zIndex: 1
    }
  },
  destination: {
    marginLeft: '24px',
    position: 'relative',
    marginBottom: '16px',
    '&::before': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '-24px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: '#0088ff',
      zIndex: 2
    }
  },
  locationStatusName: {
    color: '#15354e',
    maxWidth: '100%',
    fontSize: '10px',
    position: 'absolute',
    top: 0,
    display: 'inline-table',
    whiteSpace: 'nowrap'
  },
  carrierInfo: {
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: '200px',
    width: '100%',
    height: '100%',
    maxHeight: '80px',
    backgroundImage: (props) =>
      props && props.booking
        ? `url(${props?.booking?.company_logo})`
        : 'none'
  }
});



const ShipmentOverview = ({ classes, booking }) => {

  const auth = useSelector((state) => state.session.auth)








  const [loading, setLoading] = useState(false)



  // const [shipmentStatus, setShipmentStatus] = useState()
  // const [run, setRun] = useState(true)

  const [shipmentOverviewDetail, setShipmentOverviewDetail] = useState(null)

  useEffect(() => {
    setLoading(true)
    const data_to_send = {
      forwarder_id: auth.accountId,
      shipment_id: booking
    }
    ShipmentsService.fetchShipmentOverviewDetail(data_to_send, auth.token).then(res => {
      if (res.error) {
        console.log(res.error)
        notify(dispatch, "error", res.error)
      } else {
        setShipmentOverviewDetail(res);
        // setOpenRightDrawer(true);
      }
      setLoading(false)
    })
  }, [booking])























  const dispatch = useDispatch()


  const [positions, setPositions] = useState([])
  const [midPoint, setMidPoint] = useState([])

  useEffect(() => {
    // setLoading(true)
    const data_to_send = {
      shipment_id: booking,
    }
    ShipmentsService.fetchContainersLocation(data_to_send).then(res => {
      if (res.error) {
        // notify(dispatch, "error", res.error)
        console.log(res.error)
        // setLoading(false)
      } else {
        setPositions(res.trackingData)
        setMidPoint(res.midPointOfOriginAndDestination)
        // setLoading(false)
      }
    })
  }, [])


  return (
    <>

      {/* {loading && <LoaderComponent loading={loading} />} */}

      {loading && <SkeletonComponent height='300px' instances={1} />}
      {loading && <SkeletonComponent height='150px' instances={1} />}
      {loading && <SkeletonComponent height='150px' instances={1} />}

      {!loading &&
        <div className={`${Style.container} w-100 h-100 position-relative`}>
          <div className="d-flex align-items-start justify-content-between flex-wrap c-gap-1 mb-3">
            {/* <div className={`${Style.route_text} d-flex align-items-center font-weight-bold mb-2`}>
            <span className='w-40 text-truncate'>{renderShipmentAddress(shipmentOverviewDetail?.origin)}</span>
              <img
                className={`${Style.route_icon} ${classes.routeIcon}`}
                src={RouteIcon}
                alt="route icon"
              />
              <span className='w-40 text-truncate'>{renderShipmentAddress(shipmentOverviewDetail?.destination)}</span>
            </div> */}


            <div className="font-size-xs position-relative d-flex c-flex-1 justify-content-end">
              <Button

                className={`${Style.status_popup_btn} btn-pill btn-primary text-capitalize font-size-xs px-2 py-1`}
              >
                {shipmentOverviewDetail?.shipment_status}
              </Button>




            </div>

          </div>

          <div className={classes.mapWrapper}>
            <LeafletMap positions={positions} midPoint={midPoint} zoom={1} height={200} width={'100%'} />
          </div>

          <div className="my-4">

            <div className={Style.grid_container}>
              <div>
                <span className="font-weight-bold text-uppercase font-size-sm d-block mb-3">
                  ID: {shipmentOverviewDetail?.booking_id}
                </span>
                <ShipmentIcon
                  load_type={shipmentOverviewDetail?.load_type}
                  cargo_icon={shipmentOverviewDetail?.cargo_icon}
                  cargo_type={shipmentOverviewDetail?.cargo_type}
                  cargo_quantity={shipmentOverviewDetail?.cargo_count}
                  count={1}
                  editable={false}
                />
              </div>
              <div>
                {/* <FromTo
                  origin={`${shipmentOverviewDetail?.origin?.city}, ${shipmentOverviewDetail?.origin?.country} | ${shipmentOverviewDetail?.origin?.port} `}
                  origin_country_code={shipmentOverviewDetail?.origin?.country}
                  destination_country_code={shipmentOverviewDetail?.destination?.country}
                  destination={`${shipmentOverviewDetail?.destination?.city}, ${shipmentOverviewDetail?.destination?.country} | ${shipmentOverviewDetail?.destination?.port} `}
                /> */}
                <FromTo
                  origin={renderShipmentAddress(shipmentOverviewDetail?.origin)}
                  origin_country_code={shipmentOverviewDetail?.origin?.country?.code}
                  destination={renderShipmentAddress(shipmentOverviewDetail?.destination)}
                  destination_country_code={shipmentOverviewDetail?.destination?.country?.code}
                />
              </div>
            </div>
          </div>
          <div className="divider my-3" />

          <div>
            <Grid container spacing={2}>
              <Grid item md={8}>

                <DetailRow label={"VESSEL/CARRIER:"} value={shipmentOverviewDetail?.carrier?.name} />




                <DetailRow label='Ready Date:' value={shipmentOverviewDetail?.ready_on_date} />
                <DetailRow label='Transit Time' labelHelper={'(estimated):'} value={transitTimeCalculator(shipmentOverviewDetail?.transit_time?.estimate)} />
                <DetailRow label='Arrival Date' labelHelper={'(estimated):'} value={transitTimeCalculator(shipmentOverviewDetail?.transit_time?.port_to_port)} />
                <DetailRow label='Pickup Date:' value={shipmentOverviewDetail?.pickupDate === "Pending" ? shipmentOverviewDetail?.pickupDate : moment(shipmentOverviewDetail?.pickupDate).format("ll")} />
                <DetailRow label='Departure Date' value={shipmentOverviewDetail?.departureDate === "Pending" ? shipmentOverviewDetail?.departureDate : moment(shipmentOverviewDetail?.departureDate).format("ll")} />
              </Grid>
              <Grid item xs={4} justify="end">
                {shipmentOverviewDetail?.company_logo && (
                  <div className={classes.carrierInfo} />
                )}
              </Grid>
            </Grid>
          </div>
          <div className="divider my-3" />



          <div>
            <span className={`font-size-sm c-font-dark`} style={{ margin: "0 0 5px 0" }}>
              Commodities:
            </span>
            {shipmentOverviewDetail?.commodities.map((item) => (
              <div style={{ display: "flex", alignItems: "baseline", margin: "0 0 3px 0" }}>
                <span>
                  <CircleRoundedIcon className='text-success' style={{ fontSize: "10px", margin: "0 10px 0 0" }} />
                </span>
                <span className="font-size-xs mw-100" style={{ whiteSpace: "wrap" }}>{item.code} - {item.description}</span>
              </div>
            ))}
          </div>

          <div className="divider my-3" />


          <div className={`d-flex justify-content-between align-items-center c-gap-1 flex-wrap `}>
            <div className={`d-flex flex-column`}>
              <span className={`font-size-sm c-font-dark`} id='services-requested'>Services Requested</span>
              <div className={`d-flex flex-column pt-1`} style={{ gap: "0.5rem" }}>
                {shipmentOverviewDetail?.services.length > 0 ?
                  <>
                    {shipmentOverviewDetail?.services.map((item, index) => (
                      <GreenStatus label={item?.serviceName} key={index} />
                    ))}
                  </>
                  :
                  <span style={{ fontSize: "12px" }}>
                    No Services Selected
                  </span>}
              </div>
            </div>

          </div>


        </div>
      }
    </>
  );
};

ShipmentOverview.defaultProps = {};

ShipmentOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentOverview);
