import React, { useState } from 'react'
import Style from "./ChattingIcon.module.css"
import ChatIcon from '@mui/icons-material/Chat';
import { IconButton, Tooltip } from '@material-ui/core';
import { ShipmentsService } from 'service';
import { setChatRoom } from 'actions';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { userPermission } from 'util/forwarderPermissionRoles';
import { setLoader } from '../../../actions';
const ChattingIcon = ({ shipment_id, disabled = true, varient }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const auth = useSelector((state) => state.session.auth)
    const handleClick = () => {
        if (disabled) {
            return
        }
        // setLoading(true)
        dispatch(setLoader(true))
        ShipmentsService.startChatting(shipment_id).then(res => {
            if (res.success) {
                dispatch(setChatRoom(res.chatRomId))
                history.push('/mailBox')
            } else {
                notify(dispatch, "error", res.error)
            }
            // setLoading(false)
            dispatch(setLoader(false))
        })
    }



    return (
        <>{userPermission.inbox.create.includes(auth.accountCmpyLinkType) &&
            <>
                {/* {loading && <LoaderComponent loading={loading} />} */}
                {varient === 1 &&
                    <Tooltip
                        placement="bottom"
                        title={"Chat with Shipper"}
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <IconButton className={`bg-primary ${Style.varient_1}`} disabled={disabled} onClick={handleClick} >
                            <ChatIcon className="text-white" />
                        </IconButton>
                    </Tooltip>
                }
                {varient === 2 &&
                    <Tooltip
                        placement="bottom"
                        title={"Chat with Shipper"}
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <IconButton disabled={disabled} onClick={handleClick} >
                            <ChatIcon className="text-dark" fontSize='small' />
                        </IconButton>
                    </Tooltip>
                }
            </>
        }
        </>
    )
}

export default ChattingIcon