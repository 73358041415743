import { connect } from 'react-redux';
import { setHeaderDrawerToggle } from 'reducers/ThemeOptions';
import HeaderActions from './HeaderActions';

const mapStateToProps = (state) => ({
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderDrawerToggle: (enable) => dispatch(setHeaderDrawerToggle(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderActions);
