export const DOCUMENT_CATEGORY = {
  INSURANCE: { value: 0, label: 'Insurance' },
  CUSTOMS: { value: 1, label: 'Customs Docs' },
  SHIPMENT_QUOTE: { value: 2, label: 'Shipment Quote' },
  CUSTOMS_QUOTE: { value: 3, label: 'Customs Quote' },
  SHIPMENT: { value: 4, label: 'Shipment' },
  GENERAL: { value: 5, label: 'General' }
};

export const DOCUMENTS_LIST = [
  {
    id: 0,
    title: 'Bill of Lading',
    url: 'https://www.ms-ins.com/pdf/cargo/MARINECARGOINSURANCECLAUSES.pdf',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.SHIPMENT
  },
  {
    id: 1,
    title: 'Your policy agreement',
    url: 'https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.docx',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.INSURANCE
  },
  {
    id: 2,
    title: 'Customs Papers',
    url: 'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.CUSTOMS
  },
  {
    id: 3,
    title: 'Customs Quote',
    url: 'https://file-examples.com/wp-content/uploads/2019/09/file-sample_100kB.rtf',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.CUSTOMS_QUOTE
  },
  {
    id: 4,
    title: 'Report Analytics',
    url: 'https://file-examples.com/wp-content/uploads/2017/02/file_example_XLS_100.xls',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.GENERAL
  },
  {
    id: 5,
    title: 'Shipment Quote from Jecmeck',
    url: 'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.SHIPMENT_QUOTE
  },
  {
    id: 6,
    title: 'Shipment Quote from Movehub',
    url: 'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf',
    shipmentId: '23hjdjbh45559+hfhgfk235',
    bookingNumber: 'KP-505620',
    category: DOCUMENT_CATEGORY.SHIPMENT_QUOTE
  }
];
