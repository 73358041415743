import * as moment from 'moment';

export default class LodgingModel {
  static createRoomList = rooms => (rooms ? rooms.map(room => LodgingModel.createRoomsObj(room)) : []);

  static createRoomsObj = room => {
    let roomObj = {
      '@type': 'RoomType',
      underName: {
        familyName: '',
        givenName: ''
      }
    };

    if (room.givenName) {
      roomObj.underName.givenName = room.givenName;
    }

    if (room.underName && room.underName.givenName) {
      roomObj.underName.givenName = room.underName.givenName;
    }

    if (room.familyName) {
      roomObj.underName.familyName = room.familyName;
    }

    if (room.underName && room.underName.familyName) {
      roomObj.underName.familyName = room.underName.familyName;
    }

    if (room.lodgingUnitType) {
      roomObj.lodgingUnitType = room.lodgingUnitType;
    }

    if (room.roomType) {
      roomObj.lodgingUnitType = room.roomType;
    }

    if (room.bedding) {
      roomObj.bedding = room.bedding;
    }

    if (room.amenities) {
      roomObj.amenities = room.amenities;
    }

    if (room.membershipId) {
      roomObj.programMembership = {
        Name: `${roomObj.underName.givenName} ${roomObj.underName.familyName}`,
        membershipNumber: room.membershipId
      };
    }

    if (room.membershipNumber) {
      roomObj.programMembership = {
        Name: `${roomObj.underName.givenName} ${roomObj.underName.familyName}`,
        membershipNumber: room.membershipNumber
      };
    }

    if (room.confirmStatus) {
      roomObj.confirmStatus = room.confirmStatus;
    }

    return roomObj;
  };

  static createNewLodgingReservation = (lodging, umTripId) => {
    const checkinDate = `${lodging.checkinDate.format('MM/DD/YYYY')} ${lodging.checkinTime}`;
    const checkoutDate = `${lodging.checkoutDate.format('MM/DD/YYYY')} ${lodging.checkoutTime}`;
    return lodging.nameUmId !== ''
      ? {
          '@type': 'LodgingReservation',
          umTripId,
          name: lodging.name,
          checkinDate: moment.utc(checkinDate, 'MM/DD/YYYY HH:mm').format(),
          checkoutDate: moment.utc(checkoutDate, 'MM/DD/YYYY HH:mm').format(),
          reservationFor: {
            name: lodging.name,
            umId: lodging.nameUmId
          },
          crossDate: lodging.crossDate
        }
      : {
          '@type': 'LodgingReservation',
          umTripId,
          name: lodging.name,
          checkinDate: moment.utc(checkinDate, 'MM/DD/YYYY HH:mm').format(),
          checkoutDate: moment.utc(checkoutDate, 'MM/DD/YYYY HH:mm').format(),
          reservationFor: {
            name: lodging.name
          },
          crossDate: lodging.crossDate
        };
  };

  static createLodgingReservation = (lodging, umTripId) => {
    const { subReservation, ...props } = lodging;

    let lodgingReservationObj = {
      '@type': 'LodgingReservation',
      umId: lodging.umId,
      umTripId,
      crossDate: lodging.crossDate,
      ...props
    };

    if (lodging.subReservation) {
      lodgingReservationObj.rooms = LodgingModel.createRoomList(lodging.subReservation);
    }

    return lodgingReservationObj;
  };

  /**
   *
   * @param type
   * @param checkinDate
   * @param checkoutDate
   * @param reservationNumber
   * @param reservationStatus
   * @param name
   * @param umId
   * @param crossDate
   * @returns {{'@type': *, name: *, checkinDate: *, checkoutDate: *, reservationNumber: *, reservationStatus: *, reservationFor: {name: *, umId: *}, crossDate: *}}
   */
  static createSummaryObj = (
    type,
    checkinDate,
    checkoutDate,
    reservationNumber,
    reservationStatus,
    name,
    umId,
    crossDate
  ) => {
    let lodgingSummaryObj = {
      '@type': type,
      name,
      checkinDate,
      checkoutDate,
      reservationNumber,
      reservationStatus,
      reservationFor: {
        name
      },
      crossDate
    };

    if (umId !== '') {
      lodgingSummaryObj.reservationFor.umId = umId;
    }

    return lodgingSummaryObj;
  };

  /**
   *
   * @param umTripId
   * @param umId
   * @param rates
   * @param rooms
   * @param description
   * @param cancellationPolicy
   * @param pricing
   * @param summary
   * @returns {{name: never, umTripId: *, umId: *, cancellationPolicy: *, rates: *, rooms: *, description: *}}
   */
  static generateLodgingReservation = (
    umTripId,
    umId,
    rates,
    rooms,
    description,
    cancellationPolicy,
    pricing,
    summary
  ) => {
    return {
      umTripId,
      umId,
      ...pricing,
      ...summary,
      cancellationPolicy,
      rates,
      rooms,
      description
    };
  };
}
