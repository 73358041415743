import { useEffect, useState } from 'react';
import Style from './filter.module.css';
import DropdownOptions from './DropdownOptions';
import { Button, Checkbox, TextField, Tooltip } from '@material-ui/core';
import ShippingRouteBox from './ShippingRouteBox/ShippingRouteBox';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { ForwarderService, MyShipperService } from 'service';
import { useDispatch, useSelector } from 'react-redux';
import StyledInput from 'components/StyledInput';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { setFilterDataInRedux } from 'actions';
import { TRANSPORT_MODES_LIST, fetchCargoCategories } from 'util/constants';

/* eslint-disable */
const Filters = ({ filters, setFilters, handlePageChange, handleFilter }) => {
  const data = [{ label: 'hell0', value: 'hoii' }];

  const [city, setCity] = useState(null);

  const auth = useSelector((state) => state.session.auth);

  // const [services, setServices] = useState([]);

  // const [addOnServices, setAddOnServices] = useState([]);

  // useEffect(()=>{

  // },[filters])

  // useEffect(() => {
  //   //   setLoading(true);
  //   ForwarderService.fetchService(auth?.cmpyId, auth?.token).then((response) => {
  //     if (response.error) {
  //       console.log(response.error);
  //     } else {
  //       setServices(response);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   MyShipperService.fetch_all_services(auth?.token).then((res) => {
  //     if (res.error) {
  //       console.log(res.error);
  //     } else {
  //       setAddOnServices(res);
  //     }
  //   });
  // }, []);

  // console.log(addOnServices, 'abcgdy');

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFilters((prev) => {
      return {
        ...prev,
        [name]: value
      };
    });
    // handlePageChange('', 1);
    // dispatch(
    //   setFilterDataInRedux({
    //     ...filters,
    //     request_id: value,
    //     page: 'shippingLeads'
    //   })
    // );
  };

  const dispatch = useDispatch();

  // const handleTransportMethod = (e) => {
  //   const value = e.target.value;

  //   if (filters.transportation_mode.includes(value)) {
  //     const newModes = filters.transportation_mode.filter(
  //       (item) => item !== value
  //     );
  //     setFilters({ ...filters, transportation_mode: newModes, loads: [] });
  //     dispatch(
  //       setFilterDataInRedux({
  //         ...filters,
  //         transportation_mode: newModes,
  //         loads: [],
  //         page: 'shippingLeads'
  //       })
  //     );
  //   } else {
  //     const newModes = [...filters.transportation_mode, value];
  //     setFilters({ ...filters, transportation_mode: newModes, loads: [] });
  //     dispatch(
  //       setFilterDataInRedux({
  //         ...filters,
  //         transportation_mode: newModes,
  //         loads: [],
  //         page: 'shippingLeads'
  //       })
  //     );
  //   }

  //   handlePageChange('', 1);
  // };
  // const handleLoadsChange = (e) => {
  //   const value = e.target.value;
  //   if (filters.loads.includes(value)) {
  //     const newLoads = filters.loads.filter((item) => item !== value);
  //     setFilters({ ...filters, loads: newLoads });
  //     dispatch(
  //       setFilterDataInRedux({
  //         ...filters,

  //         loads: newLoads,
  //         page: 'shippingLeads'
  //       })
  //     );
  //   } else {
  //     const newLoads = [...filters.loads, value];
  //     setFilters({ ...filters, loads: newLoads });
  //     dispatch(
  //       setFilterDataInRedux({
  //         ...filters,

  //         loads: newLoads,
  //         page: 'shippingLeads'
  //       })
  //     );
  //   }

  //   handlePageChange('', 1);
  // };

  // const handleAddOnServices = (e) => {
  //   const value = e.target.value;

  //   if (filters.services.includes(value)) {
  //     const newServices = filters.services.filter((item) => item !== value);
  //     setFilters({ ...filters, services: newServices });
  //     dispatch(
  //       setFilterDataInRedux({
  //         ...filters,
  //         services: newServices,
  //         page: 'shippingLeads'
  //       })
  //     );
  //   } else {
  //     const newServices = [...filters.services, value];
  //     setFilters({ ...filters, services: newServices });
  //     dispatch(
  //       setFilterDataInRedux({
  //         ...filters,
  //         services: newServices,
  //         page: 'shippingLeads'
  //       })
  //     );
  //   }

  //   handlePageChange('', 1);
  // };

  const resetFilter = () => {
    setFilters({
      ready_date_from: '',
      ready_date_to: '',
      origin: {
        country: '',
        state: '',
        city: '',
        port: ''
      },
      destination: {
        country: '',
        state: '',
        city: '',
        port: ''
      },
      request_id: '',
      loads: [],
      transportation_mode: [],
      services: [],
      carriers: []
    });
    handlePageChange('', 1);

    dispatch(setFilterDataInRedux(null));
  };

  // console.log(filters?.request_id,"abcd");

  return (
    <div className={`${Style.container}`}>
      {/* <TextField id="standard-basic" label="Request Id" variant="standard" /> */}
      <div className="d-flex align-items-center">
        <FilterAltOutlinedIcon />
        <span className="font-weight-bold font-size-lg">Filters</span>
      </div>
      <div style={{ margin: '0 0 -10px 0' }} className="divider" />

      <TextField
        id="standard-basic"
        name="request_id"
        label="Request Id"
        value={filters?.request_id}
        onChange={handleChange}
      />
      {/* <StyledInput
        id="standard-basic"
        name="request_id"
        label="Request Id"
        value={filters?.request_id}
        onChange={handleChange}
      /> */}

      <div className={`${Style.flex_col}`} style={{ gap: '1rem' }}>
        <span className="font-size-md font-weight-bold">
          Shipping Route
        </span>
        {/* <span className={Style.label_head}>Shipping Route</span> */}
        <ShippingRouteBox
          // services={services}
          filters={filters}
          setFilters={setFilters}
          handlePageChange={handlePageChange}
          from=""
          to=""
        />
      </div>

      {/* <div className={Style.flex_col}>
                <span className={Style.label_head}> TYPE OF SHIPMENTS</span>
                <div>
                    <Checkbox id="all-filter" />
                    <label htmlFor="all-filter" className="c-font-title c-font-light">All</label>
                </div>

            </div> */}

      {/* <InputLabel htmlFor={'Select City*'}>
        <Autocomplete
          style={{ width: '100%' }}
          size="small"
          value={filters.origin}
          id="combo-box-demo"
          options={services}
          // closeIcon={false}
          onChange={(e, value) => {
            setFilters((prev) => {
              return {
                ...prev,
                origin: value
              };
            });
            handlePageChange('', 1);

            dispatch(
              setFilterDataInRedux({
                ...filters,

                origin: value,
                page: 'shippingLeads'
              })
            );
          }}
          getOptionLabel={(option) =>
            `${option?.address?.addressLocality?.label}(${option?.address?.addressLocality?.country_id?.value})`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name={'City'}
              label={'Select City*'}
              variant={'outlined'}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }}
            />
          )}

          // renderInput={(params) => <TextField {...params} label="Select City*" />}
        />
      </InputLabel> */}

      {/* <div>
        <span className="font-size-md font-weight-bold">
          Select Transporation Method
        </span>
      </div>
      <div className={Style.TwoFieldContainer} style={{ gap: '10px' }}>
        {[
          { label: 'SEA', value: 'SEA' },
          { label: 'AIR', value: 'AIR' }
        ].map((option, index) => (
          <Tooltip
            placement="top"
            title={option.label}
            arrow
            style={{ width: 'max-content' }}
            classes={{ tooltip: 'tooltip-kargo-blue' }}>
            <label
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <input
                type="checkbox"
                value={option.value}
                checked={filters.transportation_mode.includes(option.value)}
                onChange={handleTransportMethod}
              />
              <span className="font-size-sm text-black text-truncate">
                {option.label}
              </span>
            </label>
          </Tooltip>
        ))}
      </div>

      {filters.transportation_mode.includes('SEA') && (
        <>
          {' '}
          <div>
            <span className="font-size-md font-weight-bold">
              Select Load Type
            </span>
          </div>
          <div className={Style.TwoFieldContainer} style={{ gap: '10px' }}>
            {[
              { label: 'FCL', value: 'FCL' },
              { label: 'LCL', value: 'LCL' },
              { label: 'BULK', value: 'BULK' }
            ].map((option, index) => (
              <Tooltip
                placement="top"
                title={option.label}
                arrow
                style={{ width: 'max-content' }}
                classes={{ tooltip: 'tooltip-kargo-blue' }}>
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={filters.loads.includes(option.value)}
                    onChange={handleLoadsChange}
                  />
                  <span className="font-size-sm text-black text-truncate">
                    {option.label}
                  </span>
                </label>
              </Tooltip>
            ))}
          </div>{' '}
        </>
      )} */}

      {/* <div>
        <span className="font-size-md font-weight-bold">Select Services</span>
      </div>
      <div className={Style.TwoFieldContainer} style={{ gap: '10px' }}>
        {addOnServices.map((option, index) => (
          <Tooltip
            placement="top"
            title={option.serviceName}
            arrow
            style={{ width: 'max-content' }}
            classes={{ tooltip: 'tooltip-kargo-blue' }}>
            <label
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <input
                type="checkbox"
                value={option._id}
                checked={filters.services.includes(option._id)}
                onChange={handleAddOnServices}
              />
              <span className="font-size-sm text-black text-truncate">
                {option.serviceName}
              </span>
            </label>
          </Tooltip>
        ))}
      </div> */}

      {/* 

      {filters?.transportation_mode.air && (
        <div>
          <label htmlFor={'Air'}>
            Air:
            <Checkbox name="air-sub" onChange={handleCheckboxChange} />
          </label>
        </div>
      )} */}
      <span className="font-size-md font-weight-bold">
        Select Transporation Method
      </span>
      {TRANSPORT_MODES_LIST &&
        TRANSPORT_MODES_LIST.map((item, index) => (
          <DropdownOptions
            filters={filters}
            setFilters={setFilters}
            id={`basic-${index}`}
            header={item.label}
            iconSrc={`${item.label.toLowerCase()}`}
            options={fetchCargoCategories(item)}
          />
        ))}
      <div style={{display:"flex",justifyContent:"space-evenly"}}>
        <Button
          className="btn-link btn-link-primary p-0"
          onClick={handleFilter}>
          Apply Filters
        </Button>
        <Button
          className="btn-link btn-link-danger p-0"
          onClick={resetFilter}>
          Reset Filters
        </Button>
      </div>
      {/* <DropdownOptions
                header="Land"
                iconSrc="\assets\forwarder\shipping_leads\land.svg"
                options={["FCL", "FTL / LTL", "RAIL"]}
            /> */}
      {/* <DropdownOptions
        iconSrc="\assets\forwarder\shipping_leads\air.svg"
        header="Air"
        options={['Air']}
      /> */}
    </div>
  );
};
/* eslint-enable */
export default Filters;
