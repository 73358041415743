import { setActiveReservation } from 'actions'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ForwarderService } from 'service'
import { notify } from 'sites/shipper/components/Toast/toast'
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog'
import Style from "./CancelShipmentPopup.module.css";
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading'
import { Button, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core'
import SectionSubHeading from 'components/Common/Heading/SectionSubHeading/SectionSubHeading'
import OutlineFieldSimple from 'components/Common/OutlineFieldSimple/OutlineFieldSimple'
import { Close } from '@mui/icons-material'
import ModalSimple from 'components/ModalSimple'

const CancelShipmentPopup = ({
    shipmentId,
    handleClose,
    run,
    setRun,
    refresh,
    setRefresh,
    open
}) => {

    const cancelShipmentReasons = [
        {
            label: "Incorrect or incomplete shipping documents",
            value: "Incorrect or incomplete shipping documents"
        },
        {
            label: "Delays or scheduling conflicts with the selected carrier",
            value: "Delays or scheduling conflicts with the selected carrier"
        },
        {
            label: "Problems with customs clearance, including missing or incorrect paperwork",
            value: "Problems with customs clearance, including missing or incorrect paperwork"
        },
        {
            label: "Changes in the planned route or transportation mode",
            value: "Changes in the planned route or transportation mode"
        },
        {
            label: "Other",
            value: "Other"
        },
    ]

    const [reason, setReason] = useState()
    const [reasonExplanation, setReasonExplanation] = useState()
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [error, setError] = useState()
    const auth = useSelector((state) => state.session.auth)
    const dispatch = useDispatch()
    const handleSubmit = () => {
        if (!reason && !reasonExplanation) {
            setError("Please Select Valid Reason")
        }
        const data_to_send = {
            forwarder_id: auth.accountId,
            company_id: auth.cmpyId,
            reason: reasonExplanation,
            type: "FORWARDER",
            shipment_id: shipmentId
        }


        ForwarderService.cancelShipment(data_to_send, auth.token).then(res => {
            if (res.error) {
                notify(dispatch, "error", res.error)
            } else {
                notify(dispatch, "success", res.message)
                handleClose()
                dispatch(setActiveReservation(null));
                setRun(!run)
                setRefresh(!refresh)
            }
        })
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setReason(value)
        if (value === 'Other') {
            setReasonExplanation("")
            return
        }
        setReasonExplanation(e.target.value)
    }

    return (
        <>
            <ModalSimple
                modalTitle={"Cancel Shipment"}
                open={open}
                action={handleSubmit}
                modalDescription={<SectionSubHeading title={'Reason for Cancellation of Shipment :'} />}
                maxWidth={"xl"}
                disabled={!reasonExplanation}
                handleClose={() => { handleClose(); setReason(null)}}
                actionButtonText={"Confirm"}
            >
                <div className={Style.container}>

                    <div className={Style.main}>


                        <FormControl>
                            <RadioGroup
                                defaultValue=""
                                name="radio-buttons-group"
                                value={reason}
                                onChange={handleChange}
                            >
                                {cancelShipmentReasons?.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={<Radio size='small' />}
                                        label={<span className={Style.radio_label}>{item.label}</span>}
                                        value={item.value}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        {reason === "Other" &&
                            <OutlineFieldSimple
                                type='text'
                                placeholder='Enter a valid reason for cancellation'
                                onChange={(e) => setReasonExplanation(e.target.value)}
                                value={reasonExplanation}
                                isMultiline
                                maxRows={3}
                                rows={2}
                            />
                        }
                    </div>
                    {error && <span style={{ fontSize: "12px", color: "red" }}>{error}</span>}
                    {/* <div>
                        <Button
                            className={`btn-danger ${Style.btn_disabled}`}
                            onClick={() => setConfirmDialogOpen(true)}
                            disabled={!reasonExplanation}
                        >
                            Cancel Now
                        </Button>
                    </div>

                    <IconButton
                        className={`btn-neutral-primary icon-btn d-30 ${Style.close_btn}`}
                        onClick={handleClose}
                    >
                        <Close fontSize='small' />
                    </IconButton> */}
                </div>

            </ModalSimple>
            <ConfirmDialog
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleSubmit}
                question={`Are you sure you want to cancel this shipment`}
            />



        </>
    )
}

export default CancelShipmentPopup