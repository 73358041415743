export const BillingGuide = [
    {
        heading:"Earnings Tab",
        icon:"",
        description:"Monitor and Analyze Your Monthly Earnings with Detailed Insights.",
         content: [
            "The Earnings tab provides a comprehensive overview of your financial performance on a monthly basis. Here, you can view a graphical analysis of your monthly earnings, allowing you to track trends and make informed decisions. The tab also details upcoming charges and total earnings, helping you stay on top of your financial obligations and revenues. Additionally, you can see the status of payments, identifying how many payments have been received and how many are still pending. The tab also includes information on shipment commissions, enabling you to track how much commission you earn from shipments between different locations."
           ]
    },

    {
        heading:"Payouts Tab",
        icon:"",
        description:"Manage and Track Your Earnings and Payouts Efficiently",
         content: [
            "The Payouts tab offers a detailed breakdown of your financial transactions and earnings. This tab includes analytics on total earnings, upcoming earnings, and confirmed earnings. You can also view the schedule for upcoming payouts, ensuring you know when to expect your payments. Additionally, the tab lists all transactions made with shippers, providing a clear record of your financial interactions. You can also review and manage the payment methods used for these transactions, ensuring that your preferred payment methods are up-to-date and properly configured. This comprehensive view helps you manage your finances with greater accuracy and ease."
        ]
    },
]