import { getBaseUrl } from 'config/config';
// import { HttpMethods, HttpService } from './index';
import { HttpMethods, httpService } from './global';

export default class TransactionService {



  static async fetchTransaction(forwarder_id, shipper_id, purpose, token, page, type) {
    const url = `transaction/forwarder/fetch?forwarder_id=${forwarder_id}&shipper_id=${shipper_id}&purpose=${purpose}&page=${page}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: type,
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async fetchForwarderCommissions(id, token, page) {
    const url = `transaction/forwarder/fetch-shipment-commisions?forwarder_id=${id}&page=${page}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_my_wallet',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchEarningAnalysis(token) {
    const url = `transaction/forwarder/fetch-earning-analysis`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_my_wallet',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


}
