import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import CustomLink from 'components/customLink/CustomLink';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import CallIcon from '@material-ui/icons/Call';
import BusinessIcon from '@material-ui/icons/Business';
import SecurityIcon from '@material-ui/icons/Security';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ANIMATING_STATE,
  HEIGHT_TOGGLE_ANIMATION,
  INITIAL_STATE
} from 'util/animationVariants';
import { addressToString, getFileExtensionIcon } from 'util/Utils';
import DetailBoxWrapper from './DetailBoxWrapper/DetailBoxWrapper';
import DummyContent from './DummyContent/DummyContent';
import PersonIcon from '@mui/icons-material/Person';
import Style from "./ShipmentCollaborators.module.css";

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
    // padding: '1rem 2rem',
    // overflowX: 'hidden'
  },
  motionWrapper: {
    display: 'flex'
  },
  highlightBox: {
    display: 'flex',
    backgroundColor: '#ecf1f9', //'#f4f7fc', //#edf3fd
    width: '100%',
    // padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden',
    alignItems: 'center',
    marginTop: '1rem'
  },
  row: {
    margin: '10px',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  routeDetails: {
    minWidth: '200px',
    marginRight: '40px'
  },
  infoRow: {
    display: 'flex',
    marginBottom: '5px'
  },
  infoKey: {
    fontWeight: '600',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#9fb1c1',
    minWidth: '140px',
    marginRight: '25px'
  },
  infoValue: {
    color: '#15354e',
    maxWidth: '100%',
    fontSize: '12px'
  },
  bookingTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    cursor: 'pointer',
    // marginBottom: '10px',
    // textTransform: 'uppercase',
    // '&:hover': {
    //   color: theme.palette.darkaccent
    // },
    '& > span': {
      display: 'flex',
      // maxWidth: 'calc(100% - 23px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignItems: 'center',
      marginRight: '10px'
    }
  },
  locationTitle: {
    fontWeight: 600,
    fontSize: '9px',
    lineHeight: '10px',
    textTransform: 'uppercase',
    color: '#9fb1c1',
    marginBottom: '5px'
  },
  location: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#15354e',
    whiteSpace: 'normal'
  },
  logoBg: {
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: '200px',
    width: '100%',
    height: '40px',
    /* width: auto; */
    maxHeight: '80px',
    margin: '1rem'
  },
  forwarderLogo: {
    backgroundImage: (props) =>
      props.booking.forwarder.company_log
        ? `url(${props.booking.forwarder.company_log})`
        : 'none'
  },
  // customsLogo: {
  //   backgroundImage: (props) =>
  //     props &&
  //       props.booking &&
  //       props.booking.customBrokerInfo &&
  //       props.booking.customBrokerInfo.logo
  //       ? `url(${props.booking.customBrokerInfo.logo})`
  //       : 'none'
  // },
  // insuranceLogo: {
  //   backgroundImage: (props) =>
  //     props &&
  //       props.booking &&
  //       props.booking.insuranceInfo &&
  //       props.booking.insuranceInfo.logo
  //       ? `url(${props.booking.insuranceInfo.logo})`
  //       : 'none'
  // }
});

const ShipmentCollaborators = ({ classes, booking }) => {

  // console.log(booking)

  const [openForwarderDetail, setOpenForwarderDetail] = useState(true);

  const toggleForwarderDetail = () => {
    setOpenForwarderDetail(!openForwarderDetail);
  };

  const [openShipperDetail, setOpenShipperDetail] = useState(true);

  const toggleShipperDetail = () => {
    setOpenShipperDetail(!openShipperDetail);
  };

  const [openCustomsDetail, setOpenCustomsDetail] = useState(true);

  const toggleCustomsDetail = () => {
    setOpenCustomsDetail(!openCustomsDetail);
  };

  const [openInsuranceDetail, setOpenInsuranceDetail] = useState(true);

  const toggleInsuranceDetail = () => {
    setOpenInsuranceDetail(!openInsuranceDetail);
  };
  console.log(booking)

  return (
    <div className={classes.root}>

      <div>















































        {/* Forwarder */}
        {booking.forwarder.company_name &&

          <div className="mb-5">
            <BusinessIcon fontSize="small" className="mr-2" />
            <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
              Freight Forwarder
            </span>
            <div className="divider mb-3" />
            <div className="d-flex justify-content-between align-items-center">
              <div
                className={clsx(classes.bookingTitle, 'font-size-sm mt-2')}
                onClick={() => toggleForwarderDetail()}>
                <span>{booking.forwarder.company_name}</span>
                <span className="text-black-50 font-size-xs">
                  {openForwarderDetail ? (
                    <Tooltip
                      title="Collapse"
                      arrow
                      classes={{ tooltip: 'tooltip-kargo-blue' }}>
                      <KeyboardArrowUp />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Expand"
                      arrow
                      classes={{ tooltip: 'tooltip-kargo-blue' }}>
                      <KeyboardArrowDown />
                    </Tooltip>
                  )}
                </span>
              </div>
              <div>

              </div>
            </div>
            <AnimatePresence>
              {openForwarderDetail && (
                <motion.div
                  className={classes.motionWrapper}
                  variants={HEIGHT_TOGGLE_ANIMATION}
                  initial={INITIAL_STATE}
                  animate={
                    openForwarderDetail ? ANIMATING_STATE : INITIAL_STATE
                  }
                  exit={INITIAL_STATE}>
                  <div className="bg-light w-100 rounded position-relative overflow-hidden">
                    <div className={classes.row}>
                      <div className={classes.routeDetails}>

                        <div className="mb-3">
                          <div className={classes.locationTitle}>
                            <span>Agent</span>
                          </div>
                          <div className={classes.location}>
                            <span>
                              {booking.forwarder.fullName}
                            </span>
                          </div>
                        </div>



                        <div className="mb-3">
                          <div className={classes.locationTitle}>
                            <span>Address</span>
                          </div>
                          <div className={classes.location}>
                            <span>
                              {addressToString(
                                booking.forwarder.address
                              )}
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className={classes.locationTitle}>
                            <span>Telephone</span>
                          </div>
                          <div className={classes.location}>
                            <span>
                              <a
                                href={`tel:${booking.forwarder.phone_number}`}>
                                {booking.forwarder.phone_number}
                              </a>
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

        }










































        {/* Shipper */}

        <div className="mb-5">
          <BusinessIcon fontSize="small" className="mr-2" />
          <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
            Shipper Details
          </span>
          <div className="divider mb-3" />
          <div className="d-flex justify-content-between align-items-center">
            <div
              className={clsx(classes.bookingTitle, 'font-size-sm mt-2')}
              onClick={() => toggleShipperDetail()}>
              <span>{booking.shipper.fullName}</span>
              <span className="text-black-50 font-size-xs">
                {openShipperDetail ? (
                  <Tooltip
                    title="Collapse"
                    arrow
                    classes={{ tooltip: 'tooltip-kargo-blue' }}>
                    <KeyboardArrowUp />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Expand"
                    arrow
                    classes={{ tooltip: 'tooltip-kargo-blue' }}>
                    <KeyboardArrowDown />
                  </Tooltip>
                )}
              </span>
            </div>
            <div>

            </div>
          </div>
          <AnimatePresence>
            {openShipperDetail && (
              <motion.div
                className={classes.motionWrapper}
                variants={HEIGHT_TOGGLE_ANIMATION}
                initial={INITIAL_STATE}
                animate={
                  openShipperDetail ? ANIMATING_STATE : INITIAL_STATE
                }
                exit={INITIAL_STATE}>
                <div className="bg-light w-100 rounded position-relative overflow-hidden">
                  <div className={classes.row}>
                    <div className={classes.routeDetails}>

                      {/* <div className="mb-3">
                        <div className={classes.locationTitle}>
                          <span>Agent</span>
                        </div>
                        <div className={classes.location}>
                          <span>
                            {booking.forwarder.fullName}
                          </span>
                        </div>
                      </div> */}

                      <div className="mb-3">
                        <div className={classes.locationTitle}>
                          <span>Email</span>
                        </div>
                        <div className={classes.location}>
                          <span>
                            {booking.shipper.email}
                          </span>
                        </div>
                      </div>



                      <div className="mb-3">
                        <div className={classes.locationTitle}>
                          <span>Address</span>
                        </div>
                        <div className={classes.location}>
                          <span>
                            {addressToString(
                              booking.shipper.address
                            )}
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className={classes.locationTitle}>
                          <span>Telephone</span>
                        </div>
                        <div className={classes.location}>
                          <span>
                            <a
                              href={`tel:${booking.shipper.phone_number}`}>
                              {booking.shipper.phone_number}
                            </a>
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>













































        {/* consignor  */}
        {booking.consignor &&
          <DetailBoxWrapper
            classes={classes}
            data={booking.consignor}
            title='Consignor'
            icon={<img src='assets\forwarder\shipmentDrawer\consignor.svg' style={{ width: '21px' }} />}
          >
            <DummyContent classes={classes} data={booking.consignor} />
          </DetailBoxWrapper>
        }
































        {/* consignee */}
        {booking.consignee &&
          <DetailBoxWrapper
            classes={classes}
            data={booking.consignee}
            title='Consignee'
            icon={<PersonIcon fontSize="small" className="mr-2" />}
          >
            <DummyContent classes={classes} data={booking.consignee} />
          </DetailBoxWrapper>
        }



























        {/* {booking.customBrokerInfo && (
            <div className="mb-5">
              <LocalAtmIcon fontSize="small" className="mr-2" />
              <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                Customs Broker
              </span>
              <div className="divider mb-3" />
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className={clsx(classes.bookingTitle, 'font-size-sm mt-2')}
                  onClick={() => toggleCustomsDetail()}>
                  <span>{booking.customBrokerInfo.fullName}</span>
                  <span className="text-black-50 font-size-xs">
                    {openCustomsDetail ? (
                      <Tooltip
                        title="Collapse"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <KeyboardArrowUp />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Expand"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <KeyboardArrowDown />
                      </Tooltip>
                    )}
                  </span>
                </div>
                <div>
                  

               
                </div>
            </div>
            


              <AnimatePresence>
                {openCustomsDetail && (
                  <motion.div
                    className={classes.motionWrapper}
                    variants={HEIGHT_TOGGLE_ANIMATION}
                    initial={INITIAL_STATE}
                    animate={
                      openCustomsDetail ? ANIMATING_STATE : INITIAL_STATE
                    }
                    exit={INITIAL_STATE}>
                    <div className="bg-light w-100 rounded position-relative overflow-hidden">
                      <div className={classes.row}>
                        <div className={classes.routeDetails}>
                        
                            <div className="mb-3">
                              <div className={classes.locationTitle}>
                                <span>Agent</span>
                              </div>
                              <div className={classes.location}>
                                <span>
                                  {`${booking.customBrokerInfo.firstName}, ${booking.customBrokerInfo.lastName}`}
                                </span>
                              </div>
                            </div>
                         

                         
                            <div className="mb-3">
                              <div className={classes.locationTitle}>
                                <span>Address</span>
                              </div>
                              <div className={classes.location}>
                                <span>
                                  {addressToString(
                                    booking.customBrokerInfo.address
                                  )}
                                </span>
                              </div>
                            </div>
                         

                          
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
            </AnimatePresence>
            


            </div>
          )} */}







        {/* {booking.insuranceInfo && (
            <div className="mb-5">
              <SecurityIcon fontSize="small" className="mr-2" />
              <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                Cargo Insurance Provider
              </span>
              <div className="divider mb-3" />
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className={clsx(classes.bookingTitle, 'font-size-sm mt-2')}
                  onClick={() => toggleInsuranceDetail()}>
                  <span>{booking.insuranceInfo.companyName}</span>
                  <span className="text-black-50 font-size-xs">
                    {openInsuranceDetail ? (
                      <Tooltip
                        title="Collapse"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <KeyboardArrowUp />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Expand"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <KeyboardArrowDown />
                      </Tooltip>
                    )}
                  </span>
                </div>
                
              </div>
              <AnimatePresence>
                {openInsuranceDetail && (
                  <motion.div
                    className={classes.motionWrapper}
                    variants={HEIGHT_TOGGLE_ANIMATION}
                    initial={INITIAL_STATE}
                    animate={
                      openInsuranceDetail ? ANIMATING_STATE : INITIAL_STATE
                    }
                    exit={INITIAL_STATE}>
                    <div className="bg-light w-100 rounded position-relative overflow-hidden">
                      <div className={classes.row}>
                        <div className={classes.routeDetails}>
                          {booking.insuranceInfo.website && (
                            <div className="mb-3">
                              <div className={classes.locationTitle}>
                                <span>Company Website</span>
                              </div>
                              <div className={classes.location}>
                                <a
                                  href={booking.insuranceInfo.website}
                                  target="_blank">
                                  Click here
                                </a>
                              </div>
                            </div>
                          )}

                          {booking.insuranceInfo.files && (
                            <>
                              <div className="mb-3">
                                <div className={classes.locationTitle}>
                                  <span>Coverage Documents</span>
                                </div>
                                <div className={classes.location}>
                                  {booking.insuranceInfo.files.map(
                                    (file, index) => {
                                      return (
                                        <div className="mb-2">
                                          <motion.a
                                            className="link"
                                            href={file.url}
                                            target="_blank"
                                            download>
                                            <motion.img
                                              className="d-20"
                                              src={getFileExtensionIcon(
                                                file.url
                                              )}
                                              alt="download"
                                              whileTap={{ scale: 1.5 }}
                                              whileHover={{ cursor: 'pointer' }}
                                            />
                                            <span className="font-size-xs ml-3">
                                              {file.title}
                                            </span>
                                          </motion.a>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )} */}





      </div>

    </div>
  );
};

ShipmentCollaborators.defaultProps = {};

ShipmentCollaborators.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentCollaborators);
