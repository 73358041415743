import React, { useState, lazy, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  List,
  ListItem,
  Menu,
  Typography,
  Tooltip
} from '@material-ui/core';
import { CONTAINER_STATUS_LENGTH, SHIPMENT_STATUS } from 'util/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from 'components/Tabs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ChatIcon from '@material-ui/icons/Chat';
import CallIcon from '@material-ui/icons/Call';
import Style from "./ShipmentCardPreview.module.css";
import ShipmentNotes from './ShipmentNotes/ShipmentNotes';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import CancelShipmentPopup from './ShipmentActivity/CancelShipmentPopup/CancelShipmentPopup';
import { ForwarderService } from 'service';
import { useSelector, useDispatch } from 'react-redux';
import ChatService from '../../../../../service/ChatService';
import { setChatRoom } from '../../../../../actions';
import { useHistory } from 'react-router-dom'
import ForwarderTeam from 'service/ForwarderTeam';
import ChattingIcon from 'components/Shipments/ChattingIcon/ChattingIcon';
// const ShipmentOverview = lazy(() => import('./ShipmentOverview'));
import ShipmentOverview from './ShipmentOverview';
import ShipmentPayments from '../../ShipmentDetail/payment';


const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '3rem',
    overflowX: 'hidden'
  }
});

// const animatedComponents = makeAnimated();

const ShipmentCardPreview = ({
  classes,
  booking,
  activeShipment,
  refresh,
  setRefresh,
  setActiveReservation,
  unAssignedShipment = false,
  view_detail_tab,
  shipment_status,
  shipment_id,
}) => {
  const history = useHistory()


  // console.log(booking)
  const content = [
    {
      label: 'Overview',
      value: 'overview',
      View: (
        <ShipmentOverview
          booking={booking?._id}
        />
      )
    },
    {
      label: 'Payment',
      value: "payment",
      // View: <ShipmentPayment shipmentId={booking?._id} />
      View: <div style={{ margin: "-1rem" }}><ShipmentPayments shipment_id={booking?._id} /></div>
    },
  ];


  const [run, setRun] = useState(false)

  const [openModal, setOpenModal] = useState(false)



  return (
    <div className={`${classes.root} ${Style.container}`}>
      <div className="my-2 d-flex align-items-center justify-content-between">
        <div>
          <Typography className="font-weight-bold font-size-lg">
            Shipment Details
          </Typography>
        </div>

        <div className='d-flex c-gap-1 align-items-center'>
          {!unAssignedShipment &&
            <>
              <ChattingIcon
                shipment_id={booking?._id}
                disabled={booking.shipment_status === "unassigned" || booking.shipment_status === "completed" || booking.shipment_status === "cancelled" ? true : false}
                varient={2}
              />
              <Button
                size="small"
                onClick={() => history.push(`/shipment-detail/${booking?._id}`)}
                className="btn-primary shadow-none py-2"
              >
                View Detail
              </Button>
            </>
          }


        </div>
      </div>

      <div className="divider my-3" />


      <div className="py-3">
        <Tabs content={content} onClick={(e) => {
          if (!shipment_id) { return }
          history.replace(`/shipments/${shipment_id}?view_detail=${true}&view_detail_tab=${e}`)
        }} active={view_detail_tab || "overview"} />
      </div>


      <CancelShipmentPopup
        open={openModal}
        handleClose={() => setOpenModal(false)}
        shipmentId={booking?.overview?.id}
        run={run}
        setRun={setRun}
        refresh={refresh}
        setRefresh={setRefresh}
      />





    </div>
  );
};

ShipmentCardPreview.defaultProps = {};

ShipmentCardPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentCardPreview);
