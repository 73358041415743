import { HttpMethods, httpService } from './global';
export default class WebsiteService {

    static async fetchShippingLeads(page, data,) {
        const url = `website/shipment-leads/fetch?page=${page}`;
        return httpService(HttpMethods.POST, url, data, null)
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

}
