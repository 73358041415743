import { createMuiTheme } from '@material-ui/core';
import shadows from './shadows';

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3c44b1'
    },
    grey: {
      300: '#fefefe',
      A100: '#f8f9ff'
    },
    secondary: {
      main: '#4191ff'
    },
    error: {
      main: '#f83245'
    },
    success: {
      main: '#1bc943'
    },
    info: {
      main: '#11c5db'
    },
    warning: {
      main: '#f4772e'
    },
    helpers: {
      primary: '#3c44b1',
      main: 'rgba(25, 46, 91, .035)'
    },
    dark: '#06113C !important',
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1100,
      xl: 1381
      // Breakpoints from v1
      // xs: 320,
      // sm: 576,
      // md: 768,
      // lg: 992,
      // xl: 1200
    }
  },
  shape: {
    borderRadius: '0.2rem'
  },
  overrides: {
    MuiTab: {
      root: {
        fontWeight: 'bold'
      }
    },
    MuiButton: {
      sizeSmall: {
        padding: '6px 20px',
        fontSize: 14
      },
      outlinedSmall: {
        padding: '6px 20px',
        fontSize: 14
      },
      textSmall: {
        padding: '6px 20px',
        fontSize: 14
      },

      sizeMedium: {
        padding: '10px 22px',
        fontSize: 15
      },
      outlined: {
        padding: '10px 22px',
        fontSize: 14
      },
      text: {
        padding: '10px 22px',
        fontSize: 14
      },

      sizeLarge: {
        padding: '16px 28px',
        fontSize: 16
      },
      outlinedLarge: {
        padding: '16px 28px',
        fontSize: 16
      },
      textLarge: {
        padding: '16px 28px',
        fontSize: 16
      },

      root: {
        textTransform: 'none',
        fontWeight: 'normal',
        padding: '10px 22px',
        fontSize: 14
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#070919',
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: '#070919'
      }
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    htmlFontSize: 16,
    fontSize: 14
  },
  shadows,
  size: {
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    60: '3.75rem',
    64: '4rem',
    72: '4.5rem'
  },
  weight: {
    lightest: 200,
    light: 400,
    regular: 500,
    bold: 700
  },
  zIndex: {
    low: 1,
    medium: 2,
    mediumHigh: 3,
    high: 4,
    highest: 5
  }
});

export default MuiTheme;
