import * as moment from 'moment';

export default class NoteModel {
  static createNewNoteReservation = (note, umTripId) => {
    const dateConverted = note.date && note.time !== '' ? `${note.date.format('MM/DD/YYYY')} ${note.time}` : null;
    let noteObj = {
      '@type': 'Note',
      name: note.title,
      umTripId: umTripId,
      tag: note.tag
    };

    if (note.description) {
      noteObj.description = note.description;
    }

    if (note.linkedBooking) {
      noteObj.linkedDetailId = note.linkedBooking;
    }

    if (dateConverted) {
      noteObj.noteTimestamp = moment.utc(dateConverted, 'MM/DD/YYYY HH:mm').format();
    }

    if (note.isPrivate) {
      noteObj.noteType = 'PRIVATE';
    } else {
      noteObj.noteType = 'DEFAULT';
    }

    return noteObj;
  };

  static createNewInsuranceNoteReservation = (note, umTripId) => {
    let noteObj = {
      '@type': 'InsuranceNote',
      provider: note.provider,
      policyNum: note.policyNum,
      umTripId: umTripId,
      tag: note.tag,
      noteType: 'INSURANCE'
    };

    if (note.date && note.time !== '') {
      const dateConverted = `${note.date.format('MM/DD/YYYY')} ${note.time}`;
      noteObj.noteTimestamp = moment.utc(dateConverted, 'MM/DD/YYYY HH:mm').format();
    }

    if (note.linkedBooking) {
      noteObj.linkedDetailId = note.linkedBooking;
    } else {
      noteObj.linkedDetailId = '';
    }

    if (note.policyNum) {
      noteObj.policyNum = note.policyNum;
    }

    if (note.policyType) {
      noteObj.policyType = note.policyType;
    }

    if (note.emergencyNum) {
      noteObj.emergencyNum = note.emergencyNum;
    }

    if (note.importantInfo) {
      noteObj.importantInfo = note.importantInfo;
    }

    if (note.policyDesc) {
      noteObj.policyDesc = note.policyDesc;
    }

    return noteObj;
  };

  static generateNoteReservation = (name, umTripId, umId, description, date, time, isPrivate, tag, linkedBooking) => {
    let noteObj = {
      '@type': 'Note',
      name,
      umId,
      umTripId,
      description,
      tag
    };

    if (date && time !== '') {
      const dateConverted = `${date.format('MM/DD/YYYY')} ${time}`;
      noteObj.noteTimestamp = moment.utc(dateConverted, 'MM/DD/YYYY HH:mm').format();
    }

    if (linkedBooking) {
      noteObj.linkedDetailId = linkedBooking;
    } else {
      noteObj.linkedDetailId = '';
    }

    if (isPrivate) {
      noteObj.noteType = 'PRIVATE';
    } else {
      noteObj.noteType = 'DEFAULT';
    }

    return noteObj;
  };

  static generateInsuranceNoteReservation = (
    provider,
    umTripId,
    umId,
    date,
    time,
    tag,
    linkedBooking,
    policyNum,
    policyType,
    emergencyNum,
    importantInfo,
    policyDesc
  ) => {
    let noteObj = {
      '@type': 'InsuranceNote',
      provider,
      umId,
      umTripId,
      tag,
      noteType: 'INSURANCE'
    };

    if (date && time !== '') {
      const dateConverted = `${date.format('MM/DD/YYYY')} ${time}`;
      noteObj.noteTimestamp = moment.utc(dateConverted, 'MM/DD/YYYY HH:mm').format();
    }

    if (linkedBooking) {
      noteObj.linkedDetailId = linkedBooking;
    }

    if (policyNum) {
      noteObj.policyNum = policyNum;
    }

    if (policyType) {
      noteObj.policyType = policyType;
    }

    if (emergencyNum) {
      noteObj.emergencyNum = emergencyNum;
    }

    if (importantInfo) {
      noteObj.importantInfo = importantInfo;
    }

    if (policyDesc) {
      noteObj.policyDesc = policyDesc;
    }

    return noteObj;
  };
}
