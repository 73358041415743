import clsx from 'clsx'

import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading'
import SectionSubHeading from 'components/Common/Heading/SectionSubHeading/SectionSubHeading'
import React from 'react'
import { getCurrencyIcon } from 'util/Shipment/shipment'

const AmountBreakdownCard = ({ data }) => {
    return (
        <div>
            <div
                className={clsx('my-3 p-3')}
                style={{
                    backgroundColor: '#ecf1f9', //'#f4f7fc', //#edf3fd
                    width: '100%',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <SectionSubHeading title={"Shipment Charges"} />
                {/* <div
                    className="text-black-50 font-weight-bold mt-4">
                    <span style={{ fontSize: '0.875rem' }}>Shipment Charges</span>
                </div> */}
                <div className='mt-4 px-2'>
                    {data?.shipping_charges_breakDown?.shipment_charges_breakdown.map((item) => (
                        <>

                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <div className="font-size-sm">
                                        <span>{item?.label}</span>
                                    </div>
                                    <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                                        <small>Qty {item?.quantity} x {getCurrencyIcon("usd")}{item?.unit_price} USD ({item?.cargoType})</small>
                                    </div>
                                </div>
                                <div className="ml-auto text-right">
                                    <div className="text-black d-block font-size-sm">
                                        <span>{getCurrencyIcon("usd")}{item?.sub_total} USD</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
                {data?.shipping_charges_breakDown?.service_charges_array.length > 0 && <>
                    <SectionSubHeading title={"Add-on Service"} />
                    {/* <div
                        className="text-black-50 font-weight-bold mt-4">
                        <span style={{ fontSize: '0.875rem' }}>Add-on Services</span>
                    </div> */}
                    <div className='mt-4 px-2'>
                        {data?.shipping_charges_breakDown?.service_charges_array.map((item) => (
                            <>
                                <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <div className="font-size-sm">
                                            <span>{item?.label}</span>
                                        </div>
                                        <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                                            <small>Qty {item?.quantity} x {getCurrencyIcon(item?.unit_price?.currency)}{item?.unit_price?.amount} {item?.unit_price?.currency}</small>
                                        </div>
                                    </div>
                                    <div className="ml-auto text-right">
                                        <div className="text-black d-block font-size-sm">
                                            <span>{getCurrencyIcon(item?.sub_total?.currency)}{item?.sub_total?.amount} {item?.sub_total?.currency}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </>}

                <div className='pr-2'>




                    <div className="divider my-3 bg-dark " />





                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="font-size-sm font-weight-bold">
                                <span>Shipping Cost</span>
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="font-weight-bold text-black d-block font-size-sm">
                                <span>{getCurrencyIcon(data?.shipping_cost?.currency)}{data?.shipping_cost?.value} {data?.shipping_cost?.currency}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            <div className="font-size-sm">
                                <span>Commission</span>
                            </div>
                            <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                                <small>({data?.commissions?.percentage}%)</small>
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="text-black d-block font-size-sm">
                                <span>{getCurrencyIcon(data?.commissions?.amount?.currency)}{data?.commissions?.amount?.value} {data?.commissions?.amount?.currency}</span>
                            </div>
                        </div>
                    </div>




                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            <div className="font-size-sm">
                                <span>Platform fee</span>
                            </div>
                            <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                                <small>({data?.platform_fee?.percentage}%)</small>
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="text-black d-block font-size-sm">
                                <span> - {getCurrencyIcon(data?.platform_fee?.amount?.currency)}{data?.platform_fee?.amount?.value} {data?.platform_fee?.amount?.currency}</span>
                            </div>
                        </div>
                    </div>




                    <div className="divider my-3 bg-dark " />


                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="font-size-sm font-weight-bold">
                                <span>Sub Total</span>
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="font-weight-bold text-black d-block font-size-sm">
                                <span>{getCurrencyIcon(data?.sub_total_earned?.currency)}{data?.sub_total_earned?.value} {data?.sub_total_earned?.currency}</span>
                            </div>
                        </div>
                    </div>



                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            <div className="font-size-sm">
                                <span>Taxes</span>
                            </div>
                            <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                                <small>({data?.taxes?.percentage}%)</small>
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="text-black d-block font-size-sm">
                                <span>- {getCurrencyIcon(data?.taxes?.amount?.currency)}{data?.taxes?.amount?.value} {data?.taxes?.amount?.currency}</span>
                            </div>
                        </div>
                    </div>


                    <div className="divider my-3 bg-dark " />

                    <div className="d-flex justify-content-between mt-3">
                        <div>
                            <SectionHeading title={"Total"} />
                        </div>
                        <div style={{ marginRight: "-1rem" }}>
                            <SectionHeading title={`${getCurrencyIcon(data?.total_earned?.currency)}${data?.total_earned?.value} ${data?.total_earned?.currency}`} />
                        </div>
                    </div>
                </div>


                {/* <div className="divider my-3 bg-dark " />

                <div className="d-flex justify-content-between">
                    <div>
                        <div className="font-size-sm font-weight-bold">
                            <span>Total</span>
                        </div>
                    </div>
                    <div className="ml-auto text-right">
                        <div className="font-weight-bold text-black d-block font-size-sm">
                            <span>${data?.rate_sheet_total}</span>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>
    )
}

export default AmountBreakdownCard