import React, { useEffect } from 'react'
import Style from "./ModalPopup.module.css"
import { Dialog, DialogContent } from '@material-ui/core'
import PropTypes from 'prop-types';

const ModalPopupWrapper = ({
    // open,
    // onClose,
    backdrop = true,
    useOld = false,
    // children

    children,
    open,
    scroll,
    onClose,
    // action,
    maxWidth,
    className,
    noStyle = false,
    fullWidth= false
}) => {

    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleModalClose = () => {
        // handleClose();
    };

    const handleAction = () => {
        // action();
    };

    useEffect(() => {
        if (useOld && open) {
            document.body.style.overflow = 'hidden';
        }
        else if (!open) {
            document.body.style.overflow = 'auto'
        }
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [open])

    return (
        <>
            {useOld ?
                <div className={`${Style.container} ${!open && Style.collapse} ${backdrop && Style.backdrop}`}>
                    {children}
                </div>
                :
                <Dialog
                    open={open}
                    onClose={onClose}
                    scroll={scroll}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className={`${className} ${noStyle && Style.noStyle}`}
                >
                    <DialogContent dividers={scroll === 'paper'}>
                        <div
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            {children}
                        </div>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

ModalPopupWrapper.defaultProps = {
    scroll: 'paper',
    maxWidth: 'md'
};

ModalPopupWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    scroll: PropTypes.oneOf(['paper', 'body']),
    handleClose: PropTypes.func.isRequired,
    // action: PropTypes.func.isRequired,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false])
};

export default ModalPopupWrapper