import { REQUEST_APP_REFRESH } from '../actions/serviceworker';

export const initialState = {
  update: false,
  variant: 'info'
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_APP_REFRESH:
      return { ...state, update: action.payload };

    default:
      return { ...state, update: initialState.update };
  }
}
