import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import RouteIcon from 'assets/svg/RouteIcon.svg';

import { Grid, LinearProgress, Typography } from '@material-ui/core';
import CountryFlag from 'components/CountryFlag';
import LocationPin from 'assets/svg/location/pin.svg';
import { formatDate } from 'util/Utils';
import LeafletMap from 'components/LeafletMap';
import clsx from 'clsx';
import { CONTAINER_STATUS_LENGTH, SHIPMENT_STATUS } from 'util/constants';
import ShipmentIcon from 'sites/shipper/pages/Shipments/Reusables/ShipmentIcon/ShipmentIcon';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FromTo from 'components/Common/from_to/FromToVert';
import { ShipmentsService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux';

const styles = (theme) => ({
  mapWrapper: {
    height: 150,
    width: '100%'
  },
  routeIcon: {
    margin: '0 15px',
    height: '1rem'
  },
  origin: {
    marginLeft: '24px',
    position: 'relative',
    paddingBottom: '16px',
    '&::before': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '-24px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: '#0088ff',
      zIndex: 2
    },
    '&::after': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '-20px',
      width: '2px',
      height: '100%',
      backgroundColor: '#0088ff',
      zIndex: 1
    }
  },
  destination: {
    marginLeft: '24px',
    position: 'relative',
    marginBottom: '16px',
    '&::before': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '-24px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: '#0088ff',
      zIndex: 2
    }
  },
  locationStatus: {
    display: 'flex',
    alignItems: 'center',
    transform: (props) => {
      if (props && props.booking) {
        const perc = `${Math.floor(
          (props.booking.status * 100) /
          CONTAINER_STATUS_LENGTH
        )}%`;
        return `translateX(calc(${perc} - 14px))`;
      } else {
        return '';
      }
    }
  },
  locationStatusName: {
    color: '#15354e',
    maxWidth: '100%',
    fontSize: '10px',
    position: 'absolute',
    top: 0,
    display: 'inline-table',
    whiteSpace: 'nowrap'
  },
  carrierInfo: {
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: '200px',
    width: '100%',
    height: '60px',
    maxHeight: '80px',
    backgroundImage: (props) =>
      props && props.booking
        ? `url(${props.booking?.carrier_logo})`
        : 'none'
  }
});

const ShipmentOverview = ({ classes, booking }) => {
  const dispatch = useDispatch()

  const [openRoute, setOpenRoute] = useState(true);
  const [anchorElMenu2, setAnchorElMenu2] = useState(null);

  const toggleRouteDetail = () => {
    setOpenRoute(!openRoute);
  };

  const handleClickMenu2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorElMenu2(null);
  };


  const [positions, setPositions] = useState([])
  const[midPoint, setMidPoint] = useState([])
  useEffect(() => {
    const data_to_send = {
      shipment_id: booking._id,
    }
    ShipmentsService.fetchContainersLocation(data_to_send).then(res => {
      if (res.error) {
        notify(dispatch, "error", res.error)
      } else {
        setPositions(res.trackingData)
        setMidPoint(res.midPointOfOriginAndDestination)
      }
    })
  }, [])


  return (
    <div className="w-100 h-100">
      <div className="d-flex align-items-center font-weight-bold font-size-sm mb-3">
        <span className="d-flex align-items-center text-truncate mr-2">
          <span>{`${booking?.origin?.city} | ${booking?.origin?.port}` }</span>
          <img className={classes.routeIcon} src={RouteIcon} alt="route icon" />
          <span>{`${booking?.destination?.city} | ${booking?.destination?.port}` }</span>
        </span>
      </div>
     
      <div className="divider my-3" />

      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="font-size-xs text-uppercase text-black-50 mr-3">
          <small className="font-weight-bold">Overall Status</small>
        </div>
        <div className="font-size-xs">
          <div className="badge badge-pill badge-primary text-capitalize font-weight-normal font-size-xs">
            {booking.status}
          </div>
        </div>
      </div>

      <div className={classes.mapWrapper}>
      <LeafletMap positions={positions} midPoint={midPoint} zoom={3} height={150} width={300} />
      </div>

      <div className="my-4">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="mr-0 w-100">
              <Typography className="font-weight-bold text-uppercase font-size-sm mb-3">
                ID: {booking.shipment_id}
              </Typography>
              <ShipmentIcon
                load_type={booking?.load.type}
                cargo_icon={booking?.load.icon}
                cargo_type={booking?.load.cargo_type}
                cargo_quantity={booking?.load.quantity}
                count={1}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            {/* <div className="px-3 pt-3 bg-light w-100 rounded position-relative overflow-hidden">
              <div className={classes.origin}>
                <div className="font-size-xs text-uppercase text-black-50 mb-1">
                  <small className="font-weight-bold">Place of Loading</small>
                </div>
                <div className="font-size-xs">
                  <CountryFlag
                    size={16}
                    countryCode={booking?.shipmentInfo?.origin?.country?.value}
                  />
                  <span className="ml-2">{booking.origin}</span>
                </div>
              </div>

              <div className={classes.destination}>
                <div className="font-size-xs text-uppercase text-black-50 mb-1">
                  <small className="font-weight-bold">Place of Discharge</small>
                </div>
                <div className="font-size-xs">
                  <CountryFlag
                    size={16}
                    countryCode={booking?.shipmentInfo?.destination?.country?.value}
                  />
                  <span className="ml-2">{booking.destination}</span>
                </div>
              </div>
            </div> */}
            <FromTo
              origin={`${booking?.origin?.city}  |  ${booking?.origin?.port}`}
              origin_country_code={booking?.origin?.country}
              destination={`${booking?.destination?.city}  |  ${booking?.destination?.port}`}
              destination_country_code={booking?.destination?.country}
            />
          </Grid>
        </Grid>
      </div>

      <div className="mt-4">
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            {booking?.carrier_logo && (
              <div className={classes.carrierInfo} />
            )}
          </Grid> */}
          <Grid item xs={12}>
            <div className="d-flex mb-1">
              <span className="font-size-xs font-weight-bold text-uppercase minw-150 mr-3">
                CARRIER:
              </span>
              <span className="font-size-xs mw-100">
                {booking?.carrier_name}
              </span>
            </div>

            <div className="d-flex mb-1">
              <span className="font-size-xs font-weight-bold text-uppercase minw-150 mr-3">
                Transport Mode:
              </span>
              <span className="font-size-xs mw-100">
                {booking?.mode_of_transport}
              </span>
            </div>

            {/* {booking?.shipmentInfo?.billOfLading && (
              <div className="d-flex mb-1">
                <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                  BILL OF LADING #:
                </span>
                <span className="font-size-xs mw-100">
                  {booking?.shipmentInfo?.billOfLading?.id}
                </span>
              </div>
            )}

            {booking?.shipmentInfo?.containerNo && (
              <div className="d-flex mb-1">
                <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                  CONTAINER #:
                </span>
                <span className="font-size-xs mw-100">
                  {booking?.shipmentInfo?.containerNo}
                </span>
              </div>
            )}

            {booking?.shipmentInfo?.bookingRef && (
              <div className="d-flex mb-1">
                <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                  BOOKING REF:
                </span>
                <span className="font-size-xs mw-100">
                  {booking?.shipmentInfo?.bookingRef}
                </span>
              </div>
            )} */}
          </Grid>
        </Grid>
      </div>
      <div className="divider my-3" />
      {/* <div className="my-4 p-3 bg-light w-100 rounded position-relative overflow-hidden">
        <div className={classes.locationStatus}>
          <div className="position-relative d-25">
            <img className="h-100" src={LocationPin} alt="location pin" />
            <div
              className={clsx(classes.locationStatusName, 'font-weight-bold', {
                'right-100':
                  booking?.shipmentInfo?.containerStatus?.status?.value >
                  CONTAINER_STATUS_LENGTH / 2,
                'left-100':
                  booking?.shipmentInfo?.containerStatus?.status?.value <=
                  CONTAINER_STATUS_LENGTH / 2
              })}>
              <span>{booking?.shipmentInfo?.containerStatus?.location}</span>
            </div>
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          className={`progress-sm progress-bar-rounded ${booking.status === SHIPMENT_STATUS.CARGO_HOLD
            ? 'progress-bar-danger'
            : booking.status === SHIPMENT_STATUS.CARGO_READY
              ? 'progress-bar-warning'
              : 'progress-bar-success'
            }`}
          value={Math.floor(
            (booking?.shipmentInfo?.containerStatus?.status?.value * 100) /
            CONTAINER_STATUS_LENGTH
          )}
        />
        <div className="align-box-row progress-bar--label mt-2">
          <div>
            <div className="text-black-50 font-size-xs">
              {formatDate(booking?.createdTimestamp)}
            </div>
            <span className="font-weight-bold d-block font-size-xs">{`${booking?.origin}`}</span>
          </div>

          <div className="ml-auto text-right">
            <div className="text-black-50 font-size-xs">
              {formatDate(booking?.shipmentInfo?.expectedDelivery)}
            </div>
            <span className="font-weight-bold d-block font-size-xs">{`${booking?.destination}`}</span>
          </div>
        </div>
      </div> */}

      <div className="w-100">
        <div>
          <span className="font-size-xs font-weight-bold text-uppercase minw-150 mr-3">
            Commodities:
          </span>
          {booking?.commodity_name?.length > 0 && booking?.commodity_name?.map((item) => (
            <div style={{ display: "flex", alignItems: "baseline", margin: "0 0 3px 0" }}>
              <span>
                <CircleRoundedIcon style={{ width: '8px', height: '8px', color: "rgba(0, 0, 0, 0.6)", margin: "0 10px 0 0" }} />
              </span>
              <span className="font-size-xs mw-100" style={{ whiteSpace: "wrap" }}>{item.code} - {item.description}</span>
            </div>
          ))}
        </div>
        <div className="divider my-3" />
        <div className='mb-2'>
          <span className="font-size-xs font-weight-bold text-uppercase minw-150 mr-3">
            Services:
          </span>
          {booking?.services?.length > 0 ? booking?.services?.map((item) => (
            <div style={{ display: "flex", alignItems: "baseline", margin: "0 0 2px 0" }}>
              <span>
                <CircleRoundedIcon style={{ width: '8px', height: '8px', color: "rgba(0, 0, 0, 0.6)", margin: "0 10px 0 0" }} />
              </span>
              <span className="font-size-xs mw-100" style={{ whiteSpace: "wrap" }}>{item.serviceName}</span>
            </div>
          )) :
            <div style={{ display: "flex", alignItems: "baseline", margin: "0 0 3px 0" }}>
              <span className="font-size-xs mw-100" style={{ whiteSpace: "wrap" }}>No Service Selected</span>
            </div>}
        </div>
      </div>


    </div>
  );
};

ShipmentOverview.defaultProps = {};

ShipmentOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentOverview);
