import {
    MAKE_OFFER_INFO
} from '../actions';

export const initialState = {
    request_id: "",
    transport_mode: "",
    ready_on: "",
    services: "",
    origin: {},
    destination: {},
    load: {},
    shipper: ""
};

export default function makeOfferDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case MAKE_OFFER_INFO:
            return {
                request_id: action.payload.request_id,
                transport_mode: action.payload.transport_mode,
                ready_on: action.payload.ready_on,
                services: action.payload.services,
                origin: action.payload.origin,
                destination: action.payload.destination,
                load: action.payload.load,
                shipper: action.payload.shipper
            };
        default:
            return state;
    }
}
