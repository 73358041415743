import Style from "./BreakdownTableRow.module.css"
import { Divider } from "@material-ui/core";
import { getCurrencyIcon, numberToLocaleString } from "util/Shipment/shipment";
/* eslint-disable */

const BreakdownTableRow = ({ data }) => {

    console.log(data)
    return (
        <>
            <Divider />

            <div className={Style.container}>

                <div>{data.fee_code}</div>
                <div>{data.fee_name}</div>
                <div>{data.comment}</div>
                <div>{data.units}</div>
                <div>{getCurrencyIcon("usd")}{numberToLocaleString(data.unit_price)} USD</div>
                <div>{getCurrencyIcon("usd")}{numberToLocaleString(data.amount)} USD</div>
            </div>
        </>
    )
}
/* eslint-disable */
export default BreakdownTableRow

