import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { AccountTypeLabel } from 'util/accountType'
import { VerificationStatus } from 'util/VerificationStatus'

const InreviewStrip = ({
    status,
    type,
    onClick,
}) => {

    const chipStyle = classNames(`d-flex align-items-center m-0 px-2 h-auto py-2 text-capitalize`, {
        [`border-1  badge text-warning badge-neutral-warning`]: status === VerificationStatus.INREVIEW,
        [`border-1  badge text-success badge-neutral-success`]: status === VerificationStatus.APPROVED,
        [`border-1  badge text-danger badge-neutral-danger`]: status === VerificationStatus.REJECTED,
        // [`badge text-${color} badge-neutral-${color}`]: variant === "light",
        // [`border-1 badge text-light badge-${color}`]: variant === "filled"
    })

    // console.log("name : ", name, "color : ", color)

    // font-size-xs

    const renderIconm = () => {
        switch (status) {
            case VerificationStatus.APPROVED:
                return "check-circle"
            case VerificationStatus.REJECTED:
                return "times-circle"
            case VerificationStatus.INREVIEW:
                return "pause-circle"
            default:
                return "check-circle"
        }
    }


    const renderText = () => {
        if (type === AccountTypeLabel.Individual && status === VerificationStatus.APPROVED) { return "Congratulations! Your individual kyc is approved." }
        if (type === AccountTypeLabel.Individual && status === VerificationStatus.INREVIEW) { return "Your individual kyc is in review." }
        if (type === AccountTypeLabel.Individual && status === VerificationStatus.REJECTED) { return "Your individual kyc is rejected" }
        if (type === AccountTypeLabel.Company && status === VerificationStatus.APPROVED) { return "Congratulations! Your company kyc is approved." }
        if (type === AccountTypeLabel.Company && status === VerificationStatus.INREVIEW) { return "Your company kyc is in review." }
        if (type === AccountTypeLabel.Company && status === VerificationStatus.REJECTED) { return "Your company kyc is rejected" }
    }
    return (
        <>
            <p
                className={`${chipStyle}`}
                style={{
                    borderRadius: "5px",
                    fontSize: "16px",
                    gap: "1rem",
                    letterSpacing: "0.2px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    width: "100%",
                    cursor: "default"
                }}>
                <FontAwesomeIcon
                    icon={["far", renderIconm()]}
                    className="d-20"
                />
                {renderText()}
                {(onClick && status === VerificationStatus.REJECTED) &&
                    <Button onClick={onClick}>
                        View More
                    </Button>
                }
            </p>
        </>
    )
}

export default InreviewStrip