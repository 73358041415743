import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core'
import Style from "./MobileChatView.module.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PageContent from 'sites/shipper/components/layout/PageContent';
import MobilePaneFooter from '../MobilePaneFooter/MobilePaneFooter';
import OfferChat from '../../ChatViewPane/OfferChat/OfferChat';
import ChatItem from '../../ChatViewPane/ChatItem/ChatItem';

import avatar3 from 'assets/images/avatars/avatar3.jpg';
import avatar7 from 'assets/images/avatars/avatar7.jpg';
import people2 from 'assets/images/stock-photos/people-3.jpg';
import people1 from 'assets/images/stock-photos/people-2.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { setChatData, updateChatData } from 'actions';
import { useParams } from 'react-router-dom';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import CustomChip from 'sites/shipper/components/CustomChip/CustomChip';

const data1 = {
    isUser: true,
    avatar: avatar3,
    userName: 'Akshay',
    message: "Hello this is Akshay",
    files: {
        pictures: [people1, people2],
        links: [{ link: 'https:google.com', name: 'google' }]
    },
    dateTime: '11:01 AM | Yesterday'
}
const data2 = {
    isUser: false,
    avatar: avatar7,
    userName: 'Flash',
    message: "Hello Akshay, how are you? Hello Akshay, how are you? Hello Akshay, how are you? Hello Akshay, how are you? Hello Akshay, how are you?",
    files: {
        pictures: [],
        links: []
    },
    dateTime: '11:01 AM | Yesterday'
}
const offerData = {
    isUser: true,
    avatar: avatar3,
    userName: 'Ninja',

    offer: {},

    dateTime: '11:01 AM | Yesterday'
}


const MobileChatView = () => {
    const history = useHistory();
    const { id } = useParams()
    const [isMoreOpen, setIsMoreOpen] = useState(null);
    const chatFromRedux = useSelector((state) => state.chatRoom.chat_data)
    const chatRoomId = useSelector((state) => state.chatRoom.room_id)

    const socket = useSelector((state) => state.socketConnection.socket)
    const dispatch = useDispatch()
    const [scroll, setScroll] = useState(true)


    useEffect(() => {

        if (!socket) return;
        if (!chatRoomId) {
            history.replace('/m/mailbox')
            return
        }


        const data_to_send = {
            room: chatRoomId
        }


        socket?.emit("forwarderChat", data_to_send)
        socket?.on("forwarderChatResult", (data) => {
            dispatch(setChatData(data))
        })


    }, [socket, chatRoomId])











    useEffect(() => {
        if (!socket) return;

        const handleReceiveMessage = (data) => {
            // window.alert(`${chatRoomId} ${chatRoomId === data.room} ${data.room}`)
            if (chatRoomId === data.room) {
                dispatch(updateChatData(data.message));
                setScroll(!scroll)
            }
        };

        socket.on('recieve_message', handleReceiveMessage);

        // Clean up the event listener when the component unmounts
        return () => {
            socket.off('recieve_message', handleReceiveMessage);
        };
    }, [socket, chatRoomId, dispatch]);







    const chatWindowRef = useRef(null);

    // Function to scroll to the top of the chat window
    const scrollToTop = () => {
        if (chatWindowRef.current) {
            const { scrollHeight, clientHeight } = chatWindowRef.current;
            chatWindowRef.current.scrollTop = scrollHeight - clientHeight;
        }
    };


    // Call the scrollToTop function whenever new messages are added
    useEffect(() => {
        scrollToTop();
    }, [chatRoomId,scroll]);







    return (
        <div className={`${Style.container} bg-gray-400`}>
            <div className={Style.top_bar}>
                <IconButton
                    onClick={() => history.replace('/m/mailBox')}
                    size='small'
                >
                    <KeyboardBackspaceIcon style={{ color: 'var(--primary-text)' }} />
                </IconButton>
                <span className={Style.person_name}>
                    {chatFromRedux && chatFromRedux.shipper_name}
                </span>
                <div className={Style.more_container}>


                    {chatFromRedux && chatFromRedux.chat_type &&
                        <>
                            <IconButton
                                size='small'
                                onClick={(e) => setIsMoreOpen(e.currentTarget)}
                            >
                                <MoreVertIcon style={{ color: isMoreOpen ? 'var(--primary)' : 'var(--primary-text)' }} />
                            </IconButton>
                            {/* options popup */}
                            <SmallPopupWrapper
                                size='md'
                                open={isMoreOpen}
                                onClose={() => setIsMoreOpen(null)}
                                vertical='bottom'
                            >
                                <div className={Style.more_popup}>
                                    <div
                                        onClick={() => { history.push('/m/mailBox/shipment-details'); setIsMoreOpen(null) }}
                                    >
                                        Shipment Details
                                    </div>
                                </div>
                            </SmallPopupWrapper>
                            {/* <div className={`${Style.more_popup} ${!isMoreOpen && Style.hide_more_popup}`}>
                                <div>
                                </div>
                            </div> */}

                        </>
                    }
                </div>
            </div>


            <div className={Style.main}>
                <PageContent noPadding>
                    <div className="app-inner-content-layout--main p-0 position-relative">
                        <div className="position-absolute w-100 h-100">
                            <PerfectScrollbar
                                containerRef={(ref) => (chatWindowRef.current = ref)}
                                options={{
                                    suppressScrollX: true,
                                    wheelPropagation: false
                                }}>

                                <div className="app-inner-content-layout app-inner-content-layout-fixed">
                                    <div className="app-inner-content-layout--main order-3 order-lg-2 card-box border-0">
                                        <div className="chat-wrapper p-3">
                                            {/* {chatFromRedux && chatFromRedux.conversation.map((message, index) => (
                                                <>
                                                    {message.type === "offer" ? <OfferChat id={index} socket={socket} data={message} /> : message.type === "text" ? <ChatItem data={message} /> : <></>}


                                                </>
                                            ))} */}

                                            
                                            {chatFromRedux?.conversation.map((chat, index) => (
                                                <div key={index}>
                                                    <div style={{ zIndex: "9999", position: "sticky", top: "10px" }}>
                                                        <center>
                                                            <CustomChip color={"kargo-blue"} variant={"light"} rounded name={chat?.created} />
                                                        </center>
                                                    </div>
                                                    {chat?.data.map((message, index) => (
                                                        <>
                                                            {message.type === "offer" ? <OfferChat data={message} id={index} /> : message.type === "text" ? <ChatItem data={message} /> : <></>}
                                                        </>
                                                    ))}
                                                </div>
                                            ))}


                                            {/* <ChatItem data={data1} />
                                            <ChatItem data={data2} /> */}

                                            {/* <OfferChat data={offerData} /> */}
                                        </div>
                                    </div>
                                </div>

                            </PerfectScrollbar>
                        </div>
                    </div>
                    {(chatFromRedux &&  (chatFromRedux.shipment_details.status === "active" || chatFromRedux.shipment_details.status === "pending")) &&
                        <MobilePaneFooter scroll={scroll} setScroll={setScroll}/>
                    }
                </PageContent>
            </div>


        </div>
    )
}

export default MobileChatView