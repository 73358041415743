import DashService from '../service/DashService';

export const INBOX_CREATED_TRIP = 'INBOX_CREATED_TRIP';
export const inboxCreatedTrip = () => ({
  type: INBOX_CREATED_TRIP
});

export const INBOX_CREATED_TRIP_SUCCESS = 'INBOX_CREATED_TRIP_SUCCESS';
export const inboxCreatedTripSuccess = payload => ({
  type: INBOX_CREATED_TRIP_SUCCESS,
  payload,
  msg: 'inboxCreateSuccess' //payload.message
});

export const INBOX_CREATED_TRIP_FAILURE = 'INBOX_CREATED_TRIP_FAILURE';
export const inboxCreatedTripFailure = payload => ({
  type: INBOX_CREATED_TRIP_FAILURE,
  payload,
  msg: payload.message
});

export function inboxCreateTrip(payload, token) {
  return dispatch => {
    dispatch(inboxCreatedTrip());

    return DashService.inboxCreateTrip(payload, token)
      .then(res => {
        if (res.returnCode && res.returnCode !== 0) {
          return dispatch(inboxCreatedTripFailure(res));
        } else {
          return dispatch(inboxCreatedTripSuccess(res));
        }
      })
      .catch(err => dispatch(inboxCreatedTripFailure(err)));
  };
}

export const INBOX_COPIED_TRIP = 'INBOX_COPIED_TRIP';
export const inboxCopiedTrip = () => ({
  type: INBOX_COPIED_TRIP
});

export const INBOX_COPIED_TRIP_SUCCESS = 'INBOX_COPIED_TRIP_SUCCESS';
export const inboxCopiedTripSuccess = payload => ({
  type: INBOX_COPIED_TRIP_SUCCESS,
  payload,
  msg: payload.message ? payload.message : 'inboxCopySuccess'
});

export const INBOX_COPIED_TRIP_FAILURE = 'INBOX_COPIED_TRIP_FAILURE';
export const inboxCopiedTripFailure = payload => ({
  type: INBOX_COPIED_TRIP_FAILURE,
  payload,
  msg: payload.message
});

export function inboxCopyTrip(payload, token) {
  return dispatch => {
    dispatch(inboxCopiedTrip());

    return DashService.inboxCopyTrip(payload, token)
      .then(res => {
        if (res.returnCode && res.returnCode !== 0) {
          dispatch(inboxCopiedTripFailure(res));
        } else {
          dispatch(inboxCopiedTripSuccess(res));
        }
        return res;
      })
      .catch(err => dispatch(inboxCopiedTripFailure(err)));
  };
}
