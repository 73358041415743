import { connect } from 'react-redux';

import ShipmentDocuments from './ShipmentDocuments';
import { setActiveReservation } from 'actions/reservations';
import { DOCUMENTS_LIST } from 'util/documentsList';

const mapStateToProps = (state) => ({
  info: state.dashboard.info,
  auth: state.session.auth,
  user: state.user.info,
  documents: DOCUMENTS_LIST
});

const mapDispatchToProps = (dispatch) => ({
  // setActiveReservation: (shipment) => dispatch(setActiveReservation(shipment))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDocuments);
