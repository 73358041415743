import React, { useEffect, useRef } from 'react'
import Style from "./SmallPopupWrapper.module.css";
import { Menu } from '@material-ui/core';
import PropTypes from "prop-types";

const SmallPopupWrapper = ({
    open,
    size = 'md',
    onClose,
    children,
    className,
    popoverClass,
    vertical = 'top',
    horizontal = 'right',
    noStyle = false
}) => {

    // const containerRef = useRef();

    // const handleClickOutside = (event) => {
    //     if (containerRef.current && !containerRef.current.contains(event.target)) {
    //         onClose();
    //     }
    // };

    // useEffect(() => {
    //     if (open) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [open, onClose]);


    // const handleClick = (event) => {
    //     setOpen(event.currentTarget);
    // };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            {/* <div
                ref={containerRef}
                className={`${Style.container} ${!open && Style.collapse}`}
            >
                {children}
            </div> */}


            <Menu
                anchorEl={open}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: { vertical },
                    horizontal: { horizontal }
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(open)}
                classes={{ list: 'p-0' }}
                onClose={handleClose}
                className={`${className} ${noStyle && Style.noStyle}`}
                PopoverClasses={popoverClass}
            >
                <div className={`dropdown-menu-${size} overflow-hidden p-0`}>
                    {children}
                </div>
            </Menu>
        </>
    )
}

SmallPopupWrapper.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'xl', 'lg', false]),
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

export default SmallPopupWrapper