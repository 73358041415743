import { Divider } from '@material-ui/core'
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading'
import React from 'react';
import Style from "./MobileContentWrapper.module.css"

const MobileContentWrapper = ({ title = '', actionComponent, children }) => {
    return (
        <div className={Style.container}>
            <div className='d-flex justify-content-between align-items-center c-gap-half'>
                <SectionHeading title={title} />
                {actionComponent}
            </div>
            <Divider />
            {children}
        </div>
    )
}

export default MobileContentWrapper