export const InsightsGuide = [
    {
        heading:"Analytics",
        icon:"",
        description:"Make Informed Decisions with Comprehensive Insights",
         content: [
            "Forwarders can make informed decisions by accessing insights on shippers, active regions, total shipments, and total earnings. The analytics are not limited to these aspects; a dedicated page provides detailed analytics to support better decision-making. Forwarders can also review their earnings using filters to analyze data daily, weekly, or monthly."
        ]
    },
    {
        heading:"Shipment Analytics and Transactions",
        icon:"",
        description:"Detailed Understanding of Shipments and Transactions",
         content: [
            "Forwarders can gain an in-depth understanding of shipment analytics, including pending, active, completed, and canceled shipments. Here's an overview of these terms.",
            "Active Shipments: Shipments that have been initiated by the forwarder but are not yet completed.",
            "Pending Shipments: Shipments that have been accepted by the forwarder but have not yet been initiated.",
            "Completed Shipments: Shipments that have reached their destination with all steps completed and the status marked as out for delivery.",
            "Completed Shipments: Shipments that have reached their destination with all steps completed and the status marked as out for delivery.",
            "Cancelled Shipments: Shipments that have been created but subsequently canceled by either the forwarder or the shipper through the shipment details resource center.",
            "Forwarders also have access to the transaction history of shippers, allowing them to view the latest ten transactions at any time."
        ]
    },
    {
        heading:"Create Shipment",
        icon:"",
        description:"Effortless Shipment Creation for Forwarders",
         content: [
            "As a forwarder, you can easily create shipments for your shippers. There are two methods available: select the shipper from your shipper list on the dashboard or add the shipper first and then create the shipment. This flexible approach ensures seamless management of your shipping operations."
        ]
    },
    {
        heading:"Add Shipper on Dashboard Page",
        icon:"",
        description:"Simplified Shipper Addition for Efficient Operations",
         content: [
            "Adding a shipper to your forwarder dashboard is essential for creating shipments. On the Insights page click 'Add Shipper' Enter the shipper's email address, and they will receive a notification email. Once the shipper confirms their participation, they will be added to your shipper list, allowing you to create shipments for them effortlessly."
        ]
    },
]