import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({});

const HeaderActions = ({ classes }) => {
  return (
    <>
      <div className="">
        <Button
          variant="contained"
          //   onClick={openUserMenu}
          size="small"
          className="btn-outline-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 shadow-none">
          <span className="btn-wrapper--label">Get a Quote</span>
          <span className="btn-wrapper--icon"></span>
        </Button>

        <Button
          variant="contained"
          size="small"
          className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 shadow-none"
          //   onClick={toggle1}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8" />
          </span>
          <span className="btn-wrapper--label">New Shipment</span>
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(HeaderActions);
