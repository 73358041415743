import React from 'react'
import Style from './EmptyContainer.module.css';

const EmptyContainer = ({
    source = "",
    title = "",
    description = ""

}) => {


    const renderImage = () => {
        switch (source) {
            case "":
                return "/assets/helpGuide/helpGuideHomeBanner.svg"
            case null:
                return "/assets/helpGuide/helpGuideHomeBanner.svg"
            default:
                return source
        }
    }


    return (
        <div className={`bg-white ${Style.bannerContainer}`}>

            <img src={renderImage()} className={Style.bannerImage} />
            {title && <span className="font-weight-bold text-black font-size-sm text-capitalize my-3 pt-2 opacity-7">{title}</span>}
            {description && <span className="font-size-xs">{description}</span>}
        </div>
    )
}

export default EmptyContainer