import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONTAINER_STATUS } from 'util/constants';
import { Grid } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  highlightBox: {
    backgroundColor: '#ecf1f9', //'#f4f7fc', //#edf3fd
    width: '100%',
    // padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden'
  },
  infoKey: {
    fontWeight: '600',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#9fb1c1',
    minWidth: '140px',
    marginRight: '25px'
  },
  infoValue: {
    color: '#15354e',
    maxWidth: '100%',
    fontSize: '12px'
  },
  carrierInfo: {
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: '200px',
    width: '100%',
    height: '100%',
    /* width: auto; */
    maxHeight: '80px',
    backgroundImage: (props) =>
      props && props.booking
        ? `url(${props.booking.shipmentInfo.carrierInfo.company.logo})`
        : 'none'
  }
});

const ShipmentTracking = ({ classes, booking }) => {
  return (
    <>
      <div className="my-4">
        <Grid container spacing={2}>
          <Grid item md={8}>
            <div className="d-flex mb-1">
              <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                VESSEL/CARRIER:
              </span>
              <span className="font-size-xs mw-100">
                {booking.shipmentInfo.carrierInfo.company.shortName}
              </span>
            </div>

            <div className="w-100">
              {booking.shipmentInfo.billOfLading && (
                <div className="d-flex mb-1">
                  <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                    BILL OF LADING #:
                  </span>
                  <span className="font-size-xs mw-100">
                    {booking.shipmentInfo.billOfLading.id}
                  </span>
                </div>
              )}

              {booking.shipmentInfo.containerNo && (
                <div className="d-flex mb-1">
                  <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                    CONTAINER #:
                  </span>
                  <span className="font-size-xs mw-100">
                    {booking.shipmentInfo.containerNo}
                  </span>
                </div>
              )}

              {booking.shipmentInfo.bookingRef && (
                <div className="d-flex mb-1">
                  <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                    BOOKING REF:
                  </span>
                  <span className="font-size-xs mw-100">
                    {booking.shipmentInfo.bookingRef}
                  </span>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={4} justify="end">
            {booking.shipmentInfo.carrierInfo.company.logo && (
              <div className={classes.carrierInfo} />
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider my-4 " />

      <div className="bg-light rounded text-left py-4">
        <div className="timeline-list mx-5">
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-info text-white">
                <FontAwesomeIcon icon={['far', 'building']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.EMPTY_TO_SHIPPER.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                17<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                <FontAwesomeIcon icon={['far', 'gem']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.GATE_IN_FULL.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                18<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                <FontAwesomeIcon icon={['far', 'object-group']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.RAIL_DEPART_ORIGIN.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                18<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-night-sky text-white">
                <FontAwesomeIcon icon={['far', 'bell']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.POSITIONED_OUT_ORIGIN.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                19<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-midnight-bloom text-white">
                <FontAwesomeIcon icon={['far', 'keyboard']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.LOADED.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                20<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>

          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-info text-white">
                <FontAwesomeIcon icon={['far', 'building']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.TRANSSHIPMENT_DISCHARGED.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                17<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                <FontAwesomeIcon icon={['far', 'gem']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.TRANSSHIPMENT_LOADED.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                18<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                <FontAwesomeIcon icon={['far', 'object-group']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.DISCHARGED.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                18<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-night-sky text-white">
                <FontAwesomeIcon icon={['far', 'bell']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.POSITIONED_OUT_DESTINATION.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                19<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
          <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
              <div className="timeline-item--icon-wrapper rounded-pill bg-midnight-bloom text-white">
                <FontAwesomeIcon icon={['far', 'keyboard']} />
              </div>
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                {CONTAINER_STATUS.POSITIONED_IN_DESTINATION.label}
              </h4>
              <small className="mt-2 d-block">
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className="mr-1"
                />
                20<sup>th</sup> December, 2022
              </small>
              <small className="mt-2 d-block">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                1:30 PM PST
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ShipmentTracking.defaultProps = {};

ShipmentTracking.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired
};

export default withStyles(styles)(ShipmentTracking);
