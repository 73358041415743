import {
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    SIGNUP_REQUEST,
    EXISITING_EMAIL_REQUEST,
    EXISITING_EMAIL_SUCCESS,
    EXISITING_EMAIL_ERROR
  } from '../actions';
  
  export const initialState = {
    userCreated:  null,
    userData: null,
    email: null,
    emailExists: null,
  };
  
  export default function(state = initialState, action) {
      console.log('action.payload', action)
    switch (action.type) {
      case SIGNUP_SUCCESS:
        return { ...state, userCreated: action.payload, email: null, emailExists: null };

      case SIGNUP_FAILURE:
        return { ...state, userCreated: false };

      case EXISITING_EMAIL_SUCCESS:
      case EXISITING_EMAIL_ERROR:
        return { ...state, emailExists: action.payload };

      case EXISITING_EMAIL_REQUEST:
        return { ...state, email: action.payload.email };

      case SIGNUP_REQUEST:
        return { ...state, userData: action.payload };

      default:
        return state;
    }
  }
  