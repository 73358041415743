import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class InsightService {
  static async fetchShipperAnalytics(id, token) {
    const url = `${getBaseUrl()}forwarder/fetch-shipper-analytics/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'insights',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchEarningOverview(id, token, option) {
    const url = `${getBaseUrl()}forwarder/fetch-earning-overview/${id}?option=${option}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'insights',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchShippmentAnalytics(id, token,type) {
    const url = `${getBaseUrl()}forwarder/fetch-shipment-analytics/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: type,
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchTransactionsAnalytics(id, token) {
    const url = `${getBaseUrl()}forwarder/fetch-transactions-analytics/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'insights',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }
}
