import Style from "./GreenStatus.module.css";
import CircleIcon from '@mui/icons-material/Circle';

/* eslint-disable */

const GreenStatus = ({ label, labelClass }) => {
    return (
        <div className={`${Style.container}`}>
            <CircleIcon className="text-success" style={{ fontSize: '10px' }} />
            <span className={` font-size-xs ${Style.label} ${labelClass}`}>{label}</span>
        </div>
    )
}
/* eslint-enable */
export default GreenStatus