import { Divider, TextField } from "@material-ui/core";
import Style from "./ShippingRouteBox.module.css";
import { IconButton } from "@mui/material";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Autocomplete } from '@material-ui/lab';
import { setFilterDataInRedux } from "actions";
import CountrySelectNew from "components/CountrySelect/CountrySelectNew";
/* eslint-disable */

const ShippingRouteBox = ({
  from,
  to,
  filters,
  setFilters,
  handlePageChange,
  services
}) => {
  const reverseOrigin = () => {
    let a = filters.origin.country;
    let b = filters.destination.country;
    let c = a;
    a = b;
    b = c;

    setFilters({
      ...filters,
      origin: {
        country: a,
        state: '',
        city: '',
        port: ''
      },
      destination: {
        country: b,
        state: '',
        city: '',
        port: ''
      }
    });
  };


   const handleCountry = (opt, name) => {
    //  console.log(opt);
     if (opt) {
       setFilters({
         ...filters,
         [name]: {
           ...filters[name],
           country: opt,
           state: '',
           city: '',
           port: ''
         }
       });
     } else {
       setFilters({
         ...filters,
         [name]: {
           ...filters[name],
           country: '',
           state: '',
           city: '',
           port: ''
         }
       });
     }
   };


  console.log(filters)

  return (
    <div className={Style.container}>
      <div className={Style.city_container}>
        <label className={Style.label} htmlFor="route_from">
          A
        </label>
        {/* <input
                    id="route_from"
                    type="text"
                    className={Style.input}
                    defaultValue={from}
                    placeholder="City, port"
                    size={1}
                /> */}

        <CountrySelectNew
          useDefault
          isClearable={true}
          isMulti={false}
          onChange={(e, opt) => handleCountry(opt, 'origin')}
          showError={false}
          value={filters.origin.country}
        />
      </div>
      <Divider />
      <div className={Style.city_container}>
        <label className={Style.label} htmlFor="route_to">
          B
        </label>
        <CountrySelectNew
          useDefault
          isClearable={true}
          isMulti={false}
          onChange={(e, opt) => handleCountry(opt, 'destination')}
          showError={false}
          value={filters.destination.country}
        />
        {/* <input
            id="route_to"
            type="text"
            className={Style.input}
            defaultValue={to}
            placeholder="City, port"
            size={1}
          /> */}
      </div>
      <IconButton
        onClick={reverseOrigin}
        className={Style.swap_btn}
        size="small">
        <SwapVertIcon />
      </IconButton>
    </div>
  );
};
/* eslint-enable */
export default ShippingRouteBox