import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Style from "./MobileShipmentDetails.module.css";
import { IconButton, Button, Tooltip } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading';
import ChatDetailPane from '../../ChatDetailPane/ChatDetailPane';
import { ACTIVE_SHIPMENTS, getShipment } from '../../../Shipments/ActiveShipments/MockData';
import { useDispatch, useSelector } from 'react-redux';
import RightDrawer from 'sites/shipper/components/layout/RightDrawer';
import { setActiveReservation } from 'actions';
import { ForwarderService } from 'service';
import ShipmentCardPreview from 'sites/shipper/pages/Shipments/ShipmentCardPreview';


const MobileShipmentDetails = () => {

    const history = useHistory();
    const chatFromRedux = useSelector((state) => state.chatRoom.chat_data)

    const dispatch = useDispatch()


    const activeShipment = useSelector((state) => state.dashboard.activeReservation)
    
    const [openRightDrawer, setOpenRightDrawer] = useState(false);


    const toogleRightDrawer = (bool) => {
        setOpenRightDrawer(bool);
        if (!bool) {
          dispatch(setActiveReservation(null));
        }
      };
    

    const handelShowShipment = () => {
        ForwarderService.fetch_shipement_forwarder("", "", chatFromRedux.shipment_details._id).then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                dispatch(setActiveReservation(res[0].slider_data));
                setOpenRightDrawer(true);
                // setData(res)
            }
        })
    }




    // syb component 
    const AttachmentMenuItem = ({ name }) => (
        <div className={Style.attachment_item}>
            <img src="assets\forwarder\chat\attachment_icon.svg" alt="..." />
            <span>
                {name}
            </span>
        </div>
    )

    return (
        <div className={Style.container}>
            <div className={Style.top_bar}>
                <IconButton
                    onClick={() => history.go(-1)}
                    size='small'
                >
                    <KeyboardBackspaceIcon style={{ color: 'var(--primary-text)' }} />
                </IconButton>
                <SectionHeading title="Shipment Details" />
            </div>

            <div className={Style.middle_container}>
                <FormControl fullWidth size='small' className={Style.select_attachments}>
                    <InputLabel id="demo-simple-select-label">Attachments</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Attackments"
                    // value={age}
                    // onChange={handleChange}
                    >
                        <MenuItem className={Style.select_attachments_menu} value={10}><AttachmentMenuItem name="Packing List Packing" /></MenuItem>
                        <MenuItem className={Style.select_attachments_menu} value={20}><AttachmentMenuItem name="Packing List" /></MenuItem>
                        <MenuItem className={Style.select_attachments_menu} value={30}><AttachmentMenuItem name="Packing List Packing" /></MenuItem>
                    </Select>
                </FormControl>

                <Tooltip title="Open Shipment" arrow>
                    <Button
                        className='btn-second d-inline-flex btn-pill px-2 py-1 shadow-none'
                    >
                        <span className="btn-wrapper--label" style={{ fontSize: '10px' }}
                            onClick={handelShowShipment}
                        >
                            Open Shipment
                        </span>
                    </Button>

                </Tooltip>
            </div>
            {chatFromRedux && chatFromRedux.shipment_details &&
                <div>
                    <ChatDetailPane
                        booking={chatFromRedux.shipment_details}
                        isMobile={true}
                    />
                </div>
            }

            {activeShipment && (
                <RightDrawer
                    onClose={() => toogleRightDrawer(false)}
                    open={openRightDrawer}
                    widthClasses={Style.res_right_drawer}
                    closeBtnClasses={Style.right_drawer_close_btn}
                >
                    <ShipmentCardPreview booking={activeShipment} />
                </RightDrawer>
            )}




        </div>
    )
}

export default MobileShipmentDetails