import { RATE_TYPES_MAPPER } from '../config/constants';

export default class CommonModel {
  static createPaginationObj = (nextCursor, nextUrl, hasMore, count) => ({
    nextCursor,
    nextUrl,
    hasMore,
    count
  });

  static createRateList = (rates) => (type) =>
    rates ? rates.map((rate) => CommonModel.createRateObj(rate)(type)) : [];

  static createRateObj = (rate) => (type) => ({
    '@type': type,
    rateName: RATE_TYPES_MAPPER[rate.rateName],
    rateDescription: rate.rateDescription,
    ratePrice: rate.ratePrice
  });

  static createPriceObj = (subtotal, taxes, fees, currency, total) => {
    let priceObj = {};

    if (subtotal !== '') {
      priceObj.subtotal = subtotal;
    }

    if (taxes !== '') {
      priceObj.taxes = taxes;
    }

    if (fees !== '') {
      priceObj.fees = fees;
    }

    if (currency !== '') {
      priceObj.currency = currency;
    }

    if (total !== '') {
      priceObj.total = total;
    }

    return priceObj;
  };

  static createCancelledReservationObj = (umId, umTripId) => ({
    umId,
    umTripId
  });

  static createUserTokenObj = (auth) => {
    if (auth && auth.token) {
      return {
        token: auth.token
      };
    }
  };
}
