import {
  ACTIVE_RESERVATION,
  LOGOUT_REQUEST,
  RECEIVE_INFO_SUCCESS,
  RECEIVE_RESERVATIONS_SUCCESS,
  RECEIVE_TRIPS_SUCCESS,
  INVALID_SESSION,
  SIGNUP_SUCCESS
} from '../actions';
import { LOGGED_IN_ELSEWHERE } from '../actions/auth';

export const initialState = {
  info: null,
  reservations: null,
  activeReservation: null,
  trips: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_INFO_SUCCESS:
      return { ...state, info: action.payload };

    case RECEIVE_RESERVATIONS_SUCCESS:
      return { ...state, reservations: action.payload };

    case ACTIVE_RESERVATION:
      return { ...state, activeReservation: action.payload };

    case RECEIVE_TRIPS_SUCCESS:
      return { ...state, trips: action.payload };

    case INVALID_SESSION:
    case LOGGED_IN_ELSEWHERE:
    case SIGNUP_SUCCESS:
    case LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
}
