import {
  LOGOUT_REQUEST,
  RECEIVE_AUTH_SUCCESS,
  VALID_SESSION,
  INVALID_SESSION,
  SIGNUP_SUCCESS
} from '../actions';
import { LOGGED_IN_ELSEWHERE, RECEIVE_AUTH_FAILURE } from '../actions/auth';

export const initialState = {
  auth: null,
  sessionTime: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_AUTH_SUCCESS:
      return { ...state, auth: action.payload };

    case VALID_SESSION:
      return { ...state, sessionTime: action.payload };

    case INVALID_SESSION:
    case RECEIVE_AUTH_FAILURE:
    case LOGGED_IN_ELSEWHERE:
    case SIGNUP_SUCCESS:
    case LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
}
