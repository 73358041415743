export const DocumentsGuide = [
    {
        heading:"Document Library",
        icon:"",
        description:"Organize and Access Shipment Documents and Images with Ease",
         content: [
            "In the Attachments tab, you will find two sections: Documents and Images. This feature allows you to efficiently manage all shipment-related documents and images.",
            "Documents: This section provides access to all documents associated with your shipments. You can view or download these documents in either list view or grid view. The documents here include those shared by forwarders and shippers, such as bills of lading, invoices, certificates, and other important paperwork required for the shipment process. This centralized repository ensures that you can quickly find and reference any necessary document.",
            "Images: In the Images section, you can browse all the images related to your shipments. These images might include photos of the packaged goods, inspection images, or any other visual documentation required for shipment verification and quality assurance. Similar to the Documents section, you can switch between list view and grid view for easy navigation and access.",
            "By utilizing the Attachments tab, forwarders can maintain an organized record of all crucial documents and images, ensuring seamless shipment management and compliance with all necessary protocols."
        ]
    },
    
    
]