import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SkeletonComponent = ({ width= "100%", height="200px", instances=5, columns=1 }) => {
  const skeletons = [];

  for (let i = 0; i < instances; i++) {
    skeletons.push(<Skeleton key={i} animation="wave" variant="rounded" width={width} height={height} />);
  }

  // return <><div className='pt-2 w-100 d-flex flex-column c-gap-1'>{skeletons}</div></>;
  return <div className='w-100' style={{display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, gap: '1rem', width: "100%", marginTop: '1rem' }}>
  {skeletons}
</div>
};

export default SkeletonComponent;
