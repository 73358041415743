import axios from 'axios';

import { getBaseUrl } from '../config/config';

export default class HttpService {
  static request(
    method,
    endpoint,
    additionalData,
    config,
    user,
    contentType = HeaderContentTypes.JSON
  ) {
    if (additionalData) {
      additionalData =
        Object.keys(additionalData).length === 0
          ? null
          : { data: additionalData };
    }

    const data = {
      ...additionalData,
      headers: {
        'Content-Type': contentType,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept'
      },
      json: true
    };

    if (user && user.token) {
      data.headers['Authorization'] = 'Bearer ' + user.token;
    }
    const baseUrl = getBaseUrl();
    const methodAndUrl = {
      method: method,
      url:
        endpoint.includes('http://') || endpoint.includes('https://')
          ? endpoint
          : baseUrl + endpoint
    };

    const request = {
      ...methodAndUrl,
      ...data,
      ...config
    };

    return axios
      .request(request)
      .then((res) => res.data)
      .catch((err) => err);
  }

  static requestFormData(method, endpoint, additionalData, config, user) {
    if (additionalData) {
      additionalData =
        Object.keys(additionalData).length === 0
          ? null
          : { data: additionalData };
    }

    const data = {
      ...additionalData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept'
      },
      json: false
    };

    if (user && user.token) {
      data.headers['Authorization'] = 'Bearer ' + user.token;
    }
    const baseUrl = getBaseUrl();
    const methodAndUrl = {
      method: method,
      url:
        endpoint.includes('http://') || endpoint.includes('https://')
          ? endpoint
          : baseUrl + endpoint
    };

    const request = {
      ...methodAndUrl,
      ...data,
      ...config
    };

    return axios
      .request(request)
      .then((res) => res.data)
      .catch((err) => err);
  }

  static postFormData(endpoint, formData, additionalConfig, user) {
    const config = {
      ...additionalConfig,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    if (user && user.token) {
      config.headers['Authorization'] = 'Bearer ' + user.token;
    }

    const baseUrl = getBaseUrl();
    const url =
      endpoint.includes('http://') || endpoint.includes('https://')
        ? endpoint
        : baseUrl + endpoint;

    return axios
      .post(url, formData, config)
      .then((res) => res.data)
      .catch((err) => err);
  }
}

export const HttpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
};

export const HeaderContentTypes = {
  FORMDATA: 'multipart/form-data',
  JSON: 'application/json; charset=UTF-8'
};
