export const SettingGuide = [
    {
        heading:"My Account",
        icon:"",
        description:"Secure and Manage Your Kargoplex Account with Confidence",
        content: [
            "Your account at Kargoplex is safe with us. Kargoplex uses your details only to verify your identity. Contact information and some personal details can be edited, but you may need to verify your identity the next time you book or create a shipment. Kargoplex is committed to respecting your privacy. You might wonder what information is shared with others. Kargoplex only releases contact information for shippers and forwarders after a shipment is confirmed. We are dedicated to providing a secure and trustworthy platform for all our users."
        ]
    },
    {
        heading:"Account Settings",
        icon:"",
        description:"Effortlessly Update Your Personal Information with Robust Securit",
        content: [
            "In the account settings, you can see and update your personal details, such as your legal name, gender, date of birth, phone number, and email. While you can edit some of these details, you will need to verify your identity again to ensure the security of your account. This additional layer of security helps protect your information and maintains the integrity of your profile."
        ]
    },
    {
        heading:"Company Settings",
        icon:"",
        description:"Manage Your Company and Team Details with Enhanced Verification",
        content: [
            "Company Tab: In the company tab, you can view and edit details like company name, job title, industry, and address. Any changes to these details will require re-verification to ensure accuracy and security. This process helps maintain the integrity of the company information and ensures that all updates are legitimate.",
            "Team Tab: The team tab allows you to manage your team members efficiently. You can see who the admin is, who the owner is, and who are your power members and members. There are predefined permissions for each role, ensuring clear and controlled access. You can also view the creation date of each team member and their status (active or suspended). Actions such as editing or deleting team members can be performed to maintain an organized and effective team structure. This is crucial for smooth shipment operations and effective communication within your team."
        ]
    },
    {
        heading:"Security Settings",
        icon:"",
        description:"Enhance Your Account Security with Comprehensive Reviews and Advanced Settings",
        content: [
            "At Kargoplex, we prioritize your account security. We regularly review accounts to ensure they are secure and provide recommendations for further improvements. The security settings are divided into three tabs: account, privacy, and cookies",
            "Account Tab: In the account tab, you can see if multi-factor authentication (MFA) is enabled or disabled. You can enable MFA using various options, update your password, and review device history to monitor platform access.",
            "Privacy Tab: The privacy tab allows you to manage activity and data sharing settings. You can control how your profile and activity are displayed and used for Kargoplex research. We are committed to your privacy at every stage. You can toggle settings like Include my profile in search engines and Use my first name and profile photo to help fight discrimination.",
            "Cookies Tab: Some cookies are essential for the website to function and cannot be switched off in our system. These are usually set in response to actions like setting your privacy preferences, logging in, or filling out forms. However, Kargoplex allows you to turn off certain cookies, such as those for security, language settings, and user experience. You can also manage other cookies, such as performance and functional cookies, to tailor your browsing experience while maintaining necessary functions."
        ]
    },
    {
        heading:"Notification Settings",
        icon:"",
        description:"Customize Your Notifications for a Personalized Experience",
        content: [
            "You can decide which notifications you want to receive and choose your preferred notification method, be it email or phone call. You can select notifications for account activity, shipment activity, in-app purchases, user policies, feedback, news and updates, freight regulations, and more. Turn on or off notifications to stay informed according to your preferences."
        ]
    },
    {
        heading:"Preferences Settings",
        icon:"",
        description:"Set Your Global Preferences for a Tailored User Experience",
        content: [
            "In this section, you can customize your language, currency, and time zone settings. Adjust these global preferences to ensure your Kargoplex experience is tailored to your needs, providing a seamless and user-friendly interface that matches your personal or business requirements."
        ]
    },  
   
]