import React from 'react'
import Style from "./ChatItem.module.css";
import { Card, Button, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import people2 from 'assets/images/stock-photos/people-3.jpg';
import people1 from 'assets/images/stock-photos/people-2.jpg';
import { useSelector } from 'react-redux';
import avatar1 from 'assets/images/avatars/avatar1.jpg';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';


function formatDateForChat(timestamp) {
    const date = new Date(timestamp);
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return ` ${formattedHours}:${formattedMinutes} ${ampm}`

    // if (date.toDateString() === currentDate.toDateString()) {
    //     return `Today, ${formattedHours}:${formattedMinutes} ${ampm}`;
    // } else if (date.toDateString() === yesterday.toDateString()) {
    //     return `Yesterday, ${formattedHours}:${formattedMinutes} ${ampm}`;
    // } else {
    //     const day = date.getDate();
    //     const month = date.toLocaleString('default', { month: 'short' });
    //     return `${day} ${month}, ${formattedHours}:${formattedMinutes} ${ampm}`;
    // }
}


const ChatItem = ({ data }) => {
    const auth = useSelector((state) => state.session.auth)
    // console.log(data);

   



    return (
        <>
            {data?.sender === auth.accountId ?

                <ChatItemLeft data={data} />
                :
                <ChatItemRight data={data} />
            }
        
        </>
    )
}

const ChatItemLeft = ({ data }) => {
    return (
        <div className={`chat-item p-2 mb-2 chat-item-reverse`}>
            <div className={`align-box-row flex-row-reverse`}>
                <div>
                    <div className="chat-box bg-gray-400 text-second">
                        <p style={{ fontSize: "14px" }}>{data?.content}</p>
                        <small className="mt-1 d-flex text-black-50" style={{fontSize:"10px",justifyContent:"end",minWidth:"100px"}} >
                            {formatDateForChat(data?.dateAndTime)}
                        </small>
                    </div>
               
                </div>
            </div>
        </div>
    )
}


const ChatItemRight = ({ data }) => {
    return (
        <div className={`chat-item p-2 mb-2`}>
            <div className={`align-box-row`}>
                <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
                    {/* <div className={`${Style.avatar}  avatar-icon rounded-circle shadow-none`}>
                        <img alt="..." src={data.senderAvatar} />
                    </div> */}
                    <CustomAvatar
                                     className={`${Style.avatar}  avatar-icon rounded-circle shadow-none`}
                                      height={30} width={30}
                                      name={data.senderName}
                                      src={data.senderAvatar}
                                    />

                </div>
                <div>
                    <div className="chat-box bg-gray-400 text-second">
                        <p className='font-weight-bold' style={{fontSize:"12px"}}>{data.senderName}</p>
                        <p style={{ fontSize: "14px" }}>{data?.content}</p>
                        <small className="mt-1 d-flex text-black-50" style={{fontSize:"10px",justifyContent:"end",minWidth:"100px"}} >
                            {formatDateForChat(data?.dateAndTime)}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ChatItem


// <div className={`chat-item p-2 mb-2 ${data?.sender === auth.accountId && 'chat-item-reverse'}`}>
// <div className={`align-box-row ${data?.sender === auth.accountId && 'flex-row-reverse'}`}>
//     <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
//         <div className={`${Style.avatar}  avatar-icon rounded-circle shadow-none`}>
//             {/* <img alt="..." src={avatar1} />  */}
//             <img alt="..." src={data.senderAvatar} />

//             {/* Change the image here make it the image of the avatar */}
//         </div>

//     </div>
//     <div>
//         <div className="chat-box bg-gray-400 text-second">

//             <p style={{ fontSize: "14px" }}>{data?.content}</p>
//             {/* <p style={{ fontSize: 'clamp(0.7rem , 2vw, 0.875rem)' }}>{data?.content}</p> */}

//             {/* images attachments  */}
//             {data?.files?.pictures?.length > 0 &&
//                 <Card className="mt-3 mb-2 pt-2 pb-2 px-1 text-center">
//                     {data?.files.pictures.map((img, index) => (
//                         <a key={index} href="#/" onClick={(e) => e.preventDefault()}>
//                             <img
//                                 alt="..."
//                                 className="img-fluid rounded m-1 shadow-sm"
//                                 src={img}
//                                 width="54"
//                             />
//                         </a>
//                     ))}
//                 </Card>
//             }

//             {/* links attachments  */}
//             {data?.files?.links?.length > 0 &&
//                 <Card className="bg-second p-1 mt-3 mb-2">
//                     <div className="text-center py-2">
//                         {data?.files.links.map((link, index) => (
//                             <Tooltip key={index} title={link.name}>
//                                 <a
//                                     href={link.link}
//                                     target='_blank'
//                                     className="btn-link p-0 btn-icon bg-ripe-malin d-inline-block text-center text-white font-size-xl d-40 rounded-circle border-0 m-2"
//                                     id={`${link.name}-${index}`}>
//                                     <FontAwesomeIcon
//                                         icon={['far', 'gem']}
//                                         className="font-size-sm"
//                                     />
//                                 </a>
//                             </Tooltip>
//                         ))}
//                     </div>
//                 </Card>
//             }
//         </div>
//         <small className="mt-2 d-block text-black-50" style={{ fontSize: 'clamp(7px, 2vw, 12px)' }}>
//             <span className='font-weight-bold'>{data.senderName} </span>| {formatDateForChat(data?.dateAndTime)}
//         </small>

//         {/* <small className="mt-2 d-block text-black-50" style={{ fontSize: 'clamp(7px, 2vw, 12px)' }}>
    
//         {formatDateForChat(data?.dateAndTime)}
//     </small> */}

//     </div>
// </div>
// </div>