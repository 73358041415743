import React from 'react'
import Style from "./index.module.css"
import IconBoxV1 from '../../Reusables/IconBoxV1/IconBoxV1'
import { useSelector } from 'react-redux'

const ScreenOne = ({ data, handleClick }) => {
    const auth = useSelector((state) => state.session.auth)
    return (
        <>
            <div className="p-4">


                <div className={`d-flex flex-column ${Style.headerContainer}`}>
                    <h3 className="font-weight-bold mb-2">User Guide</h3>


                    <div className={Style.bannerContainer}>

                        <img src="/assets/helpGuide/helpGuideHomeBanner.svg" className={Style.bannerImage} />
                        <span className="font-weight-bold text-black font-size-lg my-2">Happy to help</span>
                        <span className="font-size-xs">Incomplete shipment is due to lack of courage which can be treated by more money.</span>
                    </div>

                </div>
                <div className='d-flex flex-column c-gap-1 py-2'>
                    {data.map((item, index) => {
                        return (
                            <>
                                {(item.allowed.includes(auth.accountCmpyLinkType) || item.allowed.length === 0) &&
                                    <IconBoxV1 data={item} index={index} handleClick={handleClick} />
                                }
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default ScreenOne