import React from 'react'
import Style from './IconBoxV1.module.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const IconBoxV1 = ({ data, handleClick }) => {
    return (
        <>
            <div className={`${Style.Box} bg-light`} onClick={() => handleClick(data.value)}>
                <div>
                    <span>{data.icon}</span>
                    <span className='ml-4 font-size-sm'>{data.label}</span>
                </div>
                <ArrowForwardIosIcon className='font-size-xs' />
            </div>
        </>
    )
}

export default IconBoxV1