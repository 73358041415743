import React, { useEffect, useState, useMemo } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import CitySelect from 'components/CountrySelect/CitySelect';
import CountrySelectNew from 'components/CountrySelect/CountrySelectNew';
import StateSelect from 'components/CountrySelect/StateSelect';
import StyledInput from 'components/StyledInput';

/* eslint-disable react-hooks/exhaustive-deps */
const styles = (theme) => ({
  formFields: {
    marginBottom: '20px'
  },
  formRow: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      minWidth: '1px',
      flexBasis: '100%'
    },
    [theme.breakpoints.up('md')]: {
      '& > div': {
        minWidth: '1px',
        flexBasis: 'calc(50% - 20px)'
      }
    }
  },
  addressRow: {
    [theme.breakpoints.up('md')]: {
      '& > div:not(:first-child)': {
        minWidth: '1px',
        flexBasis: 'calc(67% - 20px)'
      },
      '& > div:not(:last-child)': {
        minWidth: '1px',
        flexBasis: 'calc(33% - 20px)'
      }
    }
  }
});


const Address = ({
  classes,
  info,
  onUpdate
}) => {

  const [data, setData] = useState({
    country: info.country,
    state: info.state,
    city: info.city,
    street: info.street,
    suite: info.suite,
    postalCode: info.postalCode
  })


  const handleCountry = (e, opt) => {
    // if (!opt) {
    //   return
    // }
    setData({
      ...data,
      country: opt,
      state: "",
      city: ""
    })
  };

  const handleState = (e, opt) => {
    // if (!opt) {
    //   return
    // }
    setData({
      ...data,
      state: opt,
      city: ""
    })
  }

  const handleCity = (e, opt) => {
    // if (!opt) {
    //   return
    // }
    setData({
      ...data,
      city: opt
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })
  }

  useEffect(() => {
    onUpdate(data)
  }, [data])

  // console.log("chvfgjdhbkj", info)
  return (
    <div className={classes.formFields}>
      <div className={ClassNames(classes.formRow, classes.addressRow)}>
        <StyledInput
          name="suite"
          label="Suite (optional)"
          value={data.suite}
          onChange={handleChange}
        />
        <StyledInput
          name="street"
          label="Street"
          value={data.street}
          onChange={handleChange}
        />
      </div>
      <div className={classes.formRow}>
        <div>


          <CountrySelectNew
            name="country"
            value={data.country}
            isMulti={false}
            onChange={handleCountry}
          />
        </div>
        <div>


          <StateSelect
            name="state"
            country={data?.country?.code}
            value={data.state}
            handleChange={handleState}
          />
        </div>
      </div>

      <div className={classes.formRow}>
        <div>

          <CitySelect
            name="city"
            value={data.city}
            state={data?.state?.code}
            handleChange={handleCity}
          />
        </div>
        <StyledInput
          name="postalCode"
          label="Postal/Zip Code"
          value={data.postalCode}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

Address.defaultProps = {
  info: {},
};

Address.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onUpdate: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(Address);
