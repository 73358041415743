import clsx from 'clsx';
import React, { useState } from 'react';
import { withRouter } from 'react-router';

const PageContent = ({ noPadding, children }) => {
  return (
    <>
      <div className={clsx('app-content--inner h-100', { 'p-0': noPadding })}>
        {children}
      </div>
    </>
  );
};

export default withRouter(PageContent);
