import Style from "./InReview.module.css";
import { Divider } from "@material-ui/core";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SocialIcons from "sites/shipper/components/SocialIcons/SocialIcons";
import BoxContainer from "components/KYCUploadSystem/BoxContainer/BoxContainer";

/* eslint-disable */
const steps = [
    'Requested',
    'In Review',
    'Verified'
];

const InReview = ({ type }) => {
    return (
        // <div className={Style.container}>




        //     <div className={Style.main}>


        //         <div className={Style.left_container}>
        <BoxContainer>

            <div className={Style.left_inner}>
                <h3 className={Style.MainHeading}>{type && type} KYC in Review</h3>
                <Divider style={{ width: "100%" }} />
                <span className={Style.left_inner_desc}>
                    Your {type ? `${type} KYC` : "Know Your Customer (KYC)"} information is currently under review. We appreciate your patience as we ensure compliance and finalize the verification process. Rest assured, we're working diligently to expedite this process for you.
                </span>
                <div style={{ width: "100%" }}>
                    <Stepper activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <Divider style={{ width: "100%" }} className={Style.bottom_divider} />
                <span className={Style.follow_text}>
                    Follow our social media account
                </span>
                <SocialIcons />
            </div>
        </BoxContainer>
        // </div>

        //         <div className={Style.right_container} >
        //             <img src="/assets/kyc/right_image.png" width="80%" />
        //         </div>


        //     </div>
        // </div>
    )
}
/* eslint-disable */
export default InReview