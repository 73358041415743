import React ,{ useEffect } from 'react'
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useState } from "react";
import TextField from '@mui/material/TextField';
import Style from "./UploadDocumentPopUp.module.css";
import FileUploader from './FileUploader/FileUploader';
import ImgCrop from 'antd-img-crop';
import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import StyledSelect from 'components/StyledSelect';


const UploadDocumentPopUp = ({
    existingData,
    open,
    documentType,
    dropDownOption,
    documentDetail,
    setDocumentDetail,
    handleDone,
    handleCancel,
    onClose,
    isEdit,
    handleEditSubmit,
    setIsEdit,
    setEditIndex,
    urlToDelete,
    setUrlToDelete
}) => {









    const handleDoneClick = () => {
        handleDone(documentType)
        onClose()
    }

    const handleCancelClick = () => {
        handleCancel()
        onClose()
    }

    const handleEditClick = () => {
        handleEditSubmit(documentType)
        onClose()
    }
    const handleChange = (event) => {

        const uploaded = existingData[documentType].filter((item, idx) => {
            if (item.docType === event.target.value) {
                setEditIndex(idx);
                return item
            }
        })[0]

        if (uploaded) {
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }

        setDocumentDetail({
            ...documentDetail,
            docType: event.target.value,
            docName: event.target.value === "Other" ? (uploaded?.docName ? uploaded?.docName : "") : event.target.value,
            docNumber: uploaded?.docNumber ? uploaded?.docNumber : "",
            uploads: uploaded?.uploads?.length > 0 ? uploaded?.uploads : []
        })
    };


    const [fileSizeError, setFileSizeError] = useState("")


    const disabledCheck = () => {
        let result = true
        const selectedDropdown = dropDownOption.filter(item => item.value === documentDetail.docType)[0]
        if (!selectedDropdown) {
            return true
        }
        if (documentDetail.docType === "Other" && !documentDetail.docName) {
            return true
        }
        if (!documentDetail.docNumber) {
            return true
        }


        if (selectedDropdown.front) {
            if (documentDetail.docType !== "Other") {
                if (documentDetail.uploads.filter(item => item.type === "front").length === selectedDropdown.limit && !selectedDropdown.back) {
                    result = false
                }
            } else {
                if (documentDetail.uploads.length > 0 && documentDetail.uploads.length <= selectedDropdown.limit) {
                    result = false
                }
            }
        }
        if (selectedDropdown.back) {
            if (documentDetail.uploads.filter(item => item.type === "back").length === selectedDropdown.limit &&
                documentDetail.uploads.filter(item => item.type === "front").length === selectedDropdown.limit) {
                result = false
            }
        }
        return result
    }


    const [docNumLimit, setDocNumLimit] = useState(null)

    useEffect(() => {
        const selectedDropdown = dropDownOption.filter(item => item.value === documentDetail.docType)[0]

        setDocNumLimit(selectedDropdown?.docNumLimit)

    }, [documentDetail.docType])

    return (
        <>
            <ModalSimple
                modalTitle={"UPLOAD DOCUMENTS"}
                open={open}
                action={!isEdit ? handleDoneClick : handleEditClick}
                maxWidth={"md"}
                disabled={disabledCheck()}
                handleClose={handleCancelClick}
                actionButtonText={!isEdit ? "Done" : "Edit"}
                fullWidth={true}
            >

                <div className="c-flex-col c-gap-1">


                    <FormControl fullWidth>
                        {/* <InputLabel id="detail-box-label">{`Select Document Type`}</InputLabel> */}
                        {/* <Select
                            labelId="detail-box-label"
                            id="demo-simple-select"
                            value={documentDetail.docType}
                            label={`Select Document Type`}
                            onChange={handleChange}
                        >
                            {dropDownOption.map((item) => (
                                <MenuItem className={Style.menu_item} value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select> */}

                        <StyledSelect
                            label="Select Document Type"
                            name="docType"
                            margin="0"
                            value={documentDetail.docType}
                            onChange={handleChange}
                            size="small"
                            // options={[
                            //     { value: 'Male', label: 'Male' },
                            //     { value: 'Female', label: 'Female' },
                            //     { value: 'Other', label: 'Other' }
                            // ]}
                            options={dropDownOption.map((item) => {
                                return {
                                    value: item.value,
                                    label: item.label
                                }
                            })}
                        />

                        <br />
                        {documentDetail.docType &&
                            <>
                                <StyledInput margin="0" value={documentDetail.docNumber} characterLimit={docNumLimit} onChange={(e) => setDocumentDetail({ ...documentDetail, docNumber: e.target.value })} label="Enter Document Number*" />
                            </>
                        }
                        {documentDetail.docType === "Other" &&
                            <>
                                <br />
                                <StyledInput margin="0" value={documentDetail.docName} onChange={(e) => setDocumentDetail({ ...documentDetail, docName: e.target.value })} label="Enter Document Name*" />
                            </>
                        }
                    </FormControl>

                    {documentDetail.docType &&
                        <>
                            <div className="c-flex-col c-gap-1">
                                <span className='font-size-xs'>{dropDownOption.filter(item => item.value === documentDetail.docType)[0]?.description}</span>
                                <span className='font-size-xs' style={{ margin: "-1rem 0" }}>Max Size Allowed : 2MB</span>
                                <span className='font-size-xs'>Max upload allowed : {dropDownOption.filter(item => item.value === documentDetail.docType)[0]?.limit}</span>
                                <div className={documentDetail.docType !== "Other" && Style.dropZoneMainContainer}>
                                    {dropDownOption.filter(item => item.value === documentDetail.docType)[0].front &&
                                        <ImgCrop>
                                            <FileUploader
                                                type="front"
                                                description={documentDetail.docType !== "Other" ? "Front Side" : "Documents"}
                                                limit={dropDownOption.filter(item => item.value === documentDetail.docType)[0].limit}
                                                documentDetail={documentDetail}
                                                setDocumentDetail={setDocumentDetail}
                                                setFileSizeError={setFileSizeError}
                                                urlToDelete={urlToDelete}
                                                setUrlToDelete={setUrlToDelete}
                                            />
                                        </ImgCrop>
                                    }
                                    {dropDownOption.filter(item => item.value === documentDetail.docType)[0].back &&
                                        <ImgCrop>
                                            <FileUploader
                                                type="back"
                                                description={"Back Side"}
                                                limit={dropDownOption.filter(item => item.value === documentDetail.docType)[0].limit}
                                                documentDetail={documentDetail}
                                                setDocumentDetail={setDocumentDetail}
                                                setFileSizeError={setFileSizeError}
                                                urlToDelete={urlToDelete}
                                                setUrlToDelete={setUrlToDelete}
                                            />
                                        </ImgCrop>
                                    }
                                </div>
                                {fileSizeError && <span style={{ color: "red", fontSize: "12px" }}>{fileSizeError}</span>}
                            </div>
                        </>
                    }

                </div>
            </ModalSimple >
        </>
    )
}

export default UploadDocumentPopUp