import Style from "./RateSheet.module.css";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TableRow from "./table_row/TableRow";
import { Divider } from "@material-ui/core";
import { Empty } from "antd";
import EmptyContainer from "sites/shipper/components/EmptyContainer/EmptyContainer";
/* eslint-disable */


const RateSheet = ({ toggleRightDrawer, rateSheets, run, setRun }) => {

    return (
        <div className={Style.container}>
            <div className={Style.table_container}>
                <Divider />
                <div className={`${Style.table_header}`}>
                    <div>
                        <span>OCC </span>
                        <HelpOutlineOutlinedIcon style={{ fontSize: 'clamp(8px, 1.5vw, 12px)' }} />
                    </div>
                    <div>
                        <span>Origin City</span>
                    </div>
                    <div>
                        <span>Origin Port</span>
                    </div>
                    <div>
                        <span>Origin Port Id </span>
                    </div>
                    <div>
                        <span>DCC </span>
                        <HelpOutlineOutlinedIcon style={{ fontSize: 'clamp(8px, 1.5vw, 12px)' }} />
                    </div>
                    <div>
                        <span>Destination City </span>
                    </div>
                    <div>
                        <span>Destination Port </span>
                    </div>
                    <div>
                        <span>Destination Port Id</span>
                    </div>
                    <div>
                        <span>Action</span>
                    </div>
                </div>
                <Divider />

                {rateSheets.length > 0 ? <>
                    {rateSheets.map((item, index) => (
                        <TableRow
                            key={index}
                            data={item}
                            run={run}
                            setRun={setRun}
                            data_id={item._id}
                            id={item._id}
                            toggleRightDrawer={toggleRightDrawer}
                        />
                    ))}
                </>
                    :
                    // <Empty />
                    <EmptyContainer 
                        title='No Routes Found'
                    />
                }



            </div>

        </div>
    )
}
/* eslint-enable */

export default RateSheet