import React from 'react'
import Style from "./NotVerified.module.css"
import DocumentBox from 'components/KYCUploadSystem/DocumentBox/DocumentBox'

const RejectedDoc = ({ rejectedDoc }) => {
    return (
        <>
            {rejectedDoc.Individual.length > 0 &&
                <DocumentBox
                    mainHeading="Personal Verification Documents (Rejected)"
                    type="Individual"
                    data={rejectedDoc}
                    showOption={false}
                    dropDownOption={[]}
                />
            }
            {rejectedDoc.is_not_registered_company &&
                <div className={Style.left_inner}>
                    <span className={Style.form_head}>  My company is not registered in government records </span>
                </div>
            }
            {!rejectedDoc.is_not_registered_company && rejectedDoc.Company.length > 0 &&
                <DocumentBox
                    mainHeading="Company Verification Documents (Rejected)"
                    type="Company"
                    data={rejectedDoc}
                    showOption={false}
                    dropDownOption={[]}
                />
            }
        </>
    )
}

export default RejectedDoc