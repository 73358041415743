import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { CommonModel } from '../../model';

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: props => props.color
  }
});

const CustomLink = ({ to, classes, update, children, history, refreshApp, auth, checkSession, color }) => {
  return (
    <Link
      to={to}
      className={classes.link}
      onClick={e => {
        e.preventDefault();
        const token = CommonModel.createUserTokenObj(auth);
        console.log('CustomLink', auth, token, update);
        checkSession(token);
        if (update) {
          refreshApp(false);
          return (window.location = to);
        }
        return history.push(to);
      }}
    >
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  history: PropTypes.object,
  update: PropTypes.bool,
  refreshApp: PropTypes.func,
  color: PropTypes.string
};

CustomLink.defaultProps = {
  color: 'inherit'
};

export default compose(
  withRouter,
  withStyles(styles)
)(CustomLink);
