import { HttpMethods, httpService } from './global';

export default class SmartDocumentServices {

  static create_document(data, token) {
    const url = `forwarder/create-document`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'smart_documents',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetch_documents(id, page, data, accountId, token) {
    const url = `forwarder/fetch-document?forwarder_id=${id}&page=${page}&accountId=${accountId}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'smart_documents',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
  static delete_documents(id, token) {
    const url = `forwarder/delete-document?document_id=${id}`;
    return httpService(HttpMethods.GET, url,null, {
      token,
      type: 'smart_documents',
      method: 'delete',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


    static fetch_document_details(id, token) {
    const url = `forwarder/fetch-document-details?document_id=${id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'smart_documents',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static edit_document(data,token) {
    const url = `forwarder/edit-document`;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'smart_documents',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipments(data, token, type, method) {
    const url = `forwarder/fetch-shipment`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: type,
      method: method,
      platform: "Forwarder"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}

