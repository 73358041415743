export const MyServicesGuide = [
    {
        heading:"Add Active Region",
        icon:"",
        description:" Define Your Operational Areas with Precision",
         content: [
            "Forwarders can add active regions in which they operate through the 'My Services' section of the forwarder dashboard. By clicking on the 'Add Active Region' CTA, forwarders need to enter specific details such as suite, street, pin code, country, state, and city to define their operational areas accurately."
            
        ]
    },
    {
        heading:"Enable/Disable Services in Your Active Region",
        icon:"",
        description:"Customize Services Based on Regional Requirements",
         content: [
            "Forwarders have the flexibility to enable or disable specific services for each active region. There are four primary services that can be provided to shippers: documentation, customs brokerage, packaging, and inspection. This feature allows forwarders to tailor their service offerings to meet the unique needs of each region."
            
        ]
    },
    {
        heading:"Services",
        icon:"",
        description:"Enhance Your Shipping Operations with Specialized Services",
         content: [
            "Forwarders can offer a range of services such as packaging, inspection, and labeling to ensure smooth and efficient shipping operations.",
            "Packaging Service: This service involves securely packaging goods to prevent damage during transit. Proper packaging ensures that items are protected from physical damage, moisture, and other environmental factors. It includes using appropriate materials like boxes, bubble wrap, and cushioning to safeguard the contents, ensuring they arrive at their destination in optimal condition.",
            "Labeling Service: Labeling involves applying accurate and clear labels to packages, which include essential information such as the recipient's address, handling instructions, and tracking numbers. Proper labeling is crucial for efficient sorting and delivery, helping to prevent delays and ensure that shipments reach the correct destination promptly.",
            "Inspection Service: Inspection services involve thoroughly checking shipments for quality and compliance with regulations before dispatch. This service ensures that the contents meet the required standards and specifications, and that there are no defects or discrepancies. Inspections can include verifying quantities, checking for damages, and ensuring that all documentation is in order, thereby reducing the risk of issues during transit and delivery."                       
            
        ]
    },
    
]