import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import CallIcon from '@material-ui/icons/Call';
import { Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import {
    ANIMATING_STATE,
    HEIGHT_TOGGLE_ANIMATION,
    INITIAL_STATE
} from 'util/animationVariants';

const DetailBoxWrapper = ({ title, icon, data, classes, children }) => {

    const [open, setOpen] = useState(true);
    const toggleDetails = () => {
        setOpen(state => !state);
    }

    return (
        <div className="mb-5">
            <span className='mr-2'>
                {icon}
            </span>
            <span className="font-size-xs font-weight-bold text-uppercase text-black-50 minw-150 mr-3">
                {title}
            </span>
            <div className="divider mb-3" />
            <div className="d-flex justify-content-between align-items-center">
                <div
                    className={clsx(classes.bookingTitle, 'font-size-sm mt-2')}
                    onClick={toggleDetails}>
                    <span>{data.company_name}</span>
                    <span className="text-black-50 font-size-xs">
                        {open ? (
                            <Tooltip
                                title="Collapse"
                                arrow
                                classes={{ tooltip: 'tooltip-kargo-blue' }}>
                                <KeyboardArrowUp />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title="Expand"
                                arrow
                                classes={{ tooltip: 'tooltip-kargo-blue' }}>
                                <KeyboardArrowDown />
                            </Tooltip>
                        )}
                    </span>
                </div>
                <div>
                    {/* <Tooltip
                        title="Chat with Agent"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <Button
                            className="bg-neutral-first text-first p-0 d-inline-block shadow-none border-0 text-center d-30 rounded-circle btn-transition-none">
                            <span>
                                <ChatIcon className="font-size-md" />
                            </span>
                        </Button>
                    </Tooltip> */}

                    {/* {data.telephone && (
                        <Tooltip
                            title="Call Company"
                            arrow
                            classes={{ tooltip: 'tooltip-kargo-blue' }}>
                            <Button
                                href={`tel:${data.telephone}`}
                                // onClick={handleClick3}
                                className="bg-neutral-success text-success p-0 d-inline-block shadow-none border-0 text-center d-30 rounded-circle btn-transition-none">
                                <span>
                                    <CallIcon className="font-size-md" />
                                </span>
                            </Button>
                        </Tooltip>
                    )} */}
                </div>
            </div>
            <AnimatePresence>
                {open && (
                    <motion.div
                        className={classes.motionWrapper}
                        variants={HEIGHT_TOGGLE_ANIMATION}
                        initial={INITIAL_STATE}
                        animate={
                            open ? ANIMATING_STATE : INITIAL_STATE
                        }
                        exit={INITIAL_STATE}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default DetailBoxWrapper