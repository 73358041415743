export default class FileModel {
  static transformFileList = files => {
    return files.map(file => FileModel.transformFileObj(file));
  };

  static transformFileObj = file => {
    return {
      name: file.name,
      url: file.url,
      fileFormat: file.fileFormat,
      umId: file.umId
    };
  };

  static createImageObj = (umTripId, name, inImgUrl, description) => {
    return {
      '@type': 'ImageObject',
      umTripId,
      name,
      description,
      inImgUrl
    };
  };

  static createFileObj = (umTripId, name, description) => {
    return {
      '@type': 'MediaObject',
      umTripId,
      name,
      description
    };
  };

  static createPdfImportObj = (inPDFFileName, inFileProvider) => {
    return {
      inPDFFileName,
      inFileProvider
    };
  };

  static createFileObjToDelete = (inTripId, inDetailId, inFileId) => {
    return {
      inTripId,
      inDetailId,
      inFileId
    };
  };

  static createFileObjToDeleteFromTrip = (inTripId, inFileId) => {
    return {
      inTripId,
      inFileId
    };
  };

  static attachFileToBookingObj = (inDetailId, inFiles) => {
    return {
      inDetailId,
      inFiles
    };
  };
}
