import AccountCmpyLinkType from "model/enum/AccountCmpyLinkType";

export const AccountCompanyShortName = Object.freeze({
    OWNER: "OWN",
    ADMIN: "ADM",
    POWER: "POW",
    MEMBER: "MEM",
});


export const userRoleArray = Object.keys(AccountCmpyLinkType)
    .filter((key) => AccountCmpyLinkType[key] !== AccountCmpyLinkType.OWNER && AccountCmpyLinkType[key] !== AccountCmpyLinkType.GUEST)
    .map((key) => {
        return {
            label: AccountCmpyLinkType[key],
            value: AccountCompanyShortName[key]
        };
    });


export const userAccessControlMatrix = {
    [AccountCmpyLinkType.OWNER]: [AccountCmpyLinkType.OWNER, AccountCmpyLinkType.ADMIN, AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
    [AccountCmpyLinkType.ADMIN]: [AccountCmpyLinkType.ADMIN, AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
    [AccountCmpyLinkType.POWER]: [AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
    [AccountCmpyLinkType.MEMBER]: [AccountCmpyLinkType.MEMBER]
}




export const allowedUerRoleArray = (role) => {
    const allowedRoles = userAccessControlMatrix[role];
    const array = userRoleArray.filter(item => allowedRoles.includes(item.label))
    return array
  }




export const userPermission = {
    dashboard: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    shipments: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER]
        ,
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    my_shipper: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    my_shipper_individual: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    my_shipper_company: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    my_shipper_shipments: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    my_shipper_transaction: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    my_services: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    active_regions: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ]
    },
    insights: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ]
    },
    documents: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    billing_my_wallet: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ]
    },
    billing_payments: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ]
    },
    billing_earnings: {
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ]
    },
    inbox: {
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    company_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    company_team_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    security_account_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    security_privacy_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    security_cookies_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    notifications_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    preferences_settings: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER,
            AccountCmpyLinkType.MEMBER
        ]
    },
    smart_documents: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    },
    shipment_leads: {
        edit: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        read: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        delete: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ],
        create: [
            AccountCmpyLinkType.OWNER,
            AccountCmpyLinkType.ADMIN,
            AccountCmpyLinkType.POWER
        ]
    }
}