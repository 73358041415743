import React, { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import Styles from "./PasswordCheckList.module.css"
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomPasswordCheckList = ({
	password,
	setIsValidPassword,
	rules = [],
	containerClass,
	minLength = 8,
	...rest
}) => {
	const handleValidatePassword = (value) => {
		setIsValidPassword(value)
	}
	return (
		<div>

			<PasswordChecklist
				rules={[
					"minLength",
					"specialChar",
					"number",
					"capital",
					...rules
				]}
				rtl={true}
				validTextColor="green"
				validColor="green"
				invalidTextColor="red"
				invalidColor="red"
				className={`${Styles.passwordContainer} bg-light ${containerClass}`}
				iconSize={6}
				minLength={minLength}
				value={password}
				onChange={handleValidatePassword}
				iconComponents={{
					ValidIcon: <CheckCircleIcon className="text-success font-size-md"/>,
					InvalidIcon: <CancelIcon className="text-danger font-size-md"/>
				}}
				{...rest}
			/>
		</div>
	);
}
export default CustomPasswordCheckList