import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ConstantValuesService from 'service/ConstantValuesService'
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField, InputLabel } from '@material-ui/core';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        width: '100%'
    },
    dropdown: {
        position: 'relative',
        width: '100%'
    },
    errorMsg: {
        color: '#f44336',
        fontSize: '12px',
        margin: '8px 14px 0'
    },
    errorBorder: {
        border: '1px solid red'
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18
        }
    }
});

const CitySelect = ({ classes, state = undefined, value = undefined, handleChange, showError = false, error = "", onBlur }) => {

    const [cities, setCities] = useState([])
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state) {
            setLoading(true)
            ConstantValuesService.fetchCityList(state,"").then(res => {
                if (res.error) {
                    console.log(res.error)
                } else {
                    setOptions(res)
                    setLoading(false)
                }
            })
        } else {
            setOptions([])
        }

    }, [state])



    return (
        <>
            {loading && <LoaderComponent loading={loading} />}
            <div className={classes.root}>
                <InputLabel htmlFor={"Select City*"}></InputLabel>
                <Autocomplete
                    style={{ width: "100%" }}
                    size='small'
                    value={value}
                    id="combo-box-demo"
                    options={options}
                    // closeIcon={false}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={"City"}
                            label={"Select City*"}
                            variant={"outlined"}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off' // disable autocomplete and autofill
                            }}
                        />
                    )}
                // renderInput={(params) => <TextField {...params} label="Select City*" />}
                />
                {showError && error && (
                    <FormHelperText variant="outlined" error>
                        {error}
                    </FormHelperText>
                )}
            </div>
            {/* <Select
                style={{ width: "200px" }}
                value={value}
                options={options}
                onChange={handleChange}
                placeholder={"Select City"}
            /> */}
        </>
    )
}

export default compose(withStyles(styles))(CitySelect);
// export default CitySelect