import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Box,
  Typography,
  Popover,
  Menu,
  Button,
  List,
  ListItem,
  Divider
} from '@material-ui/core';

import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone';
import CollectionsTwoToneIcon from '@material-ui/icons/CollectionsTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';
import { withStyles } from '@material-ui/styles';
import { handlePreview } from 'util/OpenPreviewWindow/OpenPreviewWindow';
import { useSelector } from 'react-redux';
import { userPermission } from 'util/forwarderPermissionRoles';

const styles = () => ({});

const HeaderMenu = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useSelector((state) => state.session.auth)
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      {(userPermission.smart_documents.read.includes(auth.accountCmpyLinkType) || userPermission.shipment_leads.read.includes(auth.accountCmpyLinkType)) &&
        <div className="app-header-menu">
          <Button
            onClick={handleClickMenu}
            size="small"
            className="btn-transition-none btn-neutral-dark mr-3">
            Tools
          </Button>

          <Menu
            anchorEl={anchorElMenu}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={handleCloseMenu}
            classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <Box className="overflow-hidden border-0 bg-midnight-bloom p-3 dropdown-mega-menu-md">
              <div className="p-2">
                <div className="font-weight-bold font-size-xl mb-1 text-white">
                  Forwarder Tools
                </div>
              </div>
              <div className="d-flex flex-wrap">
                {userPermission.smart_documents.read.includes(auth.accountCmpyLinkType) &&
                  <div className="w-50 p-2">
                    <Button
                      onClick={() => {
                        handlePreview('/smart-documents');
                        handleCloseMenu();
                      }}
                      className="d-block bg-white p-3 text-primary">
                      <div>
                        {/* <HomeWorkTwoToneIcon className="h1 d-block my-2 text-first" /> */}
                        <FontAwesomeIcon
                          icon={['fas', 'file-invoice']}
                          className="h1 d-block my-2 text-first"
                          style={{ width: "28px", height: "28px" }}
                        />
                        <div className="text-black font-size-md font-weight-bold">
                          Smart Documents
                        </div>
                      </div>
                    </Button>
                  </div>
                }
                {userPermission.shipment_leads.read.includes(auth.accountCmpyLinkType) &&
                  <div className="w-50 p-2">
                    <Button
                      style={{ width: '100%', textAlign: 'left' }}
                      onClick={() => {
                        handlePreview('/shipment-leads');
                        handleCloseMenu();
                      }}
                      className="d-block bg-white p-3 text-primary">
                      <div>
                        {/* <CalendarTodayTwoToneIcon className="h1 d-block my-2 text-success" /> */}
                        <FontAwesomeIcon
                          icon={['fas', 'chart-line']}
                          className="h1 d-block my-2 text-success"
                          style={{ width: "28px", height: "28px" }} />
                        <div className="text-black font-size-md font-weight-bold">
                          Shipment Leads
                        </div>
                      </div>
                    </Button>
                  </div>
                }
              </div>
            </Box>
          </Menu>
        </div>
      }
    </>
  );
};

export default withStyles(styles)(HeaderMenu);
