import React, { useEffect, useState } from 'react'
import Style from "./MakeOffer.module.css";
import ViewBreakdown from '../../shipment_rates/rate_sheet/ViewBreakdown/ViewBreakdown';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { ForwarderService } from 'service';
import BreakdownTable from 'components/breakdown_table/BreakdownTable';
import { ScaleLoader } from 'react-spinners';
import { getBaseUrl } from 'config/config';
import LeadDetails from '../../Chat/ChatViewPane/OfferChat/LeadDetails/LeadDetails';
/* eslint-disable */

// const data = {
//     "origin": {
//         "port_id": "1",
//         "port": "2",
//         "city": "3",
//         "country_code": "4"
//     },
//     "destination": {
//         "port_id": "5",
//         "port": "6",
//         "city": "7",
//         "country_code": "8"
//     },
//     "load": {
//         "fcl": {
//             "20ft": [],
//             "40ft": [],
//             "40ft_hc": [],
//             "45ft_hc": []
//         }
//     }
// }

const MakeOffer = () => {

    const [isOpen, setIsOpen] = useState(true);

    const { id } = useParams()
    // console.log(id)


    const [loading, setLoading] = useState(true)
    const [make, setMake] = useState(true)
    const [viewData, setViewData] = useState()


    const init = async () => {
        const baseUrl = getBaseUrl()
        const res = await fetch(`${baseUrl}forwarder/fetch-single-proposal?id=${id}`)
        const result = await res.json()
        if (result.error) {
            console.log(result.error)
            setMake(true)
            setLoading(false)

        } else {
            setViewData(result.charges)
            setMake(false)
            setLoading(false)
        }
        // ForwarderService.fetch_proposal_by_id(id).then(data => {
        //     console.log(data)
        //     window.alert(data.Status)
        //     if(data.error) {
        //         console.log(data.error)
        //         setMake(true)
        //         setLoading(false)
        //     }
        //     else {
        //         setViewData(data.charges)
        //         setMake(false)
        //         setLoading(false)
        //     }
        // })
    }

    console.log(make)
    useEffect(() => {
        init()
    }, [id])

    const pageData = useSelector((state) => state.makeOfferDetailsReducer)
    // console.log(pageData)
    const data = {
        "origin": {
            "port_id": "1",
            "port": pageData?.origin?.address_location?.label,
            "city": "3",
            "country_code": pageData?.origin?.address_country?.value
        },
        "destination": {
            "port_id": "5",
            "port": pageData?.destination?.address_location?.label,
            "city": "7",
            "country_code": pageData?.destination.address_country.value
        },
    }
    return (
        <>
            {loading ?
                <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ScaleLoader color={'#793de6'} loading={loading} />
                </div>
                :
                <>
                    <div className={Style.container}>
                        <div className={Style.lead_details}>
                            <LeadDetails pageData={pageData} />
                        </div>
                        {make &&
                            <ViewBreakdown pageData={pageData} open={true} name={pageData?.load?.cargoType?.label} data={data} data_id={id} isMakeOffer={true} />
                        }
                        {!make &&
                            <>
                                <div className={Style.lead_details}>
                                    <h3>Proposed Quote</h3><br />
                                    {viewData && viewData.map((d, index) => (
                                        <BreakdownTable data={d} key={index} />
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}
/* eslint-disable */
export default MakeOffer