import {
  CargocoverLogo,
  MarshLogo,
  UPSLogo,
  ChubbLogo,
  AIGLogo
} from 'assets/insuranceLogos';

export const INSURANCE_BROKER_LIST = {
  BROKER1: {
    id: '1',
    shortName: 'CargoCover',
    companyName: 'CargoCover transportation Insurance',
    telephone: '1-877-755-4934 ',
    email: 'cargocover@marsh.com',
    website: 'https://cargocover.com/',
    files: [
      {
        title: 'Your policy agreement',
        url: 'https://www.ms-ins.com/pdf/cargo/MARINECARGOINSURANCECLAUSES.pdf'
      },
      {
        title: 'CargoCover Brochure',
        url:
          'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf'
      }
    ],
    logo: CargocoverLogo
  },
  BROKER2: {
    id: '1',
    shortName: 'Marsh',
    companyName: 'MarshMcLennan',
    telephone: '1-877-755-4934 ',
    email: 'cargocover@marsh.com',
    website: 'https://www.marsh.com/us/contact-us.html',
    files: [
      {
        title: 'Your policy agreement',
        url: 'https://www.ms-ins.com/pdf/cargo/MARINECARGOINSURANCECLAUSES.pdf'
      },
      {
        title: 'Terms and Conditions',
        url:
          'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf'
      }
    ],
    logo: MarshLogo
  },
  BROKER3: {
    id: '3',
    shortName: 'UPS',
    companyName: 'UPS Capital',
    telephone: '1-888-888-1854',
    email: 'insureshieldca@ups.com',
    website: 'https://upscapital.com/ca-en/contact-ups-capital/',
    files: [
      {
        title: 'Your policy agreement',
        url: 'https://www.ms-ins.com/pdf/cargo/MARINECARGOINSURANCECLAUSES.pdf'
      },
      {
        title: 'UPS Brochure',
        url:
          'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf'
      }
    ],
    logo: UPSLogo
  },
  BROKER4: {
    id: '4',
    shortName: 'AIG',
    companyName: 'AIG Canada',
    telephone: '1-800-361-7211',
    email: null,
    website: 'https://www.aig.ca',
    files: [
      {
        title: 'Your policy agreement',
        url: 'https://www.ms-ins.com/pdf/cargo/MARINECARGOINSURANCECLAUSES.pdf'
      },
      {
        title: 'UPS Brochure',
        url:
          'https://www.cargocover.com/content/dam/marsh/Documents/PDF/canada/ca-en/cargocover-brochure1.pdf'
      }
    ],
    logo: AIGLogo
  }
};
