import Style from "./Status.module.css";
import CircleIcon from '@mui/icons-material/Circle';

/* eslint-disable */

const Status = ({ label }) => {
    return (
        <div className={Style.container}>
            <CircleIcon style={{ color: '#34A855', fontSize: '6px' }} />
            <span className={Style.label}>{label}</span>
        </div>
    )
}
/* eslint-enable */
export default Status