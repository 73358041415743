import {
  CMALogo,
  COSCOLogo,
  ESHIPPERLogo,
  MAERSKLogo,
  CSALogo,
  RAILCORPLogo,
  MSCLogo,
  HAPAGLogo,
  ZIMLogo
} from 'assets/carrierLogos';

export const SHIPPING_LINES = {
  MSC: {
    id: '1',
    shortName: 'MSC',
    fullName: 'Mediterranean Shipping Company',
    logo: MSCLogo
  },
  HAPAG: {
    id: '2',
    shortName: 'HAPAG',
    fullName: 'Hapag-Llyod',
    logo: HAPAGLogo
  },
  ZIM: {
    id: '3',
    shortName: 'ZIM',
    fullName: 'Zim Integrated Shipping Services Ltd.',
    logo: ZIMLogo
  },
  CMA: {
    id: '4',
    shortName: 'CMA-CGM',
    fullName: 'CMA-CGM',
    logo: CMALogo
  },
  COSCO: {
    id: '5',
    shortName: 'COSCO',
    fullName: 'China Ocean Shipping Company',
    logo: COSCOLogo
  },
  MAERSK: {
    id: '6',
    shortName: 'APM-Maersk',
    fullName: 'Maersk Shipping Line',
    logo: MAERSKLogo
  },
  RAILCORP: {
    id: '7',
    shortName: 'RAILCORP',
    fullName: 'Railway Corp',
    logo: RAILCORPLogo
  },
  ESHIPPER: {
    id: '8',
    shortName: 'eShipper',
    fullName: 'eShipper',
    logo: ESHIPPERLogo
  },
  CSA: {
    id: '9',
    shortName: 'CSA',
    fullName: 'CSA Trasnport',
    logo: CSALogo
  }
};
