import {
  AdgodLogo,
  CargoNaijaLogo,
  CCargoLogo,
  GoldenJetLogo,
  JecmekLogo,
  JenikLogo,
  MovehubLogo
} from 'assets/forwarderLogos';

export const FORWARDING_CMPIES = {
  JECMEK: {
    id: '1',
    shortName: 'Jecmek',
    fullName: 'Jecmek Shipping & Air Cargo Service',
    address: {
      suite: null,
      streetNumber: '41',
      streetAddress: 'Torbarrie Rd',
      addressLocality: 'North York',
      addressRegion: 'Ontario',
      postalCode: 'M3L 1G5',
      addressCountry: { label: 'Canada', value: 'CA' }
    },
    telephone: '647-339-1385',
    email: 'contact@jeckmek.com',
    website: 'www.jecmek.com',
    logo: JecmekLogo
  },
  ADGOD: {
    id: '2',
    shortName: 'ADGOD',
    fullName: 'Adgod Inc',
    address: {
      suite: null,
      streetNumber: '96',
      streetAddress: 'Millwick Drive',
      addressLocality: 'Toronto',
      addressRegion: 'Ontario',
      postalCode: 'M9L 1Y3',
      addressCountry: { label: 'Canada', value: 'CA' }
    },
    telephone: '647-430-0269',
    email: 'sales@adgodinc.com',
    website: 'www.adgodinc.com',
    logo: AdgodLogo
  },
  CCARGO: {
    id: '3',
    shortName: 'CCARGO',
    fullName: 'Canada Cargo',
    address: {
      suite: '162',
      streetNumber: '1895',
      streetAddress: 'Clements Road',
      addressLocality: 'Pickering',
      addressRegion: 'Ontario',
      postalCode: 'L1W 3V5',
      addressCountry: { label: 'Canada', value: 'CA' }
    },
    telephone: '289-660-0515',
    email: 'info@canadacargo.net',
    website: 'www.canadacargo.net',
    logo: CCargoLogo
  },
  MOVEHUB: {
    id: '4',
    shortName: 'Movehub',
    fullName: 'Movehub',
    address: null,
    telephone: null,
    email: 'charlie.clissitt@movehub.com',
    website: 'www.movehub.com',
    logo: MovehubLogo
  },
  JENIK: {
    id: '5',
    shortName: 'Jenik',
    fullName: 'Jenik Services',
    address: {
      suite: 'Level 1',
      streetNumber: '3571',
      streetAddress: '52nd Street SE',
      addressLocality: 'Calgary',
      addressRegion: 'Alberta',
      postalCode: 'T2B 3R3',
      addressCountry: { label: 'Canada', value: 'CA' }
    },
    telephone: '+1 (403) 402-0949',
    email: 'customerservice@jenikservices.ca',
    website: 'www.jenikservices.ca',
    logo: JenikLogo
  },
  CARGONAIJA: {
    id: '6',
    shortName: 'CARGONAIJA',
    fullName: 'Cargonaija',
    address: {
      suite: '1',
      streetNumber: '1000',
      streetAddress: 'North Circular Road',
      addressLocality: 'NW',
      addressRegion: 'LONDON',
      postalCode: 'NW2 7JP',
      addressCountry: { label: 'United Kingdom', value: 'UK' }
    },
    telephone: '020-390-45521',
    email: 'info@cargonaija.com',
    website: 'www.cargonaija.com',
    logo: CargoNaijaLogo
  },
  GOLDENJET: {
    id: '7',
    shortName: 'Golden Jet',
    fullName: 'Golden Jet Freight Forwarders',
    address: {
      suite: '403',
      streetNumber: '6299',
      streetAddress: 'Airport Road',
      addressLocality: 'Mississauga',
      addressRegion: 'Ontario',
      postalCode: 'L4V 1N3',
      addressCountry: { label: 'Canada', value: 'CA' }
    },
    telephone: '905-676-3812',
    email: 'goldenjet@goldenjet.com',
    website: 'www.goldenjet.com',
    logo: GoldenJetLogo
  }
};

export const FORWARDING_AGENTS = {
  FWDR1: {
    id: '1',
    firstName: 'John',
    lastName: 'Chukwu',
    company: FORWARDING_CMPIES.JECMEK
  },
  FWDR2: {
    id: '2',
    firstName: 'Jane',
    lastName: 'Smith',
    company: FORWARDING_CMPIES.ADGOD
  },
  FWDR3: {
    id: '3',
    firstName: 'Adam',
    lastName: 'Silver',
    company: FORWARDING_CMPIES.CCARGO
  },
  FWDR4: {
    id: '4',
    firstName: 'Betty',
    lastName: 'Padhilla',
    company: FORWARDING_CMPIES.GOLDENJET
  },
  FWDR5: {
    id: '5',
    firstName: 'Chris',
    lastName: 'Hemsworth',
    company: FORWARDING_CMPIES.CARGONAIJA
  },
  FWDR6: {
    id: '6',
    firstName: 'Charles',
    lastName: 'Xavier',
    company: FORWARDING_CMPIES.JENIK
  },
  FWDR7: {
    id: '7',
    firstName: 'Emeka',
    lastName: 'Okeke',
    company: FORWARDING_CMPIES.MOVEHUB
  }
};
