import React, { useEffect, useRef } from 'react'
import Style from "./AddServicePopup.module.css";
import { Button, Divider } from '@material-ui/core';
import CheckWithLabel from './CheckWithLabel/CheckWithLabel';
import { element } from 'prop-types';

const AddServicePopup = ({ open, onClose, data }) => {

    // const popupContainerRef = useRef();

    const options = ['Custom Brokerage', 'Inspection Service', 'Specialised Handling', 'Packaging', 'Labelling', 'Insurance', 'Documentation and Paperwork']

    const handleRequest = () => {
        onClose();
    }

    // // close popup on click outside 
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (popupContainerRef.current && !popupContainerRef.current.contains(event.target)) {
    //             onClose();
    //         }
    //     };

    //     if (open) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [open]);


    return (
        <>
            {/* <div
                id='pop-up-container'
                className={`${Style.container} ${!open && Style.collapse}`}
            > */}
            {/* <div
                    ref={popupContainerRef}
                    className={`${Style.popup_container} ${!open && Style.close_popup}`}
                > */}
            <div className={Style.main}>
                <div className={Style.header}>
                    <span>Add Service</span>
                </div>
                <Divider />
                <div className={Style.options_container}>
                    {options.map((option, index) => (
                        <CheckWithLabel label={option} index={index} />
                    ))}
                </div>
                <div className={Style.request_btn_container}>
                    <Button
                        className={`btn-primary btn-pill`}
                        fullWidth
                        onClick={handleRequest}
                    >
                        Request
                    </Button>
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
        </>

    )
}
export default AddServicePopup