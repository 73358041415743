import { Divider } from "@material-ui/core";
import Style from "./DetialTable.module.css";

/* eslint-disable */

const DetailTable = ({ condition, price, effective_date, expiry_date }) => {
    return (
        <div className={Style.container}>

            <div className={Style.table}>
                <div className={Style.headers}>
                    <span>{condition} Price</span>
                    <span style={{ color: 'green' }}>Effective Date</span>
                    <span style={{ color: 'red' }}>Expiry Date</span>
                </div>
                <Divider />
                <div className={Style.detail_row}>
                    <span>$ {price}</span>
                    <span>{effective_date}</span>
                    <span>{expiry_date}</span>
                </div>

            </div>

        </div>
    )
}
/* eslint-enable */
export default DetailTable