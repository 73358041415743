export const DashboardGuide = [
    {
        heading:"Analytics Cards",
        icon:"",
        description:"Comprehensive Shipping Analytics at Your Fingertips",
         content: [
            "Understand your shipping operations with precise and actionable analytics. Gain insights into active,pending, and canceled shipments, and explore a wide range of additional analytics to optimize your shipping processes."
        ]
    },
    {
        heading:"Shipment Report",
        icon:"",
        description:"Annual Shipping Performance Insights",
         content: [
            "Gain a clear understanding of your total earnings and shipments over a specific year.Utilize shipment reports to visualize your data and make informed decisions to enhance your business strategy."
        ]
    },
    {
        heading:"Create Shipment on Dashboard Page",
        icon:"",
        description:"Effortless Shipment Creation for Forwarders",
         content: [
            "As a forwarder, you can easily create shipments for your shippers.There are two methods available: select the shipper from your shipper list on the dashboard or add the shipper first and then create the shipment. This flexible approach ensures seamless management of your shipping operations."
        
        ]
    },
    {
        heading:"Add Shipper on Dashboard Page",
        icon:"",
        description:"Simplified Shipper Addition for Efficient Operations",
         content: [
            "Adding a shipper to your dashboard is essential for creating shipments.Navigate to the dashboard and click 'Add Shipper' Enter the shipper's email address, and they will receive a notification email.Once the shipper confirms their participation, they will be added to your shipper list, allowing you to create shipments for them effortlessly."
            
        ]
    },
    
        {
        heading:"Active Region on Dashboard Page",
        icon:"",
        description:"Real-Time Monitoring and Management of Operational Regions",
             content: [
            "Monitor and manage your current operational regions with ease using our forwarder platform's dashboard. This feature provides real-time insights for efficient logistics coordination and better decision-making."
        ]
    },
    {
        heading:"Forwarder Tools to Manage Shipments",
        icon:"",
        description:"Powerful Tools to Enhance Your Shipping Operations",
         content: [
            "Leverage these two robust tools to manage your shipping operations effectively.",
            "Smart Documents: Create all necessary documents for your shippers, including quotations, commercial invoices, packing lists, bills of lading, certificates of origin, and house bills of lading. Smart Documents streamline the documentation process, ensuring all required paperwork is completed accurately and efficiently.",
            "Shipment Leads: This tool is crucial for securing business opportunities at the right time and place. Specify your service routes and preferred transportation methods, whether sea freight or air freight, and view detailed information on potential shipment leads. This helps you identify and capture relevant business opportunities effectively."
            
        ]
    },
    {
        heading:"Preview Button",
        icon:"",
        description:"Quick Access to Shipment Details and Payments",
         content: [
            "Each shipment card includes a preview button, displaying key details such as shipment ID, origin, destination, container ID, container type (e.g., FCL), container size (e.g., 40 feet high cube), company, and tracking details. Clicking the preview button reveals a slider with two tabs: Overview and Payment.",
            "Overview Tab: Shows shipment details including shipment ID, place of loading, place of discharge, pickup date, departure date, and current status. It also provides a map indicating the container's current location.",
            "Payments Tab: Offers insights into the amounts paid and earned, with a detailed breakdown of shipment charges, including origin, shipment, and destination charges, taxes, platform fees, and other payment details."            
        ]
    },
    {
        heading:"View Details",
        icon:"",
        description:"Comprehensive Shipment Information at Your Disposal",
         content: [
            "The 'View Details' feature provides an in-depth understanding of your shipment, covering everything from container tracking to various tabs such as Overview, Payments, Attachments, Activity, Notes, Add-ons, Delivery, and Resource Center.",
            "if you need detailed information regarding the forwarder, consignor, consignee, attachments (e.g., House Bill of Lading, Master Bill of Lading, Insurance Certificate), dates (e.g., pickup, departure), transit times, port-to-port times, shipment value, shipping line, and more, you can find all the necessary details here. Additionally, you have the ability to update these details as needed."
           
        ]
    },
]
