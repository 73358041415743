import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class MyShipperService {
  static async fetchMyShippers(id, data, token, page) {
    const url = `${getBaseUrl()}forwarder/my-shipper/fetch?id=${id}&page=${page}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        type: 'my_shipper',
        method: 'read',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async addMyShipper(id, data, token) {
    const url = `${getBaseUrl()}forwarder/my-shipper/create?id=${id}`;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'my_shipper',
        method: 'create',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  static async upload_kyc_documents(id, data, token, accountId) {
    const url = `${getBaseUrl()}forwarder/my-shipper/upload-kyc-doc?id=${id}&accountId=${accountId}`;
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'my_shipper',
        method: 'create',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }


  static async fetchCompanyAndRegistrationWhileAdding(email,token) {
    const url = `${getBaseUrl()}forwarder/my-shipper/fetchCompanyAndRegistrationWhileAdding/${email}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'my_shipper',
        method: 'create',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }


  

  static async fetchMyShipperById(id, token, accountId) {
    const url = `${getBaseUrl()}forwarder/my-shipper/fetch-by-id?id=${id}&accountId=${accountId}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'my_shipper',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  static async verifyMyShipperById(data, token) {
    const url = `${getBaseUrl()}forwarder/my-shipper/verify-shipper`;
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'my_shipper',
        method: 'edit',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  static async saveShipperDetails(id, data, token, accountId) {
    const url = `${getBaseUrl()}forwarder/my-shipper/save-shipper-details?id=${id}&accountId=${accountId}`;
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'my_shipper_company',
        method: 'edit',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  static async inviteShipper(data, token) {
    const url = `${getBaseUrl()}forwarder/my-shipper/invite-shipper`;
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'my_shipper',
        method: 'create',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  static async rejectShipper(data, token, accountId) {
    const url = `${getBaseUrl()}forwarder/my-shipper/reject-shipper?accountId=${accountId}`;
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,  
        type: 'my_shipper',
        method: 'edit',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }

  static async fetchShipments(data, token) {
    const url = `${getBaseUrl()}forwarder/my-shipper/fetch-shipments`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'my_shipper_shipments',
        method: 'read',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetch_all_services(token) {
    const url = `${getBaseUrl()}shippment/fetch-all-services`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'my_shipper_shipments',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const result = await res.json();
    return result;
  }
}
