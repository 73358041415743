import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AmexIcon, MasterCardIcon, VisaIcon } from 'assets/svg/paymentIcons';
import Style from "./ShipmentPayment.module.css"
import { Button } from '@material-ui/core';
import { ShipmentsService, ShipperService } from 'service';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  highlightBox: {
    backgroundColor: '#ecf1f9', //'#f4f7fc', //#edf3fd
    width: '100%',
    marginBottom: '10px',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden'
  }
});

const ShipmentPayment = ({ classes, shipmentId}) => {
  console.log('Inside Bk Details');
  const [openRoute, setOpenRoute] = useState(true);
  const [anchorElMenu2, setAnchorElMenu2] = useState(null);

  const toggleRouteDetail = () => {
    setOpenRoute(!openRoute);
  };

  const handleClickMenu2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorElMenu2(null);
  };

  const [shipmentStatus, setShipmentStatus] = useState("")
  const [stepsCleared, setStepsCleared] = useState(null)

  // useEffect(() => {
  //   ShipperService.fetchShipmentStatus(shipmentId).then(data => {
  //     if (data.error) { console.log(data.error) }
  //     else {
  //       setShipmentStatus(data.status)
  //       setStepsCleared(data.steps_cleared)
  //     }
  //   })


  // }, [refresh, shipmentId])
  // console.log("Payment", booking)

  const history = useHistory()

  // const handlePayNow = () => {
  //   if (shipmentStatus === "Incomplete") {
  //     if (stepsCleared === "2") {
  //       history.push(`/freight/quote/payment/${shipmentId}`);
  //     }
  //     if (stepsCleared === "1") {
  //       history.push(`/freight/quote/enter-shipment-details/${shipmentId}`);
  //     }
  //   }
  //   else {
  //     history.push(`/shipment/pay-due-amount/${shipmentId}`)
  //   }
  // }

  // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const auth = useSelector((state) => state.session.auth)

  const [paymentInfo, setPaymentInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    const data_to_send = {
      shipper_id: auth.accountId,
      shipment_id: shipmentId
    }
    ShipmentsService.fetchShipmentPayments(data_to_send, auth.token).then(res => {
      if (res.error) {
        console.log(res.error)
      } else {
        setPaymentInfo(res)
      }
      setLoading(false)
    })
  }, [])





  return (
    <div className={`${classes.root} ${Style.container}`}>


      {/* <Button
        disabled={booking.due_amount === 0}
        className={`${Style.request_change_btn} btn-primary px-3 py-2 font-size-xs mb-4`}
        onClick={() => setConfirmDialogOpen(true)}
      >
        Pay Now
      </Button> */}


      {/* <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={handlePayNow}
        question={`Are you sure you want to accept this request`}
      /> */}




      {/* Invoice Summary Block */}
      {/* <div className="mb-3 px-2 d-flex justify-content-between">
        <span className={`font-size-md font-weight-bold mb-0`}>
          INV #97710037
        </span>
        <small className="text-black-50">Due: 07/12/2022</small>
      </div> */}











      {/* top card  */}
      {/* <div className="bg-serious-blue rounded d-flex justify-content-between p-4">
        <div className="p-2">
          <div>
            <span className=" text-white-50 font-size-xs text-uppercase mb-2">
              Amount Paid
            </span>
          </div>
          <span className={`${Style.amount_heading} font-weight-bold mb-0 display-3`}>
            <small className="pr-1 text-white">$</small>
            <span className="text-white">{paymentInfo?.amount_info?.amount_paid}</span>
          </span>

        </div>
        <div className="p-2">
          <div>
            <span className=" text-white-50 font-size-xs text-uppercase mb-2">
              Amount Due
            </span>
          </div>
          <span className={`${Style.amount_heading} font-weight-bold mb-0 display-3`}>
            <small className="pr-1 text-white">$</small>
            <span className="text-white">{paymentInfo?.amount_info?.due_amount}</span>
          </span>
        </div>
        <div className={`${Style.badge} badge badge-success px-3`}>Completed</div>
      </div> */}

















      {/* Shipment Charges Details Block */}
      {/* <div className="text-black-50 font-weight-bold mb-2 mt-4 px-2">
        <span className="" style={{ fontSize: '0.875rem' }}>Shipment Charges</span>
      </div>



      <div className={clsx('my-3 p-3', classes.highlightBox)}>


        {paymentInfo?.amount_info?.shipment_charges_breakdown.length > 0 &&
          <>

            {paymentInfo?.amount_info?.shipment_charges_breakdown.map((item) => (
              <>
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <div className="font-size-sm">
                      <span>{item?.label}</span>
                    </div>
                    <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                      <small>Qty {item?.quantity} x ${item?.unit_price} ({item?.cargoType})</small>
                    </div>
                  </div>
                  <div className="ml-auto text-right">
                    <div className="text-black d-block font-size-sm">
                      <span>${item?.sub_total}</span>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        }


        <div className="divider my-3 bg-dark " /> */}


        {/* <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Subtotal</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${booking.shipment_charges.sub_total}</span>
            </div>
          </div>
        </div>


        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm">
              <span>Tax</span>
              <small className="ml-2 text-black-50">(18%)</small>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="text-black d-block font-size-sm">
              <span>${booking.shipment_charges.tax}</span>
            </div>
          </div>
        </div>


        <div className="divider my-3 bg-dark " /> */}

        {/* <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Total</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${paymentInfo?.amount_info?.rate_sheet_total}</span>
            </div>
          </div>
        </div>

      </div> */}










      {/* Additional Service Charges */}
      {/* {paymentInfo?.amount_info?.service_charges_array?.length > 0 &&
        <>
          <div className="text-black-50 font-weight-bold mb-2 mt-4 px-2">
            <span className="" style={{ fontSize: '0.875rem' }}>Service Charges</span>
          </div>



          <div className={clsx('my-3 p-3', classes.highlightBox)}>


            {paymentInfo?.amount_info?.service_charges_array?.map((data) => (
              <>
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <div className="font-size-sm">
                      <span>{data?.label}</span>
                    </div>
                  </div>
                  <div className="ml-auto text-right">
                    <div className="text-black d-block font-size-sm">
                      <span>${data?.amount?.amount}</span>
                    </div>
                  </div>
                </div>
              </>
            ))} */}

            {/* <div className="divider my-3 bg-dark " />
            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm font-weight-bold">
                  <span>Subtotal</span>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="font-weight-bold text-black d-block font-size-sm">
                  <span>${booking.services.sub_total}</span>
                </div>
              </div>
            </div> */}


            {/* <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm">
                  <span>Tax</span>
                  <small className="ml-2 text-black-50">(18%)</small>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="text-black d-block font-size-sm">
                  <span>${booking.services.tax}</span>
                </div>
              </div>
            </div> */}

{/* 
            <div className="divider my-3 bg-dark " />



            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm font-weight-bold">
                  <span>Total</span>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="font-weight-bold text-black d-block font-size-sm">
                  <span>${paymentInfo?.amount_info?.services_total}</span>
                </div>
              </div>
            </div>

          </div>


        </>
      } */}


























      {/* Additional Addons Charges */}

      {/* {booking.additional_charges.array.length > 0 &&
        <>

          <div className="text-black-50 font-weight-bold mb-2 mt-4 px-2">
            <span className="" style={{ fontSize: '0.875rem' }}>Additional Addon Charges</span>
          </div>



          <div className={clsx('my-3 p-3', classes.highlightBox)}>


            {booking.additional_charges.array.map((data) => (
              <>
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <div className="font-size-sm">
                      <span>{data.description}</span>
                    </div>
                  </div>
                  <div className="ml-auto text-right">
                    <div className="text-black d-block font-size-sm">
                      <span>${data.value}</span>
                    </div>
                  </div>
                </div>
              </>
            ))}

            <div className="divider my-3 bg-dark " />
            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm font-weight-bold">
                  <span>Subtotal</span>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="font-weight-bold text-black d-block font-size-sm">
                  <span>${booking.additional_charges.sub_total}</span>
                </div>
              </div>
            </div>


            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm">
                  <span>Tax</span>
                  <small className="ml-2 text-black-50">(18%)</small>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="text-black d-block font-size-sm">
                  <span>${booking.additional_charges.tax}</span>
                </div>
              </div>
            </div>


            <div className="divider my-3 bg-dark " />



            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm font-weight-bold">
                  <span>Total</span>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="font-weight-bold text-black d-block font-size-sm">
                  <span>${booking.additional_charges.total}</span>
                </div>
              </div>
            </div>

          </div>


        </>



      } */}
















































      {/* <div className={clsx('my-3 p-3', classes.highlightBox)}>


        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Platform Fee</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${booking?.platform_fee}</span>
            </div>
          </div>
        </div>

      </div> */}


    </div>
  );
};

ShipmentPayment.defaultProps = {};

ShipmentPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentPayment);
