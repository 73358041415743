import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import DomainIcon from '@mui/icons-material/Domain';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import Style from "./ProgressStepper.module.css";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: '15px',
        left: '-50%',
        right: '50%',
        '@media (max-width: 700px)': {
            top: '7.5px'
        },
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(59,201,99,1) 64%);',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(59,201,99,1) 64%);'
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        '@media (max-width: 700px)': {
            height: 1
        },
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: '30px',
    height: '30px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (max-width: 700px)': {
        width: '15px',
        height: '15px',
    },

    ...(ownerState.error && {
        backgroundImage:
            'linear-gradient(90deg, rgba(248,50,69,1) 0%, rgba(248,50,69,1) 64%);',
    }),
    ...(ownerState.active && !ownerState.error && {
        backgroundImage:
            'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(52,168,85,1) 64%);',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && !ownerState.active && !ownerState.error && {
        backgroundImage:
            'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(52,168,85,1) 64%);',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, error } = props;
    const icons = {
        1: <DomainIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
        2: <PrecisionManufacturingOutlinedIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
        3: <PrecisionManufacturingOutlinedIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
        4: <PrecisionManufacturingOutlinedIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
        5: <PrecisionManufacturingOutlinedIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
        6: <HomeOutlinedIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, error }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * Mark the step as error. Is passed to child components.
     * @default false
     */
    error: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


export default function ProgressStepper({
    currentStep,
    isError = false,
    source = '',
    destination = '',
    present = ''
}) {

    const steps = [source, '', '', '', destination];

    return (
        <>
            {present && present !== '' &&
                <div className={`${Style.present_container}`}>
                    <span style={{ color: 'var(--primary-text)', fontSize: 'clamp(10px, 1.5vw, 14px)' }} >{present}</span>
                </div>
            }
            <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    const stepProps = {}
                    if (index === currentStep && isError) {
                        stepProps.error = true;
                    }
                    return (
                        <Step key={label} >
                            <StepLabel
                                {...stepProps}
                                StepIconComponent={ColorlibStepIcon}
                            >
                                <span style={{ color: 'var(--primary-text)', fontSize: 'clamp(10px, 1.5vw, 14px)' }} >{label}</span>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </>

    );
}
