import React from 'react';
import { withStyles } from '@material-ui/styles';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, map} from 'react-leaflet';
import { Icon } from "leaflet";
// import marker from "./pin.svg";
import marker from "../../assets/svg/location/packageTracking.svg";
import { default as bezierSpline } from "@turf/bezier-spline";
import * as helpers from "@turf/helpers";

const myIcon = new Icon({
  iconUrl: marker,
  iconSize: [25, 25]
});

const styles = (theme) => ({});

const LeafletMap = ({ classes, height, width, zoom = 0, midPoint = [], positions=[]  }) => {
  
  const line = positions.length > 0 && midPoint.length>0 && helpers.lineString([
    [positions[0]?.geolocation?.latitude, positions[0]?.geolocation?.longitude],
    [midPoint[0], midPoint[1]],
    [positions[positions.length - 1]?.geolocation?.latitude, positions[positions.length - 1]?.geolocation?.longitude],
  ].map(latLng => [latLng[1],latLng[0]]));

  

  const curved = positions.length > 0 && midPoint.length>0 && bezierSpline(line);

  return (
    <>
      <MapContainer
        style={{ minHeight: height, height: height, minWidth: width,zIndex:0 }}
        center={midPoint.length > 0 ? midPoint: [0,0]}
        // center={position}
        minZoom={0}
        zoom={zoom}
        className="rounded"
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {positions.length > 0 && positions?.map((item, index) => (
          <Marker key={index} position={[item?.geolocation?.latitude, item?.geolocation?.longitude]} icon={myIcon}>
            <Popup>{`${item?.city}, ${item?.state},  ${item?.country}`} <br/><strong>{item?.unlocode}</strong></Popup>
          </Marker>

        ))}

        {positions.length > 0 && midPoint.length>0 && <GeoJSON data={curved} style={{
              color: '#ff0000', // Adjust color as needed
              weight: 1, // Adjust width as needed
              dashArray: '5, 10' // Adjust the dash pattern as needed
            }}/>}
      
        
      </MapContainer>
    </>
  );
};

export default withStyles(styles)(LeafletMap);
