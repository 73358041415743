import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import sessionStorage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  blacklist: ['signup', 'ThemeOptions', 'messages', 'billing']
};

let middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
} else {
  middleware = [...middleware];
}

export default function configureStore() {
  return createStore(persistedReducer, compose(applyMiddleware(...middleware)));
}
