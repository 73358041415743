
import { Button } from "@mui/material";
import DetailTable from "./DetailTable/DetailTable";
import Style from "./InnerTab.module.css";
import { Add, Edit } from "@mui/icons-material";
import { useState } from "react";
import ViewBreakdown from "../../ViewBreakdown/ViewBreakdown";
import PriceHistoryChart from "../chart/Chart";
import RateDetailCard from "../RateDetailCard/RateDetailCard";

/* eslint-disable */

const InnerTab = ({ name, data, data_id, run, setRun }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const toggleBreakdown = (bool) => {
        setIsOpen(bool)
    }

    const toggleBreakdownEdit = (bool) => {
        setIsOpenEdit(bool)
    }

    const calculateTotal = (item) => {
        let sum = 0;
        item?.map(data => {
            data.data.map(amount_data => sum += Number(amount_data.amount))
        })
        return sum
    }


    const ongoing_price = {
        effective_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.effective_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.effective_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.effective_date.split("T")[0],



        expiry_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.expiry_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.expiry_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.expiry_date.split("T")[0],

        amount: calculateTotal(data?.load?.fcl[name][(data?.load.fcl[name]).length - 1]?.price_breakdown)
    }

    const past_price = {
        effective_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.effective_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.effective_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.effective_date.split("T")[0],



        expiry_date: (data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.expiry_date === null ||
            data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.expiry_date === undefined)
            ? "NA" : data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.expiry_date.split("T")[0],

        amount: calculateTotal(data?.load?.fcl[name][(data?.load.fcl[name]).length - 2]?.price_breakdown)
    }

    return (
        <div className={Style.container}>
            <RateDetailCard
                data={{
                    ongoing_price: ongoing_price,
                    past_price: past_price,
                }}
                onEdit={() => toggleBreakdownEdit(true)}
            />
            <div className={Style.main}>

                <DetailTable
                    condition="Current"
                    price={ongoing_price.amount}
                    effective_date={ongoing_price.effective_date}
                    expiry_date={ongoing_price.expiry_date}
                />
                <DetailTable
                    condition="Old"
                    price={past_price.amount}
                    effective_date={past_price.effective_date}
                    expiry_date={past_price.expiry_date}
                />

                <div className={Style.btn_container}>
                    {(data?.load.fcl[name]).length > 0 &&
                        <Button
                            onClick={() => toggleBreakdownEdit(true)}
                            className={Style.btn}
                            variant="contained"
                            startIcon={<Edit sx={{ color: '#fff !important', fontSize: '15px !important' }} />}
                        >
                            Edit
                        </Button>
                    }
                    <Button
                        onClick={() => toggleBreakdown(true)}
                        className={Style.btn}
                        variant="contained"
                        startIcon={<Add sx={{ color: '#fff !important', fontSize: '15px !important' }} />}
                    >
                        Add
                    </Button>
                </div>
            </div>
            {/* chart  */}
            <div>
                <PriceHistoryChart calculateTotal={calculateTotal} name={name} graphData={data?.load?.fcl[name].slice(-10)} />
            </div>

            {/* breakdown  */}
            <ViewBreakdown
                data={data}
                run={run}
                setRun={setRun}
                data_id={data_id}
                name={name}
                open={isOpen}
                handleClose={() => toggleBreakdown(false)}
            />
            {isOpenEdit &&
                <ViewBreakdown
                    run={run}
                    setRun={setRun}
                    method={"edit"}
                    data={data}
                    data_id={data_id}
                    name={name}
                    open={isOpenEdit}
                    handleClose={() => toggleBreakdownEdit(false)}
                />
            }
        </div>
    )
}
/* eslint-enable */
export default InnerTab