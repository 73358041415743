import React from 'react'
import Style from './BoxContainer.module.css'
import classNames from 'classnames'

const BoxContainer = ({ children,classes }) => {

    const CardClass = classNames(Style.card, classes);

    return (
        <div className={CardClass}>{children}</div>
    )
}

export default BoxContainer