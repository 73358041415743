import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class SecurityService {
  static async fetchCookiesData(id, token) {
    const url = `${getBaseUrl()}forwarder/fetch-cookies-data/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'security_cookies_settings',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchPrivacyData(id, token) {
    const url = `${getBaseUrl()}forwarder/fetch-privacy-data/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'security_privacy_settings',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }

  static async manageCookies(data, token) {
    const url = `${getBaseUrl()}forwarder/update-cookies`;
    const result = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        type: 'security_cookies_settings',
        method: 'edit',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const response = await result.json();
    return response;
  }

  static async managePrivacy(data, token) {
    const url = `${getBaseUrl()}forwarder/update-privacy`;
    const result = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        type: 'security_privacy_settings',
        method: 'edit',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const response = await result.json();
    return response;
  }

  static async fetchLoginHistory(id, token) {
    const url = `${getBaseUrl()}forwarder/fetch-login-history?id=${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async deactivateAccount(data, token) {
    const url = `${getBaseUrl()}forwarder/deactivate-account`;
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        type: 'security_account_settings',
        method: 'delete',
        platform: 'Forwarder'
      },
      body: JSON.stringify(data)
    });
    const response = await result.json();
    return response;
  }

  static async checkDeactivateAccountShipments(id, token) {
    const url = `${getBaseUrl()}forwarder/check-deactivate-account-shipmets/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });
    const response = await result.json();
    return response;
  }
}
