export const InboxGuide = [
    {
        heading:"Chat with Shippers",
        icon:"",
        description: "Communicate Seamlessly with Shippers to Resolve Queries Efficiently.",
        content: [
            "Utilize the chat feature to communicate directly with shippers, addressing their questions and providing essential information. This tool enhances collaboration, ensuring clear and timely communication between forwarders and shippers for smoother operations."
        ]
    },
    {
        heading:"Send Documents and Share Files with Shippers",
        icon:"",
        description:"Streamline Document Sharing for Efficient Freight Forwarding",
        content: [
            "Forwarders and shippers can easily share important documents through the platform. This functionality ensures that all necessary paperwork, such as invoices, bills of lading, and inspection reports, is exchanged promptly and securely, facilitating a seamless freight forwarding process."
        ]
    },
    {
        heading:"Check a Summary of Shipment Details",
        icon:"",
        description:"Gain Quick Insights with Organized Shipment Summaries and Chat Threads",
        content: [
            "The platform allows you to view a concise summary of each shipment, including the route and relevant communication history. For example, if you have shipments with a shipper named Alex from Toronto to Montreal and another from Toronto to Shanghai, you will have separate chat threads for each route. This approach prevents confusion and ensures that all discussions and information remain organized and specific to each shipment, reducing the risk of misunderstandings and improving overall efficiency."
        ]
    },
   
]