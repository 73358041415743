import React, { useEffect, useRef } from 'react'
import Style from "./StatusPopup.module.css";
import StatusStepper from '../StatusStepper/StatusStepper';
import { Button } from '@material-ui/core';


const StatusPopup = ({ shipmentStatus, run, setRun, shippmentId,onClose }) => {

    // const popupRef = useRef();

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (popupRef.current && !popupRef.current.contains(event.target)) {
    //             onClose(); // Call the onClose prop to close the popup
    //         }
    //     };

    //     if (open) {
    //         document.addEventListener('click', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, [open]);

    return (
        <>
            {/* <div
                ref={popupRef}
                className={`${Style.container} ${!open && Style.collapse}`}
            > */}
            <div className={Style.main}>
                <center>
                    <Button className='btn-primary btn-pill font-size-xs px-3 py-1 font-size-xs shadow-none'>
                        Shipment Status
                    </Button>
                </center>
                <StatusStepper shippmentId={shippmentId} shipmentStatus={shipmentStatus} run={run} setRun={setRun} onClose={onClose} />
            </div>
            {/* </div> */}
        </>
    )
}

export default StatusPopup