import React from 'react'
import { ScaleLoader } from 'react-spinners';

const LoaderComponent = ({ loading }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                zIndex: "9999",
                backgroundColor: "rgba(0,0,0,0.2)",
                minWidth: "100vw",
                minHeight: "100vh",
                top: "0px",
                left: "0px"
            }}>
            <ScaleLoader color={'#793de6'} loading={loading} />
        </div>
    )
}

export default LoaderComponent