import { ColeLogo, QuickaLogo, InterlinkLogo } from 'assets/custombrokerLogos';

export const CUSTOM_BROKER_LIST = {
  BROKER1: {
    id: '1',
    shortName: 'ColeIntl',
    fullName: 'Cole International Inc.',
    address: {
      suite: null,
      streetNumber: '1111',
      streetAddress: '49 Avenue NE',
      addressLocality: 'Calgary',
      addressRegion: 'Alberta',
      postalCode: 'T2E 8V2',
      addressCountry: { label: 'Canada', value: 'CA' }
    },
    telephone: '1-800-313-4281',
    email: 'adam@coleintl.com',
    website: 'https://coleintl.com',
    logo: ColeLogo
  },
  BROKER2: {
    id: '2',
    shortName: 'Quicka',
    fullName: 'Quicka Clearing & Forwarding',
    address: {
      suite: null,
      streetNumber: '99',
      streetAddress: 'Rivoc Road',
      addressLocality: 'Port Harcourt',
      addressRegion: 'Rivers State',
      postalCode: null,
      addressCountry: { label: 'Nigeria', value: 'NG' }
    },
    telephone: '+234-808-8100000 ',
    email: 'inquiries@quickaltd.com',
    website: 'https://quickaltd.com',
    logo: QuickaLogo
  },
  BROKER3: {
    id: '3',
    shortName: 'Interlink',
    fullName: 'Interlink Freight Agency LLC',
    address: {
      suite: null,
      streetNumber: null,
      streetAddress: 'International Airport',
      addressLocality: 'Cargo Village Car Park',
      addressRegion: 'Dubai',
      postalCode: null,
      addressCountry: { label: 'United Arab Emirates', value: 'AE' }
    },
    telephone: '+971-55-7910800',
    email: 'info@interlinkgulf.com',
    website: 'https://interlinkgulf.com',
    logo: InterlinkLogo
  }
};
