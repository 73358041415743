export default class DocumentModel {
  static createDocObjToDeleteFromTrip = (inTripId, inDestId) => {
    return {
      inTripId,
      inDestId
    };
  };

  static createDocObjRequest = (draw, columns, order, keyword, titleOnly, start, search, length, destType, tripId) => {
    return {
      draw,
      columns,
      order,
      keyword,
      titleOnly,
      start,
      search,
      length,
      destType,
      tripId
    };
  };
}
