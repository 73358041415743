import React, { useState } from 'react';
import Style from "./DetailRow.module.css";
import { IconButton, OutlinedInput } from '@mui/material';

import { Check, Edit } from '@material-ui/icons';

const DetailRow = ({ label, value, labelHelper, editable = false, handleChange }) => {

    const [isEditOn, setIsEditOn] = useState(false);

    return (
        <div className="mb-1" style={{ display: 'grid', gridTemplateColumns: '1.2fr 0.8fr', gap: '0.5rem' }}>
            <span className={`${Style.res_font_size} font-weight-bold text-uppercase text-black-50`}>
                {label}
                {' '}<span className={`text-primary`} style={{ fontSize: '9px', textTransform: 'none' }}>{labelHelper}</span>
            </span>
            <span className={`${Style.res_font_size}`}>
                {editable ?
                    <div className='d-flex align-items-center c-gap-1'>
                        {isEditOn
                            ?
                            <OutlinedInput
                                type='date'
                                // value={value}
                                onChange={handleChange}
                                // style={{ width: '80px' }}
                                size='small'
                                inputProps={{ style: { padding: '4px 9px', fontSize: '9px' } }}
                            />

                            :
                            <>
                                {value}
                            </>
                        }
                        <IconButton
                            className='btn-neutral-primary icon-btn'
                            style={{ width: '11px', height: '11px' }}
                            onClick={() => setIsEditOn(state => !state)}
                        >
                            {isEditOn ? <Check style={{ fontSize: '10px' }} /> : <Edit style={{ fontSize: '10px' }} />}
                        </IconButton>

                    </div>
                    :
                    <>
                        {value === "Pending" ?
                            <i>{value}</i>
                            :
                            <>
                                {value}
                            </>
                        }
                    </>
                }
            </span>
        </div>
    )
}

export default DetailRow