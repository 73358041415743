import * as moment from 'moment';

export default class ActivityModel {
  /**
   *
   * @param fn
   * @returns {function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): Function}
   */
  static activityFormat = fn => {
    return a => {
      return b => {
        return c => {
          return d => {
            return e => {
              return f => {
                return g => {
                  return h => {
                    return i => {
                      return j => {
                        return k => {
                          return l => fn(a, b, c, d, e, f, g, h, i, j, k, l);
                        };
                      };
                    };
                  };
                };
              };
            };
          };
        };
      };
    };
  };

  /**
   *
   * @param type
   * @returns {*}
   */
  static getReservationTypeFormat = type => {
    if (type.includes('Event')) {
      return ActivityModel.getEventFormat;
    } else if (type.includes('FoodEstablishment')) {
      return ActivityModel.getRestaurantFormat;
    } else {
      return ActivityModel.getGeneralFormat;
    }
  };

  /**
   *
   * @param activityType
   * @param name
   * @param umId
   * @param startTime
   * @param startDate
   * @param finishTime
   * @param finishDate
   * @param startLocation
   * @param finishLocation
   * @param reservationNumber
   * @param activityTitle
   * @param crossDate
   * @returns {{'@type': string, name: *, reservationFor: {name: *, umId: *}, startLocation: {name: *}, finishLocation: {name: *}, reservationNumber: *, crossDate: *}}
   */
  static getGeneralFormat = (
    activityType,
    name,
    umId,
    startTime,
    startDate,
    finishTime,
    finishDate,
    startLocation,
    finishLocation,
    reservationNumber,
    activityTitle,
    crossDate
  ) => {
    const transformedType = activityType === 'SkiLiftTickets' ? activityType : `${activityType}Reservation`;
    const startDateConverted = startDate ? `${startDate.format('MM/DD/YYYY')} ${startTime}` : null;
    const finishDateConverted = finishDate ? `${finishDate.format('MM/DD/YYYY')} ${finishTime}` : null;

    let activityObj = {
      '@type': transformedType,
      name,
      reservationFor: {
        organizedBy: {
          name: activityTitle !== '' ? activityTitle : name,
          umId
        }
      },
      startLocation: {
        name: startLocation
      },
      finishLocation: {
        name: finishLocation
      },
      reservationNumber,
      crossDate
    };

    if (activityTitle !== '') {
      activityObj.serviceType = activityTitle;
    }

    if (startTime !== '') {
      activityObj.startTime = {
        value: moment.utc(startDateConverted, 'MM/DD/YYYY HH:mm').format()
      };
    }

    if (finishDateConverted) {
      activityObj.finishTime = {
        value: moment.utc(finishDateConverted, 'MM/DD/YYYY HH:mm').format()
      };
    }

    return activityObj;
  };

  /**
   *
   * @param activityType
   * @param name
   * @param umId
   * @param startTime
   * @param startDate
   * @param finishTime
   * @param finishDate
   * @param startLocation
   * @param finishLocation
   * @param reservationNumber
   * @param activityTitle
   * @param crossDate
   * @returns {{'@type': string, name: *, reservationFor: {activityType: *, startDate: {value: moment.Moment}, endDate: {value: moment.Moment}, location: {name: *}, organizer: {name: *}}, reservationNumber: *, finishLocation: {name: *}, crossDate: *}}
   */
  static getEventFormat = (
    activityType,
    name,
    umId,
    startTime,
    startDate,
    finishTime,
    finishDate,
    startLocation,
    finishLocation,
    reservationNumber,
    activityTitle,
    crossDate
  ) => {
    const startDateConverted = `${startDate.format('MM/DD/YYYY')} ${startTime}`;
    const finishDateConverted = finishDate ? `${finishDate.format('MM/DD/YYYY')} ${finishTime}` : null;
    let eventObj = {
      '@type': `${activityType}Reservation`,
      name,
      reservationFor: {
        activityType,
        startDate: {
          value: moment.utc(startDateConverted, 'MM/DD/YYYY HH:mm').format()
        },
        location: {
          name: startLocation
        },
        organizer: {
          name: activityTitle !== '' ? activityTitle : name
        }
      },
      reservationNumber,
      finishLocation: {
        name: finishLocation
      },
      crossDate
    };

    if (finishDateConverted) {
      eventObj.endDate = {
        value: moment.utc(finishDateConverted, 'MM/DD/YYYY HH:mm').format()
      };
    }

    if (activityTitle !== '') {
      eventObj.serviceType = activityTitle;
    }

    if (umId !== '') {
      eventObj.reservationFor.organizer.umId = umId;
    }

    return eventObj;
  };

  /**
   *
   * @param activityType
   * @param name
   * @param umId
   * @param startTime
   * @param startDate
   * @param finishTime
   * @param finishDate
   * @param startLocation
   * @param finishLocation
   * @param reservationNumber
   * @param activityTitle
   * @param crossDate
   * @returns {{'@type': string, name: *, reservationFor: {name: *, activityType: *}, reservationNumber: *, location: {name: *}, finishLocation: {name: string}, crossDate: *}}
   */
  static getRestaurantFormat = (
    activityType,
    name,
    umId,
    startTime,
    startDate,
    finishTime,
    finishDate,
    startLocation,
    finishLocation,
    reservationNumber,
    activityTitle,
    crossDate
  ) => {
    const startDateConverted = `${startDate.format('MM/DD/YYYY')} ${startTime}`;
    const finishDateConverted = finishDate && finishTime ? `${finishDate.format('MM/DD/YYYY')} ${finishTime}` : null;
    let restaurantObj = {
      '@type': `${activityType}Reservation`,
      name,
      reservationFor: {
        name: activityTitle !== '' ? activityTitle : name,
        activityType
      },
      reservationNumber,
      location: {
        name: startLocation
      },
      finishLocation: {
        name: finishLocation !== '' ? finishLocation : ''
      },
      crossDate
    };

    if (activityTitle !== '') {
      restaurantObj.serviceType = activityTitle;
    }

    if (startTime !== '') {
      restaurantObj.startTime = {
        value: moment.utc(startDateConverted, 'MM/DD/YYYY HH:mm').format()
      };
    }

    if (finishDateConverted) {
      restaurantObj.finishTime = {
        value: moment.utc(finishDateConverted, 'MM/DD/YYYY HH:mm').format()
      };
    }

    if (umId !== '') {
      restaurantObj.reservationFor.umId = umId;
    }

    return restaurantObj;
  };

  /**
   *
   * @param umTripId
   * @returns {function(*): {umTripId: *}}
   */
  static setTripId = umTripId => reservation => ({
    ...reservation,
    umTripId
  });

  /**
   *
   * @param fn
   * @returns {function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): function(*=): *}
   */
  static updatedActivityFormat = fn => {
    return a => {
      return b => {
        return c => {
          return d => {
            return e => {
              return f => {
                return g => {
                  return h => {
                    return i => {
                      return j => {
                        return k => {
                          return l => {
                            return m => fn(a, b, c, d, e, f, g, h, i, j, k, l, m);
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          };
        };
      };
    };
  };

  /**
   *
   * @param type
   * @returns {*}
   */
  static getUpdatedReservationTypeFormat = type => {
    if (type.includes('Event')) {
      return ActivityModel.getUpdatedEventFormat;
    } else if (type.includes('FoodEstablishment')) {
      return ActivityModel.getUpdatedRestaurantFormat;
    } else {
      return ActivityModel.getUpdatedGeneralFormat;
    }
  };

  /**
   *
   * @param activityType
   * @param name
   * @param nameUmId
   * @param umId
   * @param startDate
   * @param endDate
   * @param startLocation
   * @param finishLocation
   * @param reservationNumber
   * @param activityTitle
   * @param contactName
   * @param crossDate
   * @param address
   * @returns {{'@type': string, name: *, umId: *, reservationFor: {location: {}, startDate: *, endDate: *, organizer: {address: *}}, reservationNumber: *, serviceType: *, crossDate: *}}
   */
  static getUpdatedEventFormat = (
    activityType,
    name,
    nameUmId,
    umId,
    startDate,
    endDate,
    startLocation,
    finishLocation,
    reservationNumber,
    activityTitle,
    contactName,
    crossDate,
    address
  ) => {
    let eventObj = {
      '@type': `${activityType}Reservation`,
      name,
      umId,
      reservationFor: {
        location: {},
        startDate,
        endDate,
        organizer: {
          address
        }
      },
      reservationNumber,
      serviceType: activityTitle,
      crossDate
    };

    if (startLocation !== '') {
      eventObj.reservationFor.location.name = startLocation;
    }

    if (contactName !== '') {
      eventObj.reservationFor.organizer.name = contactName ? contactName : name;
    }

    if (nameUmId !== '') {
      eventObj.reservationFor.organizer.umId = nameUmId;
    }

    return eventObj;
  };

  /**
   *
   * @param activityType
   * @param name
   * @param nameUmId
   * @param umId
   * @param startTime
   * @param finishTime
   * @param startLocation
   * @param finishLocation
   * @param reservationNumber
   * @param activityTitle
   * @param contactName
   * @param crossDate
   * @param address
   * @returns {{'@type': string, name: *, umId: *, provider: {}, reservationFor: {address: *}, startTime: *, endTime: *, reservationNumber: *, location: {}, serviceType: *, crossDate: *}}
   */
  static getUpdatedRestaurantFormat = (
    activityType,
    name,
    nameUmId,
    umId,
    startTime,
    finishTime,
    startLocation,
    finishLocation,
    reservationNumber,
    activityTitle,
    contactName,
    crossDate,
    address
  ) => {
    let restaurantObj = {
      '@type': `${activityType}Reservation`,
      name,
      umId,
      provider: {},
      reservationFor: {
        address
      },
      startTime,
      endTime: startTime,
      reservationNumber,
      location: {},
      serviceType: activityTitle,
      crossDate
    };

    if (contactName !== '') {
      restaurantObj.provider.name = contactName;
    }

    if (nameUmId !== '') {
      restaurantObj.reservationFor.umId = nameUmId;
    }

    if (activityTitle !== '') {
      restaurantObj.reservationFor.serviceType = activityTitle;
    }

    if (startLocation !== '') {
      restaurantObj.location.name = startLocation;
    }

    return restaurantObj;
  };

  /**
   *
   * @param activityType
   * @param name
   * @param nameUmId
   * @param umId
   * @param startTime
   * @param finishTime
   * @param startLocation
   * @param finishLocation
   * @param reservationNumber
   * @param activityTitle
   * @param contactName
   * @param crossDate
   * @param address
   * @returns {{'@type': string, name: *, umId: *, reservationFor: {organizedBy: {address: *}}, startTime: {value: *}, finishTime: {value: *}, startLocation: {}, finishLocation: {}, reservationNumber: *, serviceType: *, crossDate: *}}
   */
  static getUpdatedGeneralFormat = (
    activityType,
    name,
    nameUmId,
    umId,
    startTime,
    finishTime,
    startLocation,
    finishLocation,
    reservationNumber,
    activityTitle,
    contactName,
    crossDate,
    address
  ) => {
    const transformedType = activityType === 'SkiLiftTickets' ? activityType : `${activityType}Reservation`;
    let activityObj = {
      '@type': transformedType,
      name,
      umId,
      reservationFor: {
        organizedBy: {
          address
        }
      },
      startTime: {
        value: startTime
      },
      startLocation: {},
      finishLocation: {},
      reservationNumber,
      serviceType: activityTitle,
      crossDate
    };

    if (finishTime) {
      activityObj.finishTime = {
        value: finishTime
      };
    } else {
      activityObj.finishTime = null;
    }

    if (contactName !== '') {
      activityObj.reservationFor.organizedBy.name = contactName;
    }

    if (nameUmId !== '') {
      activityObj.reservationFor.organizedBy.umId = nameUmId;
    }

    if (startLocation !== '') {
      activityObj.startLocation.name = startLocation;
    }

    if (finishLocation !== '') {
      activityObj.finishLocation.name = finishLocation;
    }

    return activityObj;
  };

  static getAddress = (reservation, type) => {
    if (type.includes('Event')) {
      return reservation.item.reservationFor.organizer && reservation.item.reservationFor.organizer.address
        ? reservation.item.reservationFor.organizer.address
        : '';
    } else if (type.includes('FoodEstablishment')) {
      return reservation.item.reservationFor.address ? reservation.item.reservationFor.address : '';
    } else {
      return reservation.item.reservationFor.organizedBy && reservation.item.reservationFor.organizedBy.address
        ? reservation.item.reservationFor.organizedBy.address
        : '';
    }
  };

  /**
   *
   * @param reservation
   * @param type
   * @returns {string}
   */
  static getOrganizerUmId = (reservation, type) => {
    if (type.includes('Event')) {
      return reservation.item.reservationFor.organizer && reservation.item.reservationFor.organizer.umId
        ? reservation.item.reservationFor.organizer.umId
        : '';
    } else if (type.includes('FoodEstablishment')) {
      return reservation.item.reservationFor.umId ? reservation.item.reservationFor.umId : '';
    } else {
      return reservation.item.reservationFor.organizedBy && reservation.item.reservationFor.organizedBy.umId
        ? reservation.item.reservationFor.organizedBy.umId
        : '';
    }
  };

  /**
   *
   * @param reservation
   * @returns {function(*): string}
   */
  static getStartTime = reservation => type => {
    let startTime = '';
    if (type.includes('Event')) {
      startTime = reservation.item.reservationFor.startDate;
    } else if (type.includes('Note')) {
      startTime = reservation.item.noteTimestamp;
    } else {
      startTime = reservation.item.startTime;
    }

    return startTime;
  };

  /**
   *
   * @param reservation
   * @returns {function(*): string}
   */
  static getFinishTime = reservation => type => {
    let finishTime = '';
    if (type.includes('FoodEstablishment')) {
      finishTime = reservation.item.startTime;
    } else if (type.includes('Event')) {
      finishTime = reservation.item.reservationFor.endTime;
    } else if (type.includes('Note')) {
      finishTime = reservation.item.noteTimestamp;
    } else {
      finishTime = reservation.item.finishTime ? reservation.item.finishTime : '';
    }

    return finishTime;
  };

  /**
   *
   * @param reservation
   * @returns {function(*): (string|string)}
   */
  static getStartLocation = reservation => type => {
    let startLocation = '';
    if (type.includes('FoodEstablishment')) {
      startLocation = reservation.item.location.name ? reservation.item.location.name : '';
    } else if (type.includes('Event')) {
      startLocation = reservation.item.reservationFor.location.name
        ? reservation.item.reservationFor.location.name
        : '';
    } else if (type.includes('Note')) {
      startLocation = '';
    } else {
      startLocation = reservation.item.startLocation.name ? reservation.item.startLocation.name : '';
    }

    return startLocation;
  };

  /**
   *
   * @param reservation
   * @returns {function(*): (*|string)}
   */
  static getFinishLocation = reservation => type => {
    let finishLocation = '';
    if (type.includes('FoodEstablishment')) {
      finishLocation = reservation.item.location.name;
    } else if (type.includes('Event')) {
      finishLocation = reservation.item.reservationFor.location.name;
    } else if (type.includes('Note')) {
      finishLocation = '';
    } else {
      finishLocation =
        reservation.item.finishLocation && reservation.item.finishLocation.name
          ? reservation.item.finishLocation.name
          : '';
    }

    return finishLocation;
  };

  /**
   *
   * @param reservation
   * @returns {function(*): (string|string)}
   */
  static getContactName = reservation => type => {
    let contactName = '';
    if (type.includes('FoodEstablishment')) {
      contactName = reservation.item.provider && reservation.item.provider.name ? reservation.item.provider.name : '';
    } else if (type.includes('Event')) {
      contactName = reservation.item.reservationFor.organizer.name
        ? reservation.item.reservationFor.organizer.name
        : '';
    } else if (type.includes('Note')) {
      contactName = '';
    } else {
      contactName = reservation.item.reservationFor.organizedBy ? reservation.item.reservationFor.organizedBy.name : '';
    }

    return contactName;
  };

  /**
   *
   * @param type
   * @returns {string}
   */
  static getActivityIcon = type => {
    if (type.includes('FoodEstablishmentReservation')) {
      return 'restaurant_menu';
    } else if (type.includes('CruiseStop')) {
      return 'place';
    } else if (type.includes('Event')) {
      return 'event_note';
    } else if (type.includes('SkiRental')) {
      return 'ac_unit';
    } else if (type.includes('Ski')) {
      return 'ac_unit';
    } else if (type.includes('Note')) {
      return 'note';
    } else if (type.includes('Tour')) {
      return 'public';
    } else {
      return 'star';
    }
  };

  /**
   *
   * @param type
   * @returns {function(*)}
   */
  static getTimeDateAndLocation = type => reservation => {
    let infoObj = {};
    if (type.includes('Event')) {
      infoObj.startDate = reservation.item.reservationFor.startDate;
      infoObj.startTime = reservation.item.reservationFor.startDate;
      infoObj.finishTime = null;
      infoObj.finishDate = null;
      infoObj.startLocation = reservation.item.reservationFor.location
        ? reservation.item.reservationFor.location.name
        : '';
      infoObj.finishLocation = reservation.item.reservationFor.location
        ? reservation.item.reservationFor.location.name
        : '';
    } else if (type.includes('FoodEstablishmentReservation')) {
      infoObj.startTime = reservation.item.startTime;
      infoObj.startDate = reservation.item.startTime;
      infoObj.finishTime = reservation.item.endTime;
      infoObj.finishDate = reservation.item.endTime;
      infoObj.startLocation = reservation.item.location ? reservation.item.location.name : '';
      infoObj.finishLocation = reservation.item.location ? reservation.item.location.name : '';
    } else if (type.includes('CruiseStop')) {
      infoObj.startTime = reservation.item.startTime;
      infoObj.startDate = reservation.item.startTime;
      infoObj.finishTime = reservation.item.finishTime;
      infoObj.finishDate = reservation.item.finishTime;
      infoObj.startLocation =
        reservation.item.startLocation && reservation.item.startLocation.name
          ? reservation.item.startLocation.name
          : '';
      infoObj.finishLocation =
        reservation.item.finishLocation && reservation.item.finishLocation.name
          ? reservation.item.finishLocation.name
          : '';
    } else {
      infoObj.startTime = reservation.item.startTime;
      infoObj.startDate = reservation.item.startTime;
      infoObj.finishTime = reservation.item.finishTime;
      infoObj.finishDate = reservation.item.finishTime;
      infoObj.startLocation =
        reservation.item.startLocation && reservation.item.startLocation.name
          ? reservation.item.startLocation.name
          : '';
      infoObj.finishLocation =
        reservation.item.finishLocation && reservation.item.finishLocation.name
          ? reservation.item.finishLocation.name
          : '';
    }

    return infoObj;
  };

  /**
   *
   * @param type
   */
  static getHeaders = type => {
    let infoObj = {};

    if (type.includes('Event')) {
      infoObj.startHeader = 'time&date';
      infoObj.endHeader = 'restaurantLocation';
    } else if (type.includes('FoodEstablishmentReservation')) {
      infoObj.startHeader = 'time&date';
      infoObj.endHeader = 'restaurantLocation';
    } else if (type.includes('CruiseStop')) {
      infoObj.startHeader = 'arrival';
      infoObj.endHeader = 'departure';
    } else {
      infoObj.startHeader = 'start';
      infoObj.endHeader = 'finish';
    }

    return infoObj;
  };

  static generateActivityReservation = (umTripId, umId, rates, description, cancellationPolicy, pricing, summary) => {
    return {
      '@type': 'ActivityReservation',
      name: summary.name,
      umTripId,
      umId,
      ...pricing,
      ...summary,
      description,
      cancellationPolicy,
      rates
    };
  };
}
