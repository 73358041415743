import { Input } from "antd";
import Style from "./AddRateSheetForm.module.css";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ForwarderService } from "service";
import { notify } from "sites/shipper/components/Toast/toast";
import {useDispatch} from 'react-redux'
/* eslint-disable */
const AddRateSheetForm = ({ method, editData, run, setRun, toggleRightDrawer }) => {

    const dispatch = useDispatch();
    const [userData, setUserData] = useState()
    const init = () => {
        const userInfo = JSON.parse(localStorage.getItem("persist:root"))
        // console.log(userInfo.session);
        setUserData(JSON.parse(userInfo.session).auth)
    }

    useEffect(() => {
        init()
    }, [localStorage.getItem("persist:root")])

    const [data, setData] = useState({
        origin_port_id: "",
        origin_port: "",
        origin_city: "",
        origin_country_code: "",
        destination_port_id: "",
        destination_port: "",
        destination_city: "",
        destination_country_code: ""
    })

    const handleValue = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (method === "edit") {
            setData({
                ...data,
                origin_port_id: editData.origin.port_id,
                origin_port: editData.origin.port,
                origin_city: editData.origin.city,
                origin_country_code: editData.origin.country_code,
                destination_port_id: editData.destination.port_id,
                destination_port: editData.destination.port,
                destination_city: editData.destination.city,
                destination_country_code: editData.destination.country_code
            })
        }
    }, [])
    const [error, setError] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(data)
        // console.log(userData)
        if (
            data.origin_port_id === "" ||
            data.origin_port === "" ||
            data.origin_city === "" ||
            data.origin_country_code === "" ||
            data.destination_port_id === "" ||
            data.destination_port === "" ||
            data.destination_city === "" ||
            data.destination_country_code === ""
        ) {
            setError("All Fields are mandatory")
            return
        }
        if (method === "edit") {
            ForwarderService.editRateSheet(editData._id, data).then(data => {
                if (data.message) {
                    // window.alert(data.message)
                    notify(dispatch, "success", data.message)
                }
                else {
                    // window.alert(data.error)
                    notify(dispatch, "error", data.error)
                }
            })
        } else {
            ForwarderService.createRateSheet(userData.accountId, data).then(data => {
                if (data.message) {
                    // window.alert(data.message)
                    notify(dispatch, "success", data.message)
                }
                else {
                    // window.alert(data.error)
                    notify(dispatch, "error", data.error)
                }
            })
        }
        toggleRightDrawer(false)
        setRun(!run)
    }

    return (
        <div className={Style.container}>
            <form
                className={Style.form_container}
            >
                <legend>Rate Sheet</legend>

                <div className={Style.input_container}>
                    <label htmlFor="origin-port-id" className={Style.label} >Origin Port Id</label>
                    <Input
                        required
                        value={data.origin_port_id}
                        id="origin-port-id"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_port_id"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="origin-port" className={Style.label} >Origin Port</label>
                    <Input
                        required
                        value={data.origin_port}
                        id="origin-port"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_port"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="origin-city" className={Style.label} >Origin City</label>
                    <Input
                        required
                        id="origin-city"
                        value={data.origin_city}
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_city"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="origin-country-code" className={Style.label} >Origin Contry Code</label>
                    <Input
                        required
                        id="origin-country-code"
                        value={data.origin_country_code}
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_country_code"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-port-id" className={Style.label} >Destination Port Id</label>
                    <Input
                        value={data.destination_port_id}
                        required
                        id="destination-port-id"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_port_id"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-port" className={Style.label} >Destination Port</label>
                    <Input
                        value={data.destination_port}
                        required
                        id="destination-port"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_port"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-city" className={Style.label} >Destination City</label>
                    <Input
                        required
                        value={data.destination_city}
                        id="destination-city"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_city"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-country-code" className={Style.label}>Destination Country Code</label>
                    <Input
                        required
                        value={data.destination_country_code}
                        id="destination-country-code"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_country_code"
                    />
                </div>
                {error && <p style={{ fontSize: "12px", color: "red" }}>{error}</p>}
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        style={{ textTransform: 'none', backgroundColor: '#4431bb' }}
                        variant="contained"
                        size="large"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </div>



            </form>
        </div>
    )
}
/* eslint-enable */
export default AddRateSheetForm