import { connect } from 'react-redux';

import ShipmentCardPreview from './ShipmentCardPreview';
import { setActiveReservation } from 'actions/reservations';
import { ACTIVE_SHIPMENTS } from '../ActiveShipments/MockData';

const mapStateToProps = (state) => ({
  info: state.dashboard.info,
  auth: state.session.auth,
  user: state.user.info,
  activeShipment: state.dashboard.activeReservation
});

const mapDispatchToProps = (dispatch) => ({
  setActiveReservation: (shipment) => dispatch(setActiveReservation(shipment))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentCardPreview);
