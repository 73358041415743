import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileExtensionIcon } from 'util/Utils';
import { Tooltip } from '@material-ui/core';

const styles = (theme) => ({});

const DocumentCard = ({ classes, document, handleClick }) => {
  return (
    <Tooltip
      placement="top"
      title={document.name}
      arrow
      classes={{ tooltip: 'tooltip-kargo-blue' }}>
      <motion.a
        // href={document.url}
        // target="_blank"
        // download
        onClick={() => handleClick(document.url, 'document')}
        className="card bg-light btn hover-scale-sm rounded text-left p-4">
        <div className="d-flex align-items-center">
          <div className="mr-3 text-center ">
            <img
              className="d-40"
              src={getFileExtensionIcon(document.url)}
              alt="download file"
            // whileTap={{ scale: 1.5 }}
            // whileHover={{ cursor: 'pointer' }}
            />
          </div>
          <div className="flex-grow-1 text-black-50 w-65  pr-2">
            <div className="font-size-sm text-black text-truncate">
              {document.name}
            </div>

            {/* <div className="font-weight-bold">{document.size}</div>
            <div className="font-size-xs mt-2">
              2022-11-22{' '}
              <span className="text-black">{document.lastUpdatedTs}</span>
            </div> */}
          </div>
          <div className="font-size-sm opacity-5">
            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
          </div>
        </div>
      </motion.a>
    </Tooltip>
  );
};

DocumentCard.defaultProps = {};

DocumentCard.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired
};

export default withStyles(styles)(DocumentCard);
