import React, { useEffect, useState } from 'react'
import ScreenOne from './Screens/ScreenOne'
import { HelpGuideContent, HelpGuideHome } from './Content'
import ScreenTwo from './Screens/ScreenTwo'
import ScreenThree from './Screens/ScreenThree'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
const HelpGuide = () => {

    const location = useLocation();
    const auth = useSelector((state) => state.session.auth)
    const [data, setData] = useState([])

    const [value, setValue] = useState(1)

    const [screenTwoData, setScreenTwoData] = useState(null)
    const [screenThreeData, setScreenThreeData] = useState(null)

    const handleClickScreenOne = (section_name) => {

        const headerdata = HelpGuideHome.filter((item) =>
            (item.allowed.includes(auth.accountCmpyLinkType) || item.allowed.length === 0) &&
            item.value === section_name
        )[0]

        if (!headerdata) {
            setValue(1)
            return
        }
        const data_to_set = {
            header: headerdata,
            content: HelpGuideContent[section_name]
        }
        setScreenTwoData(data_to_set)
        setValue(2)
    }

    const handleClickScreenTwo = (data) => {
        setScreenThreeData(data)
        setValue(3)
    }


    useEffect(() => {
        if (location && location.pathname) {
            const section_name = location.pathname.split("/")[1]
            handleClickScreenOne(section_name)
        }
    }, [location])

    return (
        <>

            {value === 1 &&
                <ScreenOne data={HelpGuideHome} handleClick={handleClickScreenOne} />
            }

            {value === 2 &&
                <ScreenTwo handleGoBack={() => setValue(1)} data={screenTwoData} handleClick={handleClickScreenTwo} />
            }

            {value === 3 &&
                <ScreenThree handleGoBack={() => setValue(2)} data={screenThreeData} />
            }

        </>
    )
}

export default HelpGuide