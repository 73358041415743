import React, { useState } from 'react';
import ClassNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import TabsBar from '@material-ui/core/Tabs';

import compose from 'recompose/compose';
import clsx from 'clsx';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, List, ListItem } from '@material-ui/core';

export const styles = () => ({
  tabPane: {
    position: 'relative'
  }
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const Tabs = ({ content, classes, className, onClick, active }) => {
  const [activeTab, setActiveTab] = useState(active ? active : 0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className={className}>
        <TabsBar
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <List
            component="div"
            className="nav-line d-flex nav-line-alt nav-tabs-second">
            {content &&
              content.map((item, index) => {
                const key = `Tab-${index}`;
                return (
                  <ListItem
                    {...a11yProps(index)}
                    key={key}
                    button
                    disableRipple
                    selected={activeTab === (item.value ? item.value : index)}
                    onClick={() => {
                      toggle(item.value ? item.value : index);
                      if (onClick) {
                        onClick(item.value)
                      }
                    }}>
                    <span className="font-size-md font-weight-bold">
                      {item.label}
                    </span>
                    <div className="divider" />
                  </ListItem>
                );
              })}
          </List>
        </TabsBar>

        {content &&
          content.map((item, index) => {
            const { View } = item;
            const key = `TabPanel-${index}`;
            if (activeTab !== (item.value ? item.value : index)) {
              return
            }
            return (
              <div
                key={key}
                className={clsx('tab-item-wrapper', {
                  active: activeTab === (item.value ? item.value : index)
                })}

                // eslint-disable-next-line react/no-unknown-property
                index={index}>
                <div className={ClassNames('mt-4', classes.tabPane)}>
                  {View}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default compose(withStyles(styles), withWidth())(Tabs);
