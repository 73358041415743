import { BILLING_INFO_SUCCESS, BILLING_INFO_FAILURE } from '../actions';

export const initialState = {
  info: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BILLING_INFO_SUCCESS:
      return { info: action.payload };

    case BILLING_INFO_FAILURE:
      return initialState;

    default:
      return state;
  }
}
