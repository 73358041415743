import React from 'react'
import Style from "./IconBoxV2.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const IconBoxV2 = ({ data, handleClick }) => {
    return (
        <>
            <div className={Style.main} onClick={() => handleClick(data)}>
                <div className={Style.leftContainer}>
                    {data.icon ?
                        <>{data.icon}</>
                        :
                        <FontAwesomeIcon
                            className="d-24"
                            icon={['fas', 'lightbulb']}
                        />
                    }
                </div>
                <div className={Style.rightContainer}>
                    <h3 className='font-size-lg text-capitalize'>{data.heading}</h3>
                    <span className='font-size-xs'>{data.description}</span>
                </div>
            </div>
        </>
    )
}

export default IconBoxV2