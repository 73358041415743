import React from 'react'
import Style from "./HeaderBanner.module.css"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const HeaderBanner = ({ icon, title, description, handleGoBack }) => {
    return (
        <>
            <div className={Style.container}>
                <div className={Style.iconBg}>
                    {icon}
                </div>
                <div className='d-flex align-items-center justify-content-center w-100 my-3 position-relative'>
                    <KeyboardBackspaceIcon onClick={handleGoBack} style={{ cursor: "pointer", position: "absolute", left: "1rem" }} />
                    <h3 className='text-black m-0 text-capitalize text-center' style={{ padding: "0 4rem" }}>{title}</h3>
                </div>
                {description &&
                    <span className='font-size-xs px-3'>{description}</span>
                }
            </div>
        </>
    )
}

export default HeaderBanner