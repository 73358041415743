import {
    SET_LOADER
} from '../actions';

const initialState = {
    loading: false,
};

export default function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOADER:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}