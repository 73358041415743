import { useState } from "react";
import Style from "./TableRow.module.css";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ExpandDetails from "../ExpandDetails/ExpandDetails";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { ForwarderService } from "service";
import RightDrawer from "sites/shipper/components/layout/RightDrawer";
import AddRateSheetForm from "../../AddRateSheetForm/AddRateSheetForm";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { Divider } from "@material-ui/core";
import { notify } from "sites/shipper/components/Toast/toast";
import {useDispatch} from 'react-redux';
/* eslint-disable */
const TableRow = ({ data_id, data, id, run, setRun }) => {

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const toggleExtend = (id) => {
        const element = document.getElementById(id);
        if (element.clientHeight === 0) {
            element.style.height = element.scrollHeight + "px";
        } else {
            element.style.height = 0;
        }
        setIsOpen(state => !state);
    }


    const [openRightDrawer, setOpenRightDrawer] = useState(false);
    const toggleRightDrawer = (bool) => {
        setOpenRightDrawer(bool);
    };

    // this handles the deletion of row 
    const handleDelete = () => {
        ForwarderService.deleteRateSheet(data_id).then((data) => {
            if (data.message) {
                // window.alert(data.message)
                notify(dispatch, "success", data.message)
                setRun(!run)
            } else {
                // window.alert(data.error)
                notify(dispatch, "error", data.error)
            }
        })
    }

    return (
        <>
            <div>
                <div
                    className={Style.table_row}

                >
                    {data &&
                        <>
                            <div className={Style.row_first_dv} onClick={() => toggleExtend(id)}>
                                <div>

                                    {!isOpen ?
                                        <AddCircleOutlineOutlinedIcon />
                                        :
                                        <RemoveCircleOutlineOutlinedIcon />
                                    }
                                </div>
                                <span>{data.origin.port_id}</span>
                            </div>
                            <div>
                                <span>{data.origin.port}</span>
                            </div>
                            <div>
                                <span>{data.origin.city}</span>
                            </div>
                            <div>
                                <span>{data.origin.country_code}</span>
                            </div>
                            <div>
                                <span>{data.destination.port_id}</span>
                            </div>
                            <div>
                                <span>{data.destination.port}</span>
                            </div>
                            <div>
                                <span>{data.destination.city}</span>
                            </div>
                            <div>
                                <span>{data.destination.country_code}</span>
                            </div>
                            <div className={Style.action_button}>
                                <IconButton
                                    onClick={() => toggleRightDrawer(true)}
                                    style={{ width: '20px', height: '20px' }}
                                    className={Style.edit_btn}
                                >
                                    <Edit style={{ fontSize: '12px', color: '#fff' }} />
                                </IconButton>
                                <IconButton
                                    onClick={() => setConfirmDialogOpen(true)}
                                    style={{ width: '20px', height: '20px' }}
                                    className={Style.delete_btn}
                                >
                                    <Delete style={{ fontSize: '12px', color: '#fff' }} />
                                </IconButton>
                            </div>
                        </>
                    }
                </div>

                <ExpandDetails run={run} setRun={setRun} data_id={data_id} id={id} data={data} />

                <RightDrawer
                    onClose={() => toggleRightDrawer(false)}
                    open={openRightDrawer}
                >
                    <AddRateSheetForm method={"edit"} editData={data} run={run} setRun={setRun} toggleRightDrawer={toggleRightDrawer} />
                </RightDrawer>

                {/* confirmation dialog  */}
                <ConfirmDialog
                    confirmDialogOpen={confirmDialogOpen}
                    setConfirmDialogOpen={setConfirmDialogOpen}
                    confirmDialogFunction={handleDelete}
                    question={"Are you sure you want to delete this row?"}
                />
            </div>
            <Divider />
        </>
    )
}
/* eslint-enable */
export default TableRow