import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, LinearProgress, Card, CardContent, Button } from '@material-ui/core';
import Chart from 'react-apexcharts';

export default function PriceHistoryChart({ calculateTotal, name, graphData }) {
  const formatAsUSD = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const chartDashboardStatistics2AOptions = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      strokeDashArray: '5',
      borderColor: 'rgba(125, 138, 156, 0.3)'
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    fill: {
      color: '#3c44b1',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    colors: ['#3c44b1'],
    legend: {
      show: false
    },
    labels: graphData.map((data) => data.effective_date.split("T")[0]),
    tooltip: {
      y: {
        formatter: (value) => formatAsUSD(value),
      },
    },
  };

  const chartDashboardStatistics2AData = [
    {
      name: name,
      data: graphData.map((data) => calculateTotal(data.price_breakdown)),
    },
  ];

  return (
    <>
      <div className="mb-spacing-6">
        <Chart
          options={chartDashboardStatistics2AOptions}
          series={chartDashboardStatistics2AData}
          type="area"
          height={317}
        />
      </div>
    </>
  );
}
