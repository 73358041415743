import { expireOfferFromChat } from 'actions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';


const ExpiryTimeCounter = ({ expiryTime ,index}) => {

    const socket = useSelector((state) => state.socketConnection.socket)
    const chatRoomId = useSelector((state) => state.chatRoom.room_id)
    const dispatch = useDispatch()
    const calculateTimeRemaining = (time) => {
        const now = new Date();
        const timeDifference = time - now;
        if (timeDifference <= 0) {
            // Card has already expired
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        return {
            hours,
            minutes,
            seconds,
        };
    };


    useEffect(() => {
        const timerInterval = setInterval(() => {
            const newTimeRemaining = calculateTimeRemaining(expiryTime);
            setTimeRemaining(newTimeRemaining);

            if (newTimeRemaining.hours === 0 && newTimeRemaining.minutes === 0 && newTimeRemaining.seconds === 0) {
                clearInterval(timerInterval);
                const data = {
                    index: index,
                    room_id : chatRoomId
                }
                socket.emit("expire-offer", data)
                socket.on("expire-offer-result", res => {
                    if (res.success) {
                        const data_to_dispatch = {index:index}
                        dispatch(expireOfferFromChat(data_to_dispatch))
                    }
                })

            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [expiryTime]);







    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(expiryTime));




    return (
        <>
            <div>
                <p>Expires: {timeRemaining.hours} : {timeRemaining.minutes} : {timeRemaining.seconds}</p>
            </div>
        </>
    )
}

export default ExpiryTimeCounter