import React, { useEffect, useState } from 'react'
import Style from './NotVerified.module.css'
import { Button } from '@mui/material'
import MobileStepper from '@mui/material/MobileStepper';
import KYCPageWrapper from 'components/KYCUploadSystem/KYCPageWrapper/KYCPageWrapper';
import VerificationDocuments from './VerificationDocuments';
import BoxContainer from 'components/KYCUploadSystem/BoxContainer/BoxContainer';
import PersonalDetails from './PersonalDetails';
import VerificationSummaryDetails from './VerificationSummaryDetails';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { VerificationStatus } from 'util/VerificationStatus';
const NotVerified = ({ kycStatus, data, setData, handleSubmit, error, rejectedDoc, formData, setFormData, acknowledgement, setAcknowledgement }) => {


    const [activeStep, setActiveStep] = React.useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const stepperArray = [
        <PersonalDetails formData={formData} setFormData={setFormData} kycStatus={kycStatus} />,
        <VerificationDocuments data={data} setData={setData} rejectedDoc={rejectedDoc} kycStatus={kycStatus} />,
        <VerificationSummaryDetails formData={formData} data={data} acknowledgement={acknowledgement} setAcknowledgement={setAcknowledgement} kycStatus={kycStatus} />
    ]

    const maxSteps = stepperArray.length;

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToTop();
    }, [activeStep]);


    const isNextFucntionDisabled = () => {
        if (activeStep === 0) {
            if (!formData.first_name ||
                !formData.last_name ||
                !formData.email ||
                !formData.company_name ||
                !formData.jobTitle ||
                !formData.industry ||
                // !formData.phone_number_code ||
                !formData.phone_number ||
                !formData.date_of_birth ||
                !formData.gender ||
                !formData.address.country ||
                !formData.address.state ||
                !formData.address.city ||
                !formData.address.street ||
                !formData.address.postalCode) {
                return true
            }
        }
        if (activeStep === 1) {
            // if (data.Individual.length === 0 ||
            //     (!data.is_not_registered_company && data.Company.length === 0)) {
            //     return true
            // }
            if ((kycStatus.individual === VerificationStatus.PENDING || kycStatus.individual === VerificationStatus.REJECTED) && data.Individual.length === 0) {
                return true
            }
            if ((kycStatus.company === VerificationStatus.PENDING || kycStatus.company === VerificationStatus.REJECTED) && data.Company.length === 0) {
                return true
            }
        }
        return false
    }

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    return (
        <>
            {/* <KYCPageWrapper> */}
            <h1 className={Style.MainHeading}>Verify Yourself</h1>
            {stepperArray[activeStep]}
            <BoxContainer classes={Style.mainStepperContaier}>
                <div className={Style.stepperContainer}>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={(activeStep !== maxSteps - 1) ?
                            <Button
                                size="small"
                                className={`${Style.submitButton}`}
                                onClick={handleNext}
                                disabled={isNextFucntionDisabled()}
                            >
                                Next
                            </Button>
                            :
                            <Button
                                size="small"
                                className={`${Style.submitButton}`}
                                onClick={() => setConfirmDialogOpen(true)}
                                disabled={!acknowledgement}
                            >
                                Submit
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                className={`${Style.submitButton}`}
                                onClick={handleBack}
                                disabled={activeStep === 0}
                            >
                                Back
                            </Button>
                        }
                    />
                </div>
            </BoxContainer>
            {/* </KYCPageWrapper> */}
            <ConfirmDialog
                heading="Complete Verification Process"
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleSubmit}
                question={`Are you sure you want to complete verification process`}
            />
        </>
    )
}
export default NotVerified



{/* <BoxContainer>
                    <div className={Style.left_inner}>
                        <span>Your account will be in review and you will be notified once admin approves your kyc</span>
                        <Button
                            className={`${Style.submitButton}`}
                            onClick={handleSubmit}
                            disabled={
                                data.Individual.length === 0 ||
                                (!data.is_not_registered_company && data.Company.length === 0)
                            }
                        >
                            Submit
                        </Button>
                        {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
                    </div>
                </BoxContainer> */}