export const cardWidth = 960;
export const leftDrawerWidth = 304;
export const minLeftDrawerWidth = 60;
export const rightDrawerWidth = 600;

/*-- Access Level Types --*/
export const ACCESS_LEVEL_TYPE_ALL = 'ALL';
export const ACCESS_LEVEL_TYPE_NONE = 'NONE';
export const ACCESS_LEVEL_TYPE_READ = 'READ';
export const ACCESS_LEVEL_TYPE_TRAVELER = 'TRAVELER';
export const ACCESS_LEVEL_TYPE_APPEND = 'APPEND';
export const ACCESS_LEVEL_TYPE_APPEND_N_PUBLISH = 'APPEND_N_PUBLISH';
export const ACCESS_LEVEL_TYPE_OWNER = 'OWNER';
export const ACCESS_LEVEL_TYPE_ADMIN = 'ADMIN';
export const ACCESS_LEVEL_TYPE_SUPER_ADMIN = 'SUPER_ADMIN';

export const ACCESS_LEVEL_TYPES = [
  ACCESS_LEVEL_TYPE_ALL,
  ACCESS_LEVEL_TYPE_NONE,
  ACCESS_LEVEL_TYPE_READ,
  ACCESS_LEVEL_TYPE_TRAVELER,
  ACCESS_LEVEL_TYPE_APPEND,
  ACCESS_LEVEL_TYPE_APPEND_N_PUBLISH,
  ACCESS_LEVEL_TYPE_OWNER,
  ACCESS_LEVEL_TYPE_ADMIN,
  ACCESS_LEVEL_TYPE_SUPER_ADMIN
];

/*-- Booking Types --*/
export const AIRPORT = 'AIRPORT';
export const AIRLINE = 'AIRLINE';
export const FLIGHT = 'FLIGHT';
export const HOTEL = 'HOTEL';
export const CRUISE = 'CRUISE';
export const NOTE = 'NOTE';
export const PORT = 'PORT';
export const TRANSFER = 'TRANSFER';
export const TRANSPORT = 'TRANSPORT';
export const ACTIVITY = 'ACTIVITY';
export const TRANSPORTATION = 'TRANSPORTATION';

/*-- Activity Types --*/
export const CRUISE_STOP = 'CRUISE_STOP';
export const SKI_LESSONS = 'SKI_LESSONS';
export const RESTAURANT = 'RESTAURANT';
export const EVENT = 'EVENT';

/*-- Rate Types --*/
export const REFUNDABLE = 'Refundable';
export const NON_REFUNDABLE = 'Non Refundable';
export const DAILY = 'Daily';
export const WEEKLY = 'Weekly';
export const ONE_WAY = 'One Way';
export const RETURN = 'Return';
export const PER_PERSON = 'Per Person';
export const ENTIRE_PARTY = 'Entire Party';
export const CANCELLATION = 'Cancellation';
export const NOT_APPLICATION = 'Not Application';

export const rateType = {
  Refundable: 'REFUNDABLE',
  'Non Refundable': 'NON_REFUNDABLE',
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  'One Way': 'ONE_WAY',
  Return: 'RETURN',
  'Per Person': 'PER_PERSON',
  'Entire Party': 'ENTIRE_PARTY',
  Cancellation: 'CANCELLATION',
  'Not Application': 'NOT_APPLICATION'
};

export const BOOKING_TYPES = [
  { name: 'Flight', type: 'FlightReservation' },
  { name: 'Accommodation', type: 'LodgingReservation' },
  { name: 'Cruise', type: 'CruiseReservation' },
  { name: 'Activity', type: 'ActivityReservation' },
  { name: 'Cruise Stop', type: 'CruiseStopReservation' },
  { name: 'Event', type: 'EventReservation' },
  { name: 'Restaurant', type: 'FoodEstablishmentReservation' },
  { name: 'Shore Excursion', type: 'ShoreExcursionReservation' },
  { name: 'Ski Lessons', type: 'SkiLessonsReservation' },
  { name: 'Ski Lift Tickets', type: 'SkiLiftTickets' },
  { name: 'Ski Rentals', type: 'SkiRentalReservation' },
  { name: 'Tour', type: 'TourReservation' },
  { name: 'Bus', type: 'BusReservation' },
  { name: 'Car Rental', type: 'RentalCarReservation' },
  { name: 'Car Rental Dropoff', type: 'RentalCarDropOffReservation' },
  { name: 'Ferry', type: 'FerryReservation' },
  { name: 'Public Transport', type: 'PublicTransportReservation' },
  { name: 'Transportation', type: 'TransferReservation' },
  { name: 'Rail', type: 'TrainReservation' },
  { name: 'Transfer', type: 'PrivateTransferReservation' },
  { name: 'Taxi', type: 'TaxiReservation' }
];

export const ACTIVITY_TYPES = [
  { name: 'Activity', type: 'Activity' },
  { name: 'Cruise Stop', type: 'CruiseStop' },
  { name: 'Event', type: 'Event' },
  { name: 'Restaurant', type: 'FoodEstablishment' },
  { name: 'Shore Excursion', type: 'ShoreExcursion' },
  { name: 'Ski Lessons', type: 'SkiLessons' },
  { name: 'Ski Lift Tickets', type: 'SkiLiftTickets' },
  { name: 'Ski Rentals', type: 'SkiRental' },
  { name: 'Tour', type: 'Tour' }
];

export const TRANSFER_TYPES = [
  { name: 'Bus', type: 'Bus' },
  { name: 'Car Rental', type: 'RentalCar' },
  { name: 'Car Rental Dropoff', type: 'RentalCarDropOff' },
  { name: 'Ferry', type: 'Ferry' },
  { name: 'Public Transport', type: 'PublicTransport' },
  { name: 'Transportation', type: 'Transfer' },
  { name: 'Rail', type: 'Train' },
  { name: 'Transfer', type: 'PrivateTransfer' },
  { name: 'Taxi', type: 'Taxi' }
];

export const RATE_TYPES = [
  { name: 'Refundable', type: 'REFUNDABLE' },
  { name: 'Non-Refundable', type: 'NON_REFUNDABLE' },
  { name: 'Daily', type: 'DAILY' },
  { name: 'Weekly', type: 'WEEKLY' },
  { name: 'One Way', type: 'ONE_WAY' },
  { name: 'Return', type: 'RETURN' },
  { name: 'Per Person', type: 'PER_PERSON' },
  { name: 'Entire Party', type: 'ENTIRE_PARTY' },
  { name: 'Cancellation', type: 'CANCELLATION' },
  { name: 'Not Applicable', type: 'NOT_APPLICABLE' }
];

export const RATE_TYPES_MAPPER = {
  Refundable: 'REFUNDABLE',
  'Non-Refundable': 'NON_REFUNDABLE',
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  'One Way': 'ONE_WAY',
  Return: 'RETURN',
  'Per Person': 'PER_PERSON',
  'Entire Party': 'ENTIRE_PARTY',
  Cancellation: 'CANCELLATION',
  'Not Applicable': 'NOT_APPLICABLE',
  'N/A': 'NOT_APPLICABLE'
};

export const MONTHS = [
  { name: 'January', type: 'January' },
  { name: 'February', type: 'February' },
  { name: 'March', type: 'March' },
  { name: 'April', type: 'April' },
  { name: 'May', type: 'May' },
  { name: 'June', type: 'June' },
  { name: 'July', type: 'July' },
  { name: 'August', type: 'August' },
  { name: 'September', type: 'September' },
  { name: 'October', type: 'October' },
  { name: 'November', type: 'November' },
  { name: 'December', type: 'December' }
];

export const BOOKING_INBOX_TYPES = [
  { name: 'All', type: 'All' },
  { name: 'Clientbase CBD', type: 'Clientbase CBD' },
  { name: 'Email', type: 'Email' },
  { name: 'Hotelzon', type: 'Hotelzon' },
  { name: 'SABRE', type: 'SABRE' },
  { name: 'Ski.com', type: 'Ski.com' },
  { name: 'Ultimate Jet Vacations', type: 'Ultimate Jet Vacations' },
  { name: 'Travelbound', type: 'Travelbound' },
  { name: 'API', type: 'API' }
];

export const AFAR_POINTS_OF_INTEREST_TYPES = [
  { name: 'All', type: 'All' },
  { name: 'Do', type: 'Do' },
  { name: 'Drink', type: 'Drink' },
  { name: 'Eat', type: 'Eat' },
  { name: 'Shop', type: 'Shop' },
  { name: 'Stay', type: 'Stay' }
];

export const WCITIES_CITY_CONTENT_GUIDE = [
  { name: 'City Guides', type: 'Content' },
  { name: 'Accommodations', type: 'ContentHotel' },
  { name: 'Attractions', type: 'ContentAttraction' },
  { name: 'Dining', type: 'ContentRestaurant' },
  { name: 'Nightlife', type: 'ContentNightlife' },
  { name: 'Shopping', type: 'ContentShopping' },
  { name: 'Events', type: 'Events' }
];

export const BRAND_TRAVEL_CORP = [
  { name: 'AAT Kings', type: 'aatkings' },
  { name: 'Brendan Vacations', type: 'brendanvacations' },
  { name: 'Contiki', type: 'contiki' },
  { name: 'Costsaver', type: 'costsaver' },
  { name: 'Insight Vacations', type: 'insightvacations' },
  { name: 'Luxury Gold', type: 'luxurygold' },
  { name: 'Trafalgar', type: 'trafalgar' }
];

export const filterDrawerWidth = 375;

const currentTime = new Date();

export const YEARS = [
  { name: currentTime.getFullYear() - 2, type: currentTime.getFullYear() - 2 },
  { name: currentTime.getFullYear() - 1, type: currentTime.getFullYear() - 1 },
  { name: currentTime.getFullYear(), type: currentTime.getFullYear() },
  { name: currentTime.getFullYear() + 1, type: currentTime.getFullYear() + 1 }
];

export const greyShades = ['#f8f8f8', '#f5f5f5', '#f0f0f0', '#e8e8e8', '#e0e0e0', '#dcdcdc'];

export const STATUS = {
  0: 'STATUS_ACTIVE',
  1: 'STATUS_PENDING',
  3: 'STATUS_INVITE_PENDING',
  4: 'STATUS_INVITE_SENT',
  5: 'STATUS_INVITE_ACCEPTED'
};

export const SUPPLIER_LOGOS = [
  {
    name: 'alpineAdventures',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/alphine_logo.png'
  },
  { name: 'DMCeXplorer', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/dmcexplorer.jpg' },
  { name: 'ultimateJet', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/ultimate-jet.png' },
  {
    name: 'islandsInTheSun',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/islands_sun_logo.png'
  },
  {
    name: 'quantasVacation',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/quantas_vacation_logo.png'
  },
  { name: 'travel2', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/travel2_logo.png' },
  { name: 'travelCorp', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/travelcorp-logo.jpg' },
  { name: 'aatkings', link: 'https://umapped.com/wp-content/uploads/2018/09/AATKings_transparent.png' },
  {
    name: 'brendanvacations',
    link: 'https://umapped.com/wp-content/uploads/2018/09/BrendanVacations.png'
  },
  { name: 'contiki', link: 'https://umapped.com/wp-content/uploads/2018/09/Contiki_final.png' },
  { name: 'costsaver', link: 'https://umapped.com/wp-content/uploads/2018/09/CostSaver.png' },
  {
    name: 'insightvacations',
    link: 'https://umapped.com/wp-content/uploads/2018/09/InsightVacations.png'
  },
  { name: 'luxurygold', link: 'https://umapped.com/wp-content/uploads/2018/09/Luxury_Gold_Logo_2018.png' },
  { name: 'trafalgar', link: 'https://umapped.com/wp-content/uploads/2018/09/Trafalgar_Logo-1.jpg' },
  { name: 'andBeyond', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/andbeyond-logo.png' },
  {
    name: 'Alluring+Americas',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/alluring-logo.png'
  },
  { name: 'DMAFRICA', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/dmafrica.png' },
  { name: 'Greaves+Travel', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/greaves-logo.png' },
  { name: 'FlySaa', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/southafricanairways.png' },
  {
    name: 'Triumph+Travel',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/triumph_travel_logo.png'
  },
  {
    name: 'Wilderness+Safaris',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/wilderness-logo.png'
  },
  { name: 'gogoVacations', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/gogo.jpg' },
  { name: 'shoreTrips', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/shoretrips_logo.png' },
  {
    name: 'flightcentreHolidays',
    link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/FC_holidays_preferred_logo_rgb.png'
  },

  { name: 'bigFive', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/bigfive_logo.png' },
  { name: 'globus', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/globus-low-res.jpg' }

  /*{ name: 'classicVacations' , url: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/ClassicVacations_whitepng.png'},
  { name: 'afar' , url: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/afar_logo.png'},
  { name: 'approachGuides', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/approach.png'},
  { name: 'classicVacations', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/classicvacations_logo.png'},
  
  
  { name: 'ski-com', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/ski-com.png'},
  { name: 'travelBound', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/travelbound_logo.gif'},
  { name: 'classicVacations', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/travelercollab_black.png'},
  { name: 'travelerCollab', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/travelercollab_white.png'},
  { name: 'travelscene', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/travelscene_logo.png'},
  { name: 'wcities', link: 'https://s3.amazonaws.com/static-umapped-com/public/img/suppliers/wcities-logo-small.png'},*/
];
