import React from 'react'
import Style from "./RateDetailCard.module.css"
import classNames from 'classnames'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tooltip } from '@material-ui/core';

const RateDetailCard = ({ color = 'default', data, onEdit }) => {

    const cardClass = classNames(Style.container, {
        [Style.default]: color === 'default',
        [Style.purple]: color === 'purple',
        [Style.green]: color === 'green',
        [Style.black]: color === 'black',
        [Style.gray]: color === 'gray',
    })
    return (
        <div className={cardClass}>
            <div className={Style.container_type_chip}>
                20 FT
            </div>
            <div className={Style.price_container}>
                <span className={`text-white ${Style.label}`}>
                    Previous Price
                </span>
                <span className={`text-white ${Style.prev_price}`}>
                    ${data?.past_price?.amount}
                </span>
                <div className={`text-white ${Style.dates_container}`}>
                    <span>Effective Date  </span>
                    <span>:  {data?.past_price?.effective_date}</span>

                    <span>Expiry Date </span>
                    <span>: {data?.past_price?.expiry_date}</span>
                </div>
            </div>
            <div className={Style.price_container}>
                <span className={`text-white ${Style.label}`}>
                    Current Price
                </span>

                <div className={Style.current_price_container}>
                    <div className={`${Style.current_text} ${Style.dates_container}`}>
                        <span>Effective Date  </span>
                        <span>: {data?.ongoing_price?.effective_date}</span>
                        <span>Expiry Date </span>
                        <span>: {data?.ongoing_price?.effective_date}</span>
                    </div>
                    <span className={Style.current_price}>
                        ${data?.ongoing_price?.amount}
                    </span>
                    <Tooltip title="Edit Ratesheet" arrow>
                        <div
                            onClick={onEdit}
                            className={Style.action_btn}
                        >
                            <ArrowForwardIcon className='text-white font-size-lg' />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default RateDetailCard