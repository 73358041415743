import { HttpMethods, httpService } from './global';

export default class ShipmentsService {


  static fetchShipments(data, token, type, method) {
    const url = `shipment/f/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: type,
      method: method,
      platform: "Forwarder"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentOverviewDetail(data, token) {
    const url = `shipment/f/shipment-page-overview`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentContainerDetail(data, token) {
    const url = `shipment/f/container-detail`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentActivity(data, token) {
    const url = `shipment/f/activity`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentNotes(data, token) {
    const url = `shipment/f/notes/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static createShipmentNotes(data, token) {
    const url = `shipment/f/notes/create`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentDocuments(data, token) {
    const url = `shipment/f/documents/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentPayments(data, token) {
    const url = `shipment/f/payment/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static trackContainerByContainerId(data) {
    const url = `shipment/container/track`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static getTrackingStatusFromVizion(data) {
    const url = `shipment/get-conatiner-status-using-referenceId/${data?.shipmentId}/${data?.containerId}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static shipmentBasicInfo(data) {
    const url = `shipment/shipment-basic-info`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }













  static updateImportantDates(data, token) {
    const url = `shipment/f/important-dates/post`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static allocateContainer(data, token) {
    const url = `shipment/f/allocate-container`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static initiateShipment(data, token) {
    const url = `shipment/f/initiate-shipment`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static updateContainerStatus(data, token) {
    const url = `shipment/f/update-container-status`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static cancelShipment(data, token) {
    const url = `shipment/f/cancel`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }








  static startChatting(shipment_id) {
    const url = `shipment/chat?shipment_id=${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




















  static fetchRequestAddon(data, token) {
    const url = `shipment/f/add-on/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static acceptRequestAddon(data, token) {
    const url = `shipment/f/add-on/accept`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static rejectRequestAddon(data, token) {
    const url = `shipment/f/add-on/reject`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


















  static isEligileForDelivery(data, token) {
    const url = `shipment/f/delivery/check-eligibility`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static applyForDelivery(data, token) {
    const url = `shipment/f/delivery/create`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'create',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchDelivery(data, token) {
    const url = `shipment/f/delivery/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static withdrawDelivery(data, token) {
    const url = `shipment/f/delivery/withdraw`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }













  static uploadAdditionalAttachments(data, token) {
    const url = `shipment/f/upload/additional-attachments`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  static contactSupoort(data) {
    const url = `shipment/contact/support`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  static fetchOverviewPageAttachments(data, token) {
    const url = `shipment/f/overview/attachments/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchContainersLocation(data) {

    const url = `shipment/getContainerLocations`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchAllContainersOriginAndDestination(token) {

    const url = `shipment/get-all-containers-origin-and-destination-locations`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'dashboard',
      method: 'read',
      platform: 'Forwarder'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


}