/*-- Action Creators for Messages --*/
export const CLOSE_TOAST_MESSAGE = 'CLOSE_TOAST_MESSAGE';
export const closeToastMessage = () => ({
  type: CLOSE_TOAST_MESSAGE
});

export const REQUEST_IN_PROGRESS = 'REQUEST_IN_PROGRESS';
export const requestInProgress = (payload) => ({
  type: REQUEST_IN_PROGRESS,
  payload
});
