import React, { useState } from 'react'
import Style from "./MobileOverview.module.css";
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading';
import { Divider } from '@material-ui/core';
import ShipmentIcon from '../../../Reusables/ShipmentIcon/ShipmentIcon';
import FromTo from 'components/Common/from_to/FromToVert';
import DetailRow from '../../ShipmentOverview/DetailRow/DetailRow';
import ProgressStepper from 'components/Common/ProgressStepper/ProgressStepper';
import ServiceBadge from './ServiceBadge/ServiceBadge';
import { Button } from '@mui/material';
import AddServicePopup from '../../ShipmentOverview/AddServicePopup/AddServicePopup';
import TeamMemberBox from './TeamMemberBox/TeamMemberBox';
import { Add } from '@material-ui/icons';


const dummyObj = {
    "label": "Hopper Wagon",
    "value": 304,
    "mode": {
        "label": "RAIL",
        "value": "RAIL",
        "icon": "train"
    },
    "icon": "/static/media/icon-rail-hopper-wagon.4dc7676051f4759bd4c0a9a993e210bf.svg",
    "category": {
        "label": "RAIL",
        "title": "Full Container Load",
        "value": "FRL",
        "icon": "/static/media/icon-rail-container-platform.39ab399d417481e4b177effbcd204a99.svg",
        "mode": {
            "label": "RAIL",
            "value": "RAIL",
            "icon": "train"
        }
    }
}

const MobileOverview = () => {

    const [openAddService, setOpenAddService] = useState(false);

    return (
        <div className={Style.container}>
            <span className="font-weight-bold text-uppercase font-size-xs">
                ID: KP-563899
            </span>
            <div className={Style.grid_container}>
                <ShipmentIcon
                    type={dummyObj}
                    count={100}
                    editable={true}
                />
                <div>
                    <FromTo />
                </div>
            </div>
            <div className={Style.detail_container}>
                <DetailRow label={"VESSEL/CARRIER:"} value='eShipper' />
                <DetailRow label={"Booking Ref:"} value={`193CA0679394`} />
                <DetailRow label={"PICKUP DATE:"} value={'6-03-2023'} />
                <DetailRow label={"DELIVERY DATE"} labelHelper={'(estimated):'} value={'27-03-2023'} />
                <DetailRow label={"CUSTOM CLEARANCE:"} value={'27-03-2023'} editable />
            </div>

            <div className={`my-4 ${Style.dates_container}`}>
                <div className={Style.date_field_container}>
                    {/* arrival date should be read only so only set value without handlechange */}
                    <span>Arrival Date</span>
                    <input type="date" />
                </div>
                <div className={Style.date_field_container}>
                    <span>Departure Date &nbsp; <span style={{ fontSize: '9px', color: 'var(--primary)' }}>(estimated)</span></span>
                    <input type="date" />
                </div>
            </div>

            <div className="py-3 bg-light w-100 rounded position-relative overflow-hidden">
                <ProgressStepper currentStep={2} source='Indore' destination='Bhopal' present='Dewas' />
            </div>

            <div className={Style.detail_container}>
                <DetailRow label={"COMMODITY NAME:"} value='Escavators' />
                <DetailRow label={"PRODUCT CATEGORY:"} value={`Machineries`} />
                <DetailRow label={"Weight of Cargo:"} value={`100 Tons`} />
            </div>

            <div className={Style.management_container}>

                {/* services container  */}
                <div className={Style.manage_in_container}>
                    <span className={Style.magement_title}>Services</span>
                    <div className='d-flex c-gap-1 flex-wrap'>
                        <ServiceBadge title="Packaging" />
                        <ServiceBadge title="Inspection" />
                        <ServiceBadge title="Insurance" />
                        <ServiceBadge title="Documentation and Paperwork" />
                        <Button
                            className={Style.add_service_btn}
                            onClick={() => setOpenAddService(state => !state)}
                        >
                            Add Service
                        </Button>
                    </div>
                </div>

                {/* team container  */}
                <div className={Style.manage_in_container}>
                    <span className={Style.magement_title}>Team</span>
                    <div className='d-flex flex-wrap c-gap-1 align-items-center'>
                        <TeamMemberBox />
                        <TeamMemberBox />

                        <Button
                            className={Style.add_member_btn}
                        >
                            <Add style={{ color: '#4E98FE' }} />
                        </Button>

                    </div>
                </div>
            </div>
            <div style={{ position: 'fixed', bottom: '0', zIndex: '14', width: '100%' }}>
                <AddServicePopup open={openAddService} onClose={() => setOpenAddService(false)} />
            </div>

        </div>
    )
}
export default MobileOverview