import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HeaderUserbox from './HeaderUserbox';
import { logout } from 'actions';

const mapStateToProps = (state) => ({
  auth: state.session.auth,
  userInfo: state.user.info
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
