import Style from "./ViewBreakdown.module.css";
import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import TopBar from "./TopBar/TopBar";
import Preview from "./Preview/Preview";
import { IconButton } from "@mui/material";
import { Divider } from "@material-ui/core";
import Summary from "./Summary/Summary";
import { CreateShipment, ForwarderService } from "service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImportantNote from "./ImportantNote/ImportantNote";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { notify } from "sites/shipper/components/Toast/toast";
import { useDispatch } from 'react-redux';

/* eslint-disable */

const ViewBreakdown = ({
    createCustomRate,
    pageData,
    run,
    setRun,
    method,
    open,
    handleClose,
    data,
    name,
    data_id,
    isMakeOffer,
    chatEditOffer = false,
    handleEditOfferCard
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [breakdownData, setBreakdownData] = useState({
        effective_date: null,
        expiry_date: null,
        price_breakdown: [{
            title: "Origin Charges",
            data: [
                {
                    fee_code: "PICKUP 40'",
                    fee_name: "Pickup Charge",
                    comment: "Per Container",
                    units: pageData ? pageData?.load.cargoDetails.quantity : 1,
                    unit_price: 50,
                    amount: pageData ? pageData?.load.cargoDetails.quantity * 50 : 50,
                }
            ]
        },
        {
            title: "Shipment Charges",
            data: [
                {
                    fee_code: "OCEAN 40'",
                    fee_name: "Ocean Freight Cost",
                    comment: "Per Container. Carrier: HLCL",
                    units: pageData ? pageData?.load.cargoDetails.quantity : 1,
                    unit_price: 150,
                    amount: pageData ? pageData?.load.cargoDetails.quantity * 150 : 150,
                }
            ]
        },
        {
            title: "Destination Charges",
            data: [
                {
                    fee_code: "DDO",
                    fee_name: "Destination Documentation Fee",
                    comment: "Per shipment",
                    units: pageData ? pageData?.load.cargoDetails.quantity : 1,
                    unit_price: 50,
                    amount: pageData ? pageData?.load.cargoDetails.quantity * 50 : 50,
                }
            ]
        }]
    })

    // useEffect(()=>{
    //     if(pageData.shipper === ""){

    //     }
    // })

    // this function resets the rate sheet to default 
    const handleReset = () => {
        setBreakdownData({
            ...breakdownData,
            price_breakdown: [{
                title: "Origin Charges",
                data: [
                    {
                        fee_code: "PICKUP 40'",
                        fee_name: "Pickup Charge",
                        comment: "Per Container",
                        units: pageData ? pageData?.load.cargoDetails.quantity : 1,
                        unit_price: 50,
                        amount: pageData ? pageData?.load.cargoDetails.quantity * 50 : 50,
                    }
                ]
            },
            {
                title: "Shipment Charges",
                data: [
                    {
                        fee_code: "OCEAN 40'",
                        fee_name: "Ocean Freight Cost",
                        comment: "Per Container. Carrier: HLCL",
                        units: pageData ? pageData?.load.cargoDetails.quantity : 1,
                        unit_price: 150,
                        amount: pageData ? pageData?.load.cargoDetails.quantity * 150 : 150,
                    }
                ]
            },
            {
                title: "Destination Charges",
                data: [
                    {
                        fee_code: "DDO",
                        fee_name: "Destination Documentation Fee",
                        comment: "Per shipment",
                        units: pageData ? pageData?.load.cargoDetails.quantity : 1,
                        unit_price: 50,
                        amount: pageData ? pageData?.load.cargoDetails.quantity * 50 : 50,
                    }
                ]
            }]
        })
    }

    const auth = useSelector((state) => state.session.auth)

    // this handles the submit of whole rate sheet 
    const handleSubmit = () => {
        if (isMakeOffer && pageData) {
            const data = {
                shipper_id: pageData.shipper._id,
                forwarder_id: auth.accountId,
                shipment_id: data_id,
                charges: breakdownData.price_breakdown,
                expiry_date: breakdownData.expiry_date,
                effective_date: breakdownData.effective_date,
            }
            // console.log(data);
            ForwarderService.make_offer(data).then(data => {
                if (data.message) {
                    notify(dispatch, "success", data.message)
                    history.push('/shipments')
                } else {
                    // window.alert(data.error)
                    notify(dispatch, "error", data.error)
                }
            })
        }

        else if (createCustomRate) {
            const data_to_send = {
              forwarder_id: auth.accountId,
              origin_port_id: data.origin.port_id,
              origin_port: data.origin.port,
              origin_city: data.origin.city,
              origin_country_code: data.origin.country_code,
              destination_port_id: data.destination.port_id,
              destination_port: data.destination.port,
              destination_city: data.destination.city,
              destination_country_code: data.destination.country_code,
              loadType: name,
              rateSheetData: breakdownData
            };
            CreateShipment.create_custom_rate_sheet(
              data_to_send,
              auth.token
            ).then((res) => {
              if (res.error) {
                notify(dispatch, 'error'.res.error);
              } else {
                notify(dispatch, 'success', res.message);
              }
            });
            handleReset();
            handleClose();
            setRun(!run);
        }

        else {
            const data = {
                rateSheetId: data_id,
                load_type: name,
                rate_sheet_data: breakdownData
            }
            if (breakdownData.effective_date === null) {
                // window.alert("Please Select Effective Date")
                notify(dispatch, "error", "Please Select Effective Date")
                return
            }
            // console.log("run")
            if (method === "edit") {
                ForwarderService.updatePriceBreakDown(data).then(data => {
                    if (data.message) {
                        // window.alert(data.message)
                        notify(dispatch, "success", data.message)
                    } else {
                        notify(dispatch, "error", data.error)
                        // window.alert(data.error)
                    }
                })
            } else {
                ForwarderService.createPriceBreakDown(data).then(data => {
                    if (data.message) {
                        notify(dispatch, "success", data.message)
                        // window.alert(data.message)
                    } else {
                        // window.alert(data.error)
                        notify(dispatch, "error", data.error)

                    }
                })
            }
            // setRun(!run);
            handleReset();
            handleClose();
            setRun(!run);
        }
    }


    React.useEffect(() => {
        // console.log(data)
        // console.log(data.load.fcl['20FT'][(data.load.fcl['20FT']).length-1])
        if (method === "edit" && data !== undefined) {
            if (data === undefined) {
                return
            }
            let type
            if (name === "20FT") {
                type = data.load.fcl['20FT'][(data.load.fcl['20FT']).length - 1]
            }
            if (name === "40FT") {
                type = data.load.fcl['40FT'][(data.load.fcl['40FT']).length - 1]
            }
            if (name === "40FT HC") {
                type = data.load.fcl['40FT HC'][(data.load.fcl['40FT HC']).length - 1]
            }
            if (name === "45FT HC") {
                type = data.load.fcl['45FT HC'][(data.load.fcl['45FT HC']).length - 1]
            }
            if (!type) {
                return
            }
            console.log(type);
            // setBreakdownData(type)
            setBreakdownData({
                ...breakdownData,
                effective_date: type?.effective_date?.split("T")[0],
                expiry_date: type?.expiry_date?.split("T")[0],
                price_breakdown: type?.price_breakdown
            })
        }

    }, [])






    //   this adds a table section to preview 
    const addSection = (title) => {

        const obj = { title: title, data: [] }

        setBreakdownData((previousData) => ({
            ...previousData,
            price_breakdown: [...previousData.price_breakdown, obj]
        }))
    }






    useEffect(() => {
        if (pageData?.rate_sheet) {
            setBreakdownData({
                ...breakdownData,
                price_breakdown: pageData?.rate_sheet
            })
        }
    }, [pageData])






    const handleOfferCardEdit = () => {
        let total_amount = 0;
        breakdownData?.price_breakdown.map(data => {
            data.data.map(amount_data => total_amount += Number(amount_data.amount))
        })

        handleEditOfferCard(breakdownData.price_breakdown,total_amount)
    }


    return (
        <>
            {open &&
                <div className={isMakeOffer ? Style.normal_container : Style.popup_container}>
                    <div className={Style.overflow_container}>
                        <div className={Style.container}>

                            <div className={Style.nav_and_detail}>

                                {/* hide back button on shipping leads make offer  */}
                                {!isMakeOffer
                                    ?
                                    <IconButton
                                        onClick={handleClose}
                                        className={Style.back_btn}>
                                        <KeyboardBackspaceIcon sx={{ fontSize: '15px' }} />
                                    </IconButton>
                                    :
                                    <LocationOnIcon style={{ color: 'var(--primary)' }} />
                                }
                                <span className={Style.detail_text}>{data?.origin?.port_id}, {data?.origin?.port}, {data?.origin?.city}, {data?.origin?.country_code}</span>
                                <EastIcon sx={{ fontSize: '20px' }} />
                                <span className={Style.detail_text}>{data?.destination?.port_id}, {data?.destination?.port}, {data?.destination?.city}, {data?.destination?.country_code}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={Style.detail_text}>{name}</span>
                            </div>

                            {/* top bar for title  */}
                            <div className={`${Style.main} ${Style.top_bar_container}`}>
                                <TopBar breakdownData={breakdownData} setBreakdownData={setBreakdownData} addSection={addSection} handleReset={handleReset} />
                                <ImportantNote note="Important Note: The quotation you are about to send is a custom quote tailored specifically for this shipment and shipper. It will not be included in your standard rates unless manually specified." />
                            </div>


                            <div className={Style.main}>

                                {/* preview  */}
                                <Preview pageData={pageData} method={method} breakdownData={breakdownData} setBreakdownData={setBreakdownData} />


                                {/* summary  */}
                                <Summary chatEditOffer={chatEditOffer} method={method} handleSubmit={chatEditOffer ? handleOfferCardEdit : handleSubmit} name={name} data={data} breakdownData={breakdownData} setBreakdownData={setBreakdownData} isMakeOffer={isMakeOffer} />

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}
/* eslint-enable */
export default ViewBreakdown