import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

import avatar1 from 'assets/images/avatars/avatar1.jpg';
import avatar2 from 'assets/images/avatars/avatar2.jpg';
import avatar3 from 'assets/images/avatars/avatar3.jpg';
import avatar4 from 'assets/images/avatars/avatar4.jpg';
import avatar5 from 'assets/images/avatars/avatar5.jpg';
import avatar6 from 'assets/images/avatars/avatar6.jpg';
import avatar7 from 'assets/images/avatars/avatar7.jpg';

import people2 from 'assets/images/stock-photos/people-3.jpg';
import people1 from 'assets/images/stock-photos/people-2.jpg';
import ShipmentOverview from './ShipmentOverview';

const ChatDetailPane = ({ booking, isMobile = false }) => {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);
  const [isSidebarMenuOpen2, setIsSidebarMenuOpen2] = useState(false);

  const toggleSidebarMenu = () => setIsSidebarMenuOpen(!isSidebarMenuOpen);
  const toggleSidebarMenu2 = () => setIsSidebarMenuOpen2(!isSidebarMenuOpen2);

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  return (
    <>
      <div className="w-100">
        {!isMobile && <div className="chat-header" />}

        <div className="p-4">
          <ShipmentOverview booking={booking} />
        </div>
      </div>
    </>
  );
};

export default ChatDetailPane;
