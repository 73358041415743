import React from 'react'
import Style from "./index.module.css"
import HeaderBanner from '../../Reusables/HeaderBanner/HeaderBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const ScreenThree = ({ handleGoBack, data }) => {
    return (
        <>

            <HeaderBanner
                icon={<FontAwesomeIcon className="d-24" icon={['fas', data.icon ? data.icon : 'lightbulb']} />}
                title={data.heading}
                description={data.description}
                handleGoBack={handleGoBack}
            />

            <div className='p-4'>

         

            {data && data.content && data.content.length > 0 &&
                <div className={`bg-light ${Style.container}`}>
                    {data.content.map((item, index) => (
                        <p className='my-2 font-size-xs'>{item}</p>
                    ))}
                </div>
            }
            </div>

        </>
    )
}

export default ScreenThree