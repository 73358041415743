import React from 'react';
import Style from "./tabs.module.css";


const TabsNav = ({ value, setValue, labels }) => {

    return (
        <>
            <div className={`c-flex c-bg-light c-p-half c-rounded ${Style.container}`}>
                {/* button group */}
                {labels?.map((label, idx) => (
                    <button
                        key={`${label}-${idx}`}
                        onClick={() => setValue(idx)}
                        className={value === idx ? `${Style.btn} ${Style.active}` : Style.btn}
                    >
                        <div className={Style.btn_background}></div>
                        <span>{label}</span>
                    </button>
                ))}
            </div>
        </>
    );
}

export default TabsNav;