import React from 'react'
import Style from "./WaitingRoomHeader.module.css";
import Logo from 'assets/logos/kargo-04-light.svg';
import LogoIcon from 'assets/logos/kargo-04-light-icon.svg';
import WaitingRoomUserBox from './WaitingRoomUserBox/WaitingRoomUserBox';

const WaitingRoomHeader = () => {
    return (
        <div className={`app-header app-header--shadow ${Style.container}`}>
            <div className={Style.brand}>
                <img src={Logo} alt="" className={Style.desk_logo} />
                <img src={LogoIcon} alt="" className={Style.mobi_logo} />
            </div>
            <div className={`${Style.main}`}>
                <div className="app-page-title--heading">
                    <h1>Forwarder</h1>
                </div>

                <WaitingRoomUserBox />
            </div>
        </div>
    )
}

export default WaitingRoomHeader