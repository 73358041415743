import { HttpMethods, HttpService } from './index';
import { getBaseUrl } from '../config/config';


export default class ForwarderTeam {
  static async inviteTeam(data,token) {
    const url = 'forwarder/team/invite-team';
    const result = await fetch(getBaseUrl() + url, {
      body: JSON.stringify(data),
      headers: {
       'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'company_team_settings',
        method: 'create',
        platform: 'Forwarder'
      },
      method: 'POST'
    });
    const response = await result.json();
    return response;
  }

  static async isTokenExpired(token) {
    const url = `forwarder/team/is-token-expired?token=${token}`;
    const result = await fetch(getBaseUrl() + url);
    const response = await result.json();
    return response;
  }

  static async registerTeam(data) {
    const url = 'forwarder/team/register-team';
    const result = await fetch(getBaseUrl() + url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POSt'
    });
    const response = await result.json();
    return response;
  }

  static async fetch_team(forwarder_id,token) {
    const url = `forwarder/team/fetch-team?forwarder_id=${forwarder_id}`;
    const result = await fetch(getBaseUrl() + url, {
    
      headers: {
        
        Authorization: 'Bearer ' + token,
        type: 'company_team_settings',
        method: 'read',
        platform: 'Forwarder'
      },
      method: 'GET'
    });
    const response = await result.json();
    return response;
  }

  static async update_team_member_status(data,token) {
    const url = 'forwarder/team/update-team-member-status';
    const result = await fetch(getBaseUrl() + url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'company_team_settings',
        method: 'read',
        platform: 'Forwarder'
      },
      method: 'POST'
    });
    const response = await result.json();
    return response;
  }

  static async get_team_member_by_id(id,token) {
    const url = `forwarder/team/get-team-member-by-id?id=${id}`;
    const result = await fetch(getBaseUrl() + url, {
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'company_team_settings',
        method: 'read',
        platform: 'Forwarder'
      },
      method: 'GET'
    });
    const response = await result.json();
    return response;
  }
  static async updatePermission(id, type, accountId, cmpyId) {
    const url = `forwarder/team/update-permission?id=${id}&type=${type}&accountId=${accountId}&cmpyId=${cmpyId}`;
    const result = await fetch(getBaseUrl() + url);
    const response = await result.json();
    return response;
  }

  static async removeTeamMember(id, accountId, cid,token) {
    const url = `${getBaseUrl()}forwarder/team/remove-team?id=${id}&accountId=${accountId}&cid=${cid}`;
    const result = await fetch(url,  {
    
      headers: {
        
        Authorization: 'Bearer ' + token,
        type: 'company_team_settings',
        method: 'delete',
        platform: 'Forwarder'
      },
      method: 'DELETE'
    });
    const response = await result.json();
    return response;
  }

  static async fetchForwarderPermission(id, name, token) {
    const url = `${getBaseUrl()}forwarder/team/fetch-forwarder-permission?id=${id}&name=${name}`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchForwarderActivity(forwarder_id, company_id, token, skip) {
    const url = `${getBaseUrl()}forwarder/team/fetch-forwarder-activity?forwarder_id=${forwarder_id}&company_id=${company_id}&skip=${skip}`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchTeamRoles(token) {
    const url = `${getBaseUrl()}forwarder/fetch-team-roles`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async update_role(token,data) {
    const url = 'forwarder/team/update-role';
    const result = await fetch(getBaseUrl() + url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: 'company_team_settings',
        method: 'edit',
        platform: 'Forwarder'
      },
      method: 'PUT'
    });
    const response = await result.json();
    return response;
  }
}
