import React, { useState } from 'react'
import Style from "./index.module.css"
import HeaderBanner from '../../Reusables/HeaderBanner/HeaderBanner'
import IconBoxV2 from '../../Reusables/IconBoxV2/IconBoxV2'
import AnimatedSearchbar from 'components/Common/AnimatedSearchbar/AnimatedSearchbar'

const ScreenTwo = ({ handleGoBack, data, handleClick }) => {

    const [filteredContent, setFilteredContent] = useState(data.content);

    const [searchKeyWord, setSearchKeyWord] = useState("")
    const handleSearch = (value) => {
        const keyWord = value
        setSearchKeyWord(keyWord)
        const results = [];
        data.content.forEach(obj => {
            if (obj.heading.toLowerCase().includes(keyWord.toLowerCase())) {
                results.push(obj);
            } else if (obj.description.toLowerCase().includes(keyWord.toLowerCase())) {
                results.push(obj);
            } else {
                obj.content.forEach(content => {
                    if (content.toLowerCase().includes(keyWord.toLowerCase())) {
                        results.push(obj);
                    }
                });
            }
        });
        setFilteredContent(results)
    }
    return (
        <>
            <HeaderBanner
                icon={data?.header?.icon}
                title={data?.header?.label}
                description={data?.header?.description}
                handleGoBack={handleGoBack}
            />

            <div className='d-flex flex-column c-gap-1 p-4'>
                <div className='w-100'>
                    <AnimatedSearchbar
                        value={searchKeyWord}
                        placeholder={"Search anything"}
                        onChange={handleSearch}
                    />
                </div>
                {filteredContent.length > 0 ?
                    <>
                        {
                            filteredContent.map((item, index) => (
                                <IconBoxV2 data={item} key={index} handleClick={handleClick} />
                            ))
                        }
                    </>
                    :
                    <div className='d-flex justify-content-center mt-4 pt-4'>
                        <p className="font-size-sm font-weight-bold">No Result Found</p>
                    </div>
                }
            </div>
        </>
    )
}

export default ScreenTwo