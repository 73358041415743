export const PERSONAL_DOCUMENTS = [
    {
        label: "Passport",
        value: "Passport",
        description: "Upload a clear, full-page photo of your passport showing all personal details and photo ID.",
        front: true,
        back: false,
        limit: 1,
        docNumLimit:25
    },
    {
        label: "National Identity card",
        value: "National Identity card",
        description: "Upload a scanned copy of both sides of your National Identity Card.",
        front: true,
        back: true,
        limit:1,
        docNumLimit:25
    },
    {
        label: "Social security card",
        value: "Social security card",
        description: "Upload an image of your Social Security Card displaying your name and unique identification number.",
        front: true,
        back: true,
        limit:1,
        docNumLimit:25
    },
    {
        label: "Driver's license",
        value: "Driver's license",
        description: "Upload a scanned copy of your Driver's License, ensuring all details are visible.",
        front: true,
        back: true,
        limit:1,
        docNumLimit:25
    },
    {
        label: "Voter’s identity card",
        value: "Voter’s identity card",
        description: "Upload a clear image of your Voter’s Identity Card, displaying your name and voter ID number.",
        front: true,
        back: true,
        limit:1,
        docNumLimit:25
    },
    {
        label: "Birth certificate",
        value: "Birth certificate",
        description: "Upload a clear, legible copy of your Birth Certificate showing your name and date of birth.",
        front: true,
        back: false,
        limit:1,
        docNumLimit:25
    },
    {
        label: "Other",
        value: "Other",
        description: "Upload any other relevant identification document not listed above. Please specify details in the provided space.",
        front: true,
        back: false,
        limit:5,
        docNumLimit:25
    }
];




export const COMPANY_DOCUMENTS = [
    {
        label: "Certified articles of incorporation",
        value: "Certified articles of incorporation",
        description: "Upload a certified copy of your company's articles of incorporation.",
        front: true,
        back: false,
        limit:1,
        docNumLimit:40
    },
    {
        label: "Government-issued business license",
        value: "Government-issued business license",
        description: "Upload a copy of your valid government-issued business license.",
        front: true,
        back: false,
        limit:1,
        docNumLimit:40
    },
    {
        label: "Partnership agreement",
        value: "Partnership agreement",
        description: "Upload a copy of your partnership agreement detailing company structure and responsibilities.",
        front: true,
        back: false,
        limit:1,
        docNumLimit:40
    },
    {
        label: "Trust instrument",
        value: "Trust instrument",
        description: "Upload the trust instrument document establishing the terms of the trust.",
        front: true,
        back: false,
        limit:1,
        docNumLimit:40
    },
    {
        label: "Freight forwarders association certificate",
        value: "Freight forwarders association certificate",
        description: "International or regional Freight forwarders association number/certificate from organisations such as FIATA, CIFFA (Canadian) etc",
        front: true,
        back: false,
        limit:1,
        docNumLimit:40
    },
    {
        label: "Other",
        value: "Other",
        description: "Upload any other relevant company document not listed above. Please specify details in the provided space.",
        front: true,
        back: false,
        limit:5,
        docNumLimit:40
    }
]