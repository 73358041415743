import DashService from '../service/DashService';

/*-- Action Creators for Info Fetch --*/
export const REQUEST_INFO = 'REQUEST_INFO';
export const requestInfo = () => ({
  type: REQUEST_INFO
});

export const RECEIVE_INFO_SUCCESS = 'RECEIVE_INFO_SUCCESS';
export const receiveInfoSuccess = (data) => ({
  type: RECEIVE_INFO_SUCCESS,
  payload: data
});

export const RECEIVE_INFO_FAILURE = 'RECEIVE_INFO_FAILURE';
export const receiveInfoFailure = (msg) => ({
  type: RECEIVE_INFO_FAILURE,
  msg
});

export function fetchInfo(id, token) {
  return (dispatch) => {
    dispatch(requestInfo());

    return DashService.info(id, token)
      .then((res) => {
        if (res.returnCode === 0) {
          dispatch(receiveInfoSuccess(res));
        } else {
          dispatch(receiveInfoFailure(res.msg));
        }

        return res;
      })
      .catch((res) => dispatch(receiveInfoFailure(res.msg)));
  };
}
