import { getBaseUrl } from '../config/config';
import {HttpMethods, httpService } from './global';


export default class CreateShipment {
 

  static async fetchMyShippers(forwarder_id, email, token) {
    const url = `forwarder/create-shipment/fetch-my-shippers?forwarder_id=${forwarder_id}&email=${email}`;
       return httpService(HttpMethods.GET, url, null, {
         token,
         type: 'shipments',
         method: 'create',
         platform: 'Forwarder'
       })
         .then((res) => {
           return res;
         })
         .catch((err) => console.log(err));

  }

  static async fetch_rate_sheet(data, token) {
    const url = `forwarder/create-shipment/fetch-rate-sheet`;
     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'shipments',
       method: 'create',
       platform: 'Forwarder'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));

  }

  static async create_shippment_forwarder(data, token) {
    const url = `forwarder/create-shipment/create-shippment`;
      return httpService(HttpMethods.POST, url, data, {
        token,
        type: 'shipments',
        method: 'create',
        platform: 'Forwarder'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

  static async update_shippment_doc_forwarder(id, data, token) {
    const url = `forwarder/create-shipment/update-shippment-document?id=${id}`;
      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'shipments',
        method: 'create',
        platform: 'Forwarder'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

  static async create_custom_rate_sheet(data, token) {
    const url = `forwarder/create-shipment/create-custom-rate-sheet`;
      return httpService(HttpMethods.POST, url, data, {
        token,
        type: 'shipments',
        method: 'create',
        platform: 'Forwarder'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }
}
