import React from 'react'
import Style from "./ServiceBadge.module.css"


// this return the color according to the title 
const getColor = (title) => {
    if (title === 'Packaging' || title === 'Documentation and Paperwork') return '#FCDB66';
    else if (title === 'Custom brokerage' || title === 'Labelling') return '#3c44b1';
    else if (title === 'Inspection') return '#F57666';
    else if (title === 'Insurance') return '#5BCDCB';
    else return '#3c44b1';
}

const ServiceBadge = ({ title }) => {

    const color = getColor(title);

    const dynamicStyles = {
        backgroundColor: `${color}1A`,
        borderColor: color,
        color: color,
    };

    return (
        <div className={`${Style.container}`} style={dynamicStyles}>
            {title}
        </div>
    )
}

export default ServiceBadge