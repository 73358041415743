import React, { useState, useEffect } from 'react'
import ShipmentsService from '../../../../../service/ShipmentsService'
import LoaderComponent from 'sites/shipper/components/LoaderComponent'
import AmountInfoCard from './AmountInfoCard/AmountInfoCard'
import { useSelector } from 'react-redux'
import AmountBreakdownCard from './AmountBreakdownCard/AmountBreakdownCard'
import ServicesBreakDownCard from './ServicesBreakDownCard/ServicesBreakDownCard'
import AddonAmountBreakDown from './AddonAmountBreakDown/AddonAmountBreakDown'
import SkeletonComponent from '../../../components/Skeleton/Skeleton'

const ShipmentPayments = ({ shipment_id }) => {
  const auth = useSelector((state) => state.session.auth)
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    const data_to_send = {
      shipper_id: auth.accountId,
      shipment_id: shipment_id
    }
    ShipmentsService.fetchShipmentPayments(data_to_send, auth.token).then(res => {
      if (res.error) {
        console.log(res.error)
      } else {
        setPaymentInfo(res)
      }
      setLoading(false)
    })
  }, [])






  return (
    <>
      {/* {loading && <LoaderComponent loading={loading} />} */}
      <div className='bg-white p-3' style={{ borderRadius: "6px", minHeight: "60vh" }}>
        {loading && <SkeletonComponent instances={1} height='150px' />}
        {loading && <SkeletonComponent instances={1} height='350px' />}

        {!loading &&
          <>
            <AmountInfoCard amount_info={paymentInfo} />
            <AmountBreakdownCard data={paymentInfo} />
          </>
        }
      </div>
    </>
  )
}

export default ShipmentPayments