import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class DashboardService {
  static async fetchShipmentReport(id, token,year) {
    const url = `${getBaseUrl()}forwarder/fetch-shipment-report/${id}?year=${year}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'read',
        platform:"Forwarder"
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchShipmentOverview(id, token) {
    const url = `${getBaseUrl()}forwarder/fetch-shipment-overview/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'read',
        platform: 'Forwarder'
      }
    });
    const response = await result.json();
    return response;
  }
}
