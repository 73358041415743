import React from 'react'
import Style from "./ImportantNote.module.css";
import { Info } from '@material-ui/icons';

const ImportantNote = ({ note }) => {
    return (

        <div className={Style.main}>

            <Info style={{ backgroundColor: '#fff', color: 'var(--primary)' }} />

            <div className={Style.note}>
                {note}
            </div>
        </div>

    )
}

export default ImportantNote