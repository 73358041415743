const searchTripColumns = [
  { data: 'tripName', name: '', searchable: false, orderable: true, search: { value: '', regex: false } },
  { data: 'tripDate', name: '', searchable: false, orderable: true, search: { value: '', regex: false } },
  { data: 'status', name: '', searchable: false, orderable: false, search: { value: '', regex: false } },
  { data: 'createdBy', name: '', searchable: false, orderable: false, search: { value: '', regex: false } },
  { data: 'id', name: '', searchable: false, orderable: false, search: { value: '', regex: false } }
];

const sharedTripColumns = [
  {
    data: 'tripName',
    name: '',
    searchable: false,
    orderable: true,
    search: {
      value: '',
      regex: false
    }
  },
  {
    data: 'tripDate',
    name: '',
    searchable: false,
    orderable: true,
    search: {
      value: '',
      regex: false
    }
  },
  {
    data: 'status',
    name: '',
    searchable: false,
    orderable: false,
    search: {
      value: '',
      regex: false
    }
  },
  {
    data: 'createdBy',
    name: '',
    searchable: false,
    orderable: false,
    search: {
      value: '',
      regex: false
    }
  },
  {
    data: 'company',
    name: '',
    searchable: false,
    orderable: false,
    search: {
      value: '',
      regex: false
    }
  },
  {
    data: 'accessLevel',
    name: '',
    searchable: false,
    orderable: false,
    search: {
      value: '',
      regex: false
    }
  },
  {
    data: 'id',
    name: '',
    searchable: false,
    orderable: false,
    search: {
      value: '',
      regex: false
    }
  }
];

export default class DashboardModel {
  static createSearchTripObj = (
    allTrips,
    draw,
    length,
    status,
    filterDate,
    keyword,
    dir,
    column,
    start = 0,
    cmpyId = null
  ) => {
    return {
      allTrips,
      filterDate,
      start,
      status,
      draw,
      length,
      keyword,
      columns: searchTripColumns,
      order: [{ column, dir }],
      search: { value: '', regex: false },
      cmpyId: cmpyId
    };
  };

  static createSharedTripObj = (columns = sharedTripColumns, draw, length, search, start, dir, column, allTrips, status, filterDate) => {
    return {
      start,
      draw,
      length,
      columns,
      order: [{ column, dir }],
      search: { value: search, regex: false },
      allTrips,
      status,
      filterDate
    };
  };

  static createTripObj = (inTripId, name, cmpyid, startTime, endTime, tripTags) => {
    return {
      inTripId: inTripId,
      inTripName: name,
      inTripAgency: cmpyid,
      inStartDate: startTime,
      inEndDate: endTime,
      inTripTag: tripTags && tripTags !== '' ? tripTags : null
    };
  };

  static tripObj = tripState => {
    return {
      tripName: tripState.tripName ? tripState.tripName : null,
      startDate: tripState.startDate ? tripState.startDate : null,
      endDate: tripState.endDate ? tripState.endDate : null,
      inTripId: tripState.inTripId ? tripState.inTripId : null,
      tripTags: tripState.tripTags ? tripState.tripTags : null
    };
  };

  static collaboratorObj = objCollab => {
    const collabUser = objCollab.user ? objCollab.user : null;
    const userInvite = objCollab.userInvite ? objCollab.userInvite : null;
    const collabOrInvite = collabUser ? collabUser : userInvite;
    const collaborator = collabOrInvite ? collabOrInvite : objCollab;

    return {
      userId: collaborator.userId ? collaborator.userId : collaborator.userid ? collaborator.userid : null,
      firstName: collaborator.firstName
        ? collaborator.firstName
        : collaborator.firstname
        ? collaborator.firstname
        : null,
      lastName: collaborator.lastName ? collaborator.lastName : collaborator.lastname ? collaborator.lastname : null,
      fullName: collaborator.fullName
        ? collaborator.fullName
        : collaborator.firstName && collaborator.lastName
        ? collaborator.firstName + ' ' + collaborator.lastName
        : collaborator.firstname && collaborator.lastname
        ? collaborator.firstname + ' ' + collaborator.lastname
        : collaborator.firstName
        ? collaborator.firstName
        : collaborator.firstname
        ? collaborator.firstname
        : null,

      email: collaborator.email ? collaborator.email : null,
      accessLevel:
        collabUser || userInvite ? objCollab.accesslevel : collaborator.accessLevel ? collaborator.accessLevel : null,
      note: collabUser || userInvite ? objCollab.note : collaborator.note ? collaborator.note : '',
      profilePhoto: collaborator.profilePhoto
        ? collaborator.profilePhoto
        : collaborator.profilephoto
        ? collaborator.profilephoto
        : null,
      profilePhotoUrl: collaborator.profilePhotoUrl
        ? collaborator.profilePhotoUrl
        : collaborator.profilephotourl
        ? collaborator.profilephotourl
        : null,
      inInviteId: userInvite ? objCollab.pk : collaborator.inInviteId ? collaborator.inInviteId : null,
      status: collabUser || userInvite ? objCollab.status : collaborator.status ? collaborator.status : null,
      emailLogs: []
    };
  };

  static createCollaboratorObj = collaborator => {
    let collaboratorObj = {};

    if (collaborator.userId) {
      collaboratorObj.inShareUserId = collaborator.userId;
    }
    if (collaborator.firstName) {
      collaboratorObj.inFirstName = collaborator.firstName;
    }
    if (collaborator.lastName) {
      collaboratorObj.inLastName = collaborator.lastName;
    }
    if (collaborator.email) {
      collaboratorObj.inEmail = collaborator.email;
    }
    if (collaborator.accessLevel) {
      collaboratorObj.inAccessLevel = collaborator.accessLevel;
    }
    if (collaborator.userId) {
      collaboratorObj.inShareNote = collaborator.note;
    } else {
      collaboratorObj.inNote = collaborator.note;
      collaboratorObj.inInviteNote = collaborator.note;
    }

    if (collaborator.inInviteId) {
      collaboratorObj.inInviteId = collaborator.inInviteId;
    }

    return collaboratorObj;
  };

  static createTravelerObj = traveler => {
    let travelerObj = {};

    if (traveler.email) {
      travelerObj.inEmail = traveler.email;
    }

    if (traveler.firstName) {
      travelerObj.inFirstName = traveler.firstName;
    }

    if (traveler.lastName) {
      travelerObj.inLastName = traveler.lastName;
    }

    if (traveler.mobile) {
      travelerObj.inMSISDN = traveler.mobile;
    }
    if (traveler.inPassengerId) {
      travelerObj.inPassengerId = traveler.inPassengerId;
    }

    return travelerObj;
  };

  static travelerObj = traveler => {
    return {
      email: traveler.email ? traveler.email : null,
      firstName: traveler.firstName ? traveler.firstName : null,
      lastName: traveler.lastName ? traveler.lastName : null,
      mobile: traveler.msisdn ? traveler.msisdn : traveler.mobile ? traveler.mobile : null,
      name: traveler.name
        ? traveler.name
        : traveler.firstName || traveler.lastName
        ? (traveler.firstName ? traveler.firstName : '') +
          (traveler.lastName ? (traveler.firstName ? ' ' : '') + traveler.lastName : '')
        : null,
      inPassengerId: traveler.id ? traveler.id : null,
      isUmappedUser: traveler.isUmappedUser ? traveler.isUmappedUser : false,
      emailLogs: []
    };
  };

  static activityTripLog = activity => {
    return {
      agentName: activity.agentName ? activity.agentName : null,
      dateTime: activity.time ? activity.time : null,
      action: activity.action ? activity.action : null,
      description: activity.description ? activity.description : null,
      reviewUrl: activity.reviewUrl ? activity.reviewUrl : null
    };
  };

  static coBrandingsObj = branding => {
    return {
      cmpyId: branding.cmpyId,
      cmpyLogoUrl: branding.cmpyLogoUrl,
      cmpyName: branding.cmpyName,
      isEnabled: branding.isEnabled,
      isMainBrand: branding.isMainBrand,
      cmpyLogoName: branding.cmpyLogoName
    };
  };
}
