import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Dialog, DialogContent } from '@material-ui/core';
import Style from "./ShipmentActivity.module.css";

import PerfectScrollbar from 'react-perfect-scrollbar';

import avatar1 from 'assets/images/avatars/avatar1.jpg';
import avatar2 from 'assets/images/avatars/avatar2.jpg';
import avatar3 from 'assets/images/avatars/avatar3.jpg';
import avatar4 from 'assets/images/avatars/avatar4.jpg';
import avatar5 from 'assets/images/avatars/avatar5.jpg';
import avatar6 from 'assets/images/avatars/avatar6.jpg';
import avatarAdmin from 'assets/images/avatars/admin-icon-light.svg';
import { withStyles } from '@material-ui/styles';
import ActivityCard from './ActivityCard/ActivityCard';
import { Pagination } from '@mui/material';
import RequestChangePopup from './RequestChangePopup/RequestChangePopup';
import { Empty } from 'antd';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import CancelShipmentPopup from './CancelShipmentPopup/CancelShipmentPopup';
import { ForwarderService } from 'service';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import { useDispatch, useSelector } from 'react-redux';
import Rating from '@mui/material/Rating';
import { notify } from 'sites/shipper/components/Toast/toast';
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import OutlineFieldSimple from 'components/Common/OutlineFieldSimple/OutlineFieldSimple';
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';
const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  }
});


const ShipmentActivity = ({ classes, booking, isMobile = false, shipmentId, refresh, setRefresh }) => {

  const auth = useSelector((state) => state.session.auth)
  const [shipmentStatus, setShipmentStatus] = useState()
  const dispatch = useDispatch()

  const [isReviewGiven, setIsReviewGiven] = useState(false)
  const [exstingReview, setExistingReview] = useState()
  const [requestChangeData, setRequestChangeData] = useState()

  useEffect(() => {
    ForwarderService.fetchShipmentStatus(shipmentId).then(data => {
      if (data.error) { console.log(data.error) }
      else { setShipmentStatus(data.status) }
    })

    ForwarderService.checkReviewGiven(shipmentId, auth.token).then(data => {
      setIsReviewGiven(data.success)
      if (data.success) {
        setExistingReview(data.result)
      }
    })

    ForwarderService.fetchRequestCreated(shipmentId, auth.token).then(res => {
      if (res.success) {
        setRequestChangeData(res.data)
      } else {
        setRequestChangeData(null)
      }
    })

  }, [refresh])



  const [confirmDialogOpen1, setConfirmDialogOpen1] = useState(false)
  const [confirmDialogOpen2, setConfirmDialogOpen2] = useState(false)
  const [amount, setAmount] = useState()
  const [error, setError] = useState()


  const handleRejectRequest = () => {
    const data_to_send = {
      shipment_id: shipmentId,
      requestDate: requestChangeData.requested_on,
      forwarder_id: auth.accountId
    }
    ForwarderService.rejectRequestCreated(data_to_send, auth.token).then(res => {
      if (res.error) {
        console.log(error)
      } else {
        notify(dispatch, "success", res.message)
        setRefresh(!refresh)
      }
    })
  }

  const handleAcceptRequest = () => {
    let data_to_send = {}
    if (requestChangeData.port.isRequested) {
      if (!amount) {
        setError("Enter Amount : If you do not want to charge enter 0")
        return
      }
      data_to_send = {
        shipment_id: shipmentId,
        requestDate: requestChangeData.requested_on,
        forwarder_id: auth.accountId,
        amount: amount
      }
    } else {
      data_to_send = {
        shipment_id: shipmentId,
        requestDate: requestChangeData.requested_on,
        forwarder_id: auth.accountId
      }
    }
    ForwarderService.sendProposalOnRequestChange(data_to_send, auth.token).then(res => {
      if (res.error) {
        console.log(error)
      } else {
        notify(dispatch, "success", res.message)
        setRefresh(!refresh)
      }
    })
  }

  return (
    <div className={classes.root}>

      {/* {shipmentStatus === "Incomplete" &&
        <div className='d-flex justify-content-end position-relative'>
          <Button
            className={`${Style.request_change_btn} btn-primary px-3 py-2 font-size-xs`}
            // onClick={toggleRequestpopup}
            onClick={() => setOpenModal(true)}
          >
            Cancel Shipment
          </Button>
        </div>
      } */}






      {requestChangeData && <>
        <div className=''>
          <div className={`bg-neutral-dark d-flex justify-content-between mb-4 p-3`}>
            <div className={`d-flex justify-content-between align-items-center w-100`}>
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                Request Change
              </h6>
              <div>
                {requestChangeData.status === "Requested" &&
                  <div className={`d-flex c-gap-1`}>
                    <Button
                      className={`${Style.request_change_btn} btn-primary px-3 py-2 font-size-xs`}
                      onClick={() => setConfirmDialogOpen1(true)}
                    >
                      Reject Request
                    </Button>
                    <Button
                      className={`${Style.request_change_btn} btn-primary px-3 py-2 font-size-xs`}
                      onClick={() => setConfirmDialogOpen2(true)}
                    >
                      Accept Request
                    </Button>
                  </div>
                }
                {requestChangeData.status === "Proposal Sent" &&
                  <span className='text-primary font-size-xs'>
                    Shipper has not accepted the proposal
                  </span>
                }
                {requestChangeData.status === "Accepted" &&
                  <span className='text-danger font-size-xs'>
                    Ask Shiper to pay the due amount
                  </span>
                }
              </div>
            </div>
            <div className="d-flex align-items-center"></div>
          </div>
          <div className={Style.rating}>
            {requestChangeData.services_requested.length > 0 &&
              requestChangeData.services_requested.map((item, index) => (
                <React.Fragment key={index}>
                  <span className={Style.rating_label}>{item.label}:</span>
                  <span className={`${Style.rating_value}`}>{item.amount.currency}{' '}{item.amount.amount}</span>
                </React.Fragment>
              ))
            }
          </div>
          {requestChangeData.port.isRequested &&
            <span className={Style.rating}>
              <span className={Style.rating_label}>Port change to:</span>

              <span className={`font-weight-bold ${Style.rating_value}`}>
                {requestChangeData?.port?.port?.address_location?.label}, {requestChangeData?.port.port?.address_country?.label}
                {requestChangeData.status !== "Requested" &&
                  <>
                    {requestChangeData.port.amount.value !== 0 && <> : {requestChangeData.port.amount.value}</>}
                  </>
                }
              </span >
              {requestChangeData.status === "Requested" &&
                <OutlineFieldSimple placeholder='Enter Amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
              }

              {error && <span style={{ fontSize: "12px", color: "red" }}>{error}</span>}
            </span>
          }

        </div>
      </>
      }

















      {isReviewGiven && exstingReview && <>
        <div className=''>
          <div className={`bg-neutral-dark d-flex justify-content-between mb-4 p-3`}>
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                Shipment Rating & Review
              </h6>
            </div>
            <div className="d-flex align-items-center"></div>
          </div>

          <div className={Style.rating}>

            <span className={Style.rating_label}>Over all rating:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.over_all}
              <Rating readOnly size='small' value={exstingReview?.rating?.over_all} />
            </span>

            <span className={Style.rating_label}>Handling Cargo:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.handling_cargo}
              <Rating readOnly size='small' value={exstingReview?.rating?.handling_cargo} />
            </span>

            <span className={Style.rating_label}>Would Recommanded:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.would_recommended}
              <Rating readOnly size='small' value={exstingReview?.rating?.would_recommended} />
            </span>

            <span className={Style.rating_label}>Communication:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.communication}
              <Rating readOnly size='small' value={exstingReview?.rating?.communication} />
            </span>

          </div>
          <div className={`d-flex flex-column mt-3`}>
            <div className={Style.review_box}>
              {exstingReview?.review}
            </div>
          </div>
        </div>
      </>
      }









      <div className={`bg-neutral-dark d-flex justify-content-between p-3 ${isMobile ? 'mt-2' : 'mt-4'}`}>
        <div>
          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
            Shipment Activity Feed
          </h6>
        </div>
        <div className="d-flex align-items-center"></div>
      </div>

      <div className="divider my-4" />

      <div className="bg-white ">
        {booking.length > 0 ?
          <>
            {booking.map((data) => (
              <ActivityCard data={data} />
            ))}
          </>
          :
          // <Empty />
          <EmptyContainer 
              title='No Activities Found'
          />
        }
      </div>


      {/* <Dialog
        fullWidth
        maxWidth="xl"
        open={openModal}
        onClose={(e) => e.preventDefault()}
        //   TransitionComponent={Transition}
        style={{ zIndex: '12' }}
      >
        <DialogContent className="p-4 mt-4">
          <CancelShipmentPopup
            handleClose={() => setOpenModal(false)}
            shipmentId={shipmentId}
            run={run}
            setRun={setRun}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </DialogContent>
      </Dialog> */}
      {/* <ModalPopupWrapper
        maxWidth="lg"
        open={openModal}
      >
        <CancelShipmentPopup
          handleClose={() => setOpenModal(false)}
          shipmentId={shipmentId}
          run={run}
          setRun={setRun}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </ModalPopupWrapper> */}




      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen1}
        setConfirmDialogOpen={setConfirmDialogOpen1}
        confirmDialogFunction={handleRejectRequest}
        question={`Are you sure you want to reject this request`}
      />

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen2}
        setConfirmDialogOpen={setConfirmDialogOpen2}
        confirmDialogFunction={handleAcceptRequest}
        question={`Are you sure you want to accept this request. You will not be able to edit it again`}
      />




    </div>
  );
};

ShipmentActivity.defaultProps = {};

ShipmentActivity.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentActivity);
