import React from 'react'
import Style from './ErrorPopUp.module.css'
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading'
import { Button, IconButton } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import ModalSimple from 'components/ModalSimple'
const ErrorPopUp = ({ open, error, onSubmit, onCancel }) => {



    return (
        <>
            <ModalSimple
                modalTitle={"Service Not Activated"}
                open={open}
                action={onSubmit}
                modalDescription={error}
                maxWidth={"sm"}
                handleClose={onCancel}
                actionButtonText={"Activate Now"}
            >

            </ModalSimple>
        </>
    )
}

export default ErrorPopUp